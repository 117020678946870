import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toast";
import MasterServices from "../../../ApiServices/MasterServices";
import Breadcrumb from "../../../components/Breadcrumb";
import AddVendors from "./AddVendors";
import Contact_list from "./Contact_list";
import Vendor_items from "./Vendor_items";

const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    name: "",
    stability: "",
  },
};

function VendorDetail() {
  const pages = [{ title: "Vendors List", href: "/vendors" }];
  const [loading, setLoading] = useState(false);
  const [testList, setTestList] = useState([]);
  const [vendorData, setVendorData] = useState({});
  const { vendor_id : vId } = useParams();
  const [modal, setModal] = useState(initialModalState);

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Tabib | Vendor List";
    getVendorById(vId);
  }, [vId]);
  

  
  const getVendorById = async (id) => {
    setLoading(true);
    await MasterServices.getOneVendor(id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setVendorData(data.data);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const initialtabs = [
    // {
    //   name: "QC Information",
    //   indicator: "qc_information",
    //   href: "#",
    //   current: true,
    // },
    { name: "Vendor Information", indicator: "vendor_info", current: true },
    {
      name: "Vendor Items",
      indicator: "vendor_items",

      current: false,
    },
    { name: "Contact List", indicator: "contact_list",  current: false },
  ];

  const [tabs, setTabs] = useState(initialtabs);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleTabsChange = (tab) => {
    const newTabs = tabs.map((t) => {
      if (t.name === tab.name) {
        return { ...t, current: true };
      } else {
        return { ...t, current: false };
      }
    });
    console.log({ newTabs });
    setTabs(newTabs);
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {/* {renderModal()} */}
      <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
      >
          <span className="w-5 mr-2"><ArrowUturnLeftIcon size={18} /></span> Back
      </button>
      
      <h1 className="text-xl font-semibold text-gray-900">
            {vId ? "Update Vendor" : "Add Vendor"}
          </h1>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>

          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-cyan-500 focus:outline-none focus:ring-cyan-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name} onClick={() => handleTabsChange(tab)}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tab.current
                      ? "border-cyan-500 text-cyan-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                  onClick={() => handleTabsChange(tab)}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {console.log(
        tabs.find((x) => x.indicator === "vendor_info" && x.current === true)
      )}
      <div className="mt-5">
        {tabs.find((x) => x.indicator === "vendor_items" && x.current === true) && (
          <Vendor_items />
        )}

        {tabs.find(
          (x) => x.indicator === "contact_list" && x.current === true
        ) && <Contact_list />}

        {tabs.find((x) => x.indicator === "vendor_info" && x.current === true) && (
          <AddVendors />
        )}
      </div>
    </div>
  );
}
export default VendorDetail;
