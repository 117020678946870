import React, { useState, useEffect, } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { res_user_report_columns } from '../../../components/tables/tableheader';
import MasterServices from '../../../ApiServices/MasterServices'
import moment from 'moment';
import Select from 'react-select'
import ReservationServices from '../../../ApiServices/ReservationServices';
import { toast } from 'react-toast';
import { utils, writeFile } from 'xlsx';
import { capitalise } from '../../../utilities/utilities';
import { convertToAnotherTimezone } from '../../../helper/commonHelper';


let filterInitialValues = {
    // filterFrom: "",
    // filterTo: "",
    // filterStatus: "",
    // filterHos: "",
    // filterBkType: "",
    // filterOrg: "",
    // filterSampleId: "",
    // filterPatientMob: "",
    // filterPatientIdentification: "",
    // filterPatientName: "",
    // filterPatientPassport: "",
    // filterTestType: "",
    // filterPatientId: "",
    // filterReservationId: "",
    // filterMrn: "",
  };

export default function ReservationUserReport() {

    const pages = [{ title: "User Report", href: "/res-patient-report" }];
    const [isLoading, setIsLoading] = useState(true)
    const [resUserReportData, setResUserReportData] = useState([])
    const [resUserReportFilters, setResUserReportFilters] = useState({
        start_date: "",
        end_date: "",
        users: [],
    })
    const [selectedUsers, setSelectedUsers] = useState(false)
    const [userLoading, setUserLoading] = useState(true)
    const [userList, setUserList] = useState(false);

    useEffect(() => {
        getAllUsers()
        getResUserReport();
    }, [])

    const getAllUsers = async () => {
        try {
            setUserLoading(true)
            const { data } = await ReservationServices.getAllUserList();
            if (data.status) {
                let newUsers = data.data;
                let users = newUsers.map((el) => {
                    let obj = { ...el, label: el.user_first_name, value: el.user_id }
                    return obj
                })
                setUserList(users);
            }
            setUserLoading(false)
        } catch (error) {
            console.log(error);
            setUserLoading(false)
        }
    };

    async function getResUserReport() {
        // console.log(resUserReportFilters)
        setIsLoading(true)
        try {
            let payload = {
                start_date: resUserReportFilters.start_date || '1949-05-26',
                end_date: resUserReportFilters.end_date || (moment().add(1,'days').format('YYYY-MM-DD')),
                users: resUserReportFilters.users,
            }
            const { data, status } = await ReservationServices.getAllPatientsLogs(payload);
            if (status === 200) {
                setResUserReportData(data.data)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const handleMultiUsers = (event) => {
        setSelectedUsers(event)
        let users = [];
        for (let i = 0; i < event.length; i++) {
            users.push(event[i].user_id)
        }
        setResUserReportFilters((prev) => {
            return {
                ...prev,
                users,
            }
        })
    }

    const generateObjectToPdf = async () => {
        // setIsLoading(true)
        try {

            console.log("resUserReportData", resUserReportData)
            let sortedData = resUserReportData.map(object => {
                
                //Before Update
                let beforeUpdate = [];
                if (object.rpl_old_data.patient_first_name && object.rpl_update_keys != '' && object.rpl_update_keys.includes('Name')) {
                  beforeUpdate.push(object.rpl_old_data.patient_first_name)
                }
                if (object.rpl_old_data.patient_email && object.rpl_update_keys != '' && object.rpl_update_keys.includes('email')) {
                  beforeUpdate.push(object.rpl_old_data.patient_email)
                }
                if (object.rpl_old_data.relationship && object.rpl_update_keys != '' && object.rpl_update_keys.includes('Relationship')) {
                  beforeUpdate.push(object.rpl_old_data.relationship)
                }
                if (object.rpl_old_data.patient_mobile && object.rpl_update_keys != '' && object.rpl_update_keys.includes('mobile')) {
                  beforeUpdate.push(object.rpl_old_data.patient_mobile)
                }
                if (object.rpl_old_data.patient_dob && object.rpl_update_keys != '' && object.rpl_update_keys.includes('dob')) {
                  beforeUpdate.push(object.rpl_old_data.patient_dob)
                }
                if (object.rpl_old_data.patient_passport_no && object.rpl_update_keys != '' && object.rpl_update_keys.includes('passport')) {
                  beforeUpdate.push(object.rpl_old_data.patient_passport_no)
                }
                if (object.rpl_old_data.patient_reasion_test && object.rpl_update_keys != '' && object.rpl_update_keys.includes('reasonfortest')) {
                  beforeUpdate.push(object.rpl_old_data.patient_reasion_test)
                }
                if (object.rpl_old_data.patient_ref_mrn_no && object.rpl_update_keys != '' && object.rpl_update_keys.includes('mrnno')) {
                  beforeUpdate.push(object.rpl_old_data.patient_ref_mrn_no)
                }
                if (object.rpl_old_data?.m_gender_relation?.gender_name_en && object.rpl_update_keys != '' && object.rpl_update_keys.includes('gender')) {
                  beforeUpdate.push(object.rpl_old_data?.m_gender_relation?.gender_name_en)
                }
                if (object?.rpl_old_data?.m_country_relation?.country_name && object.rpl_update_keys != '' && object.rpl_update_keys.includes('country')) {
                  beforeUpdate.push(object?.rpl_old_data?.m_country_relation?.country_name)
                }
    
                //After Update
                let afterUpdate = [];
                if (object.rpl_new_data.patient_first_name && object.rpl_update_keys != '' && object.rpl_update_keys.includes('Name')) {
                  afterUpdate.push(object.rpl_new_data.patient_first_name)
                }
                if (object.rpl_new_data.patient_email && object.rpl_update_keys != '' && object.rpl_update_keys.includes('email')) {
                  afterUpdate.push(object.rpl_new_data.patient_email)
                }
                if (object.rpl_new_data.relationship && object.rpl_update_keys != '' && object.rpl_update_keys.includes('Relationship')) {
                  afterUpdate.push(object.rpl_new_data.relationship)
                }
                if (object.rpl_new_data.patient_mobile && object.rpl_update_keys != '' && object.rpl_update_keys.includes('mobile')) {
                  afterUpdate.push(object.rpl_new_data.patient_mobile)
                }
                if (object.rpl_new_data.patient_dob && object.rpl_update_keys != '' && object.rpl_update_keys.includes('dob')) {
                  afterUpdate.push(object.rpl_new_data.patient_dob)
                }
                if (object.rpl_new_data.patient_passport_no && object.rpl_update_keys != '' && object.rpl_update_keys.includes('passport')) {
                  afterUpdate.push(object.rpl_new_data.patient_passport_no)
                }
                if (object.rpl_new_data.patient_reasion_test && object.rpl_update_keys != '' && object.rpl_update_keys.includes('reasonfortest')) {
                  afterUpdate.push(object.rpl_new_data.patient_reasion_test)
                }
                if (object.rpl_new_data.patient_ref_mrn_no && object.rpl_update_keys != '' && object.rpl_update_keys.includes('mrnno')) {
                  afterUpdate.push(object.rpl_new_data.patient_ref_mrn_no)
                }
                if (object.rpl_new_data?.m_gender_relation?.gender_name_en && object.rpl_update_keys != '' && object.rpl_update_keys.includes('gender')) {
                  afterUpdate.push(object.rpl_new_data?.m_gender_relation?.gender_name_en)
                }
                if (object?.rpl_new_data?.m_country_relation?.country_name && object.rpl_update_keys != '' && object.rpl_update_keys.includes('country')) {
                  afterUpdate.push(object?.rpl_new_data?.m_country_relation?.country_name)
                }
    
                return {
                    ...object,
                    beforeUpdate: beforeUpdate.toString(),
                    afterUpdate: afterUpdate.toString()
                }
    
            })
            console.log(sortedData)
            let newSheetdata = [];
            if (sortedData?.length > 0) {
                sortedData.map((obj, index) => {
                    const newData = {
                        SrNo: index+1,
                        ReservationId: obj.rpl_reservation_id || '',
                        PatientName: obj.rpl_patient_name || '',
                        UpdatedBy: obj?.rpl_uu_relation?.user_first_name || '',
                        UpdatedTypes: obj.rpl_update_keys || '',
                        BeforeUpdate: obj.beforeUpdate || '',
                        AfterUpdate: obj.afterUpdate || '',
                        UpdatedTime: moment(convertToAnotherTimezone(obj.rpl_created_at)).format("do-MMM-YY hh:mm:ss") || ''
                    }
                    newSheetdata.push(newData);
                })
            }

            let payload = {
                    columns: ['SrNo', 'Reservation Id', 'Patient Name', 'Updated By', 'Updated Types', 'Before Update', 'After Update', 'Updated Time'],
                    pdf_name: 'Patient_Report_From_' + moment(resUserReportFilters.start_date || '1949-05-26').format("DD-MM-YYYY") + "_To_" + moment(resUserReportFilters.end_date || (moment().add(1,'days').format('YYYY-MM-DD'))).format("DD-MM-YYYY"),
                    rows: newSheetdata
        }
            const { data, status } = await MasterServices.generateObjectToPDF(payload);
            console.log(data)
            if (status === 200) {
                if(data?.data?.pdf_url != '') {
                    // window.location.replace(data.pdf_url);
                    window.open(data.data.pdf_url, '__blank');
                }
                    // setResUserReportData(data.data)
            }
            // setIsLoading(false)
        } catch (error) {
            console.log(error)
            // setIsLoading(false)
        }
    }

    const handleExport = (excel_type) => {
        console.log("resUserReportData", resUserReportData)
        let sortedData = resUserReportData.map(object => {
            
            //Before Update
            let beforeUpdate = [];
            if (object.rpl_old_data.patient_first_name && object.rpl_update_keys != '' && object.rpl_update_keys.includes('Name')) {
              beforeUpdate.push(object.rpl_old_data.patient_first_name)
            }
            if (object.rpl_old_data.patient_email && object.rpl_update_keys != '' && object.rpl_update_keys.includes('email')) {
              beforeUpdate.push(object.rpl_old_data.patient_email)
            }
            if (object.rpl_old_data.relationship && object.rpl_update_keys != '' && object.rpl_update_keys.includes('Relationship')) {
              beforeUpdate.push(object.rpl_old_data.relationship)
            }
            if (object.rpl_old_data.patient_mobile && object.rpl_update_keys != '' && object.rpl_update_keys.includes('mobile')) {
              beforeUpdate.push(object.rpl_old_data.patient_mobile)
            }
            if (object.rpl_old_data.patient_dob && object.rpl_update_keys != '' && object.rpl_update_keys.includes('dob')) {
              beforeUpdate.push(object.rpl_old_data.patient_dob)
            }
            if (object.rpl_old_data.patient_passport_no && object.rpl_update_keys != '' && object.rpl_update_keys.includes('passport')) {
              beforeUpdate.push(object.rpl_old_data.patient_passport_no)
            }
            if (object.rpl_old_data.patient_reasion_test && object.rpl_update_keys != '' && object.rpl_update_keys.includes('reasonfortest')) {
              beforeUpdate.push(object.rpl_old_data.patient_reasion_test)
            }
            if (object.rpl_old_data.patient_ref_mrn_no && object.rpl_update_keys != '' && object.rpl_update_keys.includes('mrnno')) {
              beforeUpdate.push(object.rpl_old_data.patient_ref_mrn_no)
            }
            if (object.rpl_old_data?.m_gender_relation?.gender_name_en && object.rpl_update_keys != '' && object.rpl_update_keys.includes('gender')) {
              beforeUpdate.push(object.rpl_old_data?.m_gender_relation?.gender_name_en)
            }
            if (object?.rpl_old_data?.m_country_relation?.country_name && object.rpl_update_keys != '' && object.rpl_update_keys.includes('country')) {
              beforeUpdate.push(object?.rpl_old_data?.m_country_relation?.country_name)
            }

            //After Update
            let afterUpdate = [];
            if (object.rpl_new_data.patient_first_name && object.rpl_update_keys != '' && object.rpl_update_keys.includes('Name')) {
              afterUpdate.push(object.rpl_new_data.patient_first_name)
            }
            if (object.rpl_new_data.patient_email && object.rpl_update_keys != '' && object.rpl_update_keys.includes('email')) {
              afterUpdate.push(object.rpl_new_data.patient_email)
            }
            if (object.rpl_new_data.relationship && object.rpl_update_keys != '' && object.rpl_update_keys.includes('Relationship')) {
              afterUpdate.push(object.rpl_new_data.relationship)
            }
            if (object.rpl_new_data.patient_mobile && object.rpl_update_keys != '' && object.rpl_update_keys.includes('mobile')) {
              afterUpdate.push(object.rpl_new_data.patient_mobile)
            }
            if (object.rpl_new_data.patient_dob && object.rpl_update_keys != '' && object.rpl_update_keys.includes('dob')) {
              afterUpdate.push(object.rpl_new_data.patient_dob)
            }
            if (object.rpl_new_data.patient_passport_no && object.rpl_update_keys != '' && object.rpl_update_keys.includes('passport')) {
              afterUpdate.push(object.rpl_new_data.patient_passport_no)
            }
            if (object.rpl_new_data.patient_reasion_test && object.rpl_update_keys != '' && object.rpl_update_keys.includes('reasonfortest')) {
              afterUpdate.push(object.rpl_new_data.patient_reasion_test)
            }
            if (object.rpl_new_data.patient_ref_mrn_no && object.rpl_update_keys != '' && object.rpl_update_keys.includes('mrnno')) {
              afterUpdate.push(object.rpl_new_data.patient_ref_mrn_no)
            }
            if (object.rpl_new_data?.m_gender_relation?.gender_name_en && object.rpl_update_keys != '' && object.rpl_update_keys.includes('gender')) {
              afterUpdate.push(object.rpl_new_data?.m_gender_relation?.gender_name_en)
            }
            if (object?.rpl_new_data?.m_country_relation?.country_name && object.rpl_update_keys != '' && object.rpl_update_keys.includes('country')) {
              afterUpdate.push(object?.rpl_new_data?.m_country_relation?.country_name)
            }

            return {
                ...object,
                beforeUpdate: beforeUpdate.toString(),
                afterUpdate: afterUpdate.toString()
            }

        })
        console.log(sortedData)
        if (sortedData?.length > 0) {
            let newSheetdata = [];
            sortedData.map((obj, index) => {
                const newData = {
                    SrNo: index+1,
                    ReservationId: obj.rpl_reservation_id,
                    PatientName: obj.rpl_patient_name,
                    UpdatedBy: obj?.rpl_uu_relation?.user_first_name,
                    UpdatedTypes: obj.rpl_update_keys,
                    BeforeUpdate: obj.beforeUpdate,
                    AfterUpdate: obj.afterUpdate,
                    UpdatedTime: moment(convertToAnotherTimezone(obj.rpl_created_at)).format("do-MMM-YY hh:mm:ss")
                }
                newSheetdata.push(newData);
            })
            let fileName = 'Coupon_Report_From_' + moment(resUserReportFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(resUserReportFilters.end_date).format("DD-MM-YYYY") + '.' + excel_type;

            const headings = [['SrNo', 'Reservation Id', 'Patient Name', 'Updated By', 'Updated Types', 'Before Update', 'After Update', 'Updated Time']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, fileName);
        } else {
            toast.error("Data Not Found!");
        }
    }


    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">

                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">User Report</h1>
                </div>

                <div className="mt-5 flex gap-3 items-center">

                    {/* start date */}
                    <div className=" sm:mt-0">
                        <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date From</label>

                        <input
                            disabled={isLoading}
                            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                            type="date"
                            name="start_date"
                            id="start_date"
                            max={moment().format("YYYY-MM-DD")}
                            value={resUserReportFilters.start_date}
                            onChange={(e) => {
                              setResUserReportFilters((prev) => {
                                if (prev.end_date < e.target.value) {
                                    return {
                                        ...prev,
                                        end_date: '',
                                        start_date: e.target.value
                                    }
                                }
                                return {
                                    ...prev,
                                    start_date: e.target.value,
                                }
                            })
                            }
                            }
                        />
                    </div>

                    {/* end date */}
                    <div className=" sm:mt-0">
                        <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date To</label>

                        <input
                            disabled={isLoading}
                            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                            type="date"
                            name="end_date"
                            id="end_date"
                            max={moment().format("YYYY-MM-DD")}
                            value={resUserReportFilters.end_date}
                            onChange={(e) => {
                              setResUserReportFilters((prev) => {
                                if (prev.start_date > e.target.value) {
                                    return {
                                        ...prev,
                                        end_date: '',
                                        start_date: prev.start_date
                                    }
                                }
                                return {
                                    ...prev,
                                    end_date: e.target.value,
                                }
                            })
                            }
                            }
                        />
                    </div>

                    {
                        !userLoading ?
                            <div className="">
                                <label
                                    htmlFor="fk_location_ids"
                                    className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Users</label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                        // defaultValue={testList[0]}
                                        isMulti
                                        name="fk_user_ids"
                                        id="fk_user_ids"
                                        value={selectedUsers}
                                        onChange={(e) => handleMultiUsers(e)}
                                        options={userList}
                                        className="basic-multi-select"
                                        classNamePrefix="Select Test"
                                    />
                                </div>
                            </div>
                            : null

                    }


                    <button
                        disabled={isLoading}
                        onClick={() => {
                            getResUserReport()
                        }}
                        className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isLoading ? "opacity-40" : "opacity-100"}`}
                    >
                        Get Data
                    </button>
                </div>

                <div className='mt-5 flex gap-1 items-center'>
                  {/* <button
                    onClick={() => handleExport('copy')}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mr-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    Copy
                  </button> */}
                  <button
                    onClick={() => handleExport('csv')}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    CSV
                  </button>

                  <button
                    onClick={() => handleExport('xlsx')}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    Excel
                  </button>
                  <button
                    onClick={() => generateObjectToPdf()}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    PDF
                  </button>
                  {/* <button
                    onClick={() => handlePrint()}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    Print
                  </button> */}
                </div>
            </div>
            {/* {
                isLoading ? (
                    <FallingLinesLoader />
                ) : ((resUserReportData.length > 0) ? (
                    <Table
                        columns={res_user_report_columns()}
                        data={resUserReportData}
                    />
                ) : <TableEmpty />)
            } */}
            <Table
                zoom='zoom-065'
                columns={res_user_report_columns()}
                data={resUserReportData}
            />

        </>
    )

}