import React, { useState, useEffect, Fragment, } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { coupon_report_columns, all_logs_report_columns, urgent_logs_report_columns, report_pdf_generated_logs_columns } from '../../../components/tables/tableheader';
import MasterServices from '../../../ApiServices/MasterServices'
import moment from 'moment';
import Select from 'react-select'
import ReservationServices from '../../../ApiServices/ReservationServices';
import { toast } from 'react-toast';
import { utils, writeFile } from 'xlsx';
import { capitalise } from '../../../utilities/utilities';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { convertToAnotherTimezone } from '../../../helper/commonHelper';
import { Dialog, Transition } from "@headlessui/react";
import SampleServices from '../../../ApiServices/SampleServices';

const initialUrgentLogsFilters = {
    start_date: '',
    end_date: '',
    users: []
}

export default function UrgentLogs() {

    const pages = [{ title: "Report PDF Generated", href: "/report-pdf-generated" }];
    const [isLoading, setIsLoading] = useState(true)
    const [urgentLogsData, setUrgentLogsData] = useState([])
    const [urgentLogsFilters, setUrgentLogsFilters] = useState(initialUrgentLogsFilters)
    const [selectedUsers, setSelectedUsers] = useState(false)
    const [userLoading, setUserLoading] = useState(true)
    const [userList, setUserList] = useState(false);
    const printRef = React.useRef();

    const [selectedReportRes, setSelectedReportRes] = useState(false)
    const [openSendReport, setOpenSendReport] = useState(false)
    const [reportSmsNumber, setReportSmsNumber] = useState("")

    useEffect(() => {
        getAllUsers()
        getUrgentLogs();
    }, [])

    const getAllUsers = async () => {
        try {
            setUserLoading(true)
            const { data } = await ReservationServices.getAllPatientList();
            if (data.status) {
                let newUsers = data.data;
                let users = newUsers.map((el) => {
                    let obj = { ...el, label: el.patient_first_name, value: el.patient_id }
                    return obj
                })
                setUserList(users);
            }
            setUserLoading(false)
        } catch (error) {
            console.log(error);
            setUserLoading(false)
        }
    };

    async function getUrgentLogs() {
        setIsLoading(true)
        try {
            const { data, status } = await MasterServices.getReportGeneratedLogs();
            if (status === 200) {
                setUrgentLogsData(data.data)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    async function getUrgentLogsFilter(payload) {
        setIsLoading(true)
        try {
            if(!payload.start_date) {
                payload.start_date = moment(new Date()).subtract(30, "days").format("YYYY-MM-DD")   
            }
            if(!payload.end_date) {
                payload.end_date = moment(new Date()).format("YYYY-MM-DD")
            }             
            const { data, status } = await MasterServices.getReportGeneratedLogsFilter(payload);
            if (status === 200) {
                setUrgentLogsData(data.data)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const generateObjectToPdf = async () => {
        // setIsLoading(true)
        try {
            let newSheetdata = [];
            if (urgentLogsData?.length > 0) {
                urgentLogsData.map((obj, index) => {
                    const newData = {
                        patientName: obj?.t_sample_generated[0]?.m_patient_relation?.patient_first_name || '-',
                        phoneNo: obj?.t_sample_generated[0]?.m_patient_relation?.patient_mobile || '-',
                        date: moment(convertToAnotherTimezone(obj?.sample_collected)).format('DD-MM-YYYY') || '-',
                        referDoctor: obj?.t_sample_generated[0]?.t_reservation_relation?.m_ot_as_ref_clinic_relation?.mc_name || '-',
                        testName: obj?.t_sample_generated[0]?.t_test_relation?.test_name_en || '-',
                        nationalID: obj?.t_sample_generated[0]?.m_patient_relation?.patient_id_no || '-',
                        time: moment(convertToAnotherTimezone(obj?.sample_collected)).format('hh:mm:ss A') || '-'
                    }
                    newSheetdata.push(newData);
                })
            }
            let payload = {
                columns: ['PATIENT NAME', 'PHONE NO', 'DATE', 'REFER DOCTOR', 'LAB TEST NAME', 'NATIONAL ID', 'TIME'],
                pdf_name: 'Pdf_Generated_Report_' + moment(urgentLogsFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(urgentLogsFilters.end_date).format("DD-MM-YYYY"),
                rows: newSheetdata
        }
            const { data, status } = await MasterServices.generateObjectToPDF(payload);
            console.log(data)
            if (status === 200) {
                if(data?.data?.pdf_url != '') {
                    // window.location.replace(data.data.pdf_url);
                    window.open(data.data.pdf_url, '__blank');
                }
                    // setResUserReportData(data.data)
            }
            // setIsLoading(false)
        } catch (error) {
            console.log(error)
            // setIsLoading(false)
        }
    }

    async function getUrgentLogsPDF() {
        try {
            const { data } = await MasterServices.getUrgentLogsPDF(urgentLogsFilters);

            // console.log(data)

            const blob = await new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'generated.pdf';
            link.click();
            URL.revokeObjectURL(url);
            console.log('PDF downloaded successfully!');

        } catch (error) {
            console.log(error)
        }
    }

    const handleMultiUsers = (event) => {
        setSelectedUsers(event)
        let users = [];
        for (let i = 0; i < event.length; i++) {
            users.push(event[i].value)
        }
        setUrgentLogsFilters((prev) => {
            return {
                ...prev,
                users,
            }
        })
    }

    const handleExport = (excel_type) => {
        if (urgentLogsData?.length > 0) {
            let newSheetdata = [];
            urgentLogsData.map((obj, index) => {
                const newData = {
                    patientName: obj?.t_sample_generated[0]?.m_patient_relation?.patient_first_name || '-',
                    phoneNo: obj?.t_sample_generated[0]?.m_patient_relation?.patient_mobile || '-',
                    date: moment(convertToAnotherTimezone(obj?.sample_collected)).format('DD-MM-YYYY') || '-',
                    referDoctor: obj?.t_sample_generated[0]?.t_reservation_relation?.m_ot_as_ref_clinic_relation?.mc_name || '-',
                    testName: obj?.t_sample_generated[0]?.t_test_relation?.test_name_en || '-',
                    nationalID: obj?.t_sample_generated[0]?.m_patient_relation?.patient_id_no || '-',
                    time: moment(convertToAnotherTimezone(obj?.sample_collected)).format('hh:mm:ss A') || '-'
                }
                newSheetdata.push(newData);
            })
            let fileName = 'Pdf_Generated_Report_' + moment(urgentLogsFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(urgentLogsFilters.end_date).format("DD-MM-YYYY") + '.' + excel_type;

            const headings = [['PATIENT NAME', 'PHONE NO', 'DATE', 'REFER DOCTOR', 'LAB TEST NAME', 'NATIONAL ID', 'TIME']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, fileName);
        } else {
            toast.error("Data Not Found!");
        }
    }

    const handlePdfGenerate = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

        let fileName = 'Coupon_Report_From_' + moment(urgentLogsFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(urgentLogsFilters.end_date).format("DD-MM-YYYY") + '.pdf';

        pdf.save(fileName);
    }

    const handleReportSmsModelOpen = (r_res) =>{
        setSelectedReportRes(r_res)
        setOpenSendReport(true)
        // console.log(r_res)
        // console.log("reportSMSdata", r_res.t_sample_generated[0].m_patient_relation.patient_mobile)
        setReportSmsNumber(r_res.t_sample_generated[0].m_patient_relation.patient_mobile ? r_res.t_sample_generated[0].m_patient_relation.patient_mobile : "")
    }

    const handleSendReportUrlSms = () =>{
        sendReportLinkSMS(selectedReportRes)
    }

    const sendReportLinkSMS = async (reservation_data)=>{

        // console.log("reservation_data", {
        //     link: reservation_data.sample_report_link, 
        //     phone: reservation_data.t_sample_generated[0].m_patient_relation.patient_dial_code + (reportSmsNumber+""),
        //     patient_id: reservation_data.t_sample_generated[0].m_patient_relation.patient_id,
        //     res_id: reservation_data.t_sample_generated[0].fk_reservation_id,
        // })

        let payload = {
          report_link: reservation_data.sample_report_link,
          phone: reservation_data.t_sample_generated[0].m_patient_relation.patient_dial_code + (reportSmsNumber+""),
          fk_patient_id : reservation_data.t_sample_generated[0].m_patient_relation.patient_id,
          fk_reservation_id: reservation_data.t_sample_generated[0].fk_reservation_id,
        };
    
        return await SampleServices.sendReportLinkSMS(payload)
          .then((response) => {
            const { data, status } = response;
            console.log(data);
            if (status == 200 && data.data) {
              toast.success(data.message);
              console.log(data.data)
              closeReportSMSModal()
            } else {
              toast.error(data.message);
            }
          })
          .catch((error) => {
            const msg = "sendReportLinkSMS catch Error"; //error.response.data.message;
            toast.error(msg);
          });
    }

    const closeReportSMSModal = () =>{
        setOpenSendReport(false)
        setReportSmsNumber("")
        setSelectedReportRes(false)
    }

    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">

                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">Report PDF Generated</h1>
                </div>

                <div className="mt-5 flex gap-3 items-center">

                    {/* start date */}
                    <div className=" sm:mt-0">
                        <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date From</label>

                        <input
                            disabled={isLoading}
                            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                            type="date"
                            name="start_date"
                            id="start_date"
                            max={moment().format("YYYY-MM-DD")}
                            value={urgentLogsFilters.start_date}
                            onChange={(e) => {
                                setUrgentLogsFilters((prev) => {
                                    if (prev.end_date < e.target.value) {
                                        return {
                                            ...prev,
                                            end_date: '',
                                            start_date: e.target.value
                                        }
                                    }
                                    return {
                                        ...prev,
                                        start_date: e.target.value,
                                    }
                                })
                            }
                            }
                        />
                    </div>

                    {/* end date */}
                    <div className=" sm:mt-0">
                        <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date To</label>

                        <input
                            disabled={isLoading}
                            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                            type="date"
                            name="end_date"
                            id="end_date"
                            max={moment().format("YYYY-MM-DD")}
                            value={urgentLogsFilters.end_date}
                            onChange={(e) => {
                                setUrgentLogsFilters((prev) => {
                                    if (prev.start_date > e.target.value) {
                                        return {
                                            ...prev,
                                            end_date: '',
                                            start_date: prev.start_date
                                        }
                                    }
                                    return {
                                        ...prev,
                                        end_date: e.target.value,
                                    }
                                })
                            }
                            }
                        />
                    </div>

                    {/* {
                        !userLoading ?
                            <div className="">
                                <label
                                    htmlFor="fk_location_ids"
                                    className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Users</label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                        // defaultValue={testList[0]}
                                        isMulti
                                        name="fk_user_ids"
                                        id="fk_user_ids"
                                        value={selectedUsers}
                                        onChange={(e) => {
                                            console.log(e)
                                            handleMultiUsers(e)}}
                                        options={userList}
                                        className="basic-multi-select"
                                        classNamePrefix="Select Test"
                                    />
                                </div>
                            </div>
                            : null

                    } */}


                    <button
                        disabled={isLoading}
                        onClick={() => {
                            getUrgentLogsFilter(urgentLogsFilters)
                        }}
                        className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isLoading ? "opacity-40" : "opacity-100"}`}
                    >
                        Get Data
                    </button>
                </div>

                <div className='mt-5 flex gap-1 items-center'>
                    {/* <button
                    onClick={() => handleExport('copy')}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mr-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    Copy
                  </button> */}
                    <button
                        onClick={() => handleExport('csv')}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        CSV
                    </button>

                    <button
                        onClick={() => handleExport('xlsx')}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        Excel
                    </button>

                    <button
                        onClick={() => generateObjectToPdf()}
                        // onClick={() => getUrgentLogsPDF()}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        PDF
                    </button>

                    {/* 
                  <button
                    onClick={() => handlePrint()}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    Print
                  </button> */}
                </div>
            </div>
            {
                isLoading ? (
                    <FallingLinesLoader />
                ) : ((urgentLogsData.length > 0) ? (
                    <Table
                        zoom='zoom-075'
                        printRef={printRef}
                        columns={report_pdf_generated_logs_columns(handleReportSmsModelOpen)}
                        data={urgentLogsData}
                    />
                ) : <TableEmpty />)
            }

            <div>
                <Transition.Root show={openSendReport} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-30"
                    onClose={() => closeReportSMSModal()}
                >
                    <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                                <div>Number to Send Report URL </div>
                                <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                <input
                                    value={reportSmsNumber}
                                    onChange={(e) => {
                                    // console.log(e.target.value)
                                    setReportSmsNumber(e.target.value);
                                    }}
                                    type="number"
                                    placeholder="Enter Valid Number"
                                    name="sms_number"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                />
                                {!reportSmsNumber || +reportSmsNumber < 500000000 || +reportSmsNumber >= 600000000 ? (
                                    <p className="mt-1 ml-1 text-xs text-red-500">
                                    Please enter a vaild number without country code
                                    </p>
                                )
                                    : null
                                }
                                </div>
                                <div className="flex justify-end">
                                <button
                                    onClick={handleSendReportUrlSms}
                                    disabled={ reportSmsNumber < 500000000 || reportSmsNumber >= 600000000 }
                                    className={`${reportSmsNumber >= 500000000 && reportSmsNumber < 600000000 ? "hover:bg-cyan-700 bg-cyan-600 text-white focus:ring-cyan-500" : "bg-gray-300 text-gray-500"} ml-4 inline-flex rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                                >
                                    Send SMS
                                </button>
                                </div>
                            </div>
                            </div>
                        </Dialog.Panel>
                        </Transition.Child>
                    </div>
                    </div>
                </Dialog>
                </Transition.Root>
            </div>
        </>
    )

}