import React, { useEffect, useState } from "react";

import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import { Switch } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { VendorSchema } from "../../../schemas";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import PhoneInputWithCountry, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'

const AddVendors = () => {
  const pages = [{ title: "Vendors", href: "/vendors" }];
  const navigate = useNavigate();
  const inditalState = {
    vendor_account_name: "",
    vendor_legal_name: "",
    vendor_mobile: "",
    vendor_address: "",

    vendor_city: "",
    vendor_country: "",
    vendor_pin: "",
  };
  const [formState, setFormState] = useState(inditalState);
  const [loading, setLoading] = useState(false);
  const [vendorData, setVendorData] = useState({});
  const [mobileNumber, setMobileNumber] = useState('');
	const [userMobileNumber, setUserMobileNumber] = useState('');
	const [mobileNumberValid, setMobileNumberValid] = useState(true);
	const [mobileCountryCode, setMobileCountryCode] = useState('')
	const [initialRender, setInitialRender] = useState(true);
  const { vendor_id: vId } = useParams();
  useEffect(() => {
    document.title = "Tabib | Add Vendor";
    if (vId) {
      getVendorById(vId);
    }
  }, [vId]);
  useEffect(() => {
    if (vId) {
      if (vendorData) {
        let newFormStat = {
          vendor_account_name: vendorData.vendor_account_name,
          vendor_legal_name: vendorData.vendor_legal_name,
          vendor_mobile: vendorData.vendor_mobile,
          vendor_address: vendorData.vendor_address,
          vendor_city: vendorData.vendor_city,
          vendor_country: vendorData.vendor_country,
          vendor_pin: vendorData.vendor_pin,
        };
        setMobileNumber(`+${vendorData.vendor_dial_code}${vendorData.vendor_mobile}`)

        setFormState(newFormStat);
      }
    }
  }, [vendorData, vId]);
  //Mobile number validation in patient
  useEffect(() => {
    if(!initialRender) {
      // console.log(mobileNumber.toString())
      if (mobileNumber && isValidPhoneNumber(mobileNumber.toString())) {
      const phoneNumber = parsePhoneNumber(mobileNumber)
      console.log(phoneNumber)
      if (phoneNumber) {
        setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
        setUserMobileNumber(phoneNumber.nationalNumber);
      }
      setMobileNumberValid(true);
      } else {
      setMobileNumberValid(false)
      }
    } else {
      setInitialRender(false)
    }
  }, [mobileNumber])

  const onMobileNumberChange = (value) => {
		if(value) {
			setMobileNumber(value)
		}
	}
  const addVendor = async (payload) => {
    setLoading(true);
    await MasterServices.addVendor(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Vendor added Successfully");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
    navigate("/vendors");
  };
  const updateVendor = async (id, payload) => {
    setLoading(true);
    await MasterServices.updateVendor(id, payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Vendor Updated Successfully");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
    navigate("/vendors");
  };

  const getVendorById = async (id) => {
    setLoading(true);
    await MasterServices.getOneVendor(id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setVendorData(data.data);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {vId ? null : <Breadcrumb pages={pages} />}
      <div>
        <div className="sm:flex-auto">
          {/* <h1 className="text-xl font-semibold text-gray-900">
            {vId ? "Update Vendor" : "Add Vendor"}
          </h1> */}
          <div className="">
            <h1 className="text-xl font-semibold text-gray-900">{vendorData?.vendor_account_name}</h1>
          </div>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <Formik
          initialValues={formState}
          validationSchema={VendorSchema}
          enableReinitialize={true}
          onSubmit={(values, action) => {
            const body = {
              ...values,
              vendor_is_active: true,
              vendor_pin: parseInt(values.vendor_pin),
              vendor_dial_code: mobileCountryCode,
              vendor_mobile: userMobileNumber
            };
            if (vId) {
              delete body.vendor_is_active;
              updateVendor(vId, body);
              action.resetForm();
            } else {
              addVendor(body);
            }
            action.resetForm();

            action.setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="space-y-8 divide-y divide-gray-200"
            >
              <div className="space-y-6 sm:space-y-5">
                <div className="space-y-6 sm:space-y-5 sm:pt-5">
                  <div className="space-y-6 sm:space-y-5">
                    <h2>Profile Information</h2>
                    <div className="ml-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location_name"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Account Name
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="vendor_account_name"
                          id="name"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vendor_account_name}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.vendor_account_name &&
                          touched.vendor_account_name ? (
                          <p className="text-red-700">
                            {errors.vendor_account_name}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="ml-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Legal Name
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="vendor_legal_name"
                          id="vendor_legal_name"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vendor_legal_name}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.vendor_legal_name &&
                          touched.vendor_legal_name ? (
                          <p className="text-red-700">
                            {errors.vendor_legal_name}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="ml-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Phone Number
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0 w-80">
                        <PhoneInputWithCountry
                          defaultCountry="SA"
                          value={mobileNumber}
                          placeholder="Enter phone number"
                          onChange={e => {
                            values.vendor_mobile = e
                            onMobileNumberChange(e)}
                          }
                        />
                        {/* {errors.vendor_mobile && touched.vendor_mobile ? (
                          <p className="text-red-600 text-sm">{errors.vendor_mobile}</p>
                        ) : null} */}
                        {
                          !mobileNumberValid ? (
                          <p className="text-red-700">Mobile Number is invalid</p>
                          ) : null
                        }
                      </div>
                    </div>
                    <h2 className="mt-4">Invoicing Information</h2>
                    <div className="ml-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Vendor Address
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="vendor_address"
                          id="vendor_address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vendor_address}
                          autoComplete="off"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.vendor_address && touched.vendor_address ? (
                          <p className="text-red-700">
                            {errors.vendor_address}
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="ml-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Vendor City
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="vendor_city"
                          id="vendor_city"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vendor_city}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.vendor_city && touched.vendor_city ? (
                          <p className="text-red-700">{errors.vendor_city}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="ml-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Vendor Country
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="vendor_country"
                          id="vendor_country"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vendor_country}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.vendor_country && touched.vendor_country ? (
                          <p className="text-red-700">
                            {errors.vendor_country}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="ml-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Vendor Postal Code
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="vendor_pin"
                          id="vendor_pin"
                          autoComplete="off"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.vendor_pin}
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {errors.vendor_pin && touched.vendor_pin ? (
                          <p className="text-red-700">{errors.vendor_pin}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="ml-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Lead Time (Days)
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <input
                          type="text"
                          name="description"
                          id="location-code"
                          autoComplete="off"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                        />
                        {/* {errors.description && touched.description ? (
                      <p className="text-red-700">{errors.description}</p>
                    ) : null} */}
                      </div>
                    </div>
                    <div className="ml-3 sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="location-code"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Active
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <Switch
                          // onBlur={handleBlur}
                          name="enable"
                          className={
                            true
                              ? "bg-cyan-600 relative inline-flex h-Switch6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              : "bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          }
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                            aria-hidden="true"
                            className={
                              true
                                ? "translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                : "translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                            }
                          />
                        </Switch>
                        {/* {errors.inventory_type && touched.inventory_type ? (
                  <p className="text-red-600 text-sm">{errors.inventory_type}</p>
                ) : null} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    onClick={() => {
                      values.vendor_mobile = mobileNumber
                      if(mobileNumber == '') {
                        setMobileNumberValid(false);
                      }
                    }}
                    type="submit"
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AddVendors;
