

//With live server
//export const BASEURL = window.location.hostname.includes("localhost") ? process.env.LOCAL_API_URL : process.env.LIVE_API_URL;

//Without .env
// export const BASEURL = "https://api-tabib.enpointe.io/";

// // Project Name
// export const dashboardName = "Tabib App";

//With live server
//export const BASEURL = window.location.hostname.includes("localhost") ? process.env.LOCAL_API_URL : process.env.LIVE_API_URL;
//Without .env
// export const BASEURL = window.location.hostname.includes("localhost") ? ("http://localhost:8004") : (window.location.hostname.includes("tabibdesk")?"https://api.tabibdesk.com/":"https://api-tabib.enpointe.io/");
export const BASEURL = window.location.hostname.includes("localhost") ? ("http://localhost:8004") : (window.location.hostname.includes("lims.joyitsolutions.co")?"https://api-joylabs.enpointe.io/":"https://api-tabib.enpointe.io/");

// export const BASEURL = window.location.hostname.includes("localhost") ? "http://localhost:8004" : "https://api-tabib.enpointe.io/";
export const timeZone = "Asia/Riyadh";
// Project Name
export const dashboardName = "Tabib App";
