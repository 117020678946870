import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { purchase_order_list_columns, purchase_order_list_items_columns } from "../../../components/tables/tableheader";
import { toast } from "react-toast";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import MasterServices from "../../../ApiServices/MasterServices";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../../components/Modal";
import InputBox from "../../../components/InputBox";
import Button from "../../../components/Button";
import DropDownSelector from "../../../components/multiSelectDropdown";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useSelector } from "react-redux";

const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    wpoi_name: "",
    item_name: "",
    quantity: "",
    price: "",
    location: "",
  },
};

const initialModalStatePurchaseItems = {
  state: false,
  data: [],
}

function PurchaseOrder() {
  const pages = [{ title: "Purchase order", href: "/purchase_order", module_id: 39 }];
  const [loading, setLoading] = useState(true);
  const {
		loginUserInfo: { userData },
	} = useSelector((state) => state);
  const [vendorlist, setVendorList] = useState([]);
  const [purchaseorderList, setPurchaseOrderList] = useState([]);
  const [modal, setModal] = useState(initialModalState);
  const [selected, setSelected] = useState("");
  const [modalPurchaseItems, setModalPurchaseItems] = useState(initialModalStatePurchaseItems);
  const [itemsList, setItemsList] = useState([]);
  const [itemSelected, setItemSelected] = useState("");
  const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		po_id: null,
	});
  const [approveConfirmationModal, setApproveConfirmationModal] = useState({
		status: false,
		data: null,
	});


  const navigate = useNavigate();
  useEffect(() => {
    // onPackageList();
    console.log(selected);
    getAllItems();
    getAllPurchaseOrder();
    document.title = "New Tabib | Purchase Order";
  }, []);

  const getAllPurchaseOrder = () => {
    MasterServices.getPurchaseOrder('All').then((response) => {
      const { data, status } = response;
      if (status == 200) {
        if (data?.data?.length > 0) {
          setPurchaseOrderList(data.data);
          console.log(data.data);
        } else {
          toast.error("PurchaseOrder Not Found");
        }
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
      setLoading(false);
    });
  };

  const addNewPurchaseOrder = (payload) => {
    MasterServices.addPurchaseOrder(payload).then((response) => {
      const { data, status } = response;
      if (status == 200) {
        toast.success("Control List Added Successfully");
        getAllPurchaseOrder();
        setModal(initialModalState);
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
      setLoading(false);
    });
  };

  const getAllItems = async () => {
    setLoading(true);
    await MasterServices.getItemsList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setItemsList(data.data);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
    setLoading(false);
  };

  const updtePurcahseOrderStatus = async (payload) => {
    await MasterServices.setPurchaseOrderStatus(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200 && data.status) {
          getAllPurchaseOrder();
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const generatePdf = async (payload) => {
    await MasterServices.generatePOPdf(payload)
    .then((response) => {
      const { data, status } = response;
      if (status == 200 && data.status) {
        getAllPurchaseOrder();
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    })
    .catch((error) => {
      const msg = error;
      toast.error(msg);
      setLoading(false);
    });
  }

  const updtePurcahseOrderApprovedStatus = async (payload) => {
    await MasterServices.setPurchaseOrderApproveStatus(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200 && data.status) {
          getAllPurchaseOrder();
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const deletePurchaseOrder = async (payload) => {
    await MasterServices.deletePurchaseOrder(payload)
      .then((response) => {
        const { data, status } = response;
        setConfirmationModal({ po_id: null, status: false });
        if (status == 200 && data.status) {
          getAllPurchaseOrder();
          toast.success(data.message);
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const renderPurchaseItemsModel = () => {
    const { state, data } = modalPurchaseItems;

    return (
        <Modal
            title="Item List"
            open={state}
            size="max-w-3xl relative"
            setOpen={() => setModalPurchaseItems((prev) => ({ ...prev, state: false }))}
        >
         <Table
          columns={purchase_order_list_items_columns({})}
          data={data}
        />   
        </Modal>
    );
};

  const openPurchaseOrderItem = (data) => {
    setModalPurchaseItems({
      data: data,
      state: true
    })
  }

  const onDeleteOpen = (po_id) => {
		setConfirmationModal({ po_id, status: true });
	};

  const onAppoveOpen = (data) => {
		setApproveConfirmationModal({ data, status: true });
	};



  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => deletePurchaseOrder(confirmationModal.po_id)}
      />
      <ConfirmationModal
          open={approveConfirmationModal.status}
          confirmationButtonText="Yes"
          setOpen={setApproveConfirmationModal}
          onDelete={() => updtePurcahseOrderApprovedStatus(approveConfirmationModal.data)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Purchase Order</h1>
      </div>
      <div className="mt-4 flex">
        <Link
          to="/add-purchase-order"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : purchaseorderList.length > 0 ? (
        <Table
          columns={purchase_order_list_columns({updtePurcahseOrderStatus, openPurchaseOrderItem, onDeleteOpen, onAppoveOpen, userData, generatePdf})}
          data={purchaseorderList}
        />
      ) : (
        <TableEmpty />
      )}
      {renderPurchaseItemsModel()}
    </div>
  );
}
export default PurchaseOrder;
