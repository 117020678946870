import React, { Fragment, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Table from "../../../components/tables/tableSampleProcess";
import { sample__processing_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition, } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from 'react-toast'
import SampleServices from '../../../ApiServices/SampleServices';
import queryString from 'query-string';
import moment from "moment";
import Select from 'react-select'
import MasterServices from "../../../ApiServices/MasterServices";

let filterInitialValues = {
  filterFrom: "",
  filterTo: "",
  filterStatus: "",
  filterHos: "",
  filterBkType: "",
  filterOrg: "",
  filterSampleId: "",
  filterPatientMob: "",
  filterPatientIdentification: "",
  filterPatientName: "",
  filterPatientPassport: "",
  filterTestType: "",
  filterPatientId: "",
  filterReservationId: "",
  filterMrn: "",
  filterOutsourcedClinic: "",
};

const bookingType = [
  { name: "Normal", id: "1" },
  { name: "OPD", id: "2" },
  { name: "Emergancy", id: "3" },
  { name: "ICU", id: "4" },
];

function SampleProcessing() {
  const pageSize = 10;
  const {
    hospital: { list: hospitalList },
  } = useSelector((state) => state);
  const pages = [{ title: "Sample Processing", href: `/sample-processing`, module_id: 12 }];
  const [filterFrom, setFilterFrom] = useState('');
  const [filterOutsourcedClinic, setFilterOutsourcedClinic] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterHos, setFilterHos] = useState('');
  const [filterBkType, setFilterBkType] = useState('');
  const [filterOrg, setFilterOrg] = useState('');
  const [filterSampleId, setFilterSampleId] = useState('');
  const [filterPatientMob, setFilterPatientMob] = useState('');
  const [filterPatientIdentification, setFilterPatientIdentification] = useState('');
  const [filterPatientName, setFilterPatientName] = useState('');
  const [filterPatientPassport, setFilterPatientPassport] = useState('');
  const [filterTestType, setFilterTestType] = useState('');
  const [filterPatientId, setFilterPatientId] = useState('');
  const [filterReservationId, setFilterReservationId] = useState('');
  const [filterMrn, setFilterMrn] = useState('');
  const [pageArr, setPageArr] = useState('');
  console.log(pageArr);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchByDropdown, setSearchByDropdown] = useState('name');
  const [searchByInput, setSearchByInput] = useState('');
  const [tempSampleList, setTempSampleList] = useState([]);
  const [sampleList, setSampleList] = useState(false);
  const [outsourceClinicList, setOutsourceClinicList] = useState([])
  const [filterIsOn, setFilterIsOn] = useState(false);
  const [sampleStatuses, setSampleStatuses] = useState(false);
  const selectedLocation = JSON.parse(localStorage.getItem("userLocData"))[0]
  const [approveCheckBoxes, setApproveCheckBoxes] = useState([]);
  const [validateCheckBoxes, setValidateCheckBoxes] = useState([]);

  useEffect(() => {
    console.log(approveCheckBoxes, "approveCheckBoxes");
    console.log(validateCheckBoxes, "validateCheckBoxes");
  }, [approveCheckBoxes, validateCheckBoxes])

  useEffect(() => {
    setTempSampleList(sampleList);
    setLoading(false)
    getAllSampleStatuses();
  }, [sampleList])

  useEffect(() => {
    let te = [];
    if(sampleList && sampleList.length > 0) {
      te = sampleList.filter((row) => {
        if(row?.patient_first_name?.toLowerCase().includes(searchByInput?.toLowerCase()) && searchByDropdown == 'name'){
          return true
        } else if(row?.patient_mobile?.toLowerCase().includes(searchByInput?.toLowerCase()) && searchByDropdown == 'mobile_no') {
          return true
        } else if(`${row?.sample_id}`?.toLowerCase().includes(searchByInput?.toLowerCase()) && searchByDropdown == 'sample_id') {
          return true
        } else if(`${row?.reservation_id}`?.toLowerCase().includes(searchByInput?.toLowerCase()) && searchByDropdown == 'reservation_id') {
          return true
        } else if(`${row?.patient_report_mrn}`?.toLowerCase().includes(`${searchByInput}`?.toLowerCase()) && searchByDropdown == 'mrn') {
          return true
        } else {
          return false
        }
      })
    }
    // console.log(searchByDropdown, searchByInput, "hdhdjdjdjdjdjdj")
    setTempSampleList(te);
  }, [searchByDropdown, searchByInput])

  const getSingleReservationDetails = async (payload) => {
    // console.log("payload=>", payload)
    // return false;
    return await SampleServices.getAllReservationSamplesResult({ reservation_id: payload?.reservation_id, fk_patient_id: payload?.fk_patient_id, sp_gen_id: payload?.sp_gen_id })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          return data.data;
        } else {
          toast.error(data.message)
        }
      }).catch((error) => {
        const msg = "getSingleReservationDetails catch Error"; //error.response.data.message;
        // toast.error(msg);
      });
  }

  const changeRefRange = async (payload) => {
    let res = await SampleServices.changeRefRangeOfSample(payload)
    if (res.status == 200) {
      toast.success(res.data.message);
      getAllSampleList(currentPage);
    } else {
      toast.error(res.data.message)
    }
  }

  const generateReport = async (payload) => {
    // console.log("In the function")
    // return false
    let singleReservationDetails = await getSingleReservationDetails(payload);
    payload.samplecreatedby = '';
    payload.paymentcollectedbyusername = '';
    payload.payment_collected_timestamp = '';
    if (payload.ValueType == "Changed") {
      payload.all_Pack_lists = singleReservationDetails.all_Pack_lists;
      singleReservationDetails.all_lists = payload.all_lists;
    } else {
      payload.all_Test_lists = singleReservationDetails.all_Test_lists;
      payload.all_Test_lists_new = singleReservationDetails.all_Test_lists;
      payload.all_Pack_lists = singleReservationDetails.all_Pack_lists;
      // console.log("In the function=>", payload.all_Test_lists);
    }


    // payload.all_Test_lists_new = payload.all_Test_lists_new.map((val, key) => {
    //   if (val.test_id === payload.fk_test_id && val.fk_sample_type_id === payload.fk_sample_type_id) {
    //     const updatedVal = {
    //       ...val, // Copy all properties of val
    //       all_subtest_lists: val.all_subtest_lists.map((val2, key2) => {

    //         if (val2.ttp_id && val2.test_result.length > 0) {
    //           return {
    //             ...val2, // Copy all properties of val2
    //             mrr_unit: (val2.test_result[0].ssp_mrr_unit === null ||
    //               val2.test_result[0].ssp_mrr_unit === undefined ||
    //               val2.test_result[0].ssp_mrr_unit === "null" ||
    //               val2.test_result[0].ssp_mrr_unit === "undefined" ||
    //               val2.test_result[0].ssp_mrr_unit === "") ? val.mrr_unit : val2.test_result[0].ssp_mrr_unit, // Update mrr_unit in val2
    //           };

    //         }
    //         return val2; // Return unchanged val2
    //       })
    //     };
    //     return updatedVal; // Return updated object
    //   }
    //   return val; // Return unchanged object
    // });
    payload.all_Test_lists_new = payload.all_Test_lists_new.map((val, key) => {
      if (val.test_id === payload.fk_test_id && val.fk_sample_type_id === payload.fk_sample_type_id) {
        const updatedVal = {
          ...val, // Copy all properties of val
          all_subtest_lists: val.all_subtest_lists.map((val2, key2) => {
            if (val2.ttp_id && val2.test_result && val2.test_result.length > 0) {
              let mrr_unit;

              if (
                val2.test_result[0].ssp_mrr_unit === null ||
                val2.test_result[0].ssp_mrr_unit === undefined ||
                val2.test_result[0].ssp_mrr_unit === "null" ||
                val2.test_result[0].ssp_mrr_unit === "undefined" ||
                val2.test_result[0].ssp_mrr_unit === ""
              ) {
                mrr_unit = val2.mrr_unit;
              } else {
                mrr_unit = val2.test_result[0].ssp_mrr_unit;
              }

              return {
                ...val2, // Copy all properties of val2
                mrr_unit: mrr_unit, // Update mrr_unit in val2
              };
            }
            return val2; // Return unchanged val2
          }),
        };
        return updatedVal; // Return updated object
      }
      return val; // Return unchanged object
    });

    // return false;
    if (Array.isArray(singleReservationDetails?.all_Test_lists) && singleReservationDetails?.all_Test_lists[0]
      && Array.isArray(singleReservationDetails?.all_Test_lists[0]?.all_subtest_lists)
      && singleReservationDetails?.all_Test_lists[0]?.all_subtest_lists.length > 0) {
      let tempArr = [...singleReservationDetails?.all_Test_lists[0]?.all_subtest_lists];
      let outputTemp = tempArr.map((v) => {
        let tprrlabInputs = [];
        let test_result = [];
        let is_sub_rr;
        let sub_ref_range = [];
        if (Array.isArray(sampleList)) {
          tprrlabInputs = sampleList.filter(sl => {
            if (sl?.ttp_name_en == v?.ttp_name_en && sl?.sample_id == payload?.fk_sample_id) {
              tprrlabInputs = sl?.tprrlabInputs || [];
              test_result = sl?.test_result || [];
              is_sub_rr = sl?.is_sub_rr;
              sub_ref_range = sl?.sub_ref_range || []
            }
          })
        }
        return {
          ...v,
          tprrlabInputs,
          test_result,
          is_sub_rr,
          sub_ref_range,
        }
      })
      payload.all_Test_lists = outputTemp;
    }
    if (singleReservationDetails.all_lists.length > 0) {

      payload.samplecreatedby = singleReservationDetails.all_lists[0].samplecreatedby;
      payload.paymentcollectedbyusername = singleReservationDetails.all_lists[0].paymentcollectedbyusername;
      payload.payment_collected_timestamp = moment(singleReservationDetails.all_lists[0].payment_collected_timestamp).format("DD-MM-YYYY HH:MM:SS");
    }
    payload.sample_report_type = "Normal_sample";
    let res = await SampleServices.generateReport(payload)
    if (res.status == 200) {
      toast.success(res.data.message);
      //getAllSampleList(1)
      getAllSampleList(currentPage);
    } else {
      toast.error(res.data.message)
    }
  }

  const viewReport = async (payload) => {
  }



  const handleUpdateLocChange = async (payload, lab) => {
    let LabDetails = JSON.parse(lab);
    // if (LabDetails?.lab_analyzer_id && LabDetails?.loc_id) {
    if (LabDetails?.lab_analyzer_id) {
      payload.lab_analyzer_id = LabDetails?.lab_analyzer_id;
      payload.lab_analyzer_loc_id = LabDetails?.fk_loc_id;
      let labPayload = {
        lab_analyzer_id: LabDetails?.lab_analyzer_id,
        lab_analyzer_loc_id: LabDetails?.fk_loc_id,
        lab_analyzer_fk_titem_id: LabDetails?.fk_titem_id,
        // ssp_value: payload?.ssp_value,
        fk_sample_id: payload?.fk_sample_id,
        fk_test_id: payload?.fk_test_id,
        fk_reservation_id: payload?.fk_reservation_id,
        ssp_id: payload?.test_result?.ssp_id,
        ssp_value: payload?.test_result?.ssp_value,
        fk_subtest_id: payload?.ttp_id,
        fk_patient_id: payload?.patient_id,
      }
      // let labPayload = {
      //   lab_analyzer_id: LabDetails?.lab_analyzer_id,
      //   lab_analyzer_loc_id: LabDetails?.loc_id,
      //   lab_analyzer_fk_titem_id: LabDetails?.fk_titem_id,
      //   ssp_value: payload?.ssp_value,
      //   fk_sample_id: payload?.fk_sample_id,
      //   fk_test_id: payload?.fk_test_id,
      //   fk_reservation_id: payload?.fk_reservation_id,
      //   ssp_id: payload?.ssp_id,
      //   fk_subtest_id: payload?.ttp_id,
      // }
      setLoading(true)
      let res = await SampleServices.updateSampleTestResultLabAnalygerLoc(labPayload)
      if (res.status == 200) {
        toast.success(res.data.message)
        //getAllSampleList(1)
        getAllSampleList(currentPage);
        setLoading(false)
      } else {
        toast.error(res.data.message)
        setLoading(false)
      }
    }
  }

  const handleMultiOutsourceClinic = (event) => {
    let clinic = [];
    event.map((el) => {
      clinic.push(el)
    })
    setFilterOutsourcedClinic(clinic)
}

  const [filterFlag, setFilterFlag] = useState(true);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [loading, setLoading] = useState(true);
  const [commentsModal, setCommentsModal] = useState(false);
  const [commentData, setCommentData] = useState();
  const [maxVisiblePages, setMaxVisiblePages] = useState(1);
  const cancelButtonRef = useRef(null);
  const [sample_id, setSampleid] = useState('');
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    sample_id: null,
    sample_is_active: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Tabib | Sample Processing";
    getOutsourceClinicList();
    getAllSampleList();
    // setTimeout(() => {
    //   getAllSampleList(1);
    // }, 100)
  }, []);

  async function getOutsourceClinicList() {
    // setLoading(true)
    try {
        const clinicsFromApi = await MasterServices.getClinicList();

        let newClinic = clinicsFromApi.data.data;
        let Clinics = newClinic.map((el) => {
            let obj = { ...el, label: el.mc_name, value: el.mc_id }
            return obj
        })

        setOutsourceClinicList(Clinics)
        setLoading(false)
    } catch (error) {
        console.log(error)
        setLoading(false)
    }
}

  const onFilter = (index) => {
    filterInitialValues = {
      filterFlag: true,
      filterFrom: filterFrom ? filterFrom : '',
      filterTo: filterTo ? filterTo : '',
      filterStatus: filterStatus ? filterStatus : '',
      filterHos: filterHos ? filterHos : '',
      filterBkType: filterBkType ? filterBkType : '',
      filterOrg: filterOrg ? filterOrg : '',
      filterSampleId: filterSampleId ? filterSampleId : '',
      filterPatientMob: filterPatientMob ? filterPatientMob : '',
      filterPatientIdentification: filterPatientIdentification ? filterPatientIdentification : '',
      filterPatientName: filterPatientName ? filterPatientName : '',
      filterPatientPassport: filterPatientPassport ? filterPatientPassport : '',
      filterTestType: filterTestType ? filterTestType : '',
      filterPatientId: filterPatientId ? filterPatientId : '',
      filterReservationId: filterReservationId ? filterReservationId : '',
      filterMrn: filterMrn ? filterMrn : '',
      filterOutsourcedClinic: filterOutsourcedClinic ? filterOutsourcedClinic : '',
      page: index || 1,
      pageSize: pageSize

    };

    // console.log("filterInitialValues=>", filterInitialValues)
    getAllSampleListFilterList(queryString.stringify(filterInitialValues));
  }

  const onFilterClear = () => {
    setFilterFrom('');
    setFilterTo('');
    setFilterStatus('');
    setFilterHos('');
    setFilterBkType('');
    setFilterOrg('');
    setFilterSampleId('');
    setFilterPatientMob('');
    setFilterPatientIdentification('');
    setFilterPatientName('');
    setFilterPatientPassport('');
    setFilterTestType('');
    setFilterPatientId('');
    setFilterReservationId('');
    setFilterMrn('');
    setFilterOutsourcedClinic([]);
    //getAllSampleList(1)
    getAllSampleList(currentPage);
  }

  const sampleListModification = (sampleList) => {
    // setLoading(true);
    let finalList = [];
    let unique = [];
    sampleList.map((test) => {
      let testObj = {};
      if (test.is_sub_test && test.all_subtest_lists?.length > 0) {
        for (let i = 0; i < test.all_subtest_lists.length; i++) {
          let subTestObj = { ...test }
          delete subTestObj.all_subtest_lists
          subTestObj.subTestDetails = test.all_subtest_lists[i]

          subTestObj.ttp_id = subTestObj.subTestDetails.ttp_id;
          subTestObj.is_sub_rr = subTestObj.subTestDetails.is_sub_rr;
          subTestObj.sub_ref_range = subTestObj.subTestDetails.sub_ref_range;

          subTestObj.updated_fk_lab_ids = subTestObj.subTestDetails.test_result.length > 0 ? subTestObj.subTestDetails.test_result[0].fk_lab_id : null;
          subTestObj.updated_fk_lab_analyzer_ids = subTestObj.subTestDetails.test_result.length > 0 ? subTestObj.subTestDetails.test_result[0].fk_lab_analyzer_id : null;
          subTestObj.ssp_id = subTestObj.subTestDetails.test_result.length > 0 ? subTestObj.subTestDetails.test_result[0].ssp_id : null;
          subTestObj.ssp_value = subTestObj.subTestDetails.test_result.length > 0 ? subTestObj.subTestDetails.test_result[0].ssp_value : null;
          subTestObj.ssp_comment = subTestObj.subTestDetails.test_result.length > 0 ? subTestObj.subTestDetails.test_result[0].ssp_comment : null;
          subTestObj.ssp_correction_value = subTestObj.subTestDetails.test_result.length > 0 ? subTestObj.subTestDetails.test_result[0].ssp_correction_value : null;
          subTestObj.fk_sp_status_id = subTestObj.subTestDetails.test_result.length > 0 ? subTestObj.subTestDetails.test_result[0].fk_status_id : subTestObj.fk_status_id;
          testObj = { ...subTestObj }

          finalList.push(testObj);

          // 30-03-2023 Comment By Priyanshu Singh Start
          // const testDatauniqueArray = Object.values(finalList.reduce(function (obj, element) {
          //   const key = element.sp_gen_id ;
          //   if (!obj[key]) {
          //     obj[key] = element;
          //   }
          //   return obj;
          // }, {}));
          // finalList = testDatauniqueArray.sort((a, b) => b.sample_id - a.sample_id);
          // 30-03-2023 Comment By Priyanshu Singh End
        }
      } else {
        testObj = test
        finalList.push(testObj);
      }
    })

    var filtered = finalList.filter(function ({ sp_gen_id, fk_reservation_id, fk_test_id, fk_patient_id, fk_res_patient_test_pack_id, fk_color_id, sample_id, test_id, patient_id, sp_status_id, ttp_id }) {
      var key = `${sp_gen_id}${fk_reservation_id}${fk_test_id}${fk_patient_id}${fk_res_patient_test_pack_id}${fk_color_id}${sample_id}${test_id}${patient_id}${sp_status_id}${ttp_id}`;
      return !this.has(key) && this.add(key);
    }, new Set);

    let i = 0;
    for (const samples of filtered) {
      samples.ValueType = "Not Changed";
      const index = filtered.findIndex(obj => obj.fk_reservation_id === samples.fk_reservation_id && obj.fk_patient_id === samples.fk_patient_id && obj.fk_status_id === samples.fk_status_id);
      samples.buttonResultFlag = false;
      if (index === i && samples.buttonResultFlag == false) {
        samples.buttonResultFlag = true;
      }
      i++;
    }
    // console.log("finalList filtered=>", filtered)
    // console.log("finalList unique=>", finalList)
    setSampleList(filtered);
    setLoading(false)
  }

  const getAllSampleListFilterList = (payload) => {
    // console.log("params payload=>", payload)
    setLoading(true)
    SampleServices.getAllSampleListForProcessingFilterList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if(data.data?.length == 0){
            toast.warn("No Samples with current filters!");
            setSampleList(false);
            setMaxVisiblePages(1);
            setCurrentPage(1);
            setPageArr([1]);
          }else{
            toast.success("Sample List Found!");
            setSampleList(data?.data?.all_lists);
            setMaxVisiblePages(data?.data?.maxVisiblePages);
            setCurrentPage(data?.data?.currentPage);
            setPageArr(data?.data?.paginationArray);
            setFilterIsOn(true);
          }
          setLoading(false)
        } else {
          toast.success(data.message);
          toast.error("Fatal Error Please Contact Admin")
          setLoading(false)
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg)
        setLoading(false)
      });
  }

  // Initial Load Sample List
  const getAllSampleList = (index, isLoading = true) => {
    if(isLoading){
      setLoading(true)
    }
    // if (id == 2) {
    //   setLoading(true)
    // }
    let payload = {
      page: index,
      pageSize: pageSize
    }
    SampleServices.getAllSampleListForProcessingPagination(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.all_lists?.length > 0) {
            sampleListModification(data.data?.all_lists)
            setMaxVisiblePages(data.data?.maxVisiblePages)
            setPageArr(data.data?.paginationArray);
            setCurrentPage(data.data?.currentPage);
          } else {
            toast.error("SampleList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllSampleList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  }

  const validateAndApprove = (payload) => {
      setLoading(true)
      SampleServices.validateAndApproveResult({
        list: payload ? payload : []
      })
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            toast.success("Updated Successfully!");
            setApproveCheckBoxes([]);
            setValidateCheckBoxes([]);
            getAllSampleList(currentPage, false);

          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
          setLoading(false)
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg)
          setLoading(false)
        });
  }

  const updateComment = () => {
    if (commentData) {
      setLoading(true)
      SampleServices.addSampleComment({
        sample_id: sample_id,
        sample_comment: commentData,
      })
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            toast.success("Comment Added Successfully!");
            // getAllSampleList(1);

            setCommentsModal(false)
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
          setLoading(false)
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg)
          setLoading(false)
        });
    } else {
      setCommentsModal(true)
      toast.error("Please Enter Comment");
    }
  }

  const onDeleteSample = (sample_id, sample_is_active) => {
    let Newsample_is_active = false;
    Newsample_is_active = sample_is_active == true ? false : true;
    let text = "Activated";
    if (Newsample_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    SampleServices.setSampleStatus({
      sample_id,
      sample_is_active: Newsample_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Sample " + text + " Successfully!")
          setConfirmationModal({ sample_id: null, status: false });
          //getAllSampleList(1)
          getAllSampleList(currentPage);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
    setConfirmationModal({ sample_id: null, status: false });
  };

  const handleSaveResult = async (payload) => {
    // console.log(" handleSaveResult payload=>", payload)
    let res = await SampleServices.saveSampleTestResult(payload)
    if (res.status == 200) {
      toast.success(res.data.message)
      //getAllSampleList(1)
      getAllSampleList(currentPage, false);
    } else {
      toast.error(res.data.message)
    }
  
    const result_index = payload?.inputIndex;
    const result_value = payload?.result_val.toString();
    const updatedData = [...tempSampleList];
    if (updatedData[result_index]?.test_result.length > 0) {
      updatedData[result_index].test_result[0].ssp_value = result_value;
      setTempSampleList(updatedData);
    }
  
    if (activeIndex < tempSampleList.length - 1) {
      setActiveIndex(payload?.inputIndex + 1);
    }
  }
  
  
  const handleUpdateResult = async (payload) => {
    let res = await SampleServices.updateSampleTestResult(payload)
    if (res.status == 200) {
      toast.success(res.data.message)
      getAllSampleList(currentPage, false);
    } else {
      toast.error(res.data.message)
    }
    
    const result_index = payload?.inputIndex;
    const result_value = payload?.result_val.toString();
    const updatedData = [...tempSampleList];
    if (updatedData[result_index]?.test_result.length > 0) {
      updatedData[result_index].test_result[0].ssp_value = result_value;
      setTempSampleList(updatedData);
    }
  
    if (activeIndex < tempSampleList.length - 1) {
      setActiveIndex(payload?.inputIndex + 1);
    }
  
  }

  const handleValidateResult = async (payload) => {
    // console.log("handleValidateResult=>", payload);
    // return false;
    payload.ssp_pos_neg_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_pos_neg_value : null;
    payload.ssp_text_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_text_value : null;
    payload.ssp_attachment = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_attachment : null;
    payload.ssp_comment = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_comment : null;
    payload.ssp_correction_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_correction_value : null;
    payload.ssp_id = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_id : null;
    let res = await SampleServices.validateResult(payload)
    if (res.status == 200) {
      toast.success(res.data.message)
      //getAllSampleList(1)
      getAllSampleList(currentPage);
    } else {
      toast.error(res.data.message)
    }
  }

  const handleApproveResult = async (payload) => {
    payload.ssp_pos_neg_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_pos_neg_value : null;
    payload.ssp_text_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_text_value : null;
    payload.ssp_attachment = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_attachment : null;
    payload.ssp_comment = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_comment : null;
    payload.ssp_correction_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_correction_value : null;
    payload.ssp_id = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_id : null;
    let res = await SampleServices.approveResult(payload)
    if (res.status == 200) {
      toast.success(res.data.message)
      //getAllSampleList(1)
      getAllSampleList(currentPage);
    } else {
      toast.error(res.data.message)
    }
  }

  const handleMixResultSaveUpdate = () => {
    //console.log("handleMixResultSaveUpdate called");
    //getAllSampleList(1)
    getAllSampleList(currentPage);
  }

  


  // Function to handle page change in the table component
  const handlePageChange = (index) => {
    setLoading(true);
    setCurrentPage(index);
    // Call the fetchUserData function with the updated page
    let payload = {
      page: index,
      pageSize: pageSize
    }
    SampleServices.getAllSampleListForProcessingPagination(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.all_lists?.length > 0) {
            sampleListModification(data.data?.all_lists)
            setMaxVisiblePages(data.data?.maxVisiblePages)
            setPageArr(data.data?.paginationArray);
            setCurrentPage(data.data?.currentPage);
          } else {
            toast.error("SampleList Not Found")
            setLoading(false);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllSampleList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  };


  const handleUpdateComment = async (payload) => {
    // setLoading(true)
    let res = await SampleServices.updateSampleTestResultComment(payload)
    if (res.status == 200) {
      toast.success(res.data.message)
      //getAllSampleList(1)
      getAllSampleList(currentPage);
    } else {
      toast.error(res.data.message)
    }
    setLoading(false)
  }


  const handleSaveUnit = async (originalCell, newUnitValue, ttp_id) => {

    // console.log("ajay originalCell", originalCell);
    // console.log("ajay newUnitValue", newUnitValue);
    // console.log("ajay ttp_id", ttp_id);

    let payload = originalCell;
    payload.newUnitValue = newUnitValue;
    // console.log(" handleSaveResult payload=>", payload)
    let res = await SampleServices.saveSampleTestParamUnitResult(payload)
    if (res.status == 200) {
      toast.success(res.data.message)
      // getAllSampleList(1)
    } else {
      toast.error(res.data.message)
    }
    // const updatedRows = sampleList.map(row => {

    // });
    let singleReservationDetails = await getSingleReservationDetails(originalCell);
    originalCell.all_Test_lists = singleReservationDetails.all_Test_lists;
    originalCell.all_Test_lists_new = singleReservationDetails.all_Test_lists;
    originalCell.all_Pack_lists = singleReservationDetails.all_Pack_lists;
    originalCell.all_lists = singleReservationDetails.all_lists;
    originalCell.ValueType = "Changed";
    // console.log("row happy sampleList=>", originalCell);
    const updatedRows = sampleList.map(row => {
      // console.log("row happy=>", row);
      if (row.sample_id === originalCell.fk_sample_id && row.fk_test_id === originalCell.fk_test_id && row.fk_test_params_id === originalCell.fk_test_params_id && row.reservation_id === originalCell.fk_reservation_id && row.ttp_id === originalCell.ttp_id && row.fk_patient_id === originalCell.fk_patient_id && row.fk_status_id === originalCell.fk_status_id && row.fk_sample_type_id === originalCell.fk_sample_type_id && row.fk_loc_id === originalCell.fk_loc_id && row.sp_gen_id === originalCell.sp_gen_id && row.mrr_id === originalCell.mrr_id) {
        // console.log("Ajay=>", row)

        // originalCell.all_Test_lists.map((val, key) => {
        //   if (val.test_id == originalCell.fk_test_id && val.fk_sample_type_id == originalCell.fk_sample_type_id) {
        //     alert("1")
        //     val.mrr_unit = newUnitValue;

        //     val.all_subtest_lists.map((val2, key2) => {
        //       if (val2.ttp_id == ttp_id) {
        //         val.mrr_unit = newUnitValue;   alert(val.mrr_unit);
        //       }

        //     });
        //   }

        // })
        // originalCell.all_Test_lists_new.map((val, key) => {
        //   if (val.test_id == originalCell.fk_test_id && val.fk_sample_type_id == originalCell.fk_sample_type_id) {
        //     val.mrr_unit = newUnitValue;
        //     val.all_subtest_lists.map((val2, key2) => {
        //       if (val2.ttp_id == ttp_id) {
        //         val.mrr_unit = newUnitValue;
        //       }
        //     });
        //   }
        // })
        originalCell.all_Test_lists = originalCell.all_Test_lists.map((val, key) => {
          if (val.test_id === originalCell.fk_test_id && val.fk_sample_type_id === originalCell.fk_sample_type_id) {
            const updatedVal = {
              ...val, // Copy all properties of val
              mrr_unit: newUnitValue, // Update mrr_unit
              all_subtest_lists: val.all_subtest_lists.map((val2, key2) => {
                if (val2.ttp_id === ttp_id) {
                  return {
                    ...val2, // Copy all properties of val2
                    mrr_unit: newUnitValue // Update mrr_unit in val2
                  };
                }
                return val2; // Return unchanged val2
              })
            };
            return updatedVal; // Return updated object
          }
          return val; // Return unchanged object
        });

        originalCell.all_Test_lists_new = originalCell.all_Test_lists_new.map((val, key) => {
          if (val.test_id === originalCell.fk_test_id && val.fk_sample_type_id === originalCell.fk_sample_type_id) {
            const updatedVal = {
              ...val, // Copy all properties of val
              mrr_unit: newUnitValue, // Update mrr_unit
              all_subtest_lists: val.all_subtest_lists.map((val2, key2) => {
                if (val2.ttp_id === ttp_id) {
                  return {
                    ...val2, // Copy all properties of val2
                    mrr_unit: newUnitValue // Update mrr_unit in val2
                  };
                }
                return val2; // Return unchanged val2
              })
            };
            return updatedVal; // Return updated object
          }
          return val; // Return unchanged object
        });






        // console.log("row2=>", payload.newUnitValue, row)
        row.mrr_unit = newUnitValue;

        // console.log("row3=>", row)
        return row;
      } else {
        return row
      }
    });
    setSampleList(updatedRows);
  }

  const getAllSampleStatuses = () => {
    MasterServices.getAllSampleStatuses()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setSampleStatuses(data.data);
          }
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const handleValidateCheckBoxes = (checked, payload) => {
    //handle validate check boxes
    console.log("checked", checked);
    console.log("row", payload);
    payload.ssp_pos_neg_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_pos_neg_value : null;
    payload.ssp_text_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_text_value : null;
    payload.ssp_attachment = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_attachment : null;
    payload.ssp_comment = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_comment : null;
    payload.ssp_correction_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_correction_value : null;
    payload.ssp_id = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_id : null;
    if(checked) {
      setValidateCheckBoxes(prev => [...prev, { type: 'validate', data: payload, key:`${payload.sample_id}-${payload.ttp_name_en}` }])
    } else {
      setValidateCheckBoxes(prev => [...prev].filter(v => `${payload.sample_id}-${payload.ttp_name_en}` != v.key))
    }
  }

  const handleApproveCheckBoxes = (checked, payload) => {
    //handle approve check boxes
    console.log("checked", checked);
    console.log("row", payload);
    payload.ssp_pos_neg_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_pos_neg_value : null;
    payload.ssp_text_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_text_value : null;
    payload.ssp_attachment = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_attachment : null;
    payload.ssp_comment = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_comment : null;
    payload.ssp_correction_value = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_correction_value : null;
    payload.ssp_id = (payload.test_result && payload.test_result.length > 0) ? payload.test_result[0].ssp_id : null;
    if(checked) {
      setApproveCheckBoxes(prev => [...prev, { type: 'approve', data: payload, key:`${payload.sample_id}-${payload.ttp_name_en}` }])
    } else {
      setApproveCheckBoxes(prev => [...prev].filter(v => `${payload.sample_id}-${payload.ttp_name_en}` != v.key))
    }
  }

  return (
    <>
      {/* <div className="px-4 py-5 mb-2 bg-white rounded-md shadow-lg sm:px-6 ring-1 ring-white ring-opacity-5 focus:outline-none">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
          <div className="flex justify-between py-3 pl-2">
            <div className="flex items-center space-x-2">
              <div className="relative">
                <button
                  onClick={() => {
                    setFilterFlag(filterFlag ? false : true);
                  }}
                  className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1">
                  <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-3 h-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                        />
                      </svg>
                    </div>
                    <div className="hidden sm:block">Filters {filterFlag ? 'On' : 'Off'}</div>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <ConfirmationModal
        title={"Are you sure you want to " + (confirmationModal.sample_is_active ? "Disable" : "Activate") + " this Sample?"}
        confirmationButtonText="Yes"
        // description={"Do you really want to " + (confirmationModal.sample_is_active ? "In-Activate" : "Activate") + " Sample"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteSample(confirmationModal.sample_id, confirmationModal.sample_is_active)}
      />
      <div className="flex items-center justify-between my-2">
        <div className="flex">
          <input
            type="text"
            name="searchByInput"
            id="searchByInput"
            value={searchByInput}
            onChange={e => setSearchByInput(e.target.value)}
            placeholder="search by"
            className="block w-25 h-10 rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
          />
          <select
            className="block w-25 ml-2 h-10 rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
            name="searchByDropdown"
            id="searchByDropdown"
            value={searchByDropdown}
            onChange={e => setSearchByDropdown(e.target.value)}
          >
            <option value="name" selected>Name</option>
            <option value="mobile_no">Mobile No.</option>
            <option value="sample_id">Sample ID</option>
            <option value="reservation_id">Reservation ID</option>
            <option value="mrn">MRN</option>
          </select>
        </div>
        <div className="relative">
          <button
            onClick={() => {
              setFilterFlag(filterFlag ? false : true);
            }}
            className="relative z-0 inline-flex text-sm rounded-md shadow-sm focus:ring-accent-500 focus:border-accent-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1">
            <span className="relative inline-flex items-center px-3 py-3 space-x-2 text-sm font-medium text-gray-600 bg-white border border-gray-300 rounded-md sm:py-2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                  />
                </svg>
              </div>
              <div className="hidden sm:block">Filters {filterFlag ? 'On' : 'Off'}</div>
            </span>
          </button>
        </div>

      </div>
      {filterFlag ? (
        <div className="px-4 py-5 mb-2 bg-white rounded-md shadow-lg sm:px-6 ring-1 ring-white ring-opacity-5 focus:outline-none">
          <div className="space-y-6 sm:space-y-5">
            {loading ? (
              <></>
              // <FallingLinesLoader />
            ) : (

              <div className="grid grid-cols-3 gap-3 mt-5 lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:grid-cols-12 sm:gap-0">
                <div className="basis-3/4"></div>
                <div className="basis-3/4"></div>
                <button
                  type="button"
                  className="px-1 py-1 font-bold text-right text-red-500 rounded hover:text-red-700" onClick={() => setFilterFlag(filterFlag ? false : true)}>X</button>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterFrom"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation From
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="date"
                      name="filterFrom"
                      id="filterFrom"
                      value={filterFrom}
                      onChange={(e) => {
                        setFilterFrom(e.target.value);
                      }
                      }
                    />

                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterTo"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation To
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="date"
                      name="filterTo"
                      id="filterTo"
                      value={filterTo}
                      onChange={(e) => { setFilterTo(e.target.value); }
                      }
                    />
                  </div>
                </div>
                {/* <div className="basis-3/4">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Hospital
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterHos}
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      name="fk_hos_id"
                      id="fk_hos_id"
                      onInput={(e) => { setFilterHos(e.target.value); }}
                    >
                      <option >Select Hospital</option>
                      {hospitalList.map((hospital, i) => (
                        <option selected={hospital.hos_id === filterInitialValues.filterHos ? "selected" : ""} key={i} value={hospital.hos_id}>
                          {hospital.hos_name_en}
                        </option>
                      ))}
                    </select>
                  </div>


                </div> */}

                {/* <div className="basis-3/4">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Organization
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterOrg}
                      onInput={(e) => { setFilterOrg(e.target.value); }}
                      name="filterOrg"
                      id="filterOrg"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm">
                      <option>Select Organization</option>
                      <option>Organization 1</option>
                      <option>Organization 2</option>
                    </select>
                  </div>
                </div> */}
                {/* <div className="basis-3/4">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation Type
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterBkType}
                      onInput={(e) => { setFilterBkType(e.target.value); }}
                      name="filterBkType"
                      id="filterBkType"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm">
                      <option>Select Reservation Type</option>
                      {bookingType.map((bkType, i) => (
                        <option selected={bkType.name === filterInitialValues.filterBkType ? "selected" : ""} key={i} value={bkType.name}>
                          {bkType.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

                {/* <div className="basis-3/4">
                  <label
                    htmlFor="filterStatus"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Status
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <select
                      value={filterStatus}
                      onInput={(e) => { setFilterStatus(e.target.value); }}
                      name="filterStatus"
                      id="filterStatus"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm">
                      <option>Select Status</option>
                      <option value="1">Active</option>
                      <option value="2">In-Active</option>
                    </select>
                  </div>
                </div> */}

                {
                  sampleStatuses && sampleStatuses.length > 0 ? (
                    <div className="basis-3/4">
                      <label
                        htmlFor="filterStatus"
                        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                      >
                        Sample Status
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                        <select
                          value={filterStatus}
                          onInput={(e) => { setFilterStatus(e.target.value); }}
                          name="filterStatus"
                          id="filterStatus"
                          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm">
                          <option>Select Status</option>
                          {sampleStatuses?.map((spStatus, i) => (
                            <option selected={spStatus.sp_status_id === filterInitialValues.filterStatus ? "selected" : ""} key={i} value={spStatus.sp_status_id}>
                              {spStatus.sp_status_name_en}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  ):null
                }

                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientName"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Name
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      value={filterPatientName}
                      onInput={(e) => { setFilterPatientName(e.target.value); }}
                      placeholder="Search By Name"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientName"
                      id="filterPatientName"
                      onKeyUp={(e)=>{
                        if(e.key == "Enter"){
                          onFilter()
                        }
                      }}
                    />
                  </div>
                </div>

                {/* <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientPassport"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Passport
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Passport"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientPassport"
                      id="filterPatientPassport"
                      value={filterPatientPassport}
                      onInput={(e) => { setFilterPatientPassport(e.target.value); }}
                    />
                  </div>
                </div> */}
                {/* <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Patient ID
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Patient ID"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientId"
                      id="filterPatientId"
                      value={filterPatientId}
                      onInput={(e) => { setFilterPatientId(e.target.value); }}
                    />
                  </div>
                </div> */}
                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientMob"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Mobile No.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Mobile No."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientMob"
                      id="filterPatientMob"
                      value={filterPatientMob}
                      onInput={(e) => { setFilterPatientMob(e.target.value); }}
                      onKeyUp={(e)=>{
                        if(e.key == "Enter"){
                          onFilter()
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="basis-3/4">
                  <label
                    htmlFor="filterPatientIdentification"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Identification
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Identification"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterPatientIdentification"
                      id="filterPatientIdentification"
                      value={filterPatientIdentification}
                      onInput={(e) => { setFilterPatientIdentification(e.target.value); }}
                      onKeyUp={(e)=>{
                        if(e.key == "Enter"){
                          onFilter()
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterReservationId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Reservation No.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Reservation No."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterReservationId"
                      id="filterReservationId"
                      value={filterReservationId}
                      onInput={(e) => { setFilterReservationId(e.target.value); }}
                      onKeyUp={(e)=>{
                        if(e.key == "Enter"){
                          onFilter()
                        }
                      }}
                    />
                  </div>
                </div>
                {/* <div className="basis-3/4">
                  <label
                    htmlFor="filterSampleId"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Sample ID.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By Sample ID."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterSampleId"
                      id="filterSampleId"
                      value={filterSampleId}
                      onInput={(e) => { setFilterSampleId(e.target.value); }}
                    />
                  </div>
                </div> */}
                <div className="basis-3/4">
                  <label
                    htmlFor="filterMrn"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    MRN.
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <input
                      placeholder="Search By MRN."
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterMrn"
                      id="filterMrn"
                      value={filterMrn}
                      onInput={(e) => { setFilterMrn(e.target.value); }}
                      onKeyUp={(e)=>{
                        if(e.key == "Enter"){
                          // console.log("Key", e.key)
                          onFilter()
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="basis-3/4">
                  <label
                    htmlFor="filterOutsourcedClinic"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Referral/Outsourced Clinic
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    {/* <input
                      placeholder="Search By Referral/Outsourced Clinic"
                      className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      type="text"
                      name="filterOutsourcedClinic"
                      id="filterOutsourcedClinic"
                      value={filterOutsourcedClinic}
                      onInput={(e) => { setFilterOutsourcedClinic(e.target.value); }}
                    /> */}
                    <Select
                      // defaultValue={testList[0]}
                      isMulti
                      name="filterOutsourcedClinic"
                      id="filterOutsourcedClinic"
                      value={filterOutsourcedClinic}
                      onChange={(e) => handleMultiOutsourceClinic(e)}
                      options={outsourceClinicList}
                      className="basic-multi-select"
                      classNamePrefix="Select Test"
                    />
                  </div>
                </div>

                <div className="basis-3/4"><button
                  type="button"
                  className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                  onClick={() => {
                    onFilter();
                  }}
                  size="default"
                  variant="outlined"

                >Search
                </button><button
                  type="button"
                  className={`rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-1`}
                  onClick={() => {
                    onFilterClear();
                  }}
                  size="default"
                  variant="outlined"

                >Clear
                  </button></div>
              </div>
            )}
          </div>
        </div>
      ) : (<></>)}
        <div className="flex items-center justify-between">
          <div></div>
          <div className="ml-5">
          {/* {validateCheckBoxes.length > 0 && (
            <p>Validate: {validateCheckBoxes.length} </p>
            )}
          {approveCheckBoxes.length > 0 && (
            <p className="ml-4">Approve: {approveCheckBoxes.length} </p>
          )} */}
          {(approveCheckBoxes.length > 0 || validateCheckBoxes.length > 0)&&
            <button
            type="button"
            className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            onClick={() => { validateAndApprove([...approveCheckBoxes, ...validateCheckBoxes]); }}
            >
            {approveCheckBoxes.length > 0 && validateCheckBoxes.length > 0 ? `Validate(${validateCheckBoxes.length}) & Approve(${approveCheckBoxes.length})` : validateCheckBoxes.length > 0 ? `Validate(${validateCheckBoxes.length})` : `Approve(${approveCheckBoxes.length})`}
          </button>
          }
          </div>
        </div>
      <div >
          <div >
            {
              loading ? (
                <FallingLinesLoader />
              ) : ((tempSampleList.length > 0) ? (
                <Table
                  zoom='zoom-06'
                  maxVisiblePages={maxVisiblePages}
                  onPageChange={filterIsOn ? onFilter : handlePageChange}
                  currentPage={currentPage}
                  pageArr={pageArr}
                  columns={sample__processing_columns({ 
                    handleUpdateComment, handleUpdateResult, handleSaveResult, handleValidateResult, handleApproveResult, generateReport, viewReport, selectedLocation,
                    handleUpdateLocChange, handleMixResultSaveUpdate, handleSaveUnit, activeIndex, handleValidateCheckBoxes, handleApproveCheckBoxes, validateCheckBoxes, approveCheckBoxes, changeRefRange })}
                  data={tempSampleList}
                  isSearchBar={false}
                />
              ) : <></>)
            }
          </div>
      </div>

      <Transition.Root show={commentsModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={setCommentsModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 booking-quotation-table-w sm:p-6">
                  <div>
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="pt-5 space-y-6 sm:space-y-5 sm:pt-5">
                          <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Add Comment
                            </h3>
                          </div>
                          <div className="flex items-center justify-between">

                            <textarea
                              type="text"
                              value={commentData}
                              className="block border-gray-300 rounded-md shadow-sm input-w-class focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              onInput={(e) =>
                                setCommentData(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      <div className="pt-5">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setCommentsModal(false)}
                            type="button"
                            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => { updateComment(); }}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
export default SampleProcessing;