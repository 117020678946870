import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toast";
import MasterServices from "../../../ApiServices/MasterServices";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { vendors_list_colums } from "../../../components/tables/tableheader";
import ConfirmationModal from "../../../components/ConfirmationModal";

const VendorsList = () => {
  const pages = [{ title: "Vendors List", href: "/vendors", module_id: 37 }];
  const [loading, setLoading] = useState(false);
  const [vedorList, setVendorList] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		vendor_id: null,
	});


  useEffect(() => {
    getAllVendorList();
    document.title = "Tabib | Vendor List";
  }, []);

  const getAllVendorList = async () => {
    setLoading(true);
    await MasterServices.getVendorList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if(data.data && data.data.length > 0) {
            setVendorList(data.data);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const updteStatusVendor = (id, payload) => {
    MasterServices.updateStatusVendor(id, payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Vendor Status Updated Successfully");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        getAllVendorList();
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const deleteVendor = (payload) => {
    MasterServices.deleteVendor(payload)
      .then((response) => {
        const { data, status } = response;
        setConfirmationModal({ vendor_id: null, status: false });
        if (status == 200) {
          toast.success("Vendor Deleted Successfully");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        getAllVendorList();
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const onDeleteOpen = (vendor_id) => {
		setConfirmationModal({ vendor_id, status: true });
	};


  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => deleteVendor({vendor_id: confirmationModal.vendor_id})}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Vendor List</h1>
      </div>
      <div className="mt-4 flex">
        <Link
          type="button"
          to="/add-vendor"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : loading == false ? (
        <Table
          columns={vendors_list_colums({ onDeleteOpen, updteStatusVendor })}
          data={vedorList}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
};

export default VendorsList;
