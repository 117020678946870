import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { package_columns } from "../../../components/tables/tableheader";
import { toast } from 'react-toast'
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import MasterServices from '../../../ApiServices/MasterServices'
import { read, utils } from 'xlsx';

function PackageList() {
  const pages = [{ title: "Package List", href: "/package", module_id: 7 }];
  const [loading, setLoading] = useState(true)
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    package_id: null,
    package_is_active: false,
  });
  const [packageList, setPackageList] = useState(false)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({
    status: false,
    package_id: null,
  });

  useEffect( () => {
    document.title = "Tabib | Packages";
    setTimeout(()=>{
      onPackageList('All');
    },500)
  }, []);
  
  const onPackageList = async (id) => {
    setLoading(true)
    MasterServices.getPackageList('All')
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setPackageList(data.data);
          } else {
            toast.error("Package Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });

  }

  const onChangeStatusOpen = (package_id, package_is_active) => {
    setConfirmationModal({ package_id, package_is_active, status: true });
  };

  const onDeleteOpen = (package_id) => {
    setDeleteConfirmationModal({ package_id, status: true });
  }


  const onStatusChange = async (package_id, package_is_active) => {
    let Newpackage_is_active = false;
    Newpackage_is_active = package_is_active == true ? false : true;
    let text = "Activated";
    if (Newpackage_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setPackageStatus({
      package_id,
      package_is_active: Newpackage_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Package " + text + " Successfully!")
          setConfirmationModal({ package_id: null, status: false });
          onPackageList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg)
      });
  }

  const onDeletePackage = async (package_id) => {
    MasterServices.deletePackage({
      package_id,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Package Deleted Successfully!")
          setDeleteConfirmationModal({ package_id: null, status: false });
          onPackageList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.meesage;
        toast.error(msg)
      });
  };

  const importPackagesAPICall = (data) => {
    const payload = { excel_data: data }
    // console.log("importPackagesAPICall", payload);
    // return false;
    MasterServices.importPackages(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          console.log("Imported package data:", data)
          toast.success("Packages Imported Successfully");
        } else {
          toast.error(data.message)
        }
        onPackageList('All');
      })
      .catch((error) => {
        const msg = "Error while saving";
        toast.error(msg)
      });
  }

  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          // console.log("handleImport in Package: ", rows);
          let newData = [];
          if (rows.length > 0) {
            rows.map((obj, index) => {
              if (obj.package_name_english != undefined || obj.package_price != null || obj.package_price != '') {
                const newObj = {
                  package_name_en: obj.package_name_english,
                  package_name_ar: obj.package_name_arabic ? obj.package_name_arabic : obj.package_name_english,
                  package_short_description_en: obj.package_short_description_english,
                  package_short_description_ar: obj.package_short_description_arabic,
                  package_long_description_en: obj.package_long_description_english,
                  package_long_description_ar: obj.package_long_description_arabic,
                  package_price: obj.package_price.toString(),
                  package_sub_category: obj.package_sub_category,
                  
                  from_availability_date: obj.available_from_date,
                  to_availability_date: obj.available_to_date, 
                  package_test_names: obj.package_test_names
                  // fk_test_id: filterTest(obj.test_name_english.toLowerCase())
                };
                newData.push(newObj);
              }
            });
            // console.log("newArray: ", newData);
            // const filteredArray = newData.filter(obj => obj.fk_rr_id !== undefined && obj.fk_test_id !== undefined);
            // console.log("filteredArray: ", filteredArray);
            if (newData.length > 0) {
              // console.log("newData", newData)
              importPackagesAPICall(newData);
            }
          } else {
            toast.success("Excel is empty")
          }
        }
      }
      reader.readAsArrayBuffer(file);
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={"Are you sure you want to " + (confirmationModal.package_is_active ? "Disable " : "Activate") + " this Package?"}
        confirmationButtonText="Yes"
        // description={"Do you really want to " + (confirmationModal.package_is_active ? "In-Activate? " : "Activate?")+" Package"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onStatusChange(confirmationModal.package_id, confirmationModal.package_is_active)}
      />
      <ConfirmationModal
        title={"Are you sure you want to delete this Package?"}
        confirmationButtonText="Yes"
        // description={"Do you really want to " + (confirmationModal.package_is_active ? "In-Activate? " : "Activate?")+" Package"}
        open={deleteConfirmationModal.status}
        setOpen={setDeleteConfirmationModal}
        onDelete={() => onDeletePackage(deleteConfirmationModal.package_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Package List</h1>

      </div>
      <div className="mt-4 flex">
        <input type="file"
          onChange={handleImport}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
          placeholder="Import File"
        />
        <Link
          to="/add-package"
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
      </div>
      {
        loading ? (
          <FallingLinesLoader />
        ) : ((packageList.length > 0) ? (
          <Table
            columns={package_columns({ onDeleteOpen, onChangeStatusOpen })}
            data={packageList}
          />
        ) : <TableEmpty />)
      }

    </div >
  );
}
export default PackageList;