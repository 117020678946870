import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { hospitalSchema } from "../../../schemas";
import { useDispatch } from "react-redux";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { hospital_columns } from "../../../components/tables/tableheader";
import {
  deleteHospital, getAllHospitals, addHospital,
  updateHospital
} from "../../../redux/hospital/actions";
import { Dialog, Transition, } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import {
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'


function HospitalList() {
  const initialValues = {
    hos_id: "",
    hos_name_ar: "",
    hos_name_en: "",
    hos_logo_url: "",
    hos_is_active: "",
  };
  const pages = [{ title: "Lab List", href: "/lab", module_id: 4 }];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [hos_id, setHospitalid] = useState('')
  const imageInputRef = useRef("");
  const [loading, setLoading] = useState(true);
  const [hospitalList, setHospitalList] = useState(false)
  const [content, setHospitalLogo] = useState(false);
  const [uploadedMediaPath, setUploadedMediaPath] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    hos_id: null,
    hos_is_active: false,
  });
  const [formState, setFormState] = useState(initialValues);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Tabib | Hospitals";
    setTimeout(() => {
      getAllHospitalList();
    }, 500)
  }, []);
  
  const getAllHospitalList = () => {
    setLoading(true)
    MasterServices.getHospitalList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setHospitalList(data.data);
          } else {
            toast.error("Hospital List Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllHospitalList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  }

  const handleDrawer = (type, id, obj) => {
    if (type === "edit", id) {
      setHospitalid(id)
      const initialValues = {
        hos_id: obj.hos_id,
        hos_name_ar: obj.hos_name_ar,
        hos_name_en: obj.hos_name_en,
        hos_logo_url: obj.hos_logo_url,
        hos_is_active: obj.hos_is_active,
      };
      setFormState(initialValues);
      setUploadedMediaPath(initialValues.hos_logo_url);
      setHospitalLogo(initialValues.hos_logo_url);
    } else {
      setHospitalid("")
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const configHeaders = {
    headers: {
      "content-type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  };

  const UploadImage = async (payload) => {
    // const { data } = await axios.post(`${API}/api/upload/media`, payload, configHeaders);
    // return getAxiosInstance().post(
    //   `${API}/upload/media`,
    //   payload,
    //   configHeaders
    // );
  };

  const handleUploadFileChange = (event) => {
    let inputEle = event?.target;

    if (inputEle && inputEle.files && inputEle.files.length > 0) {
      let file = inputEle.files[0];
      imageInputRef.current = file.name;

      const formData = new FormData();

      let file_type = file.type.split("/")[0];

      formData.append("media_type", file_type);

      setHospitalLogo(URL.createObjectURL(file));

      formData.append("media", file);
      //! work needed on this
      UploadImage(formData)
        .then((res) => {
          let upload_response = res;
          if (upload_response.status) {
            let response_data = upload_response.data;

            setUploadedMediaPath(response_data.data.db_file_path);
            console.log("uploadedPath", response_data.data.db_file_path);
            console.log("mediaUploadResponseData", response_data);
          } else {
            console.log("Data status is false");
          }
        })
        .catch((err) => {
          console.log("Error in uploading image");
        })
        .finally(() => {
          console.log("Uploading finally");
        });
    } else {
      console.log("Please select a file");
    }
  };

  const onDeleteOpen = (hos_id, hos_is_active) => {
    setConfirmationModal({ hos_id, hos_is_active, status: true });
  };

  const onDeleteHospital = (hos_id, hos_is_active) => {
    setLoading(true)
    let Newhos_is_active = false;
    Newhos_is_active = hos_is_active == true ? false : true;
    let text = "Activated";
    if (Newhos_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setHospitalStatus({
      hos_id,
      hos_is_active: Newhos_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Hospital " + text + " Successfully!")
          setConfirmationModal({ hos_id: null, status: false });
          getAllHospitalList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setLoading(false)
      })
      .catch((error) => {
        const msg = error.response.data.message;
        setLoading(false)
        toast.error(msg)
      });
    setConfirmationModal({ hos_id: null, status: false });
    
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formState,
      validationSchema: hospitalSchema,
      onSubmit: (values, action) => {
        let body = {
          hos_id: hos_id,
          hos_is_active: true,
          hos_name_ar: values.hos_name_ar,
          hos_name_en: values.hos_name_en,
          hos_logo_url: uploadedMediaPath,
        }
        if (hos_id === undefined || hos_id === null || hos_id === "") {
          dispatch(addHospital(body));
        }
        else {
          dispatch(updateHospital(hos_id, body));
        }
        dispatch(getAllHospitals());
        action.resetForm();
        if (modalOpenFlage === true) {
          
          setmodalOpenFlage(false);
        };
        navigate("/lab");
      },

    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={"Are you sure you want to " + (confirmationModal.hos_is_active ? "Disable " : "Activate") + " this Hospital?"}
        confirmationButtonText="Yes"
        // description={"Do you really want to " + (confirmationModal.hos_is_active ? "In-Activate" : "Activate")+" Hospital"}
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteHospital(confirmationModal.hos_id, confirmationModal.hos_is_active)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
				<h1 className="text-xl font-semibold text-gray-900">Lab List</h1>
				<p className="mt-2 text-sm text-gray-700">A list of all the Labs.</p>
			</div>
      <div className="mt-4 flex">
        <Link
          to="/add-hospital"
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
      </div>
      {
        loading ? (
          <FallingLinesLoader />
        ) : ((hospitalList.length > 0) ? (
          <Table
            columns={hospital_columns({ onDeleteOpen, handleDrawer })}
            data={hospitalList}
          />
        ) : <TableEmpty />)
      }


      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form onSubmit={handleSubmit} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">

                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {hos_id ? "Update" : "Add"} Hospital
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 px-4 sm:px-6">
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Hospital EN Name
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.hos_name_en}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Hospital EN Name"
                                    name="hos_name_en"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.hos_name_en && touched.hos_name_en ? (
                                    <p className="text-red-600 text-sm">{errors.hos_name_en}</p>
                                  ) : null}
                                </div>
                              </div>
                              <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="project-name"
                                    className="block text-sm font-medium text-gray-900"
                                  >
                                    Hospital AR Name
                                  </label>
                                </div>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <input
                                    value={values.hos_name_ar}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    placeholder="Enter Hospital AR Name"
                                    name="hos_name_ar"
                                    autoComplete="off"
                                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                  />
                                  {errors.hos_name_ar && touched.hos_name_ar ? (
                                    <p className="text-red-600 text-sm">{errors.hos_name_ar}</p>
                                  ) : null}
                                </div>
                              </div>

                              {/* <div className="space-y-6 pt-6 pb-5">
                                <div>
                                  <label
                                    htmlFor="hos_logo_url"
                                    className="relative cursor-pointer rounded-md bg-white font-medium text-cyan-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-cyan-500 focus-within:ring-offset-2 hover:text-cyan-500"
                                  >
                                    <span>Upload a file</span>
                                    <input
                                      id="hos_logo_url"
                                      name="hos_logo_url"
                                      type="file"
                                      className="sr-only"
                                      onChange={(v) => {
                                        handleUploadFileChange(v);
                                      }}
                                    />
                                  </label>
                                  <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs text-gray-500">
                                  Media file up to 10MB
                                </p>
                                {uploadedMediaPath ? (
                                  <h5>{uploadedMediaPath}</h5>
                                ) : null}
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false)
                            }
                            }
                          >
                            Cancel
                          </button>
                          <button
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {hos_id ? 'Update' : 'Add'}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div >
  );
}
export default HospitalList;