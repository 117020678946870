import { useEffect } from 'react';
import { storage_location_list_columns } from '../../../components/tables/tableheader';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Modal from '../../../components/Modal';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import Breadcrumb from '../../../components/Breadcrumb';
import Select from 'react-select';
import chroma from 'chroma-js';

import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import { FallingLines } from 'react-loader-spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';
import ConfirmationModal from '../../../components/ConfirmationModal';
const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    name: '',
    lab_id: '',
    address: '',
  },
};

function StorageLocation() {
  const [loading_button, set_loading_button] = useState(false);
  const pages = [{ title: 'Storage Location', href: '/storage-locations', module_id: 29 }];
  const [loading, setLoading] = useState(false);
  const [packageList, setPackageList] = useState(false);
  const [selected_box, set_selected_box] = useState({});
  const [controlList, setControlList] = useState([]);
  const [modal, setModal] = useState(initialModalState);

  const navigate = useNavigate();
  useEffect(() => {
    getAllLabs();
    getAllStorageLocations();
    document.title = 'New Tabib | Storage Location';
  }, []);
  useEffect(() => {
    if (modal.edit_id !== '') {
      // getStorageLocationBId(modal.edit_id);
    }
  }, [modal.edit_id]);
  const [labs, setLabs] = useState({
    loading: false,
    data: [],
  });
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    sl_id: null,
  });
  const [storageLocations, setStorageLocations] = useState({
    loading: false,
    data: [],
  });

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),

    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   return {
    //     ...styles,
    //     backgroundColor: isDisabled
    //       ? undefined
    //       : isSelected
    //       ? data.color
    //       : isFocused
    //       ? 'transparent'
    //       : undefined,
    //     color: isDisabled
    //       ? '#ccc'
    //       : isSelected
    //       ? 'transparent'
    //         ? 'white'
    //         : 'black'
    //       : data.color,
    //     cursor: isDisabled ? 'not-allowed' : 'default',

    //     ':active': {
    //       ...styles[':active'],
    //       backgroundColor: !isDisabled
    //         ? isSelected
    //           ? 'cyan'
    //           : '#155E75'
    //         : undefined,
    //     },
    //   };
    // },
    input: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        border: isFocused ? 'transparent' : '',
      };
    },

    placeholder: (styles) => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  const getAllLabs = async () => {
    setLabs((prev) => ({ ...prev, loading: true }));
    await MasterServices.getLabs()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setLabs({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createStorageLocation = async (body) => {
    set_loading_button(true);
    setLoading(true);
    await MasterServices.addStorageLocation(body)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllStorageLocations();
          toast.success('Storage Location Added Successfully');
        }
        set_loading_button(false);
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
        set_loading_button(false);
        setLoading(false);
      });
  };

  const getAllStorageLocations = async () => {
    setStorageLocations((prev) => ({ ...prev, loading: true }));
    await MasterServices.getAllStorageLocation()
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 200) {
          setStorageLocations({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        setStorageLocations((prev) => ({ ...prev, loading: false }));
        console.log(err);
      });
  };

  const getStorageLocationBId = async (id) => {
    await MasterServices.getOneStorageLocation(id)
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 200) {
          setModal((prev) => ({
            ...prev,
            type: 'edit',
            data: {
              name: data.data.name,
              address: data.data.address,
              lab_id: data.data.lab_id,
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateStorageLocation = async (id, body) => {
    set_loading_button(true);
    setStorageLocations((prev) => ({ ...prev, loading: true }));

    await MasterServices.updateStorageLocation(id, body)
      .then((res) => {
        const { data, status } = res;
        if (status === 200 && data.status) {
          getAllStorageLocations();
          toast.success('Storage Location Updated Successfully');
          setModal((prev) => ({
            ...prev,
            data: {},
            type: '',
            edit_id: '',
            state: false,
          }));
        }
        set_loading_button(false);
      })
      .catch((err) => {
        setStorageLocations((prev) => ({ ...prev, loading: false }));
        toast.error(err.data.message);
        console.log(err);
        set_loading_button(false);
      });
  };
  const onDeleteOpen = (id) => {
    setConfirmationModal({ sl_id: id, status: true });
  };

  const onDeleteStorageLocation = async (id) => {
    await MasterServices.deleteStorageLocation(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllStorageLocations();
          toast.success('Storage Location Deleted Successfully');
          setConfirmationModal({ sl_id: null, status: false });
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };
  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('Please enter name'),
          address: Yup.string().required('Please enter address'),
          lab_id: Yup.number().required('Please Select Lab'),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          const body = {
            name: values.name,
            address: values.address,
            lab_id: values.lab_id,
          };
          if (modal.type === 'edit') {
            updateStorageLocation(modal.edit_id, body);
          } else {
            createStorageLocation(body);
            action.resetForm();
          }
          setModal((prev) => ({ ...prev, data: {}, state: false }));

          action.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Modal
            title={`${
              modal.type === 'edit' ? 'Update' : 'Add'
            } Storage Location`}
            open={state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, data: {}, state: false }));
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="name"
                  label="Name"
                  type="text"
                  value={values.name}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Location Name"
                />
                {touched.name && (
                  <p className="text-red-700 error_msg">{errors.name}</p>
                )}
              </div>

              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="address"
                  label="Address"
                  type="text"
                  value={values.address}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Address"
                />
                {touched.address && (
                  <p className="text-red-700 error_msg">{errors.address}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="lab_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Lab
                </label>

                <Select
                  className="z-99"
                  defaultValue={
                    values.lab_id
                      ? {
                          lab_id: values.lab_id,
                          label: labs.data.filter((la) => {
                            if (la.loc_id == values.lab_id) {
                              return la;
                            }
                          })[0].loc_name_en,
                        }
                      : ''
                  }
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue('lab_id', e.value);
                  }}
                  options={labs.data.map((sin) => {
                    return { value: sin.loc_id, label: sin.loc_name_en };
                  })}
                />
                {<p className="text-red-700 error_msg">{errors.lab_id}</p>}
              </div>
              <div className="mt-4"></div>
              <Button disabled={isSubmitting} type="submit">
                {loading_button ? (
                  <FallingLines color="white" width="30" visible={true} />
                ) : (
                  `${modal.type === 'edit' ? 'Update' : 'Add'} Storage Location`
                )}
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {renderModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteStorageLocation(confirmationModal.sl_id)}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          Storage Location
        </h1>
      </div>
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() =>
            setModal((prev) => ({
              ...prev,
              data: {},
              type: '',
              edit_id: '',
              state: true,
            }))
          }
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {storageLocations.loading ? (
        <FallingLinesLoader />
      ) : storageLocations.data.length > 0 ? (
        <Table
          columns={storage_location_list_columns({ setModal, onDeleteOpen })}
          data={storageLocations.data}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}
export default StorageLocation;
