import React, { useState, useEffect, } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import { coupon_report_columns, all_logs_report_columns, urgent_logs_report_columns } from '../../../components/tables/tableheader';
import MasterServices from '../../../ApiServices/MasterServices'
import moment from 'moment';
import Select from 'react-select'
import ReservationServices from '../../../ApiServices/ReservationServices';
import { toast } from 'react-toast';
import { utils, writeFile } from 'xlsx';
import { capitalise } from '../../../utilities/utilities';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { convertToAnotherTimezone } from '../../../helper/commonHelper';

const initialUrgentLogsFilters = {
    start_date: '',
    end_date: '',
    users: []
}

export default function UrgentLogs() {

    const pages = [{ title: "Urgent Report", href: "/urgent-logs" }];
    const [isLoading, setIsLoading] = useState(true)
    const [urgentLogsData, setUrgentLogsData] = useState([])
    const [urgentLogsFilters, setUrgentLogsFilters] = useState(initialUrgentLogsFilters)
    const [selectedUsers, setSelectedUsers] = useState(false)
    const [userLoading, setUserLoading] = useState(true)
    const [userList, setUserList] = useState(false);
    const printRef = React.useRef();

    useEffect(() => {
        getAllUsers()
        getUrgentLogs();
    }, [])

    const getAllUsers = async () => {
        try {
            setUserLoading(true)
            const { data } = await ReservationServices.getAllPatientList();
            if (data.status) {
                let newUsers = data.data;
                let users = newUsers.map((el) => {
                    let obj = { ...el, label: el.patient_first_name, value: el.patient_id }
                    return obj
                })
                setUserList(users);
            }
            setUserLoading(false)
        } catch (error) {
            console.log(error);
            setUserLoading(false)
        }
    };

    async function getUrgentLogs() {
        setIsLoading(true)
        try {
            const { data, status } = await MasterServices.getUrgentLogs();
            if (status === 200) {
                setUrgentLogsData(data.data)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    async function getUrgentLogsFilter(payload) {
        setIsLoading(true)
        try {
            const { data, status } = await MasterServices.getUrgentLogsFilter(payload);
            if (status === 200) {
                setUrgentLogsData(data.data)
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    const generateObjectToPdf = async () => {
        // setIsLoading(true)
        try {
            let newSheetdata = [];
            if (urgentLogsData?.length > 0) {
                urgentLogsData.map((obj, index) => {
                    const newData = {
                        reservationId: obj?.resv_tx_resv_id,
                        patientName: obj?.resv_tx_patient_fname_en,
                        testID: obj?.resv_tx_test_id ? obj?.resv_tx_test_id : obj?.resv_tx_package_id,
                        testPackage: obj?.resv_tx_test_name_en ? obj?.resv_tx_test_name_en : obj?.resv_tx_package_name_en,
                        type: obj?.resv_is_package === "Y" ? "Package" : "Test",
                        time: moment(convertToAnotherTimezone(obj?.resv_tx_updated_at)).format('DD-MM-YYYY hh:mm:ss A')
                    }
                    newSheetdata.push(newData);
                })
            }
            let payload = {
                columns: ['RESERVATION-ID', 'PATIENT-NAME', 'TEST-PACKAGE ID', 'TEST/PACKAGE', 'TYPE', 'TIME'],
                pdf_name: 'Urgent_Report_' + moment(urgentLogsFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(urgentLogsFilters.end_date).format("DD-MM-YYYY"),
                rows: newSheetdata
        }
            const { data, status } = await MasterServices.generateObjectToPDF(payload);
            console.log(data)
            if (status === 200) {
                if(data?.data?.pdf_url != '') {
                    // window.location.replace(data.data.pdf_url);
                    window.open(data.data.pdf_url, '__blank');
                }
                    // setResUserReportData(data.data)
            }
            // setIsLoading(false)
        } catch (error) {
            console.log(error)
            // setIsLoading(false)
        }
    }

    async function getUrgentLogsPDF() {
        try {
            const { data } = await MasterServices.getUrgentLogsPDF(urgentLogsFilters);

            // console.log(data)

            const blob = await new Blob([data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'generated.pdf';
            link.click();
            URL.revokeObjectURL(url);
            console.log('PDF downloaded successfully!');

        } catch (error) {
            console.log(error)
        }
    }

    const handleMultiUsers = (event) => {
        setSelectedUsers(event)
        let users = [];
        for (let i = 0; i < event.length; i++) {
            users.push(event[i].value)
        }
        setUrgentLogsFilters((prev) => {
            return {
                ...prev,
                users,
            }
        })
    }

    const handleExport = (excel_type) => {
        if (urgentLogsData?.length > 0) {
            let newSheetdata = [];
            urgentLogsData.map((obj, index) => {
                const newData = {
                    reservationId: obj?.resv_tx_resv_id,
                    patientName: obj?.resv_tx_patient_fname_en,
                    testID: obj?.resv_tx_test_id ? obj?.resv_tx_test_id : obj?.resv_tx_package_id,
                    testPackage: obj?.resv_tx_test_name_en ? obj?.resv_tx_test_name_en : obj?.resv_tx_package_name_en,
                    type: obj?.resv_is_package === "Y" ? "Package" : "Test",
                    time: moment(convertToAnotherTimezone(obj?.resv_tx_updated_at)).format('DD-MM-YYYY hh:mm:ss A')
                }
                newSheetdata.push(newData);
            })
            let fileName = 'Urgent_Report_' + moment(urgentLogsFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(urgentLogsFilters.end_date).format("DD-MM-YYYY") + '.' + excel_type;

            const headings = [['RESERVATION-ID', 'PATIENT-NAME', 'TEST-PACKAGE ID', 'TEST/PACKAGE', 'TYPE', 'TIME']];
            const wb = utils.book_new();
            const ws = utils.json_to_sheet([]);
            utils.sheet_add_aoa(ws, headings);
            utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
            utils.book_append_sheet(wb, ws, 'Report');
            writeFile(wb, fileName);
        } else {
            toast.error("Data Not Found!");
        }
    }

    const handlePdfGenerate = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);

        let fileName = 'Coupon_Report_From_' + moment(urgentLogsFilters.start_date).format("DD-MM-YYYY") + "_To_" + moment(urgentLogsFilters.end_date).format("DD-MM-YYYY") + '.pdf';

        pdf.save(fileName);
    }



    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">

                <Breadcrumb pages={pages} />
                <div className="">
                    <h1 className="text-xl font-semibold text-gray-900">Urgent Logs</h1>
                </div>

                <div className="mt-5 flex gap-3 items-center">

                    {/* start date */}
                    <div className=" sm:mt-0">
                        <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date From</label>

                        <input
                            disabled={isLoading}
                            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                            type="date"
                            name="start_date"
                            id="start_date"
                            value={urgentLogsFilters.start_date}
                            onChange={(e) => {
                                setUrgentLogsFilters((prev) => {
                                    if (prev.end_date < e.target.value) {
                                        return {
                                            ...prev,
                                            end_date: e.target.value,
                                            start_date: prev.end_date
                                        }
                                    }
                                    return {
                                        ...prev,
                                        start_date: e.target.value,
                                    }
                                })
                            }
                            }
                        />
                    </div>

                    {/* end date */}
                    <div className=" sm:mt-0">
                        <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date To</label>

                        <input
                            disabled={isLoading}
                            className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
                            type="date"
                            name="end_date"
                            id="end_date"
                            value={urgentLogsFilters.end_date}
                            onChange={(e) => {
                                setUrgentLogsFilters((prev) => {
                                    if (prev.start_date > e.target.value) {
                                        return {
                                            ...prev,
                                            end_date: prev.start_date,
                                            start_date: e.target.value
                                        }
                                    }
                                    return {
                                        ...prev,
                                        end_date: e.target.value,
                                    }
                                })
                            }
                            }
                        />
                    </div>

                    {
                        !userLoading ?
                            <div className="">
                                <label
                                    htmlFor="fk_location_ids"
                                    className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Users</label>
                                <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                        // defaultValue={testList[0]}
                                        isMulti
                                        name="fk_user_ids"
                                        id="fk_user_ids"
                                        value={selectedUsers}
                                        onChange={(e) => {
                                            console.log(e)
                                            handleMultiUsers(e)}}
                                        options={userList}
                                        className="basic-multi-select"
                                        classNamePrefix="Select Test"
                                    />
                                </div>
                            </div>
                            : null

                    }


                    <button
                        disabled={isLoading}
                        onClick={() => {
                            getUrgentLogsFilter(urgentLogsFilters)
                        }}
                        className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${isLoading ? "opacity-40" : "opacity-100"}`}
                    >
                        Get Data
                    </button>
                </div>

                <div className='mt-5 flex gap-1 items-center'>
                    {/* <button
                    onClick={() => handleExport('copy')}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mr-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    Copy
                  </button> */}
                    <button
                        onClick={() => handleExport('csv')}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        CSV
                    </button>

                    <button
                        onClick={() => handleExport('xlsx')}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        Excel
                    </button>

                    <button
                        onClick={() => generateObjectToPdf()}
                        // onClick={() => getUrgentLogsPDF()}
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                        PDF
                    </button>

                    {/* 
                  <button
                    onClick={() => handlePrint()}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                    Print
                  </button> */}
                </div>
            </div>
            {
                isLoading ? (
                    <FallingLinesLoader />
                ) : ((urgentLogsData.length > 0) ? (
                    <Table
                        printRef={printRef}
                        columns={urgent_logs_report_columns()}
                        data={urgentLogsData}
                    />
                ) : <TableEmpty />)
            }

        </>
    )

}