import { useEffect } from 'react';
import {
  storage_item_location_list_columns,
  storage_location_list_columns,
} from '../../../components/tables/tableheader';
import { useNavigate } from 'react-router-dom';
import { FallingLines } from 'react-loader-spinner';
import Select from 'react-select';

import { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Modal from '../../../components/Modal';
import InputBox from '../../../components/InputBox';
import Button from '../../../components/Button';
import Breadcrumb from '../../../components/Breadcrumb';
import { FallingLinesLoader } from '../../../components/spinners/Spinner';
import Table from '../../../components/tables/table';
import TableEmpty from '../../../components/tables/tableEmpty';
import MasterServices from '../../../ApiServices/MasterServices';
import { toast } from 'react-toast';
import ConfirmationModal from '../../../components/ConfirmationModal';
const initialModalState = {
  type: '',
  state: false,
  index: null,
  edit_id: '',
  data: {
    quantity: '',
    item_id: '',
    storage_loc_id: '',
  },
};
const filterInitialValues = {
  start_date: '',
  end_date: '',
  lab_ids: '',
}

function StorageLocationItem() {
  const pages = [{ title: 'Stock', href: '/storage-locations-items', module_id: 30 }];
  const [loading_button, set_loading_button] = useState(false);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [reportFilters, setReportFilters] = useState(filterInitialValues)
  const [selected_box, set_selected_box] = useState({
    item: null,
    storage_loc: null,
  });

  const [loading, setLoading] = useState(false);
  const [packageList, setPackageList] = useState(false);
  const [controlList, setControlList] = useState([]);

  const [modal, setModal] = useState(initialModalState);
  const [itemsList, setItemsList] = useState([]);
  const [storageLocations, setStorageLocations] = useState({
    loading: false,
    data: [],
  });
  const [storageLocationItems, setStorageLocationitems] = useState({
    loading: false,
    data: [],
  });
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    sli_id: null,
  });
  const navigate = useNavigate();
  useEffect(() => {
    getAllStorageLocationItems();
    getAllStorageLocations();
    getAllItems();
    document.title = 'New Tabib | Stock';
  }, []);
  useEffect(() => {
    if (modal.edit_id !== '') {
      getStorageLocationItemById(modal.edit_id);
    }
  }, [modal.edit_id]);
  const getAllStorageLocations = async () => {
    setStorageLocations({ loading: true, data: [] });
    await MasterServices.getAllStorageLocation()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setStorageLocations({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllItems = async () => {
    setLoading(true);
    await MasterServices.getItemsList()
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          setItemsList(data.data);
          setLoading(false);
        } else {
          toast.error('Fatal Error Please Contact Admin');
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
    setLoading(false);
  };
  const createStorageLocationItem = async (body) => {
    set_loading_button(true);
    await MasterServices.addStorageLocationItem(body)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllStorageLocationItems();
          toast.success('Stock Added Successfully');
          setModal((prev) => ({ ...prev, data: {}, state: false }));
          set_loading_button(false);
        }
      })
      .catch((err) => {
        set_loading_button(false);
        toast.error(err.data.message);
        console.log(err);
      });
  };

  const getAllStorageLocationItems = async () => {
    setStorageLocations((prev) => ({ ...prev, loading: true }));
    await MasterServices.getAllStorageLocationItem()
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 200) {
          setStorageLocationitems({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStorageLocationItemById = async (id) => {
    await MasterServices.getOneStorageLocationItem(id)
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 200) {
          setModal((prev) => ({
            ...prev,
            type: 'edit',
            data: {
              item_id: data.data.item_id,
              quantity: data.data.quantity,
              storage_loc_id: data.data.storage_loc_id,
            },
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateStorageLocationItem = async (id, body) => {
    await MasterServices.updateStorageLocationItem(id, body)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllStorageLocationItems();
          toast.success('Stock Updated Successfully');
          setModal((prev) => ({
            ...prev,
            data: {},
            type: '',
            edit_id: '',
            state: false,
          }));
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };

  const getFilterdData = async () => {
    setStorageLocations((prev) => ({ ...prev, loading: true }));
    let payload = {
      start_date: reportFilters.start_date,
      end_date: reportFilters.end_date,
      lab_ids: Array.isArray(reportFilters.lab_ids) ? reportFilters.lab_ids.map(v => v.value) : []
    }
    await MasterServices.getAllStorageLocationFilterdItem(payload)
      .then((res) => {
        const { data, status } = res;
        console.log(res);
        if (status === 200) {
          setStorageLocationitems({
            loading: false,
            data: data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const onDeleteOpen = (id) => {
    setConfirmationModal({ sli_id: id, status: true });
  };
  const onDeleteStorageLocationItem = async (id) => {
    await MasterServices.deleteStorageLocationItem(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 201) {
          getAllStorageLocationItems();
          toast.success('Stock Deleted Successfully');
          setConfirmationModal({ sli_id: null, status: false });
        }
      })
      .catch((err) => {
        toast.error(err.data.message);
        console.log(err);
      });
  };
  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object().shape({
          quantity: Yup.number().required('Please enter the quantity'),
          storage_loc_id: Yup.string().required(
            'Please select the storage location'
          ),
          item_id: Yup.string().required('Please select the item'),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          const body = {
            quantity: parseInt(values.quantity),
            storage_loc_id: parseInt(values.storage_loc_id),
            item_id: parseInt(values.item_id),
          };

          if (modal.type === 'edit') {
            updateStorageLocationItem(modal.edit_id, body);
          } else {
            createStorageLocationItem(body);
            action.resetForm();
          }
          action.setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <Modal
            title={`${modal.type === 'edit' ? 'Update' : 'Add'} Stock`}
            open={state}
            setOpen={() => setModal((prev) => ({ ...prev, state: false }))}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="item_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Item
                </label>
                <Select
                  defaultValue={values?.label}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    setFieldValue('item_id', e.value);
                  }}
                  options={itemsList.map((item) => {
                    return {
                      value: item.witem_id,
                      label: item.witem_name_en,
                    };
                  })}
                />

                {<p className="text-red-700 error_msg">{errors.item_id}</p>}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="quantity"
                  label="Quantity"
                  type="number"
                  value={values.quantity}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Quantity"
                />
                {touched.quantity && (
                  <p className="text-red-700 error_msg">{errors.quantity}</p>
                )}
              </div>

              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <label
                  htmlFor="storage_loc_id"
                  className="block text-sm font-medium text-gray-700"
                >
                  Storage Location
                </label>
                <Select
                  id="storage_loc_id"
                  name="storage_loc_id"
                  defaultValue={values?.label}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(e) => {
                    setFieldValue('storage_loc_id', e.value);
                  }}
                  options={storageLocations.data.map((sin) => {
                    return { value: sin.sl_id, label: sin.name };
                  })}
                />
                {
                  <p className="text-red-700 error_msg">
                    {errors.storage_loc_id}
                  </p>
                }
              </div>
              <div className="mt-4"></div>
              <Button disabled={isSubmitting} type="submit">
                {loading_button ? (
                  <FallingLines color="white" width="30" visible={true} />
                ) : (
                  `${modal.type === 'edit' ? 'Update' : 'Add'} Stock`
                )}
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {renderModal()}
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteStorageLocationItem(confirmationModal.sli_id)}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Stock</h1>
      </div>
      <div className="mt-5 flex gap-3 items-center">
      {/* start date */}
      <div className=" sm:mt-0">
          <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date From</label>

          <input
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="start_date"
              id="start_date"
              value={reportFilters.start_date}
              onChange={(e) => {
                  setReportFilters((prev) => {
                      // if (prev.end_date < e.target.value) {
                      //     return {
                      //         ...prev,
                      //         end_date: e.target.value,
                      //         start_date: prev.end_date
                      //     }
                      // }
                      return {
                          ...prev,
                          start_date: e.target.value,
                      }
                  })
              }
              }
          />
      </div>

      {/* end date */}
      <div className=" sm:mt-0">
          <label htmlFor="fk_location_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Date To</label>

          <input
              className="block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-60 sm:text-sm"
              type="date"
              name="end_date"
              id="end_date"
              value={reportFilters.end_date}
              onChange={(e) => {
                  setReportFilters((prev) => {
                      // if (prev.start_date > e.target.value) {
                      //     return {
                      //         ...prev,
                      //         start_date: prev.start_date,
                      //         end_date: e.target.value
                      //     }
                      // }
                      return {
                          ...prev,
                          end_date: e.target.value,
                      }
                  })
              }
            }
          />
      </div>
        <div className="">
            <label
                htmlFor="lab_ids"
                className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Select Location</label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
                <Select
                    // defaultValue={testList[0]}
                    isMulti
                    name="lab_ids"
                    id="lab_ids"
                    value={reportFilters.lab_ids}
                    onChange={(e) => setReportFilters((prev) => ({...prev, lab_ids: e}))}
                    className="basic-multi-select"
                    classNamePrefix="Select Test"
                    options={storageLocations?.data?.map((sin) => {
                      return { value: sin.sl_id, label: sin.name };
                    })}
                />
            </div>
        </div>
      <button
          onClick={() => {
              getFilterdData()
          }}
          className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
      >
          Filter
      </button>
      </div>
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() =>
            setModal((prev) => ({
              ...prev,
              data: {},
              type: '',
              edit_id: '',
              state: true,
            }))
          }
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : storageLocationItems.data.length > 0 ? (
        <Table
          columns={storage_item_location_list_columns({
            setModal,
            onDeleteOpen,
          })}
          data={storageLocationItems.data}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}
export default StorageLocationItem;
