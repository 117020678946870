import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../components/Breadcrumb'
import Select from "react-select";
import { getAllRoles } from '../../../redux/role/actions';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../../components/tables/table';
import { role_columns } from '../../../components/tables/tableheader';

const NotificationTemplate = () => {
  const pages = [{ title: "Notification Template", href: "/notification-template" }];
	const { loading, list: roleList } = useSelector((state) => state.role);
	const dispatch = useDispatch();
  const [roles, setRoles] = useState(roleList)

  useEffect(() => {
		document.title = "Tabib | Notification Template";
		dispatch(getAllRoles());
    // getAllRoleId()
	}, []);

  const handleSubmit = (e) => {
    console.log(e)
  }
  // const getAllRoleId = () => {
  //   setRoles({
  //     ...roles,
  //     data: roles.map(v => ({value: v.role_id, label: v.rol_name })),
  //   });
  // }

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Notification Template</h3>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="from_id"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    From Id
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      name="from_id"
                      id="from_id"
                      isMulti
                      // value={selectedVendorId}
                      // onChange={(e) => { setSelectedVendorId(e); values.vendor_id = e.value }}
                      // onBlur={handleBlur}
                      options={roles}
                      className="block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    />
                    {/* {errors.vendor_id && touched.vendor_id ? (
                      <p className="text-red-700">{errors.vendor_id}</p>
                    ) : null} */}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="to_id"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    To Id
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      name="to_id"
                      id="to_id"
                      isMulti
                      // value={selectedLocationId}
                      // onChange={(e) => setSelectedLocationId(e)}
                      // onBlur={handleBlur}
                      options={roles}
                      className="block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    />
                    {/* {errors.loc_id && touched.loc_id ? (
                      <p className="text-red-700">{errors.loc_id}</p>
                    ) : null} */}
                  </div>
                  
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                      Title
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="title"
                        id="title"
                        autoComplete="off"
                        className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                      Description
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <textarea
                        id="description"
                        name="description"
                        rows={3}
                        className="block w-full max-w-lg rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:py-1.5 sm:text-sm sm:leading-6"
                        defaultValue={''}
                      />
                      <p className="mt-2 text-sm text-gray-500">Write a few sentences about yourself.</p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div className="pt-5">
        <div className="flex justify-end gap-x-3">
          <button
            type="button"
            className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-cyan-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-cyan-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
          >
            Save
          </button>
        </div>
      </div>
        </form>
      </div>
    </div>
  )
}

export default NotificationTemplate