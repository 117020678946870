import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// import { userSchema } from "../../../schemas";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
// import { useDispatch } from "react-redux";
import { user_columns } from "../../../components/tables/tableheader";
import {
	deleteUser,
	getAllUsers,
	addUser,
	updateUser,
} from "../../../redux/user/actions";

import { getAllRoles } from "../../../redux/role/actions";
import { getAllLocations } from "../../../redux/location/actions";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CleaningServices, SettingsPhoneRounded } from "@mui/icons-material";
import AuthServices from "../../../ApiServices/AuthServices";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { singleUploadFiles } from "../../../helper/multiUploadHelper";
import { FileUploader } from "react-drag-drop-files";
import PhoneInputWithCountry, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import { FaSpinner } from "react-icons/fa";
import makeAnimated from 'react-select/animated';
import Select from "react-select";
import queryString from 'query-string';


const fileTypes = ["JPEG", "PNG", "GIF"];

function UserList() {
	const pages = [{ title: "User List", href: "/users", module_id: 2 }];
	const animatedComponents = makeAnimated();
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [signProgress, setSignProgress] = useState();
	const [signFileName, setSignFileName] = useState(false);
	const [signFileNamePath, setSignFilePath] = useState('');
	const [user_id, setUserid] = useState("");
	const [locationList, setLocationList] = useState(false)
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [userMobileNumber, setUserMobileNumber] = useState('');
	const [mobileNumberValid, setMobileNumberValid] = useState(true);
	const [mobileCountryCode, setMobileCountryCode] = useState('')
	const [initialRender, setInitialRender] = useState(true);
	const [selectedUserRoleId, setSelectedUserRoleId] = useState({ value: '', label: '' });
	const [selectedLocationId, setSelectedLocationId] = useState({ value: '', label: '' });
	const [selectedClinicId, setSelectedClinicId] = useState({ value: '', label: '' });

	const [outSourcingClinicId, setOutSourcingClinicId] = useState(null);
	const [outsourceClinicList, setOutsourceClinicList] = useState([]);
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		user_id: null,
	});

	const [selectMutiLocation, setMutiLocation] = useState(false);
	const [multiLocationId, setMultiLocationId] = useState();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		user: { loading: userLoading, list: userList },
		role: { list: roleList },
	} = useSelector((state) => state);


	//Mobile number validation in patient
	useEffect(() => {
		getAllActiveLocationList();
		if (!initialRender) {
			// console.log(mobileNumber.toString())
			if (mobileNumber && isValidPhoneNumber(mobileNumber.toString())) {
				const phoneNumber = parsePhoneNumber(mobileNumber)
				console.log(phoneNumber)
				if (phoneNumber) {
					setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
					setUserMobileNumber(phoneNumber.nationalNumber);
				}
				setMobileNumberValid(true);
			} else {
				setMobileNumberValid(false)
			}
		} else {
			setInitialRender(false)
		}
	}, [mobileNumber])


	const getAllActiveLocationList = () => {
		let payload = {
			search: 1
		};
		MasterServices.getAllLocationList(queryString.stringify(payload))
			.then((response) => {
				const { data, status } = response;
				if (status == 200) {
					if (data?.data?.length > 0) {
						// setLocationList(data.data);

						let locationnew = data.data;
						if (locationnew.length > 0) {
							let locations = [];
							locationnew.forEach((element) => {
								let obj = {
									label: element.label,
									value: element.value,
									m_user_loc_id: 0,
								}

								locations.push(obj);
							})
							setLocationList(locations);
						}
					} else {
						// toast.error("Location List Not Found");
					}
				} else {
					toast.error("Fatal Error Please Contact Admin")
				}
				setIsLoading(false);
			})
			.catch((error) => {
				const msg = "getAllLocationTestList catch Error";
				toast.error(msg);
				setIsLoading(false);
			});
	}

	const getAllClinicList = () => {
		MasterServices.getClinicList()
			.then((response) => {
				const { data, status } = response;
				if (status == 200) {
					if (data?.data?.length > 0) {
						// setLocationList(data.data);

						let clinicnew = data.data || [];
						if (clinicnew.length > 0) {
							let clinics = [];
							clinicnew.forEach((element) => {
								let obj = {
									label: element.mc_name,
									value: element.mc_id,
								}

								clinics.push(obj);
							})
							setOutsourceClinicList(clinics);
						}
					} else {
						// toast.error("Location List Not Found");
					}
				} else {
					toast.error("Fatal Error Please Contact Admin")
				}
				setIsLoading(false);
			})
			.catch((error) => {
				const msg = "getAllLocationTestList catch Error";
				toast.error(msg);
				setIsLoading(false);
			});
	}

	const onMobileNumberChange = (value) => {
		if (value) {
			setMobileNumber(value)
		}
	}

	useEffect(() => {
		document.title = "Tabib | Users";
		setIsLoading(true)
		dispatch(getAllUsers());
		dispatch(getAllRoles());
		// getAllLocationList()
		getAllClinicList();
		setIsLoading(false)
	}, []);

	const userSchema = Yup.object({
		user_username: Yup.string()
		.min(3)
		.max(25)
		.required("Please Enter the Username"),
		user_first_name: Yup.string()
			.min(3)
			.max(25)
			.required("Please Enter the First Name"),
		user_last_name: Yup.string()
			.min(2)
			.max(25)
			.required("Please Enter the Last Name"),
		user_email: Yup.string().email().required("Please Enter the Email address"),
		// user_mobile: Yup.string()
		// 	.min(10)
		// 	.max(10)
		// 	.required("Please Enter the Mobile Number"),
		user_password: user_id ? Yup.string() : Yup.string().min(6).required("Please Enter the Password"),
		user_role: Yup.string().required("Please Enter the Role"),
		mc_id: Yup.string().when('user_role', {
			is: '13',
			then: Yup.string().required('Please Select Clinic'),
			otherwise: Yup.string().nullable()
		}),
		/* mc_id: Yup.string().when('user_role', {
			is: (value) => value === 13,
			then: Yup.string().required('Please Select Clinic'),
			otherwise: Yup.string()
		}).nullable() */
	});

	// const getAllLocationList = async (hos_id) => {
	// 	setIsLoading(true)
	// 	MasterServices.getLocationList(hos_id)
	// 		.then((response) => {
	// 			const { data, status } = response;
	// 			// console.log(data,status,"data,status")
	// 			if (status == 200) {
	// 				if (data?.data?.length > 0) {
	// 					setLocationList(data.data);
	// 				} else {
	// 					toast.error("LocationList Not Found")
	// 				}
	// 			} else {
	// 				toast.error("Fatal Error Please Contact Admin")
	// 			}
	// 			setIsLoading(false);
	// 		})
	// 		.catch((error) => {
	// 			const msg = "getAllLocationList catch Error";
	// 			toast.error(msg);
	// 			setIsLoading(false);
	// 		});

	// 	// const clinicsFromApi = await MasterServices.getClinicList();
	// 	// setOutsourceClinicList(clinicsFromApi.data.data);
	// }

	let initialValues = {
		user_id: "",
		user_username: "",
		user_first_name: "",
		user_last_name: "",
		user_email: "",
		user_mobile: "",
		user_location_id: "",
		user_role: "",
		user_password: "",
		// conf_password: "",
		user_status: true,
		loc_id: "",
		fk_loc_ids: "",
		hos_id: "",
		user_signature: "",
		mc_id: ""
	};

	const [formUser, setFormUser] = useState(initialValues);
	const multiLocation = (event) => {
		initialValues.fk_loc_ids = JSON.stringify(event);

		setMultiLocationId(event)
		console.log(initialValues.fk_loc_ids);
	}

	const handleDrawer = (type, id, obj) => {
		setSelectedUserRoleId('')
		setSelectedLocationId('')
		setSelectedClinicId('')
		values.user_role = ""
		values.loc_id = ""
		setMobileNumberValid(true);
		setMultiLocationId();
		if ((type === "edit", id)) {
			setUserid(id);
			let mergedPhone = `+${obj.user_dial_code}${obj.user_mobile}`;
			console.log("obj=>", obj, isValidPhoneNumber(mergedPhone), mergedPhone);
			if (obj.user_mobile && obj.user_dial_code && isValidPhoneNumber(`+${obj.user_dial_code}${obj.user_mobile}`)) {
				// console.log(`+${}${obj.user_mobile}`)
				const phoneNumber = parsePhoneNumber(`+${obj.user_dial_code}${obj.user_mobile}`)
				console.log(phoneNumber)
				if (phoneNumber) {
					setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
					setMobileNumber(phoneNumber.number);
					console.log(mobileCountryCode, userMobileNumber)
				}
			}
			const initialValues = {
				user_id: obj.user_id,	
				user_username: obj.user_username,
				user_first_name: obj.user_first_name,
				user_last_name: obj.user_last_name,
				user_email: obj.user_email,
				user_mobile: obj.user_mobile,
				user_location_id: obj.user_location_id,
				user_role: obj.user_role_id,
				user_status: obj.user_is_active,
				loc_id: obj.fk_hsp_loc_id,
				hos_id: obj.fk_hos_id,
				user_signature: obj.user_signature,
				mc_id: obj.fk_ot_clinic_id ? obj.fk_ot_clinic_id : ""
			};
			setOutSourcingClinicId(obj.user_role_id + '');
			setMultiLocationId(obj.fk_loc_ids);
			console.log(selectedUserRoleId, "selectedUserRoleId")
			if (Array.isArray(roleList) && roleList.length > 0) {
				let role = roleList.filter(v => v.role_id == initialValues.user_role)
				if (role.length > 0) {
					setSelectedUserRoleId({ value: role[0].role_id, label: role[0].role_name_en })
				}
			}
			if (Array.isArray(locationList) && locationList.length > 0) {
				let role = locationList.filter(v => v.value == initialValues.loc_id)
				// let role = locationList.filter(v => v.loc_id == initialValues.loc_id)
				if (role.length > 0) {
					setSelectedLocationId({ value: role[0].value, label: role[0].label })
					// setSelectedLocationId({ value: role[0].loc_id, label: role[0].loc_name_en })
				}
			}
			console.log(outsourceClinicList, "outsourceClinicList")
			console.log(initialValues, "initialValues")
			if (Array.isArray(outsourceClinicList) && outsourceClinicList.length > 0) {
				let clinic = outsourceClinicList.filter(v => v.value == initialValues.mc_id)
				// let role = locationList.filter(v => v.loc_id == initialValues.loc_id)
				console.log("clinic: ----", clinic)
				if (clinic.length > 0) {
					setSelectedClinicId({ value: clinic[0].value, label: clinic[0].label })
					// setSelectedClinicId({ value: role[0].loc_id, label: role[0].loc_name_en })
				}
			}
			/* if(obj.fk_ot_clinic_id != null){
				initialValues.mc_id = obj.fk_ot_clinic_id;
				setOutSourcingClinicId(obj.fk_ot_clinic_id);
				console.log("obj.fk_ot_clinic_id =>", initialValues);
			} */
			console.log("handleDrawer initialValues=>", initialValues);
			setSignFilePath(obj.user_signature)
			setFormUser(initialValues);
		} else {
			setMobileNumber("");
			setSignFilePath("")
			setUserid("");
			setOutSourcingClinicId(null);
			setFormUser(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};

	const onDeleteOpen = (user_id, user_is_active) => {
		setConfirmationModal({ user_id, user_is_active, status: true });
	};

	const handleFileChange = async (field, file) => {
		const response = await singleUploadFiles(file, 'users');
		if (field == 'sign') {
			setSignFilePath(response.data);
			setSignProgress(response.progress);
		}
		// setFile(e.target.files[0]);
	};

	const setSelectedRole = async (events) => {
		console.log("setSelectedRole events", events.value);
		let cid = events.value ? events.value : null
		if (cid) {
			setOutSourcingClinicId(cid);
		} else {
			setOutSourcingClinicId(null);
		}
	}

	const onDeleteUser = async (user_id, user_is_active) => {
		let Newuser_is_active = false;
		Newuser_is_active = user_is_active == true ? false : true;
		let text = "Activated";
		if (Newuser_is_active == true) {
			text = "Activated";
		} else {
			text = "In-Activated";
		}
		setIsLoading(true)
		MasterServices.setMUserStatus({
			user_id,
			user_is_active: Newuser_is_active
		})
			.then((response) => {
				const { data, status } = response;
				if (status == 200) {
					toast.success("User " + text + " Successfully!")
					setConfirmationModal({ user_id: null, status: false });
					dispatch(getAllUsers())
					navigate("/user-list");
				} else {
					toast.error("Fatal Error Please Contact Admin")
				}
				setIsLoading(false)
			})
			.catch((error) => {
				const msg = error.response.data.message;
				toast.error(msg)
				setIsLoading(false)
			});
	};
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formUser,
			validationSchema: userSchema,
			onSubmit: async (values, action) => {
				// console.log("Inside Submit")
				if (!mobileNumberValid || mobileNumber == '') {
					mobileNumberValid(false)
					return false
				}

				let hospital = locationList.find(e => e.loc_id == values.loc_id)
				let fileSignRes
				if (signFileName) {
					fileSignRes = await singleUploadFiles(signFileName, 'users');
				}

				values.user_signature = fileSignRes ? fileSignRes : signFileNamePath;

				console.log("hospital", hospital);
				let body = {
					user_id: user_id,
					user_username: values.user_username,
					user_first_name: values.user_first_name,
					user_last_name: values.user_last_name,
					user_email: values.user_email,
					user_mobile: userMobileNumber,
					dial_code: mobileCountryCode,
					user_location_id: values.user_location_id,
					user_role: values.user_role,
					user_password: values.user_password,
					user_status: true,
					user_signature: values.user_signature,
					fk_hsp_loc_id: values.loc_id,
					hos_id: values.loc_id
				};
				body.fk_loc_ids = multiLocationId;
				if (body.user_role == 13 || body.user_role == '13') {
					body.mc_id = parseInt(values.mc_id);
				} else {
					body.mc_id = "";
				}

				console.log("body2", body);
				///return;

				if (body.user_first_name != undefined || body.user_first_name != null || body.user_first_name != "") {
					// setIsLoading(true)
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 15000)
					setDisableSubmitButton(true)
					let { data, status } = await MasterServices.saveMUser(body)
					if (status === 200) {
						if (data.status) {
							action.resetForm();
							setMobileNumber('');
							setMobileCountryCode('');
							dispatch(getAllUsers());
							setIsLoading(false)
							setmodalOpenFlage(false);
							setDisableSubmitButton(false)

						} else {
							setErrorMessage(data.message);
							setTimeout(() => {
								setErrorMessage('')
							}, 5000)
							setIsLoading(false);
							setDisableSubmitButton(false);
						}
					} else {
						setDisableSubmitButton(false);
					}
				} else {
					setDisableSubmitButton(false)
					// dispatch(updateUser(user_id, body));
					// console.log("Update Body", body)

					action.resetForm();
					if (modalOpenFlage === true) {
						dispatch(getAllUsers());
						setmodalOpenFlage(false);
					}
					setDisableSubmitButton(false)
				}
				// navigate("/user-list");
			},
		});

console.log("selectedLocationId=>",selectedLocationId)
	return (
		isLoading ? <FallingLinesLoader /> : (
			<div className="px-4 sm:px-6 lg:px-8">
				{/* <ConfirmationModal
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteUser(confirmationModal.user_id)}
			/> */}
				<ConfirmationModal
					title={"Are you sure you want to " + (confirmationModal.user_is_active ? "Disable " : "Activate") + " this User?"}
					// confirmationButtonText={confirmationModal.user_is_active ? "Disable" : "Activate"}
					confirmationButtonText="Yes"
					// description={"Do you really want to " + (confirmationModal.user_is_active ? "Disable" : "Activate") + " User"}
					open={confirmationModal.status}
					onDelete={() => onDeleteUser(confirmationModal.user_id, confirmationModal.user_is_active)}
					setOpen={setConfirmationModal}
				/>
				<Breadcrumb pages={pages} />
				<div className="">
					<h1 className="text-xl font-semibold text-gray-900">User List</h1>
					<p className="mt-2 text-sm text-gray-700">A list of all the Users.</p>
				</div>
				<div className="mt-4 flex">
					<Link
						onClick={() => handleDrawer("add", "", {})}
						type="button"
						className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
					>
						Add User
					</Link>
				</div>

				{userLoading ? (
					<FallingLinesLoader />
				) : (
					<Table
						columns={user_columns({ onDeleteOpen, handleDrawer })}
						data={userList}
					/>
				)}

				<div>
					<Transition.Root show={modalOpenFlage} as={Fragment}>
						<Dialog
							as="div"
							className="relative z-30"
							onClose={setmodalOpenFlage}
						>
							<div className="fixed inset-0" />
							<div className="fixed inset-0 overflow-hidden">
								<div className="absolute inset-0 overflow-hidden">
									<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
										<Transition.Child
											as={Fragment}
											enter="transform transition ease-in-out duration-500 sm:duration-700"
											enterFrom="translate-x-full"
											enterTo="translate-x-0"
											leave="transform transition ease-in-out duration-500 sm:duration-700"
											leaveFrom="translate-x-0"
											leaveTo="translate-x-full"
										>
											<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
												<form
													onSubmit={(e) => {
														e.preventDefault();
														// console.log("values", values);
														handleSubmit(e);
													}}
													className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
												>
													<div className="h-0 flex-1 overflow-y-auto">
														<div className="bg-cyan-700 py-6 px-4 sm:px-6">
															<div className="flex items-center justify-between">
																<Dialog.Title className="text-lg font-medium text-white">
																	{user_id ? "Update" : "Add"} User
																</Dialog.Title>
																<div className="ml-3 flex h-7 items-center">
																	<button
																		type="button"
																		className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
																		onClick={() => setmodalOpenFlage(false)}
																	>
																		<span className="sr-only">Close panel</span>
																		<XMarkIcon
																			className="h-6 w-6"
																			aria-hidden="true"
																		/>
																	</button>
																</div>
															</div>
														</div>

														<div className="flex flex-1 flex-col justify-between">
															<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="user_username"
																			className="block text-sm font-medium text-gray-900 mb-1"
																		>
																			Employee ID<span className="text-red-600">*</span>
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={values.user_username}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="text"
																			placeholder="Username"
																			name="user_username"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{errors.user_username &&
																			touched.user_username ? (
																			<p className="text-red-600 text-sm">
																				{errors.user_username}
																			</p>
																		) : null}
																	</div>
																</div>
																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="project-name"
																			className="block text-sm font-medium text-gray-900 mb-1"
																		>
																			First Name<span className="text-red-600">*</span>
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={values.user_first_name}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="text"
																			placeholder="First Name"
																			name="user_first_name"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{errors.user_first_name &&
																			touched.user_first_name ? (
																			<p className="text-red-600 text-sm">
																				{errors.user_first_name}
																			</p>
																		) : null}
																	</div>
																</div>
																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="project-name"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			Last Name<span className="text-red-600">*</span>
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={values.user_last_name}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="text"
																			placeholder="Last Name"
																			name="user_last_name"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{errors.user_last_name &&
																			touched.user_last_name ? (
																			<p className="text-red-600 text-sm">
																				{errors.user_last_name}
																			</p>
																		) : null}
																	</div>
																</div>

																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="project-name"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			User Email<span className="text-red-600">*</span>
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={values.user_email}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="email"
																			placeholder="User Email"
																			name="user_email"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																			disabled={user_id ? true : false}
																		/>
																		{errors.user_email && touched.user_email ? (
																			<p className="text-red-600 text-sm">
																				{errors.user_email}
																			</p>
																		) : null}
																	</div>
																</div>

																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="project-name"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			User Mobile<span className="text-red-600">*</span>
																		</label>
																	</div>
																	<div className="mt-1 sm:col-span-2 sm:mt-0">
																		<PhoneInputWithCountry
																			defaultCountry="SA"
																			value={mobileNumber}
																			placeholder="Enter phone number"
																			onChange={e => onMobileNumberChange(e)}
																		/>
																		{
																			!mobileNumberValid ? (
																				<p className="text-red-600 text-xs">Mobile Number is invalid</p>
																			) : null
																		}
																	</div>
																</div>
																<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																	<div>
																		<label
																			htmlFor="project-name"
																			className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																		>
																			Password<span className="text-red-600">*</span>
																		</label>
																	</div>
																	<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																		<input
																			value={values.user_password}
																			onChange={handleChange}
																			onBlur={handleBlur}
																			type="password"
																			placeholder="Enter Password"
																			name="user_password"
																			autoComplete="off"
																			className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																		/>
																		{errors.user_password &&
																			touched.user_password ? (
																			<p className="text-red-600 text-sm">
																				{errors.user_password}
																			</p>
																		) : null}
																	</div>
																</div>


																{
																	roleList && roleList.length > 0 ?

																		<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
																			<Select
																				name="role_name_en"
																				id="role_id"
																				placeholder={<span >Select Role<span className="text-red-600">*</span></span>}
																				value={selectedUserRoleId}
																				onChange={(e) => {
																					console.log(e)
																					values.user_role = e.value
																					setSelectedUserRoleId(e)
																					setSelectedRole(e)
																				}}
																				// onBlur={handleBlur}
																				components={animatedComponents}
																				options={roleList ? roleList.map(v => ({ value: v.role_id, label: v.role_name_en })) : []}
																				classNamePrefix="select"
																				className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																			/>

																			{errors.user_role && touched.user_role ? (
																				<div className="text-sm text-red-600">
																					{errors.user_role}
																				</div>
																			) : null}
																		</div>
																		: null
																}


																{
																	outSourcingClinicId && outSourcingClinicId == '13' ?

																		<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
																			{/* <select
																				className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
																				name="mc_id"
																				id="mc_id"
																				value={values.mc_id}
																				onChange={handleChange}
																				onBlur={handleBlur}
																			>
																				<option selected defaultValue={""}> Select Clinic </option>
																				{outsourceClinicList?.map((clinic, i) => (
																					<option
																						selected={clinic.mc_id === values.mc_id
																							? "selected"
																							: ""}
																						key={i}
																						value={clinic.mc_id}
																					>
																						{clinic.mc_name}
																					</option>
																				))}
																			</select> */}
																			<Select
																				name="loc_name_en"
																				id="mc_id"
																				placeholder="Select Locations"
																				value={selectedClinicId}
																				onChange={(e) => {
																					values.mc_id = e.value;
																					setSelectedClinicId(e);
																				}}
																				// onBlur={handleBlur} ? locationList.map(v => ({ value: v.loc_id, label: v.loc_name_en })) : []
																				components={animatedComponents}
																				options={outsourceClinicList}
																				classNamePrefix="select"
																				className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																			/>

																			{errors.mc_id && touched.mc_id ? (
																				<div className="text-sm text-red-600">
																					{errors.mc_id}
																				</div>
																			) : null}
																		</div>

																		: null
																}


																{
																	locationList && locationList.length > 0 ?
																		<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
																			<Select
																				name="loc_name_en"
																				id="loc_id"
																				placeholder="Select Locations"
																				value={selectedLocationId}
																				onChange={(e) => {
																					values.loc_id = e.value;
																					setSelectedLocationId(e);
																					console.log("selectedLocationId=",selectedLocationId,locationList)
																				}}
																				// onBlur={handleBlur} ? locationList.map(v => ({ value: v.loc_id, label: v.loc_name_en })) : []
																				components={animatedComponents}
																				options={locationList}
																				classNamePrefix="select"
																				className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																			/>

																			{errors.loc_id &&
																				touched.loc_id ? (
																				<div className="text-sm text-red-600">
																					{errors.loc_id}
																				</div>
																			) : null}
																		</div>

																		: null
																}
																{
																	locationList && locationList.length > 0 ?
																		<div className="basis-3/4">
																			<label htmlFor="fk_loc_ids" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Multi Location</label>
																			<div className="mt-1 sm:col-span-2 sm:mt-0">
																				<Select
																					// defaultValue={testList[0]}
																					isMulti
																					name="fk_loc_ids"
																					id="fk_loc_ids"
																					value={multiLocationId}
																					onChange={(e) => multiLocation(e)}
																					onBlur={handleBlur}
																					options={locationList}
																					className="basic-multi-select"
																					classNamePrefix="Select Multi Location"
																				/>
																				{errors.fk_loc_ids && touched.fk_loc_ids ? (
																					<p className="text-red-600 text-sm">{errors.fk_loc_ids}</p>
																				) : null}
																			</div>
																		</div>
																		: null
																}
																<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
																	<div className="basis-1/2">
																		<label htmlFor="hos_long" className="block text-sm font-medium text-gray-700">
																			Signature
																		</label>
																		<FileUploader
																			multiple={false}
																			types={fileTypes}
																			handleChange={file => handleFileChange('sign', file)}
																			name="file"
																		/>
																		{signFileNamePath ? (<img width={150} height={100} src={signFileNamePath} alt={values.user_first_name} />) : (<></>)}

																		{/* </div> */}
																		{signProgress ? (<><div className="w-full bg-gray-200 rounded-full dark:bg-gray-700 mt-2">
																			<div className="bg-cyan-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full mt-1"> {`${signProgress}%`}</div>
																		</div></>) : (<></>)}
																	</div>
																</div>
																<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0 mt-4">
																	<div className="basis-1/2 text-red-500 text-sm">
																		{/* <label htmlFor="hos_long" className="block text-sm font-medium text-gray-700"> */}
																		{errorMessage !== '' && 'Error: ' + errorMessage}
																		{/* </label> */}
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="flex flex-shrink-0 justify-end px-4 py-4">
														<button
															type="button"
															className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
															onClick={() => {
																setmodalOpenFlage(false);
															}}
														>
															Cancel
														</button>
														<button
															disabled={disableSubmitButton}
															type="submit"
															onClick={() => {
																if (mobileNumber == '') {
																	setMobileNumberValid(false)
																}
															}}

															className={`ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton ? "bg-gray-700 hover:bg-gray-700" : "bg-cyan-700"}`}
														>
															{user_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}
														</button>
													</div>
												</form>
											</Dialog.Panel>
										</Transition.Child>
									</div>
								</div>
							</div>
						</Dialog>
					</Transition.Root>
				</div>
			</div>)
	);
}
export default UserList;