import React, { useEffect, useState, Fragment, useRef } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { priceListDetailsSchema } from "../../../schemas";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TableEmpty from "../../../components/tables/tableEmpty";
import Table from "../../../components/tables/table";
import { price_list_details_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition, } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'
import Select from 'react-select'
import ConfirmationModal from "../../../components/ConfirmationModal";
import { read, utils, writeFile } from 'xlsx';

const tpTypeList = [{name: "Test", value: "T"}, {name: "Package", value: "P"}];
const visitTypeList = [{label: "Home", value: "H"}, {label: "Normal", value: "N"}];

function PriceListDetails() {

    const {price_id: price_id } = useParams();
    const initialValues = {
        price_item_id: "", 
        tp_price: "", 
        tp_selling_price: "",
        tp_type: "", 
        tp_visit_type: "", 
        fk_test_id: "", 
        fk_package_id: "", 
        fk_price_id: price_id
    };
    const pages = [{ title: "Price List Details", href: "/price-list" }];

    const [modalOpenFlage, setmodalOpenFlage] = useState(false);
    const [loading, setLoading] = useState(true)
    const [formState, setFormState] = useState(initialValues);
    const [priceList, setPriceList] = useState(false)
    const [packageList, setPackageList] = useState([])
    const [testList, setTestList] = useState([])
    const [singleTestId, setSingleTestId] = useState()
    const [priceListDetails, setPriceListDetails] = useState([])
    const [priceListInfo, setPriceListInfo] = useState()
    const [singlePackageId, setSinglePackageId] = useState()
    const [testOrPackage, setTestOrPackage] = useState('')
    const [confirmationModal, setConfirmationModal] = useState({
      status: false,
      price_item_id: null
    });
    
    const [multiTestId, setMultiTestId] = useState();
    const [multiPackageId, setMultiPackageId] = useState();
    const [multiVisitTypeId, setMultiVisitTypeId] = useState(null);

    useEffect(() => {
        document.title = "Tabib | Price List Details";
        getAllPackages();
        getAllTestList();
        getAllPriceListDetails(price_id);
        getPriceListById(price_id);
    }, []);

    const getAllPackages = async () => {
      setLoading(true)
        MasterServices.getPackageList()
        .then((response) => {
            const { data, status } = response;
            if (status == 200) {
                if (data?.data?.length > 0) {
                    let newPackageList = [];
                    data.data.map((obj, index) => {
                        obj.label = obj.package_name_en + ' - ' + obj.package_name_ar;
                        obj.value = obj.package_id;
                        newPackageList.push(obj)
                    })
                    setPackageList(newPackageList);
                    //setPackageList(data.data);
                    setLoading(false)

                } else {
                    toast.error("Package Not Found")
                    setLoading(false)
                }
            } else {
                toast.error("Fatal Error Please Contact Admin")
                setLoading(false)
            }
        })
        .catch((error) => {
            const msg = "Error getAllPackages";
            toast.error(msg);
            setLoading(false)
        });
        
    }

    const getAllTestList = async () => {
      MasterServices.getTestList()
        .then((response) => {
          const { data, status } = response;
          if (status === 200) {
            if (data?.data?.length > 0) {
              let newTestList = [];
              data.data.map((obj, index) => {
                  obj.label = obj.test_name_en + ' ' + obj.test_name_ar;
                  obj.value = obj.test_id;
                  newTestList.push(obj)
              })
              setTestList(newTestList);
            } else {
              toast.error("Test Not Found")
            }
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
          setLoading(false);
        })
        .catch((error) => {
          const msg = "Error getAllTestList";
          toast.error(msg)
        });
    }

    const getAllPriceListDetails = async (price_id) => {
      MasterServices.getSinglePriceListDetails(price_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data?.data?.length > 0) {
              console.log("getAllPriceListDetails: ", data.data)
              setPriceListDetails(data.data);
            } else {
              toast.error("PriceList details Not Found")
            }
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
          setLoading(false);
        })
        .catch((error) => {
          const msg = "Error getAllPriceListDetails";
          toast.error(msg)
        });
  
    }

    const handleDrawer = (type) => {
        if (type === "add") {
            setFormState(initialValues);
            selectType(0)
            
            setMultiTestId(null)
            setMultiPackageId(null)
            setMultiVisitTypeId(null)
        }
        if (modalOpenFlage === false) {
            setmodalOpenFlage(true);
        }
    };

    const onDeleteOpen = (price_item_id) => {
      setConfirmationModal({ price_item_id, status: true });
    };

    const onDeletePrice = (price_item_id) => {
      //dispatch(deletePrice(price_id));
      //setConfirmationModal({ price_item_id: null, status: false });

      MasterServices.setPriceListDetailStatus({price_item_id: price_item_id})
      .then((response) => {
        const { data, status } = response;
        if (status === 200) {
          toast.success("PriceList Details Removed Successfully!")
          setConfirmationModal({ price_item_id: null, status: false });
          getAllPriceListDetails(price_id);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "setPriceListDetailStatus Catch Error"; //error.response.data.message;
        toast.error(msg)
      });

    };

    const selectType = (type) => {
        //console.log("selectType : ", event.target.value);
        //initialValues.fk_package_id = JSON.stringify(event);
        
        setMultiTestId(null)
        setMultiPackageId(null)
        setMultiVisitTypeId(null)
        setTestOrPackage(type)
    }

    const getPriceListById = (price_id) => {
      MasterServices.getSinglePriceList(price_id)
      .then((response) => {
        const { data, status } = response;
        if (status) {
          console.log("setPriceList Info: ", data)
          if (data?.data != null) {
              const info = {
                  price_id: data.data.price_id,
                  price_name_en: data.data.price_name_en,
                  price_name_ar: data.data.price_name_ar,
                  price_type: data.data.price_type
              };
              setPriceListInfo(info);
          } else {
            toast.error("PriceList Info Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getAllPriceList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
    }

    const handleExport = () => {

      if(priceListDetails.length > 0){
        
        let newSheetdata = [];
        priceListDetails.map((obj, index) => {
          const newData = {
            Name: obj.fk_test_id != null ? obj.t_test_relation.test_name_en + ' - ' + obj.t_test_relation.test_name_ar : obj.t_package_relation.package_name_en + ' - ' + obj.t_package_relation.package_name_ar,
            Price: obj.tp_price,
            Home_Normal: obj.tp_visit_type == 'H' ? "Home" : "Normal",
            Test_Package: obj.tp_type == 'T' ? "Test" : "Package"
          }
          newSheetdata.push(newData);
        })

        let fileName = 'PriceListDetails.xlsx'; //'PriceList Details - '+priceListInfo.price_name_en +'_'+ priceListInfo.price_name_ar +'_'+ priceListInfo.price_type + '.xlsx'

        const headings = [['Name', 'Price', 'Home_Normal', 'Test_Package']];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, newSheetdata, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, fileName); //'PriceListDetails.xlsx'
      }else{
        toast.error("Data is empty");
      }

    }


    /* const handleImport = ($event) => {
      const files = $event.target.files;
      if (files.length) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = (event) => {
              const wb = read(event.target.result);
              const sheets = wb.SheetNames;
​
              if (sheets.length) {
                  const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                  console.log("handleImport: ",rows);
                  let newData = [];
                  if(rows.length > 0){
                    rows.map((obj, index) => {
                      const newObj = {
                        price_name: obj.Name,
                      }
                      newData.push(newObj)
                    })
                    console.log("NewFormattedData: ", newData)
                  }else{
                    toast.success("Excel is empty")
                  }
              }
          }
          reader.readAsArrayBuffer(file);
      }
    } */


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
        enableReinitialize: true,
        initialValues: formState,
        validationSchema: priceListDetailsSchema,
        onSubmit: (values, action) => {
            
            console.log("Formik : ", {values, singleTestId, singlePackageId });
            /* return false; */
            let testIDs = [];
            let packageIDs = [];
            let visitTypes = [];

            if(multiPackageId != null){
              multiPackageId.forEach((pack)=>{
                packageIDs.push(pack.package_id)
              })
            }

            if(multiTestId != null){
              multiTestId.forEach((t)=>{
                testIDs.push(t.test_id)
              })
            }

            if(multiVisitTypeId){
              multiVisitTypeId.forEach((vType)=>{
                visitTypes.push(vType.value)
              })
            }

            let body = {
                price_item_id: 0,
                tp_price: parseInt(values.tp_price),
                tp_selling_price: parseInt(values.tp_selling_price),
                tp_visit_type: visitTypes,
                fk_price_id: parseInt(price_id),
                
                fk_test_ids: testIDs,
                fk_package_ids: packageIDs,
                tp_type: values.tp_type,
            }

            console.log("body",body)

            MasterServices.savePriceListDetails(body)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    toast.success("Price List Details Added Successfully");
                    getAllPriceListDetails(price_id);
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
            })
            .catch((error) => {
                const msg = "savePriceListDetails Catch Error" //error.response.data.message;
                toast.error(msg)
            });

            action.resetForm();
            if (modalOpenFlage === true) {
                setmodalOpenFlage(false);
            };
            
        },
    });


    const handleMultiTest = (event) => {
      initialValues.fk_test_ids = JSON.stringify(event);

      setMultiTestId(event);
    };

    const handleMultiPackage = (event) => {
      initialValues.fk_package_ids = JSON.stringify(event);

      setMultiPackageId(event);
    };
    const handleMultiVisitType = (event) => {
      initialValues.tp_visit_type = JSON.stringify(event);
      setMultiVisitTypeId(event);
    };

    return (
        <div className="px-4 sm:px-6 lg:px-8">
          
          <ConfirmationModal
            title={"Are you sure want to Remove?"}
            confirmationButtonText={"Remove"}
            description={"Do you really want to remove, once removed can't be rollback?"}
            open={confirmationModal.status}
            setOpen={setConfirmationModal}
            onDelete={() => onDeletePrice(confirmationModal.price_item_id)}
          />

          {/* <Breadcrumb pages={pages} /> */}

          <div className="">
            <h1 className="text-xl font-semibold text-gray-900">Price List Details</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the Details.
            </p>
          </div>
          <div className="mt-4 flex">
            <Link
              onClick={() => handleDrawer("add")}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
            >
              Add Test/Package
            </Link>
            <Link
              onClick={() => handleExport()}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
            >
              Export
            </Link>
          </div>
    
          {
            loading ? (
              <FallingLinesLoader />
            ) : (priceListDetails.length > 0) ? (
              <Table
                columns={price_list_details_columns({ onDeleteOpen, handleDrawer })}
                data={priceListDetails}
              />
            ) : <TableEmpty />
          }
    
    
          <div>
            <Transition.Root show={modalOpenFlage} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-30"
                onClose={setmodalOpenFlage}
              >
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                      <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                          <form onSubmit={handleSubmit} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
    
                            <div className="h-0 flex-1 overflow-y-auto">
                              <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                  <Dialog.Title className="text-lg font-medium text-white">
                                  Add Price List (Test/Package)
                                  </Dialog.Title>
                                  <div className="ml-3 flex h-7 items-center">
                                    <button
                                      type="button"
                                      className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                      onClick={() => setmodalOpenFlage(false)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-1 flex-col justify-between">
                                <div className="divide-y divide-gray-200 px-4 sm:px-6">
                                  <div className="pt-6 pb-5">
                                    {/* TP-Type [Test/Package] */}
                                    <div>
                                      <label
                                        htmlFor="project-name"
                                        className="block text-sm font-medium text-gray-900"
                                      >
                                        Select Type
                                      </label>
                                    </div>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        value={values.tp_type}
                                        //onChange={handleChange}
                                        onChange={(e) => {
                                            //values.name = e.label;
                                            values.tp_type = e.target.value;
                                            selectType(e.target.value);
                                            console.log("Select Type Log: ", e.target.value)
                                        }}
                                        onBlur={handleBlur}
                                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        name="tp_type"
                                        id="tp_type"
                                        >
                                        <option >Select Type</option>
                                        {tpTypeList.map((tpTypeListObj, i) => (
                                          <option selected={tpTypeListObj.name === values.tp_type ? "selected" : ""} key={i} value={tpTypeListObj.value}>
                                            {tpTypeListObj.name}
                                          </option>
                                        ))}
                                      </select>
                                      {errors.tp_type && touched.tp_type ? (
                                      <p className="text-red-600 text-sm">{errors.tp_type}</p>
                                      ) : null}
                                    </div>


                                    {/* TEST DropDown */}
                                    { testOrPackage == 'T' ? (
                                        <div className="mt-2">
                                            <div>
                                                <label
                                                htmlFor="fk_test_ids"
                                                className="block text-sm font-medium text-gray-900"
                                                >
                                                Select Test
                                                </label>
                                            </div>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <Select
                                                    isMulti
                                                    name="fk_test_ids"
                                                    id="fk_test_ids"
                                                    value={multiTestId}
                                                    onChange={(e) => handleMultiTest(e)}
                                                    onBlur={handleBlur}
                                                    options={testList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="Select Test"
                                                />
        
                                                {errors.fk_test_ids && touched.fk_test_ids ? (
                                                <p className="text-red-600 text-sm">{errors.fk_test_ids}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null }


                                    {/* Package DropDown */}
                                    { testOrPackage == 'P' ? (
                                        <div className="mt-2">
                                            <div>
                                                <label
                                                    htmlFor="fk_package_ids"
                                                    className="block text-sm font-medium text-gray-900"
                                                >
                                                    Select Package
                                                </label>
                                            </div>
                                            <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                <Select
                                                    isMulti
                                                    name="fk_package_ids"
                                                    id="fk_package_ids"
                                                    value={multiPackageId}
                                                    onChange={(e) => handleMultiPackage(e)}
                                                    onBlur={handleBlur}
                                                    options={packageList}
                                                    className="basic-multi-select"
                                                    classNamePrefix="Select Package"
                                                />

                                                {errors.fk_package_ids && touched.fk_package_ids ? (
                                                <p className="text-red-600 text-sm">{errors.fk_package_ids}</p>
                                                ) : null}
                                            </div>
                                        </div>
                                    ) : null }


                                    {/* Visit-Type [Home/Normal] */}
                                    <div className="mt-2">
                                      <label
                                        htmlFor="tp_visit_type"
                                        className="block text-sm font-medium text-gray-900"
                                      >
                                        Select Visit Type
                                      </label>
                                    </div>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <Select
                                            isMulti
                                            name="tp_visit_type"
                                            id="tp_visit_type"
                                            value={multiVisitTypeId}
                                            onChange={(e)=>handleMultiVisitType(e)}
                                            onBlur={handleBlur}
                                            className="basic-multi-select"
                                            classNamePrefix="Select Type"
                                            options={visitTypeList}
                                      />
                                        {/* <option >Select Visit Type</option>
                                        {visitTypeList.map((visitTypeListObj, i) => (
                                          <option selected={visitTypeListObj.name === values.tp_visit_type ? "selected" : ""} key={i} value={visitTypeListObj.value}>
                                            {visitTypeListObj.name}
                                          </option>
                                        ))} */}
                                      {errors.tp_visit_type && touched.tp_visit_type ? (
                                      <p className="text-red-600 text-sm">{errors.tp_visit_type}</p>
                                      ) : null}
                                    </div>

                                    
                                    <div className="mt-2">
                                      <label
                                        htmlFor="project-name"
                                        className="block text-sm font-medium text-gray-900"
                                      >
                                        Enter Cost Price
                                      </label>
                                    </div>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.tp_price}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        placeholder="Enter Code Price"
                                        name="tp_price"
                                        autoComplete="off"
                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                      />
                                      {errors.tp_price && touched.tp_price ? (
                                        <p className="text-red-600 text-sm">{errors.tp_price}</p>
                                      ) : null}
                                    </div>


                                    <div className="mt-2">
                                      <label
                                        htmlFor="tp_selling_price"
                                        className="block text-sm font-medium text-gray-900"
                                      >
                                        Enter Selling Price
                                      </label>
                                    </div>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.tp_selling_price}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        placeholder="Enter Selling Price"
                                        name="tp_selling_price"
                                        autoComplete="off"
                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                      />
                                      {errors.tp_selling_price && touched.tp_selling_price ? (
                                        <p className="text-red-600 text-sm">{errors.tp_selling_price}</p>
                                      ) : null}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                onClick={() => {
                                  setmodalOpenFlage(false)
                                }
                                }
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Add
                              </button>
                            </div>
                          </form>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </div>
        </div>
      );
}

export default PriceListDetails;