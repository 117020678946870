import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Modal from "../../../components/Modal";
import InputBox from "../../../components/InputBox";
import Button from "../../../components/Button";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import {
  M_Units_colums,
  Units_colums,
} from "../../../components/tables/tableheader";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import { Warehouse } from "@mui/icons-material";
import ConfirmationModal from "../../../components/ConfirmationModal";
const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    mu_unit_name: "",
    mu_unit_symbol: "",
  },
};

function Units() {
  const pages = [{ title: "Units", href: "units", module_id: 36 }];
  const [loading, setLoading] = useState(false);
  const [packageList, setPackageList] = useState(false);
  const [unitList, setUnitList] = useState([]);
  const [controlList, setControlList] = useState([]);
  const [filterUnit, setFilterUnit] = useState('');
  const [modal, setModal] = useState(initialModalState);
  const unitType = [
    {
      label: "Warehouse",
      value: "WAREHOUSE",
    },
    {
      label: "Test",
      value: "TEST",
    }
  ]
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    mu_id: null,
  });
  

  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Tabib | Units";
    getAllUnits();
  }, []);
  const getAllUnits = async () => {
    setLoading(true);
    await MasterServices.getMUnitsList()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setUnitList(data.data);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        // const msg = err;
        // toast.error(msg);
        // setLoading(false);
      });
  };
  const AddUnit = (payload) => {
    setLoading(true);
    MasterServices.addMUnit(payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          getAllUnits();
          toast.success(data.message);
          setLoading(false);
          setModal((prev) => ({ ...prev, state: false }));
          getAllUnits();
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        // const msg = err;
        // toast.error(msg);
        // setLoading(false);
      });
  };

  // const getUnitById = async (id) => {
  //   await MasterServices.getOneUnits(id)
  //     .then((res) => {
  //       const { data, status } = res;
  //       if (status === 200) {
  //         setModal((prev) => ({
  //           ...prev,
  //           state: true,
  //           data: data.data,
  //           type: "edit",
  //           edit_id: id,
  //         }));
  //         setLoading(false);
  //       } else {
  //         toast.error("Fatal Error Please Contact Admin");
  //       }
  //     })
  //     .catch((err) => {
  //       const msg = err;
  //       toast.error(msg);
  //       setLoading(false);
  //     });
  // };

  const openEdit = (data) => {
    setModal({
      type: "",
      state: true,
      index: null,
      edit_id: data?.mu_id,
      data: {
        mu_unit_name: data?.mu_unit_name,
        mu_unit_symbol: data?.mu_unit_symbol,
      },
    })
  }

  const UpdateUnitStatus = (id, payload) => {
    MasterServices.updateUnitsStatus(id, payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          toast.success(data.message);
          getAllUnits();
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const UpdateUnits = (id, payload) => {
    MasterServices.updateUnits(id, payload)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          getAllUnits();
          toast.success(data.message);
          setLoading(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
        setLoading(false);
      });
  };

  const onDeleteOpen = (mu_id) => {
		setConfirmationModal({ mu_id, status: true });
	};


  const deleteUnit = (payload) => {
    MasterServices.deleteMUnit(payload)
      .then((response) => {
        const { data, status } = response;
        setConfirmationModal({ mu_id: null, status: false });
        if (status == 200) {
          toast.success("Unit Deleted Successfully");
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        getAllUnits();
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          mu_unit_name: Yup.string().required("Unit name is required"),
          mu_unit_symbol: Yup.string().required("Unit symbol is required"),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          console.log(values);
          const payload = {
            mu_unit_name: values.mu_unit_name,
            mu_unit_symbol: values.mu_unit_symbol,
          }
          if(modal.edit_id) {
            payload.mu_id = modal.edit_id;
          }
          console.log(payload, "payload nitesh")
          AddUnit(payload);
          setModal({  
            type: "",
            state: false,
            index: null,
            edit_id: "",
            data: {
              mu_unit_name: "",
              mu_unit_symbol: "",
            },})
            action.setSubmitting(false);
            action.resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title="Add Unit"
            open={state}
            setOpen={() => setModal((prev) => ({ ...prev, state: false }))}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="mu_unit_name"
                  label="Unit Name"
                  type="text"
                  value={values.mu_unit_name}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter unit Name"
                />
                {touched.mu_unit_name && (
                  <p className="text-red-700 error_msg">{errors.mu_unit_name}</p>
                )}
              </div>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="mu_unit_symbol"
                  label="Unit Symbol"
                  type="text"
                  value={values.mu_unit_symbol}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Symbol"
                />
                {touched.mu_unit_symbol && (
                  <p className="text-red-700 error_msg">{errors.mu_unit_symbol}</p>
                )}
              </div>

              <div className="mt-4"></div>
              <Button type="submit">
                {isSubmitting ? "Saving..." : "Add Unit"}
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      {renderModal()}
      <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => deleteUnit({mu_id: confirmationModal.mu_id})}
      />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Unit List</h1>
      </div>
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() => setModal({  
            type: "",
            state: true,
            index: null,
            edit_id: "",
            data: {
              mu_unit_name: "",
              mu_unit_symbol: "",
            },})}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : loading === false ? (
        <Table
          columns={M_Units_colums({ UpdateUnitStatus, UpdateUnits, openEdit, onDeleteOpen })}
          data={unitList}
        />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}
export default Units;
