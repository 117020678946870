import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../../components/tables/table";
import TableEmpty from "../../../../components/tables/tableEmpty";
import { control_list_columns } from "../../../../components/tables/tableheader";
import { toast } from "react-toast";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import MasterServices from "../../../../ApiServices/MasterServices";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../../../components/Modal";
import InputBox from "../../../../components/InputBox";
import Button from "../../../../components/Button";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import queryString from 'query-string';

const initialModalState = {
  type: "",
  state: false,
  index: null,
  edit_id: "",
  data: {
    qc_name_en: "",
    qc_name_ar: "",
    qc_stability: "",
  },
};

function ControlList() {
  const pages = [{ title: "Control List", href: "/qc/control-list", module_id: 34 }];
  const [loading, setLoading] = useState(true);
  const [packageList, setPackageList] = useState(false);
  const [controlList, setControlList] = useState([]);
  const [modal, setModal] = useState(initialModalState);

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    qc_id: null,
  });

  const navigate = useNavigate();
  useEffect(() => {
    onPackageList();
    getAllControlList();
    document.title = "Tabib | Control List";
  }, []);

  const getAllControlList = () => {
    MasterServices.getControlList().then((response) => {
      const { data, status } = response;
      if (status == 200) {
        if (data?.data?.length > 0) {
          setControlList(data.data);
        } else {
          toast.error("ControlList Not Found");
        }
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
      setLoading(false);
    });
  };

  const addNewControlList = (payload) => {
    MasterServices.addControlList(payload).then((response) => {
      const { data, status } = response;
      if (status == 200) {
        toast.success("Control List Added Successfully");
        getAllControlList();
        setModal(initialModalState);
      } else {
        toast.error("Fatal Error Please Contact Admin");
      }
      setLoading(false);
    });
  };

  const onPackageList = async () => {
    MasterServices.getPackageList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            // toast.success("Package Found");
            setPackageList(data.data);
          } else {
            toast.error("Package Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setLoading(false);
      });
  };

  const onDeleteOpen = (qc_id, qc_is_active) => {
    setConfirmationModal({ qc_id, qc_is_active, status: true });
  };

  const onDeleteQC = async (qc_id, qc_is_active) => {
    let Newqc_is_active = false;
    Newqc_is_active = qc_is_active == true ? false : true;
    let text = "Activated";
    if (Newqc_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setControlStatus({
      qc_id,
      qc_is_active: Newqc_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("QC " + text + " Successfully!")
          setConfirmationModal({ qc_id: null, status: false });
          getAllControlList();
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Fatal Error Please Contact Admin";
        toast.error(msg)
      });
  };

  const renderModal = () => {
    const { type, state, data, edit_id } = modal;

    return (
      <Formik
        initialValues={data}
        validationSchema={Yup.object({
          qc_name_en: Yup.string().required("QC name is required"),
          qc_stability: Yup.string().required("Stability is required"),
        })}
        enableReinitialize={true}
        onSubmit={(values, action) => {
          const body = {
            ...values,
            qc_name_ar: values.qc_name_en,
          };
          action.setSubmitting(false);
          action.resetForm();
          addNewControlList(body);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Modal
            title="Add Control List"
            open={state}
            setOpen={() => setModal((prev) => ({ ...prev, state: false }))}
          >
            <form onSubmit={handleSubmit}>
              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="qc_name_en"
                  label="QC Name"
                  type="text"
                  value={values.qc_name_en}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter QC Name"
                />
                {touched.qc_name_en && (
                  <p className="text-red-700 error_msg">{errors.qc_name_en}</p>
                )}
              </div>

              <div className="text-left mt-4">
                <div className="mt-4"></div>
                <InputBox
                  name="qc_stability"
                  label="Stability"
                  type="text"
                  value={values.qc_stability}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  required={true}
                  placeholder="Enter Stability"
                />
                {touched.qc_stability && (
                  <p className="text-red-700 error_msg">
                    {errors.qc_stability}
                  </p>
                )}
              </div>
              <div className="mt-4"></div>
              <Button disabled={isSubmitting} type="submit">
                {isSubmitting ? "Saving..." : "Add Control List"}
              </Button>
            </form>
          </Modal>
        )}
      </Formik>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        title={"Are you sure you want to " + (confirmationModal.qc_is_active ? "Disable " : "Activate") + " this QC?"}
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() => onDeleteQC(confirmationModal.qc_id, confirmationModal.qc_is_active)}
        setOpen={setConfirmationModal}
      />
      <Breadcrumb pages={pages} />
      {renderModal()}
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Control List</h1>
      </div>
      <div className="mt-4 flex">
        <button
          type="button"
          onClick={() => setModal((prev) => ({ ...prev, state: true }))}
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </button>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : controlList.length > 0 ? (
        <Table columns={control_list_columns(onDeleteOpen)} data={controlList} />
      ) : (
        <TableEmpty />
      )}
    </div>
  );
}
export default ControlList;
