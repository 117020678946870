import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { citySchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { city_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Api from "../../../ApiServices/Api";

const API = process.env.REACT_APP_API_URL;

function CityList() {
    const pages = [{ title: "City List", href: "/city", module_id: 25 }];
    const [cityList, setCityList] = useState(false)
    const [stateList, setStateList] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [modalOpenFlage, setmodalOpenFlage] = useState(false);
    const [city_id, setCityid] = useState("");
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        city_id: null,
    });
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Tabib | Citys";
        getAllStates()
        getAllCitys()
    }, []);

    useEffect(() => {
        console.log("State List", stateList);
    }, [stateList])

    const getAllStates = async () => {
        try {
            setIsLoading(true)
            const { data } = await Api().get(`/api/dashboard/masters/state`);
            console.log("All State Data", data)
            if (data.status) {
                setStateList(data.data);
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };

    const getAllCitys = async () => {
        try {
            setIsLoading(true)
            const { data } = await Api().get(`/api/dashboard/masters/city`);
            console.log("All Citys Data", data)
            if (data.status) {
                setCityList(data.data);
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };

    const addCity = async () => {
        try {
            setIsLoading(true)
            console.log("Add City")
            let body = {
                city_name_en: values.city_name_en,
                city_name_ar: values.city_name_ar,
                fk_state_id: +values.fk_state_id
            };
            const res = await Api().post(`/api/dashboard/masters/city`, city_id !== undefined || city_id !== null || city_id !== "" ? { ...body, city_id } : body);
            if (res.status) {
                getAllCitys()
            }
            console.log("Add City Response", res)
            setIsLoading(false)
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };

    const initialValues = {
        city_id: "",
        city_name_en: "",
        city_name_ar: "",
        fk_state_id: ""
    };

    const [formCity, setFormCity] = useState(initialValues);

    const handleDrawer = (type, id, obj) => {
        if ((type === "edit", id)) {
            setCityid(id);
            const initialValues = {
                city_id: obj.city_id,
                city_name_en: obj.city_name_en,
                city_name_ar: obj.city_name_ar,
                fk_state_id: obj.m_state_relation.state_id
            };
            setFormCity(initialValues);
        } else {
            setCityid("");
            setFormCity(initialValues);
        }
        if (modalOpenFlage === false) {
            setmodalOpenFlage(true);
        }
    };
    const onDeleteOpen = (city_id) => {
        setConfirmationModal({ city_id, status: true });
    };

    const onDeleteCity = (city_id) => {
        setConfirmationModal({ city_id: null, status: false });
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: formCity,
            validationSchema: citySchema,
            onSubmit: (values, action) => {
                // console.log("Submit Clicked", values);
                addCity()
                // console.log("Update City")
                action.resetForm();
                if (modalOpenFlage === true) {
                    getAllCitys()
                    setmodalOpenFlage(false);
                }
                navigate("/city");
            },
        });
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <ConfirmationModal
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => onDeleteCity(confirmationModal.city_id)}
            />
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">City</h1>
                <p className="mt-2 text-sm text-gray-700">A list of all the Citys.</p>
            </div>
            <div className="mt-4 flex">
                <Link
                    onClick={() => handleDrawer("add", "", {})}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                >
                    Add City
                </Link>
            </div>

            {isLoading ? (
                <FallingLinesLoader />
            ) : (
                <Table
                    columns={city_columns({ onDeleteOpen, handleDrawer })}
                    data={cityList?.length > 0 && !isLoading ? cityList : []}
                />
            )}

            {/* {isLoading ? (<FallingLinesLoader />) : */}
                <div>
                    <Transition.Root show={modalOpenFlage} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-30"
                            onClose={setmodalOpenFlage}
                        >
                            <div className="fixed inset-0" />
                            <div className="fixed inset-0 overflow-hidden">
                                <div className="absolute inset-0 overflow-hidden">
                                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                                            enterFrom="translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                                            leaveFrom="translate-x-0"
                                            leaveTo="translate-x-full"
                                        >
                                            <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        handleSubmit(e);
                                                    }}
                                                    className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                >
                                                    <div className="h-0 flex-1 overflow-y-auto">
                                                        <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                                                            <div className="flex items-center justify-between">
                                                                <Dialog.Title className="text-lg font-medium text-white">
                                                                    {city_id ? "Update" : "Add"} City
                                                                </Dialog.Title>
                                                                <div className="ml-3 flex h-7 items-center">
                                                                    <button
                                                                        type="button"
                                                                        className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                                                        onClick={() => setmodalOpenFlage(false)}
                                                                    >
                                                                        <span className="sr-only">Close panel</span>
                                                                        <XMarkIcon
                                                                            className="h-6 w-6"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-1 flex-col justify-between">
                                                            <div className="p-4 sm:p-6">
                                                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="city_name_en"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            City Name English
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.city_name_en}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter City Name En"
                                                                            name="city_name_en"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.city_name_en &&
                                                                            touched.city_name_en ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.city_name_en}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="city_name_en"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            City Name Arabic
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.city_name_ar}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter City Name Ar"
                                                                            name="city_name_ar"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.city_name_ar &&
                                                                            touched.city_name_ar ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.city_name_ar}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div>


                                                                {
                                                                    stateList && stateList?.length > 0 ? (
                                                                        <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                                                            <div>
                                                                                <label
                                                                                    htmlFor="fk_state_id"
                                                                                    className="block text-sm font-medium text-gray-900"
                                                                                >
                                                                                    State
                                                                                </label>
                                                                            </div>
                                                                            <select
                                                                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                                                                name="fk_state_id"
                                                                                id="fk_state_id"
                                                                                value={values.fk_state_id}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                            >
                                                                                <option value={null}> Select State </option>
                                                                                {stateList?.map((s, i) => (
                                                                                    <option
                                                                                        defaultValue={city_id ? values.fk_state_id : ""
                                                                                        }
                                                                                        // selected={
                                                                                        //     state.state_id === values.fk_state_id
                                                                                        //         ? "selected"
                                                                                        //         : ""}
                                                                                        key={s.state_id}
                                                                                        value={s.state_id}
                                                                                    >
                                                                                        {s.state_name_en}
                                                                                    </option>
                                                                                ))}
                                                                            </select>

                                                                            {errors.fk_state_id && touched.fk_state_id ? (
                                                                                <div className="text-sm text-red-600">
                                                                                    {errors.fk_state_id}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    ) : null
                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                        <button
                                                            type="button"
                                                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                            onClick={() => {
                                                                setmodalOpenFlage(false);
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                        >
                                                            {city_id ? "Update" : "Add"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </div>
            {/* } */}
        </div>
    );
}
export default CityList;
