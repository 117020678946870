'use strict';
import $, { error } from "jquery"; //Load jquery
import React, { Fragment, useRef, Component, createRef, useEffect, useState } from "react"; //For react component
import { useNavigate, useParams } from "react-router-dom";
//import { useFormik } from "formik";
import { uuserSchema, uuserSchema2, patientDataSchemaOC } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../components/Breadcrumb";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { getAllGenders } from "../../../redux/gender/actions";
import { getAllCountrys } from "../../../redux/country/actions";
import { getAllCategorys } from "../../../redux/category/actions";
//import { getAllPackages } from "../../../redux/package/actions";
//import { getAllTests } from "../../../redux/test/actions";
import MasterServices from '../../../ApiServices/MasterServices';
import ReservationServices from '../../../ApiServices/ReservationServices';
import Select from 'react-select'
import { Formik } from "formik";
import { toast } from 'react-toast'
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import TableEmpty from "../../../components/tables/tableEmpty";
import AddressRadioGroup from "./Address/AddressRadioGroups";
import { AddEditAddressDialog } from "./Address/AddEditAddressDialog";
import { calculateAge, convertToAnotherTimezone } from "../../../helper/commonHelper";
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

import { ResQuestionnaire } from "./ResQuestionnaire";
import AddressMap from "./Address/AddressMap";
import 'react-phone-number-input/style.css'
import PhoneInputWithCountry, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from 'react-phone-number-input'
import moment from "moment";
import { Switch } from '@headlessui/react';
import { CloseOutlined } from "@mui/icons-material";
import BadgeAddRemove from "../../../components/badge/BadgeAddRemove";

window.jQuery = $; //JQuery alias
window.$ = $; //JQuery alias

require("jquery-ui-sortable"); //For FormBuilder Element Drag and Drop
require("formBuilder");// For FormBuilder
const CircularJSON = require('circular-json');

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


const currentDate = moment().format('YYYY-MM-DD') // get current date
const currentTime = moment().format('HH:mm') // get current time
const workSteps = [
  { id: 1, name: "Select Account", href: "#", status: "current" },
  { id: 2, name: "Add Test", href: "#", status: "upcoming" },
  { id: 3, name: "Questionnaire", href: "#", status: "upcoming" },
  { id: 4, name: "Review Booking", href: "#", status: "upcoming" },
];
const identityTypeList = ["National ID", "Iqama", "BorderNo", "Gulf ID", "Passport No"];
const reasonOfTestingList = ["Diagnosis", "Travelling To Outside KSA", "Public Quarantine (Hotel)", "Home Quarantine", "covid test"];
const relationshipList = [{
  label: "Mother",
  value: "Mother",
}, {
  label: "Father",
  value: "Father",
}, {
  label: "Son",
  value: "Son",
}, {
  label: "Wife",
  value: "Wife",
}, {
  label: "Daughter",
  value: "Daughter",
},
{
  label: "Brother",
  value: "Brother",
},
{
  label: "Sister",
  value: "Sister",
},
];

const bookingType = [
  { name: "Normal", id: "1" },
  { name: "Home", id: "2" },
];
function testStatusToggle(...testStatus) {
  return testStatus.filter(Boolean).join(' ');
}
function patientNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function BookingNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function stepNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
// const [se] =useState(1)

let initialValues = {
  login_user_id: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).user_id : 0,
  user_first_name: "",
  user_last_name: "",
  user_email: "",
  user_mobile: "",
  user_dob: "",
  user_id_type: "",
  user_id_no: "",
  user_passport_no: "",
  user_reasion_test: "",
  fk_gender_id: "",
  fk_country_id: "",
  relationship: "",
  fk_questionnaire_id: "",
  userType: "",
  Type: "Add",
};

let initialValuesOC = {
  user_first_name: "",
  fk_country_id: "",
  fk_gender_id: "",
  user_file_no: "",
  user_age: "",
}

let bookinginitialValues = {
  login_user_id: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).user_id : 0,
  user_name: "",
  booking_type: "",
  test_pack_memberArr: [],
  questionsArr: [],
  questionsNewArr: [],
  questions_answer: [],
  rev_amount: 0,
  rev_payable_amount: 0,
  rev_vat_amount: 0,
  totalDiscount: 0,
  couponDetails: {},
  visit_time: currentTime,
  visit_date: currentDate,
  fk_collection_loc_id: '',
  pickup_address: {},
  TBB_role_id: localStorage.getItem("TBB_role_id") ? localStorage.getItem("TBB_role_id") : null,
  referral_clinic_id: null
};
const fb = createRef();

export default function Reservation() {
  let formikRef = useRef();
  const pages = [{ title: "Reservation", href: "/reservation", module_id: 9 }];
  const [searchQuery, setSearchQuery] = useState('');
  const {
		patientUsersList: { usersListData },
	} = useSelector((state) => state);
  const [query, setQuery] = useState("");
  const [queryBooking, setQueryBooking] = useState("");
  const [modalData, setModalData] = useState(initialValues);
  const [modalDataOC, setModalDataOC] = useState(initialValuesOC)
  const [patientList, setPatientList] = useState(false);
  const [userList, setUserList] = useState(usersListData);
  const [test_pack_memberArr, setTestTackMemberArr] = useState([]);
  const [showOutsourceClinicList, setShowOutsourceClinicList] = useState(false);
  const [outsourceClinicListMain, setOutsourceClinicListMain] = useState(null);
  const [testCount, setTestCount] = useState(false);
  const [packCount, setPackCount] = useState(false);
  const [totAmount, setTotAmount] = useState(false);
  const [packageStatus, setPackageStatus] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState(null);
  // console.log(selectedPerson);
  const [selectedBooking, setSelectedBooking] = useState(bookingType[0]);
  const [testPackageLoading, setTestPackageLoading] = useState(false);
  const [openPatient, setOpenPatient] = useState(false);
  const [openPatientOC, setOpenPatientOC] = useState(false);
  const [openAddTest, setOpenAddTest] = useState(false);
  const [validateFlag, setValidateFlag] = useState(true);
  let [userType, setUserType] = useState(false);
  let [user_id, setUserId] = useState(false);
  let [userDetails, setUserDetails] = useState(false);
  let [patientId, setOpenTestPatientId] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [clinicDiscountAmt, setClinicDiscountAmt] = useState(0);
  const [openBookingQuotation, setOpenBookingQuotation] = useState(false);
  // const [complete, setComplete] = useState(false)
  const [steps, setSteps] = useState(workSteps);
  const [addStep, setAddStep] = useState(1)
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectMutiCategory, setMutiCategory] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [userMobileNumber, setUserMobileNumber] = useState('');
  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [mobileCountryCode, setMobileCountryCode] = useState('')
  const [loading, setLoading] = useState(true);
  const [userTestData, setUserTestData] = useState([]);
  const [countryList, setCountryList] = useState([]);

  // Abhi - Address
  // All adresses of a user
  const [userAddresses, setUserAddresses] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState(false)
  const [openAddressDialog, setOpenAddressDialog] = useState(false)
  const [latLong, setLatLong] = useState([46.7318, 24.7573]);
  const [dateValue, setDateValue] = useState(currentDate)
  const [timeValue, setTimeValue] = useState(currentTime);

  const [couponDetails, setCouponDetials] = useState(false)
  const [couponCode, setCouponCode] = useState(false)
  const [totalDiscount, setTotalDiscount] = useState(0)

  let [totVatRate, setTotVatRate] = useState(0)
  const [vatRateDetails, setVatRateDetails] = useState(false)
  const [vatRate, setVatRate] = useState(false)
  const [vatRateId, setVatRateID] = useState(false)
  const [vatRateType, setVatRateType] = useState(false)
  const [isCloseModel, setIsCloseModel] = useState(false);

  const [locationList, setLocationList] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [invalidDate, setInvalidDate] = useState(false);
  const [collectionLocId, setCollectionLocId] = useState('')
  const [refClinicId, setRefClinicId] = useState('')
  const [initialRender, setInitialRender] = useState(true);
  const [filteredCountryList, setFilteredCountryList] = useState([]);
  // console.log(filteredCountryList)
  
  let [formBuilder, setFormBuilder] = useState(null);
  let [requestDone, setRequestDone] = useState(false);
  let [qstn_name, setQstnName] = useState('');
  const [questionsArr, setQuestionsArr] = useState([]);
  const [referralClinicList, setReferralClinicList] = useState([]);
  
  const [testList, setTestList] = useState([]);
  // console.log(testList);
  const [selectTests, setTest] = useState([]);
  // console.log(selectTests);
  const [selectTestsDispatch, setTestDispatch] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [selectPackages, setPackages] = useState(packageList);
  const [creditLimit, setCreditLimit] = useState("");
  
  const [isOutsourced, setIsOutsourced] = useState(false);
  const [outsourcedClinicList, setOutsourcedClinicList] = useState([]);
  const [osClinicId, setOSClinicId] = useState('')
  const [selectedOCClinic, setSelectedOCClinic] = useState('');
  const [selectedUserObject, setSelectedUserObject] = useState(null);
  const [userLocDataState, setUserLocDataState] = useState(null);
  
  const {
		loginUserInfo: { userData },
	} = useSelector((state) => state);

  useEffect(() => {
    console.log("selectedPerson", selectedPerson)
    
  }, [selectedPerson])

  useEffect(() => {
    setUserList(usersListData);
    console.log(usersListData, "patientUsersListData")
  }, [usersListData])

  const closeAddressModal = () => {
    setOpenAddressDialog(false)
    setSelectedAddress(false)
    setLatLong([46.7318, 24.7573])
  }
  //Mobile number validation in patient
  useEffect(() => {
    if (isCloseModel) {
      setMobileNumberValid(true);
    }
    if (!initialRender) {
      if (mobileNumber && isValidPhoneNumber(mobileNumber.toString())) {
        const phoneNumber = parsePhoneNumber(mobileNumber)
        if (phoneNumber) {
          setMobileCountryCode(getCountryCallingCode(phoneNumber.country));
          setUserMobileNumber(phoneNumber.nationalNumber);
        }
        setMobileNumberValid(true);
      } else {
        setMobileNumberValid(false)
      }
    } else {
      setInitialRender(false)
    }
  }, [mobileNumber])

  useEffect(()=>{
    let fk_hsp_loc_id = JSON.parse(localStorage.getItem("userLocData"))
    if(fk_hsp_loc_id){
      setUserLocDataState(fk_hsp_loc_id);
      setCollectionLocId(fk_hsp_loc_id[0]?.value)
      bookinginitialValues.fk_collection_loc_id = fk_hsp_loc_id[0]?.value ? fk_hsp_loc_id[0]?.value : 0;
      getAllReferralClinics()
    }
  },[localStorage.getItem("userLocData")])

  const getAllReferralClinics = async () => {
    let clinicsFromApi = await MasterServices.getReferralClinicsBasedOnLocation(JSON.parse(localStorage.getItem("userLocData"))[0]?.value);
  
    if (clinicsFromApi.status === 200) {
      setReferralClinicList(clinicsFromApi.data.data);
    }
  }

  const getAllTestListsRefClinic = (ref_clinic_id) => {
    setTestPackageLoading(true);
    MasterServices.getTestListForReservationReferralClinic(ref_clinic_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {

          let updatedTests = data?.data

          updatedTests?.forEach((element) => {
            element.label = element.test_name_en
            element.value = element.test_id
          });

          if (data?.data?.length > 0) {
            console.log("onChangeRefClinic testList", data?.data);
            setTest(data?.data);
            setTestList(data?.data);
          }
          setTestPackageLoading(false)
        } else {
          toast.error("Fatal Error Please Contact Admin")
          setTestPackageLoading(false)
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
        setTestPackageLoading(false)
      });
  }

  const onChangeRefClinic = async (ref_clinic_id) => {
    setTestPackageLoading(true)
    if (parseInt(ref_clinic_id) > 0) {

      console.log("onChangeRefClinic", { ref_clinic_id, testList });

      setPackageList([]);
      const packageList = await MasterServices.getAllRevPackagesForReferralClinic(ref_clinic_id);
      console.log("onChangeRefClinic packageList", packageList.data);
      if (packageList.status === 200) {

        let updatedPackages = packageList.data?.data
        if (updatedPackages.length > 0) {
          updatedPackages?.forEach((element) => {
            element.label = element.package_name_en
            element.value = element.package_id
          });
        }
        setPackageList(updatedPackages);
      }
      //const testList = await MasterServices.getAllTestListsRefClinic(ref_clinic_id);
      getAllTestListsRefClinic(ref_clinic_id);
      //console.log("onChangeRefClinic testList", testList.data);

    } else {

      console.log("onChangeRefClinic", { ref_clinic_id, selectTestsDispatch });

      setPackageList([]);
      setTest([]);
      setTestList([]);

      await getAllPackages();
      await setTest(selectTestsDispatch);
      await setTestList(selectTestsDispatch);
      setLoading(false)
    }
  }

  const getAllLocationList = () => {
    setIsLoading(true)
    MasterServices.getLocationList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setLocationList(data.data);
          } else {
            toast.error("LocationList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getAllLocationList catch Error";
        toast.error(msg);
        setIsLoading(false);
      });
  }

  const getAllGlobalSettingList = () => {
    MasterServices.getAllGlobalSettings(1)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setVatRateDetails(data?.data[0]);
            setVatRate(data?.data[0]?.gs_vat);
            setVatRateID(data?.data[0]?.gs_id);
            setVatRateType(data?.data[0]?.gs_vatType);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getAllGlobalSettingList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  }

  const addUserAddress = async (payload) => {
    try {
      let res = await ReservationServices.createUserAddress({ ...payload, fk_user_id: user_id })
      if (res.status == 200) {
        getUserAddresses()
      }
      return res
    } catch (e) {
      console.log(e)
    }
  }

  const updateUserAddress = async (payload, id) => {
    try {
      let res = await ReservationServices.updateUserAddress({ ...payload, fk_user_id: user_id }, id)
      if (res.status == 200) {
        getUserAddresses()
      }
      return res
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {

    getAllGlobalSettingList();
    if (user_id) {
      getUserAddresses()
    }
  }, [user_id])

  const [multiCategoryId, setMultiCategoryId] = useState()
  const multiCategory = (event) => {
    setMultiCategoryId(event);
  }

  const {
    gender: { list: genderList },
    category: { list: categoryList },
    //test: { list: testList },
    //package: { list: packageList },
  } = useSelector((state) => state);

  useEffect(() => {
    document.title = "Tabib | Reservation";
    dispatch(getAllGenders());
    getCountryList();
    dispatch(getAllCategorys('active'));
    //dispatch(getAllPackages('active'));
    //dispatch(getAllTests('active'));
    getAllTests('active');
    getAllLocationList();
    getAllPackages();
    getAllReferralClinics();
    getAllOutsourcedClinics();
  }, []);

  // filtering array...
  useEffect(() => {
    let tempArr = []
    if (countryList.length > 0) {
      console.log(countryList)
      let indexOfSA = countryList.findIndex(country => {
        return country.country_id == 203
      });
      // console.log("indexOFSA", indexOfSA)
      tempArr = array_move(countryList, indexOfSA, 0)
      // console.log(tempArr)
      setFilteredCountryList(tempArr)
    }
  }, [countryList])

  // Reservation

  const closeModal = () => {
    setIsCloseModel(true)
    setMobileNumber('')
    setMobileNumberValid(true)
    let resetModal = {
      user_first_name: "",
      user_last_name: "",
      user_email: "",
      user_mobile: "",
      patient_dial_code: "966",
      user_dob: "",
      user_id_type: "",
      user_id_no: "",
      user_passport_no: "",
      user_reasion_test: "",
      fk_gender_id: "",
      fk_country_id: "",
      relationship: "",
      fk_questionnaire_id: "",
      userType: "",
      Type: "Add",
    };

    setModalData((prev) => ({
      ...prev,
      resetModal,
    }));

    let resetModalOC = {
      user_first_name: "",
      fk_country_id: "",
      fk_gender_id: "",
      user_file_no: "",
      user_age: "",
    }
    setModalDataOC((prev) => ({
      ...prev,
      ...resetModalOC
    }))
    setIsCloseModel(false)
  };


  const getBookingValues = (booking_type) => {
    if (booking_type) {
      bookinginitialValues.booking_type = booking_type;
      if (bookinginitialValues.booking_type == '' || bookinginitialValues.user_name == '' || bookinginitialValues.fk_collection_loc_id == '' || bookinginitialValues.visit_date == '' || bookinginitialValues.visit_time == '') {
        setValidateFlag(true);
      } else {
        setValidateFlag(false);
      }
    }
    return booking_type;
  }

  const getLoginUserInfo = () => {
    if (localStorage.getItem('user')) {
      setLoading(true);
      MasterServices.getUserProfile(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).user_id : 0)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data?.data) {
              // setCollectionLocId(data?.data?.fk_hsp_loc_id);
              // bookinginitialValues.fk_collection_loc_id = data?.data?.fk_hsp_loc_id ? data?.data?.fk_hsp_loc_id : 0;

              if ((bookinginitialValues.visit_time == '' || bookinginitialValues.visit_date == '' || bookinginitialValues.fk_collection_loc_id == '')) {
                setValidateFlag(true);
              }
            }
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
          setLoading(false)
        })
        .catch((error) => {
          const msg = "getAllPatientList catch Error"; //error.response.data.message;
          toast.error(msg);
          setLoading(false)
        });
    }
  }
  const getUserID = (userName) => {
    if (userName) {
      let UserArr = userList.filter((User) => {
        if (User.user_first_name === userName) {
          return User.user_first_name.toLowerCase().includes(query.toLowerCase());
        }
      });
      if (UserArr.length > 0) {
        setUserId(UserArr[0].user_id);
        setUserDetails(UserArr[0]);
        console.log("UserArr[0]=>", UserArr[0])
        bookinginitialValues.user_id = UserArr[0].user_id;
        bookinginitialValues.patient_parent_id = UserArr[0].user_id;
        bookinginitialValues.patient_parent_fname = UserArr[0].user_first_name;
        bookinginitialValues.patient_parent_lname = UserArr[0].user_last_name;
        bookinginitialValues.patient_parent_email = UserArr[0].user_email;
        bookinginitialValues.patient_parent_phone = UserArr[0].user_mobile;
        bookinginitialValues.patient_parent_dial_code = UserArr[0].user_dial_code;
        bookinginitialValues.patient_parent_gender_id = UserArr[0]?.fk_gender_id;
        bookinginitialValues.patient_parent_gender_name = UserArr[0]?.m_gender_relation?.gender_name_en;
        bookinginitialValues.patient_parent_country_id = UserArr[0]?.fk_country_id;
        bookinginitialValues.patient_parent_country_name = UserArr[0]?.m_country_relation?.country_name;
        bookinginitialValues.patient_parent_dob = UserArr[0].user_dob;
        bookinginitialValues.patient_parent_id_no = UserArr[0].user_id_no;
        bookinginitialValues.patient_parent_id_type = UserArr[0].user_id_type;
        bookinginitialValues.patient_parent_id_passport_no = UserArr[0].user_passport_no;
        bookinginitialValues.patient_parent_reason_test = UserArr[0].user_reasion_test;
        bookinginitialValues.patient_parent_relationsip = UserArr[0].relationship;

        if (bookinginitialValues.booking_type == '' || bookinginitialValues.user_name == '' || bookinginitialValues.visit_date == '' || bookinginitialValues.visit_time == '' || bookinginitialValues.fk_collection_loc_id == '') {
          setValidateFlag(true);
        } else {
          setValidateFlag(false);
        }
      } else {
        setUserId(false);
      }
    }
    bookinginitialValues.user_name = userName;
    return userName;
  }

  const getAllPackages = () => {
    MasterServices.getReservatoinPackageList('active')
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {

          if (data?.data?.length > 0) {
            let updatedPackages = data?.data

            updatedPackages?.forEach((element) => {
              element.label = element.package_name_en
              element.value = element.package_id
            });

            setPackageList(updatedPackages);

          } else {
            toast.error("Package Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Error in finding packages";
        toast.error(msg)
      });
  }


  const getAllTests = (payload) => {
    MasterServices.getTestListForReservation(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {

          let updatedTests = data?.data

          updatedTests?.forEach((element) => {
            element.label = element.test_name_en
            element.value = element.test_id
          });

          if (data?.data?.length > 0) {
            //setTest(updatedTests);
            setTestDispatch(data?.data);
            setTestList(data?.data);
            setTest(data?.data);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = error.response.data.message;
        toast.error(msg)
      });
  }

  const getAllPatientList = () => {
    if (user_id) {
      setLoading(true);
      ReservationServices.getSingleUserBasedPatient(user_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data?.data?.length > 0) {
              let PatientListArr = [];
              data.data.forEach((val, key) => {
                val.testPackArr = [];
                val.testPackNameArr = [];
                PatientListArr.push(val);
              })
              if(showOutsourceClinicList){
                setPatientList(PatientListArr?.filter(v => v.relationship != 'Self'));
              } else {
                setPatientList(PatientListArr);
              }
            } else {
              toast.error("Fatal Error Please Contact Admin")
            }
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
          setLoading(false)
        })
        .catch((error) => {
          const msg = "getAllPatientList catch Error"; //error.response.data.message;
          toast.error(msg);
          setLoading(false)
        });
    }
  }

  const getAllUserList = () => {
    ReservationServices.getAllUserList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setUserList(data.data);
          } else {
            toast.error("UserList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getAllUserList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  }

  const getCountryList = () => {
    MasterServices.getCountryList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setCountryList(data.data);
            let tempArr = []
            if (data.data.length > 0) {
              let indexOfSA = data.data.findIndex(country => {
                return country.country_id == 203
              });
              // console.log("indexOFSA", indexOfSA)
              tempArr = array_move(data.data, indexOfSA, 0)
              // console.log(tempArr)
              setFilteredCountryList(tempArr)
            }
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "getCountryList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  }

  const addUserPatient = (body, action) => {
    ReservationServices.saveUserPatient(body)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (!data.status) {
            setOpenPatient(false);
            toast.error(data.message);
            return false
          }
          toast.success(`${userType} Added Successfully`);
          if (userType === 'Patient') {
            getAllPatientList();
          } else if (userType === 'User') {
            getAllUserList();
          }
          setOpenPatient(false);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "saveUserPatient Catch Error" //error.response.data.message;
        toast.error(msg)
      });
    action.resetForm();
  };


  const addUserPatientOC = (body, action) => {
    console.log(body, "body");
    ReservationServices.saveUserPatientOC(body)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (!data.status) {
            setOpenPatientOC(false);
            toast.error(data.message);
            return false
          }
          toast.success(`${userType} Added Successfully`);
          getAllPatientList();
          setOpenPatientOC(false);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "saveUserPatient Catch Error" //error.response.data.message;
        toast.error(msg)
      });
    action.resetForm();
  };

  useEffect(() => {
    // getAllUserList();
    // getAllPatientList();
    getLoginUserInfo();
    //setTest(testList);
    setPackages(packageList);
  }, []);

  const TestPackSet = () => {
    if (bookinginitialValues.booking_type == '' || bookinginitialValues.user_name == '' || bookinginitialValues.visit_date == '' || bookinginitialValues.visit_time == '' || bookinginitialValues.fk_collection_loc_id == '') {
      setValidateFlag(true);
      if (bookinginitialValues.user_name == '') {
        toast.error("Please Select Patient");
      }
      if (bookinginitialValues.booking_type == '') {
        toast.error("Please Select Booking Type");
      }
    } else {
      setValidateFlag(false);
    }
    setTest(testList);
    setPackages(packageList);
  }

  function clearData() {
    formBuilder.actions.clearFields();
  }
  function saveData() {
  }
  useEffect(() => {
    let categorynew = categoryList;
    let categorys = [];
    categorynew.forEach((element) => {
      let obj = {
        label: element.category_name_en,
        value: element.category_id,
      }
      categorys.push(obj);
    })
    setMutiCategory(categorys);
  }, [categoryList]);

  const filterTest = (multiCategoryId) => {
    let newTestArr = [];
    if (multiCategoryId.length > 0) {
      multiCategoryId.forEach((ele, key) => {

        testList.filter((Test) => {
          if (ele.label === Test.t_category_relation.category_name_en) {
            newTestArr.push(Test);
          }
        });

      });
      setTest(newTestArr);
    } else {
      setTest(testList);
    }
  }
  const filteredUserList =
    query === ""
      ? userList
      : userList.filter((User) => {
        if(User.user_first_name.toLowerCase().includes(query.toLowerCase())){
          return User.user_first_name.toLowerCase().includes(query.toLowerCase());
        } else if(User.user_id_no && User.user_id_no.toString().toLowerCase().includes(query.toLowerCase())){
          return User.user_id_no && User.user_id_no.toString().toLowerCase().includes(query.toLowerCase())
        } else if(User.user_mobile && User.user_mobile.toString().toLowerCase().includes(query.toLowerCase())){
          console.log("User.patient_ref_mrn_no", User.user_ref_mrn_no)
          return User.user_mobile && User.user_mobile.toString().toLowerCase().includes(query.toLowerCase())
        } else if(User?.patient_report_mrn && User?.patient_report_mrn.toString().toLowerCase().includes(query.toLowerCase())){
          return User?.patient_report_mrn && User?.patient_report_mrn.toString().toLowerCase().includes(query.toLowerCase())
        }
      });

  const filteredBooking =
    queryBooking === ""
      ? bookingType
      : bookingType.filter((person) => {
        return person.name.toLowerCase().includes(queryBooking.toLowerCase());
      });

  const BookingValues = () => {
  }

  const removeTestPack = (test_pack_ele, test_pack_key) => {
    bookinginitialValues.test_pack_memberArr.splice(test_pack_key, 1);
    let testCountArr = bookinginitialValues.test_pack_memberArr.filter((test) => {
      if (test.type === "Test") {
        return test;
      }
    });
    let packCountArr = bookinginitialValues.test_pack_memberArr.filter((pack) => {
      if (pack.type === "Package") {
        return pack;
      }
    });
    setTestCount(testCountArr);
    setPackCount(packCountArr);
  }

  const removeTestPackPatient = (test_pack_ele2, test_pack_key2, test_pack_ele) => { // New One
    removeTestPatient(test_pack_key2, test_pack_ele2, test_pack_ele)
    let testCountArr = bookinginitialValues.test_pack_memberArr.filter((test) => {
      if (test.type === "Test") {
        return test;
      }
    });
    let packCountArr = bookinginitialValues.test_pack_memberArr.filter((pack) => {
      if (pack.type === "Package") {
        return pack;
      }
    });
    setTestCount(testCountArr);
    setPackCount(packCountArr);
  }
  const removeTestPatient = (index, user, test_pack_ele) => {
    const updatedData = patientList.forEach((val1, ind1) => {
      if (val1.patient_id === test_pack_ele.patient_id) {
        val1.testPackArr.splice(index, 1);
        val1.testPackNameArr.splice(index, 1);
      }
    });
    setUserTestData(updatedData);
    return
  };

  const add_TestPackMemberArr = (value, type, key, patientId) => {
    if (value?.package_id) {
      value.type = "Package";
      value.discount = 0;
      value.vatAmt = 0;
    } else if (value?.test_id) {
      value.type = "Test";
      value.discount = 0;
      value.vatAmt = 0;
    }

    if (patientId) {
      value.patient_id = patientId;
    }
    let patentDetails = patientList.filter((User) => {
      if (patientId === User.patient_id) {
        return User;
      }
    });
    let patentData;
    if (patentDetails.length > 0) {
      patentData = patentDetails[0];
      value.patient_first_name = patentData?.patient_first_name;
      value.patient_last_name = patentData?.patient_last_name;
      value.patient_email = patentData?.patient_email;
      value.patient_mobile = patentData?.patient_dial_code + patentData?.patient_mobile;
      value.patient_dial_code = patentData?.patient_dial_code;
      value.patient_dob = patentData?.patient_dob;
      value.patient_id_type = patentData?.patient_id_type;
      value.patient_id_no = patentData?.patient_id_no;
      value.patient_passport_no = patentData?.patient_passport_no;
      value.patient_reasion_test = patentData?.patient_reasion_test;
      value.patient_ref_mrn_no = patentData?.patient_ref_mrn_no;
      value.relationship = patentData?.relationship;
      value.fk_questionnaire_id = patentData?.fk_questionnaire_id;
      value.fk_user_id = patentData?.fk_user_id;
      value.fk_gender_id = patentData?.fk_gender_id;
      value.gender_name_en = patentData?.m_gender_relation?.gender_name_en;
      value.fk_country_id = patentData.fk_country_id;
      value.country_name = patentData?.m_country_relation?.country_name;
      value.country_code = patentData?.m_country_relation?.country_code;
      value.country_isd_code = patentData?.m_country_relation?.country_isd_code;
      value.patient_is_active = patentData?.patient_is_active;
      value.vatRateType = patentData?.patient_is_active;
      value.vatRate = patentData?.patient_is_active;
      setMobileCountryCode(patentData?.patient_dial_code)
    }

    if (value.addKey === "Add") {
      if (value.type === "Test") {
        testList.forEach((tVal, tKey) => {
          if (tKey === key) {//  && value.patient_id === patientId
            tVal.addKey = "Remove";
          }
        });
        setTest(testList);
      }
      if (value.type === "Package") {
        packageList.forEach((pVal, pKey) => {
          if (pKey === key) {// && value.patient_id === patientId
            pVal.addKey = "Remove";
          }
        });
        setPackages(packageList);
      }
      bookinginitialValues.test_pack_memberArr.push(value);

      // Patient Wise Test Pack Start 01_04_2023
      patientList.map((ele, index) => {
        if (patientList[index].testPackArr == undefined || patientList[index].testPackArr == null) {

          patientList[index].testPackArr = [];
          patientList[index].testPackNameArr = [];
        }
        if (ele.patient_id == patientId) {
          patientList[index].testPackArr.push(value);
          // 30-03-2023 Comment By Unique Test Pack Arary Priyanshu Singh Start
          const testDatauniqueArray = Object.values(patientList[index].testPackArr.reduce(function (obj, element) {
            const key = element.test_id ? element.test_id : (element.package_id);
            if (!obj[key]) {
              obj[key] = element;
            }
            return obj;
          }, {}));
          patientList[index].testPackArr = testDatauniqueArray;
          // 30-03-2023 Comment By Unique Test Pack Arary Priyanshu Singh End
          patientList[index].testPackNameArr.push((value?.test_name_en) ? (value?.test_name_en + ' (T)\t') : (value?.package_name_en + ' (P)\t'));
          const testPackNameDatauniqueArray = Object.values(patientList[index].testPackNameArr.reduce(function (obj, element) {
            const key = element;
            if (!obj[key]) {
              obj[key] = element;
            }
            return obj;
          }, {}));
          patientList[index].testPackNameArr = testPackNameDatauniqueArray;
          // 30-03-2023 Comment By Unique Test Pack Arary Priyanshu Singh End
          addTest(ele);
          TestPackPatientSet(patentData, patientId);
          // alert(packageStatus);
          if (value.type !== "Package") {
            if (multiCategoryId) {
              multiCategory(multiCategoryId); filterTest(multiCategoryId)
            }
            setPackageStatus(false);
          }
        }
      });

      // Patient Wise Test Pack End  01_04_2023
    }

    let testCountArr = bookinginitialValues.test_pack_memberArr.filter((test) => {
      if (test.type === "Test") {
        return test;
      }
    });
    let packCountArr = bookinginitialValues.test_pack_memberArr.filter((pack) => {
      if (pack.type === "Package") {
        return pack;
      }
    });
    setTestCount(testCountArr);
    setPackCount(packCountArr);
    console.log("patientList=>", patientList);
  }

  const remove_TestPackMemberArr = (value, type, key, patientId) => {
    let NewTestArr = [];
    let NewPackArr = [];
    let patientArr;
    if (patientList.testPackArr == undefined || patientList.testPackArr == null) {
      patientList.testPackArr = [];
      patientList.testPackNameArr = [];
    } else {
      if (patientList.testPackArr.length > 0) {
        patientList.testPackArr.forEach((Pval, Pind) => {
          Pval.patient_id = patientId;
        });
        patientArr = patientList.filter((paitentData) => {
          if (patientId === paitentData.patient_id) {
            return
          }
        });
      }
    }
    if (value.addKey === "Remove") {
      if (bookinginitialValues.test_pack_memberArr.length > 0) {
        if (value.type === "Test") {
          NewTestArr = [];
          testList.forEach((val, ind) => {
            patientList.forEach((val1, ind1) => {
              if (val1.patient_id == patientId) {
                val1.testPackArr.forEach((val2, ind2) => {
                  if (val2.test_id == val.test_id && val2.test_id == value.test_id && val2.fk_category_id == val.fk_category_id && val2.type === val.type && val2.patient_id == val1.patient_id) {
                    val.addKey = "Add";
                    removeTest(ind2, val2)
                  }
                });
              }
            });
            NewTestArr.push(val);
          });
          setTest(NewTestArr);
        }

        if (value.type === "Package") {
          NewPackArr = [];
          packageList.forEach((val, ind) => {
            patientList.forEach((val1, ind1) => {
              if (val1.patient_id == patientId) {
                val1.testPackArr.forEach((val2, ind2) => {
                  if (val2.package_id == val.package_id && val2.package_id == value.package_id && val2.type === val.type && val2.patient_id == val1.patient_id) {
                    val.addKey = "Add";
                    removeTest(ind2, val2)
                  }
                });
              }
            });
            NewPackArr.push(val);
          });
          setPackages(NewPackArr);
        }

      } else {
        setTest(testList);
        setPackages(packageList);
      }

    }
    let testCountArr = bookinginitialValues.test_pack_memberArr.filter((test) => {
      if (test.type === "Test") {
        return test;
      }
    });
    let packCountArr = bookinginitialValues.test_pack_memberArr.filter((pack) => {
      if (pack.type === "Package") {
        return pack;
      }
    });
    setTestCount(testCountArr);
    setPackCount(packCountArr);
    // TestPackPatientSet(patientArr, patientId);
    if (value.type !== "Package") {
      if (multiCategoryId) {
        multiCategory(multiCategoryId); filterTest(multiCategoryId)
      }
      setPackageStatus(false);
    }

  }
  const addTest = (user) => {
    const updatedData = patientList.map((data) => {
      if (data.patient_id === user.patient_id) {
        return { ...data, testPackArr: [...data.testPackArr], testPackNameArr: [...data.testPackNameArr] };
      }
      return data;
    });
    setUserTestData(updatedData);
  };

  const removeTest = (index, user) => {
    const updatedData = patientList.forEach((val1, ind1) => {
      if (val1.patient_id === user.patient_id) {
        val1.testPackArr.splice(index, 1);
        val1.testPackNameArr.splice(index, 1);
      }
    });
    setUserTestData(updatedData);
    return
  };

  const SaveTestPackList = () => {
    let NewArr = [];
    bookinginitialValues.test_pack_memberArr = [];
    setTestTackMemberArr([]);
    if (patientList.length > 0) {
      patientList.forEach((val1, ind1) => {

        if (val1.testPackArr.length > 0) {
          val1.testPackArr.forEach((val2, ind2) => {
            val2.patient_id = val1.patient_id
            bookinginitialValues.test_pack_memberArr.push(val2);
          });
        }
      });
      setPatientList(patientList);
      // console.log("bookinginitialValues=>", bookinginitialValues)
      if (bookinginitialValues.test_pack_memberArr.length > 0) {
        finalCountTestPack();
        getTestPackPatientDetails();
      }
    }
  }

  const getTestPackPatientDetails = () => {
    // alert(bookinginitialValues.test_pack_memberArr.length)
    if (bookinginitialValues.test_pack_memberArr.length > 0) {
      ReservationServices.getTestPackPatientDetails({ patientList: patientList })
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data?.data?.length > 0) {
              let totalVatAMTArr = [];
              bookinginitialValues.test_pack_memberArr = [];
              bookinginitialValues.test_pack_memberArr = data.data;
              if (bookinginitialValues.test_pack_memberArr.length > 0) {
                finalCountTestPack();

                bookinginitialValues.test_pack_memberArr.forEach((TPA_Val, TPA_Key) => {
                  // if (TPA_Val.type === "Package") {
                  //   if ((TPA_Val.patient_dial_code == "966" || TPA_Val.patient_dial_code == "+966")) {
                  //     TPA_Val.vatAmt = 0;
                  //   } else {
                  //     TPA_Val.vatAmt = (vatRateType == 'FIXED' ? parseInt(vatRate) : ((parseInt(TPA_Val.package_price) * parseInt(vatRate)) / 100));
                  //   }
                  // }
                  // if (TPA_Val.type === "Test") {
                  //   if ((TPA_Val.patient_dial_code == "966" || TPA_Val.patient_dial_code == "+966")) {
                  //     TPA_Val.vatAmt = 0;
                  //   } else {
                  //     TPA_Val.vatAmt = (vatRateType == 'FIXED' ? parseInt(vatRate) : ((parseInt(TPA_Val.hos_price) * parseInt(vatRate)) / 100));
                  //   }
                  // }

                  if (TPA_Val.type === "Package") {
                    if ((TPA_Val?.country_name?.toLowerCase() == "saudi arabia")) {
                      TPA_Val.vatAmt = 0;
                    } else {
                      TPA_Val.vatAmt = (vatRateType == 'FIXED' ? parseInt(vatRate) : ((parseInt(TPA_Val.package_price) * parseInt(vatRate)) / 100));
                    }
                  }
                  if (TPA_Val.type === "Test") {
                    if ((TPA_Val?.country_name?.toLowerCase() == "saudi arabia")) {
                      TPA_Val.vatAmt = 0;
                    } else {
                      TPA_Val.vatAmt = (vatRateType == 'FIXED' ? parseInt(vatRate) : ((parseInt(TPA_Val.hos_price) * parseInt(vatRate)) / 100));
                    }
                  }

                  totalVatAMTArr.push(!isNaN(TPA_Val.vatAmt) ? parseInt(TPA_Val.vatAmt) : 0);

                });
                let amt = 0;
                if (totalVatAMTArr.length > 0) {
                  totalVatAMTArr.forEach((TVal, TKey) => {
                    if (amt == 0) {
                      amt = TVal && !isNaN(TVal) ? parseFloat(TVal) : 0;
                    } else {
                      amt = parseFloat(amt) + (TVal && !isNaN(TVal) ? parseFloat(TVal) : 0);
                    }
                  });
                }
                // calculate vat and discount for oc patients 
                if(showOutsourceClinicList) {
                  let OCclinicDiscount = parseInt(selectedOCClinic?.mc_oc_discount);
                  let totalDis = (parseFloat(totAmount) * OCclinicDiscount) / 100;
                  setClinicDiscountAmt(totalDis.toFixed(2));
                  setTotalDiscount(totalDis);

                  amt = 0;
                  console.log(selectedOCClinic)
                  let OCclinicVat = parseInt(selectedOCClinic?.mc_vat);
                  console.log(totAmount, OCclinicVat, "toAmount", "OCclinicVat")
                  amt = (parseFloat(totAmount) * OCclinicVat) / 100;

                }
                // ends here
                let totalVatData = parseFloat(amt).toFixed(2);
                setTotVatRate(totalVatData);
              }
            }
          }
        })
        .catch((error) => {
          const msg = "getTestPackPatientDetails catch Error"; //error.response.data.message;
          // toast.error(msg);
        });
    }
  }

  const calculateVatAmount = () => {
    let totalVatAMTArr = [];
    if (bookinginitialValues.test_pack_memberArr.length > 0) {

      bookinginitialValues.test_pack_memberArr.forEach((TPA_Val, TPA_Key) => {
        // if (TPA_Val.type === "Package") {
        //   if ((TPA_Val.patient_dial_code == "966" || TPA_Val.patient_dial_code == "+966")) {
        //     TPA_Val.vatAmt = 0;
        //   } else {
        //     TPA_Val.vatAmt = (vatRateType == 'FIXED' ? parseFloat(vatRate).toFixed(2) : ((parseFloat(TPA_Val.package_price - TPA_Val.discount).toFixed(2) * parseFloat(vatRate).toFixed(2)) / 100));
        //   }
        // }
        // if (TPA_Val.type === "Test") {
        //   if ((TPA_Val.patient_dial_code == "966" || TPA_Val.patient_dial_code == "+966")) {
        //     TPA_Val.vatAmt = 0;
        //   } else {
        //     TPA_Val.vatAmt = (vatRateType == 'FIXED' ? parseFloat(vatRate).toFixed(2) : ((parseFloat(TPA_Val.hos_price - TPA_Val.discount).toFixed(2) * parseFloat(vatRate).toFixed(2)) / 100));
        //   }
        // }

        if (TPA_Val.type === "Package") {
          if ((TPA_Val?.country_name?.toLowerCase() == "saudi arabia")) {
            TPA_Val.vatAmt = 0;
          } else {
            TPA_Val.vatAmt = (vatRateType == 'FIXED' ? parseFloat(vatRate).toFixed(2) : ((parseFloat(TPA_Val.package_price - TPA_Val.discount).toFixed(2) * parseFloat(vatRate).toFixed(2)) / 100));
          }
        }
        if (TPA_Val.type === "Test") {
          if ((TPA_Val?.country_name?.toLowerCase() == "saudi arabia")) {
            TPA_Val.vatAmt = 0;
          } else {
            TPA_Val.vatAmt = (vatRateType == 'FIXED' ? parseFloat(vatRate).toFixed(2) : ((parseFloat(TPA_Val.hos_price - TPA_Val.discount).toFixed(2) * parseFloat(vatRate).toFixed(2)) / 100));
          }
        }
        totalVatAMTArr.push(!isNaN(TPA_Val.vatAmt) ? parseFloat(TPA_Val.vatAmt).toFixed(2) : 0);

      });
      let amt = 0;
      if (totalVatAMTArr.length > 0) {
        totalVatAMTArr.forEach((TVal, TKey) => {
          if (amt == 0) {
            amt = TVal && !isNaN(TVal) ? parseFloat(TVal) : 0;
          } else {
            amt = parseFloat(amt) + (TVal && !isNaN(TVal) ? parseFloat(TVal) : 0);
          }
        });
      }

      let totalVatData = parseFloat(amt).toFixed(2);
      setTotVatRate(totalVatData);
      finalCountTestPack();
    }
  }

  const finalCountTestPack = () => {
    let testCountArr = bookinginitialValues.test_pack_memberArr.filter((test) => {
      if (test.type === "Test") {
        return test;
      }
    });
    let packCountArr = bookinginitialValues.test_pack_memberArr.filter((pack) => {
      if (pack.type === "Package") {
        return pack;
      }
    });
    setTestCount(testCountArr);
    setPackCount(packCountArr);
    // SaveTestPackquestionsArrList();
  }

  const SaveTestPackquestionsArrList = () => {
    bookinginitialValues.questionsNewArr = [];
    if (Array.isArray(bookinginitialValues.test_pack_memberArr) && bookinginitialValues.test_pack_memberArr.length > 0) {
      if (bookinginitialValues.test_pack_memberArr.length > 0) {
        bookinginitialValues.questionsArr = [];
        bookinginitialValues.test_pack_memberArr.forEach((val, ind) => {
          if (val.type == "Package") {
            bookinginitialValues.questionsArr.push(...val.questionnaires);
          } else {
            bookinginitialValues.questionsArr.push(val.questionnaires);
          }
        });
        let formData = [];
        bookinginitialValues.questionsArr.forEach((QVal, Qind) => {
          if (QVal?.qstn_json) {
            bookinginitialValues.questionsNewArr.push(...JSON.parse(QVal?.qstn_json));
          }
        })
        let resArr = [];
        if (bookinginitialValues.questionsNewArr.length > 0) {
          if (bookinginitialValues.questionsNewArr) {
            console.log("questionsArr Data Happy=>", questionsArr)

            bookinginitialValues.questionsNewArr.filter(function (item) {
              var i = resArr.findIndex(x => (x.label == item.label && x.name == item.name && x.type == item.type));
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            if (resArr.length > 0) {
              bookinginitialValues.questionsNewArr = [];
              bookinginitialValues.questionsNewArr = resArr;
            }
          }
        }
        console.log("resArr Data Happy=>", resArr);
        console.log("resArr Data Happy questionsNewArr=>", bookinginitialValues.questionsNewArr);
        setFormBuilder($(fb.current).formBuilder({
          disabledActionButtons: ['data', 'clear', 'save'],
          formData: questionsArr
        }));
      }
    } else {
      bookinginitialValues.questionsArr = [];
      setFormBuilder($(fb.current).formBuilder({
        disabledActionButtons: ['data', 'clear', 'save'],
        formData: []
      }));
    }
    // console.log("bookinginitialValues.questionsNewArr=>", bookinginitialValues.questionsNewArr);
  }

  const TestPackPatientSet = (p_patient, patientId) => {
    // setPackageStatus(false);
    let NewTestArr = [];
    let NewPackArr = [];
    if (p_patient.testPackArr == undefined || p_patient.testPackArr == null) {
      p_patient.testPackArr = [];
      p_patient.testPackNameArr = [];
    } else {
      if (p_patient.testPackArr.length > 0) {
        p_patient.testPackArr.forEach((Pval, Pind) => {
          Pval.patient_id = patientId;
        });
      }
    }
    let test_pack_memberList = bookinginitialValues.test_pack_memberArr;
    if (bookinginitialValues.test_pack_memberArr.length > 0) {
      NewTestArr = [];
      NewPackArr = [];
      testList.forEach((val, ind) => {
        val.addKey = "Add";
        patientList.forEach((val1, ind1) => {
          if (val1.patient_id == patientId) {
            val1.testPackArr.forEach((val2, ind2) => {
              if (val2.test_id == val.test_id && val2.fk_category_id == val.fk_category_id && val2.type === val.type && val2.patient_id == val1.patient_id) {
                val.addKey = "Remove";
              }
            });
          }
        });
        NewTestArr.push(val);
      });

      packageList.forEach((val, ind) => {
        val.addKey = "Add";
        patientList.forEach((val1, ind1) => {
          if (val1.patient_id == patientId) {
            val1.testPackArr.forEach((val2, ind2) => {
              if (val2.package_id == val.package_id && val2.type === val.type && val2.patient_id == val1.patient_id) {
                val.addKey = "Remove";
              }
            });
          }
        });
        NewPackArr.push(val);
      });

      setPackages(NewPackArr);
      setTest(NewTestArr);
    } else {
      setTest(testList);
      setPackages(packageList);
    }
  }
  // useEffect(() => {
  //   // console.log("formBuilder formData 2=>", formBuilder)
  //   // console.log("formBuilder formData testList=>", testList)
  // });

  useEffect( () => {
    setSteps([
      { id: 1, name: "Select Account", href: "#", status: "current" },
      { id: 2, name: "Add Test", href: "#", status: "upcoming" },
      { id: 3, name: "Questionnaire", href: "#", status: "upcoming" },
      { id: 4, name: "Review Booking", href: "#", status: "upcoming" },
    ]);
  }, [] );

  useEffect( () => () => {
    setSteps([]);
  }, [] );


  /* Search Test & Packages */
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTestList = selectTests.filter((item) =>
    (item.test_name_en && item.test_name_en.toLowerCase().includes(searchQuery.toLowerCase())) ||
    (item.test_code && item.test_code.toString().includes(searchQuery))
  );

  
  const filteredPackageList = packageList.filter((item) =>
    item.package_name_en && item.package_name_en.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const ChangeStep = (stepData) => {
    steps.map((step, key) => {
      if (stepData.id === step.id) {
        step.status = "current";
        setCurrentStep((prev) => {
          return parseInt(prev + (stepData.id));
        });
      } else {
        step.status = "upcomming";
      }
    });
    setSteps(steps);
  }

  const stepCount = (type) => {
    setFormBuilder(null);
    if (type === "add" && addStep !== 4) {

      if (addStep == 1) {
        getAllPatientList();
      }

      if (addStep == 2) {
        let count = 1;
        if (bookinginitialValues.questionsNewArr.length > 0) {
          count = 1;
        } else {
          count = 2;
        }
        if (bookinginitialValues.test_pack_memberArr.length <= 0) {
          toast.error("Please add Atleast 1 Test/Packages");
        }

        setAddStep(addStep + count)
      } else {
        setAddStep(addStep + 1);
      }
    } else if (type === "minus" && addStep !== 1) {
      setAddStep(addStep - 1);
    }
  }

  const nextStep = (type) => {
    SaveTestPackList();
    if (addStep == 2) {
      SaveTestPackquestionsArrList();
    }
    steps.map((step, key) => {
      if (key === 1) {
        TestPackSet();
        if (validateFlag) {
          return;
        }
      }
      if (type === "next") {

        if (currentStep === parseInt(key)) {
          step.status = "current";
          setCurrentStep((prev) => {
            let count = 2;
            if (key == 1) {
              if (bookinginitialValues.questionsNewArr.length > 0) {
                count = 1;
              } else {
                count = 2;
              }
              return parseInt(prev + count)
              // return parseInt(prev + 1)
            }
            return parseInt(prev + count);
          });
        } else if (currentStep > parseInt(key)) {
          step.status = "complete";
        } else {
          step.status = "upcomming";
        }
        setSteps(steps);
        if (addStep === 4) {
          setOpenBookingQuotation(true);
        }
      } else if (type === "previous") {
        if (addStep == parseInt(step.id + 1)) {
          setCurrentStep((prev) => {
            step.status = "current";
            return parseInt(prev - 1);
          });
        } else if (addStep > parseInt(step.id)) {
          step.status = "complete";
        } else if (addStep < parseInt(step.id + 1)) {
          step.status = "upcomming";
        }
        setSteps(steps);
      }
    });
    let total = [];
    if (bookinginitialValues.test_pack_memberArr.length > 0) {
      bookinginitialValues.test_pack_memberArr.forEach((ele, ind) => {
        if (ele.type === "Test") {
          total.push(!isNaN(ele.hos_price) ? parseInt(ele.hos_price) : 0);
        } else if (ele.type === "Package") {
          total.push(!isNaN(ele.package_price) ? parseInt(ele.package_price) : 0);
        }
      });
      setTotAmount(eval(total.join('+')));
      bookinginitialValues.rev_amount = totAmount;
      bookinginitialValues.rev_payable_amount = totAmount;
    }
  };

  const BookingConfirmRedirection = () => {
    if (!bookinginitialValues.visit_date && !bookinginitialValues.visit_time) {
      toast.error("Home visit date & time required");
      return false;
    }
    if (bookinginitialValues.booking_type === "Home") {
      bookinginitialValues.pickup_address = selectedAddress
    }

    bookinginitialValues.questions_answer = questionsArr;
    let total = [];
    if (bookinginitialValues.test_pack_memberArr.length > 0) {
      bookinginitialValues.test_pack_memberArr.forEach((ele, ind) => {
        if (ele.type === "Test") {
          total.push(!isNaN(ele.hos_price) ? parseInt(ele.hos_price) : 0);
        } else if (ele.type === "Package") {
          total.push(!isNaN(ele.package_price) ? parseInt(ele.package_price) : 0);
        }
      });
      setTotAmount(eval(total.join('+')));
      bookinginitialValues.rev_amount = totAmount;
      bookinginitialValues.rev_payable_amount = totAmount;
    }
    if (bookinginitialValues.rev_amount > 0) {
      // bookinginitialValues.rev_vat_amount = vatRate;
      // bookinginitialValues.rev_vat_amount = (vatRateType == 'FIXED' ? parseInt(vatRate) : ((parseInt(totAmount) * parseInt(vatRate)) / 100));
      bookinginitialValues.rev_vat_amount = totVatRate;

      bookinginitialValues.patientList = patientList;
      bookinginitialValues.fk_gs_id = vatRateId;
      bookinginitialValues.couponDetails = (couponDetails?.data?.couponDetails?.masterCoupon) ? ({ ...couponDetails?.data?.couponDetails?.masterCoupon, couponCode, cpc_id: couponDetails?.data?.couponDetails?.coupon_code?.cpc_id }) : null;
      bookinginitialValues.totalDiscount = totalDiscount;
      bookinginitialValues.vatRateDetails = vatRateDetails ? vatRateDetails : null;
      bookinginitialValues.fk_referral_clinic_id = refClinicId ? refClinicId : undefined;
      console.log("bookinginitialValues=>", bookinginitialValues);
      // return false;
      ReservationServices.saveReservation(bookinginitialValues)
        .then((response) => {
          const { data, status } = response;
          setCreditLimit(data.message);
          if (status == 200) {
            localStorage.setItem("edit_booking_reservation_user_details", JSON.stringify(userDetails));
            localStorage.setItem("edit_booking_user_id", userDetails.user_id);
            localStorage.setItem("edit_booking_reservationData", data.data.reservationData);
            localStorage.setItem("edit_booking_reservation_id", data.data.reservationData.reservation_id);
            toast.success("Reservation Added Successfully");
            navigate("/bookingConfirm")
          } else {
            toast.error("Fatal Error Please Contact Admin")
            //setCreditLimit(data.message);
          }
        })
        .catch((error) => {
          const msg = "saveReservation Catch Error" //error.response.data.message;
          toast.error(msg)
        });
    }
  }

  const getUserAddresses = async () => {
    setLoading(true)
    const userAddresses = await ReservationServices.getUserAddressesByUserId(user_id);
    if (userAddresses.status == 200) {
      setUserAddresses(userAddresses.data?.data)
    }
    setLoading(false)
  }

  const getTestNames = (pack) => {
    let tests = pack.tests
    let testStr = [];
    tests.map((t, i) => {
      testStr.push(t.t_test_relation?.test_name_en)
    })
    return testStr.join(", ");
  }

  const handleApplyCoupon = async () => {
    const body = {
      cpc_code: couponCode,
      fk_loc_id: collectionLocId,
      fk_user_id: user_id
    }
    const validateCouponRes = await MasterServices.validateCoupon(body)

    if (validateCouponRes.status) {
      // alert("Test")
      setCouponDetials(validateCouponRes.data)
      // toast.success(validateCouponRes.data.message)
      getTestPackPatientDetails();
    }
  }

  useEffect(() => {
    setSelectedPerson(null);
    setOutsourceClinicListMain(Array.isArray(filteredUserList) && filteredUserList.filter(v => v.fk_oc_id))
    if(Array.isArray(filteredUserList) && filteredUserList.filter(v =>  v.user_mobile && v.user_mobile != '' && v.user_mobile == selectedUserObject?.user_mobile).length == 1) {
      console.log(filteredUserList.filter(v =>  v.user_mobile != '' && v.user_mobile == selectedUserObject?.user_mobile ) ,"filteredUserList.filter(v =>  v.user_mobile != '' && v.user_mobile == selectedUserObject)")
      setSelectedPerson(filteredUserList.filter(v =>  v.user_mobile && v.user_mobile != '' && v?.user_mobile == selectedUserObject?.user_mobile)[0]);
    }
  }, [filteredUserList])

  const calculateDiscount = (price) => {
    let d = 0;
    let couponD = couponDetails?.data?.couponDetails?.masterCoupon
    if (couponD) {
      let { cp_discountValue, cp_max_discount, cp_value_is_percentage } = couponD
      if (cp_value_is_percentage) {
        d = price * cp_discountValue / 100
        if (d > cp_max_discount) d = cp_max_discount
      } else {
        d = cp_discountValue
      }
    }
    return d;
  }

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

  const handleCalculateDiscount = (type, id, price) => {
    let testPackageDetails = couponDetails?.data?.couponDetails
    let discount = 0;
    if (couponDetails?.data?.couponDetails) {
      if (type == 'test') {
        testPackageDetails?.tests?.forEach((el) => {
          if (el.fk_test_id == id) {
            discount = calculateDiscount(price)
            el.discount = discount;
          }
          bookinginitialValues?.test_pack_memberArr?.forEach((el2) => {
            if (el.fk_test_id == el2.test_id && el2.type == "Test") {
              el2.discount = el.discount;
            }
          });
        })
      } else if (type == 'package') {

        testPackageDetails?.packages?.forEach((el) => {
          if (el.fk_package_id == id) {
            discount = calculateDiscount(price);
            el.discount = discount;
          }
          bookinginitialValues?.test_pack_memberArr?.forEach((el2) => {
            if (el2.package_id == el.fk_package_id && el2.type == "Package") {
              el2.discount = el.discount;
            }
          })

        })
      }
    }
    return discount
  }

  const getAllOutsourcedClinics = async () => {
    const res = await MasterServices.getAllOutsourcedClinicList();
    if (res.status === 200) {
      setOutsourcedClinicList(res.data.data);
    }
  }

  const onChangeOSClinic = async (clinic_id)=>{
    onChangeRefClinic(clinic_id)
  }

  const handleNationalityChange = (e, setFieldValue) => {
    const { value } = e.target;
    console.log(value);
    setFieldValue("fk_country_id", value);

    if (value === "203") { 
      setFieldValue("user_id_type", "National ID");
    } else {
      setFieldValue("user_id_type", ""); 
    }
  };
  
  

  return (
    <div>
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900 mb-5">
          Reservation
        </h1>

        </div>
			{Array.isArray(userList) ? (
        <>
      <div className="h-screen">
        <div className="lg:border-t lg:border-b lg:border-gray-200">
          <nav
            className="mx-auto max-w-7xl"
            aria-label="Progress"
          >
            <ol
              role="list"
              className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
            >
              {steps.map((step, stepIdx) => (
                <li
                  key={step.id}
                  // onClick={() => { ChangeStep(step) }}
                  className="relative overflow-hidden lg:flex-1"
                >
                  <div
                    className={stepNames(
                      stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                      stepIdx === steps.length - 1
                        ? "border-t-0 rounded-b-md"
                        : "",
                      "border border-gray-200 overflow-hidden lg:border-0"
                    )}
                  >
                    {step.status === "complete" ? (
                      <a href={step.href} className="group">
                        <span
                          className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                          aria-hidden="true"
                        />
                        <span
                          className={stepNames(
                            stepIdx !== 0 ? "lg:pl-9" : "",
                            "px-6 py-5 flex items-center text-xs font-medium"
                          )}
                        >
                          <span className="flex-shrink-0">
                            <span className="flex h-10 w-10 items-center justify-center rounded-full bg-cyan-600">
                              <CheckIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </span>
                          <span className="mt-0.5 ml-4 flex min-w-0 flex-col">
                            <span className="text-xs font-medium">
                              {step.name}
                            </span>
                            {/* <span className="text-xs font-medium text-gray-500">{step.description}</span> */}
                          </span>
                        </span>
                      </a>
                    ) : step.status === "current" ? (
                      <a href={step.href} aria-current="step">
                        <span
                          className="absolute top-0 left-0 h-full w-1 bg-cyan-600 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                          aria-hidden="true"
                        />
                        <span
                          className={stepNames(
                            stepIdx !== 0 ? "lg:pl-9" : "",
                            "px-6 py-5 flex items-center text-xs font-medium"
                          )}
                        >
                          <span className="flex-shrink-0">
                            <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-cyan-600">
                              <span className="text-cyan-600">{step.id}</span>
                            </span>
                          </span>
                          <span className="mt-0.5 ml-4 flex min-w-0 flex-col">
                            <span className="text-xs font-medium text-cyan-600">
                              {step.name}
                            </span>
                          </span>
                        </span>
                      </a>
                    ) : (
                      <a href={step.href} className="group">
                        <span
                          className="absolute top-0 left-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                          aria-hidden="true"
                        />
                        <span
                          className={stepNames(
                            stepIdx !== 0 ? "lg:pl-9" : "",
                            "px-6 py-5 flex items-center text-xs font-medium"
                          )}
                        >
                          <span className="flex-shrink-0">
                            <span className="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                              <span className="text-gray-500">{step.id}</span>
                            </span>
                          </span>
                          <span className="mt-0.5 ml-4 flex min-w-0 flex-col">
                            <span className="text-xs font-medium text-gray-500">
                              {step.name}
                            </span>
                          </span>
                        </span>
                      </a>
                    )}

                    {stepIdx !== 0 ? (
                      <>
                        {/* Separator */}
                        <div
                          className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                          aria-hidden="true"
                        >
                          <svg
                            className="h-full w-full text-gray-300"
                            viewBox="0 0 12 82"
                            fill="none"
                            preserveAspectRatio="none"
                          >
                            <path
                              d="M0.5 0V31L10.5 41L0.5 51V82"
                              stroke="currentcolor"
                              vectorEffect="non-scaling-stroke"
                            />
                          </svg>
                        </div>
                      </>
                    ) : null}
                  </div>
                </li>
              ))}
            </ol>
          </nav>
        </div>
        <div>
          <div
            className={` ${addStep === 1 ? "content mt-5" : "content mt-5 hidden"
              }`}
          >
            {/* code for outsource clinic */}
            <div className="inline-flex align-middle mr-2 mb-3">
              <p className="text-gray-500 text-xs pt-1 pl-2 pr-2">Select Accounts</p>
                <Switch
                  checked={showOutsourceClinicList}
                  onChange={(event) => {
                    console.log(event)
                    setShowOutsourceClinicList(event);
                    setIsOutsourced(event);
                    initialValues.mc_is_outsource = event;
                    setQuery('');
                    bookinginitialValues.user_name = ''; 
                    getUserID('');
                    setSelectedPerson('');
                  }}
                  className={classNames(
                    showOutsourceClinicList ? "bg-cyan-600" : "bg-gray-200",
                    "inline-flex relative h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showOutsourceClinicList
                        ? "translate-x-5"
                        : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <p className="text-gray-500 text-xs pt-1 pl-2">Select Outsourced Clinic</p>
              </div>
            {showOutsourceClinicList ? (
            <>
              <div className="flex gap-5">
                <div className="basis-2/3 items-center">
                  <Combobox
                    disabled={userList ? false : true}
                    as="div"
                    value={selectedPerson}
                    onChange={setSelectedPerson}
                  >
                    <Combobox.Label className="block text-sm font-medium text-gray-700">
                      {/* Select Account */}
                    </Combobox.Label>
                    <div className="flex items-center">
                      <div className="relative mt-1 basis-3/4">
                        <Combobox.Input
                          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500 sm:text-xs"
                          onChange={(event) => { 
                            setQuery(event.target.value);
                            bookinginitialValues.user_name = event.target.value; 
                            getUserID(event.target.value);
                          }}
                          displayValue={(User) => (User?.user_first_name ? getUserID(User.user_first_name) : '')}
                          onClick={(e) => {
                            if (e.relatedTarget?.id?.includes('headlessui-combobox-button')) return;
                            e.target.nextSibling.click();
                          }}
                          autoComplete="off"
                        />

                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>

                        {outsourceClinicListMain?.length > 0 && (
                          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                            {outsourceClinicListMain.map((User, key) => (
                              <Combobox.Option
                                autoComplete="off"
                                disabled={User ? false : true}
                                key={key}
                                value={User}
                                className={({ active }) =>
                                  patientNames(
                                    "relative cursor-default select-none py-2 pl-3 pr-9",
                                    active
                                      ? "bg-cyan-600 text-white"
                                      : "text-gray-900"
                                  )
                                }
                                onClick={() => {
                                  setOSClinicId(User.fk_oc_id); 
                                  bookinginitialValues.os_clinic_id = User.fk_oc_id; // 18/08/23
                                  console.log(User.fk_oc_id, "User.fk_oc_id")
                                  onChangeOSClinic(User.fk_oc_id);
                                  setSelectedOCClinic(outsourcedClinicList?.filter(v => v.mc_id == User.fk_oc_id)[0])
                                }}
                              >
                                {({ active, selected }) => (
                                  <>
                                    <div className="flex">
                                      <span
                                        className={patientNames(
                                          "truncate",
                                          selected && "font-semibold"
                                        )}
                                      >
                                        {User.user_first_name}
                                      </span>
                                      <span
                                        className={patientNames(
                                          "ml-2 truncate text-gray-500",
                                          active
                                            ? "text-white"
                                            : "text-gray-500"
                                        )}
                                      >
                                        {/* {"(" + User.user_id_no + ")"} */}
                                      </span>
                                    </div>

                                    {selected && (
                                      <span
                                        className={patientNames(
                                          "absolute inset-y-0 right-0 flex items-center pr-4",
                                          active
                                            ? "text-white"
                                            : "text-cyan-600"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </Combobox.Options>
                        )}
                      </div>
                    </div>
                  </Combobox>
                </div>
              </div>
              {/* code for outsource clinic ends here */}
            </>
            ) : (
              <>
            <div className="flex gap-5">
              <div className="basis-2/3 items-center">
                <Combobox
                  disabled={userList ? false : true}
                  as="div"
                  value={selectedPerson}
                  onChange={setSelectedPerson}
                >
                  <Combobox.Label className="block text-sm font-medium text-gray-700">
                    {/* Select Account */}
                  </Combobox.Label>
                  <div className="flex items-center">
                    <div className="relative mt-1 basis-3/4">
                      {/* <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500 sm:text-xs"
                        onChange={(event) => { setQuery(event.target.value); bookinginitialValues.user_name = event.target.value; getUserID(event.target.value); }}
                        displayValue={(User) => (User?.user_first_name ? getUserID(User.user_first_name) + User.user_mobile + ${User.user_first_name} (${User.user_id_no}) ${User.patient_report_mrn} : '')}
                        onClick={(e) => {
                          if (e.relatedTarget?.id?.includes('headlessui-combobox-button')) return;
                          e.target.nextSibling.click();
                        }}
                        autoComplete="off"
                      /> */}
                      <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500 sm:text-xs"
                        onChange={(event) => { 
                          setQuery(event.target.value); 
                          bookinginitialValues.user_name = event.target.value; 
                          getUserID(event.target.value); 
                        }}
                        displayValue={(User) => (
                          User ? `${getUserID(User.user_first_name)} (${User.user_id_no ? User.user_id_no : "-"}) Mobile:${User.user_mobile ? User.user_mobile : "-" } MRN:${User.patient_report_mrn ? User.patient_report_mrn : "-"}` : ''
                        )}
                        onClick={(e) => {
                          if (e.relatedTarget?.id?.includes('headlessui-combobox-button')) return;
                          e.target.nextSibling.click();
                        }}
                        autoComplete="off"
                      />

                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Combobox.Button>

                      {filteredUserList?.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                          {filteredUserList.filter(v => !v.fk_oc_id).map((User, key) => (
                            <Combobox.Option
                              autoComplete="off"
                              disabled={User ? false : true}
                              key={key}
                              value={User}
                              className={({ active }) =>
                                patientNames(
                                  "relative cursor-default select-none py-2 pl-3 pr-9",
                                  active
                                    ? "bg-cyan-600 text-white"
                                    : "text-gray-900"
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <div className="flex">
                                    <span
                                      className={patientNames(
                                        "truncate",
                                        selected && "font-semibold"
                                      )}
                                    >
                                      {User.user_first_name}
                                    </span>
                                    <span
                                      className={patientNames(
                                        "ml-2 truncate text-gray-500",
                                        active
                                          ? "text-white"
                                          : "text-gray-500"
                                      )}
                                    >
                                      {"(" + User.user_id_no + ")"}
                                    </span>
                                  </div>
                                  <span className="text-[10px] color-gray-500">Mobile: {User.user_mobile ? User.user_mobile : '-'}, MRN: {User.patient_report_mrn ? User.patient_report_mrn : '-'} </span>

                                  {selected && (
                                    <span
                                      className={patientNames(
                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                        active
                                          ? "text-white"
                                          : "text-cyan-600"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}
                    </div>
                    <div className="sm:mt-0 sm:ml-5 sm:flex-none basis-1/4">
                      <Link
                        onClick={() => { setMobileNumberValid(true); setOpenPatient(true); setUserType('User') }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
                      >
                        Add Account
                      </Link>
                    </div>
                  </div>
                </Combobox>
              </div>
              <div className="basis-1/3">
                <Combobox
                  as="div"
                  value={selectedBooking}
                  onChange={setSelectedBooking}

                >
                  <Combobox.Label className="block text-xs font-medium text-gray-700">
                    Booking Type
                  </Combobox.Label>
                  <div>
                    <div className="relative mt-1 basis-3/4">
                      <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500 sm:text-xs"
                        onChange={(event) => {
                          console.log("event", event.target.value, selectedBooking)
                          setQueryBooking(event.target.value); bookinginitialValues.booking_type = event.target.value;
                        }
                        }
                        displayValue={(person) => (person?.name) ? getBookingValues(person?.name) : ''}
                        onClick={(e) => {
                          if (e.relatedTarget?.id?.includes('headlessui-combobox-button')) return;
                          e.target.nextSibling.click();
                        }}
                      />
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <ChevronUpDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Combobox.Button>

                      {filteredBooking?.length > 0 && (
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-xs">
                          {filteredBooking.map((person, key) => (
                            <Combobox.Option
                              key={key}
                              value={person}
                              className={({ active }) =>
                                BookingNames(
                                  "relative cursor-default select-none py-2 pl-3 pr-9",
                                  active
                                    ? "bg-cyan-600 text-white"
                                    : "text-gray-900"
                                )
                              }
                            >
                              {({ active, selected }) => (
                                <>
                                  <div className="flex">
                                    <span
                                      className={BookingNames(
                                        "truncate",
                                        selected && "font-semibold"
                                      )}
                                    >
                                      {person.name}{selected}
                                    </span>
                                    <span
                                      className={BookingNames(
                                        "ml-2 truncate text-gray-500",
                                        active
                                          ? "text-cyan-200"
                                          : "text-gray-500"
                                      )}
                                    >
                                      {person.phone}
                                    </span>
                                  </div>

                                  {selected && (
                                    <span
                                      className={BookingNames(
                                        "absolute inset-y-0 right-0 flex items-center pr-4",
                                        active
                                          ? "text-white"
                                          : "text-cyan-600"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  )}
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </Combobox.Options>
                      )}
                    </div>
                  </div>
                </Combobox>
              </div>
            </div>

              </>
            ) }
            {
              user_id ? (
                <div className="text-end mt-10">

                  {/* 18/08/23 - Abhi */}
                  {!showOutsourceClinicList && (
                  <div className="inline-flex align-middle mr-2">
                    <Switch
                      checked={isOutsourced}
                      onChange={(event) => {
                        setIsOutsourced(event);

                        initialValues.mc_is_outsource = event;
                        // formState.mc_is_outsource = event;
                        // setFormState(formState);
                        console.log("setIsOutsourced", event);
                      }}
                      className={classNames(
                        isOutsourced ? "bg-cyan-600" : "bg-gray-200",
                        "inline-flex relative h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          isOutsourced
                            ? "translate-x-5"
                            : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <p className="text-gray-500 text-xs pt-1 pl-2">Outsourced Clinic</p>
                  </div>
                  )}
                  {/* 18/08/23 - Abhi */}
                  {!showOutsourceClinicList &&
                    isOutsourced && outsourcedClinicList.length > 0 ?

                      <div className="inline-flex items-center justify-center text-xs text-gray-500 font-medium sm:w-auto mr-2">
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <select
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-xs"
                            name="clinic_id"
                            id="clinic_id"
                            onChange={(e) => {
                              setOSClinicId(e.target.value); 
                              bookinginitialValues.os_clinic_id = e.target.value; // 18/08/23
                              console.log(e.target.value)
                              onChangeOSClinic(e.target.value);
                            }
                            }
                          >
                            <option value={0} selected> Select Outsourced Clinic </option>
                            {outsourcedClinicList.map((clinic, i) => (
                              <option
                                selected={
                                  clinic.mc_id ===
                                    osClinicId
                                    ? "selected"
                                    : ""
                                }
                                key={i}
                                value={clinic.mc_id}
                              >
                                {clinic.mc_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      : null
                  }

                  {!showOutsourceClinicList &&
                    !isOutsourced && referralClinicList && referralClinicList?.length > 0 ?

                      <div className="inline-flex items-center justify-center text-xs text-gray-500 font-medium sm:w-auto mr-2">
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <select
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-xs"
                            name="clinic_id"
                            id="clinic_id"
                            value={refClinicId}
                            onChange={(e) => {
                              setRefClinicId(e.target.value);
                              bookinginitialValues.referral_clinic_id = e.target.value;
                              onChangeRefClinic(e.target.value);
                            }
                            }
                          >
                            <option value={undefined} selected> Select Referral Clinic </option>
                            {referralClinicList.map((clinic, i) => (
                              <option
                                selected={
                                  clinic.mc_id ===
                                    bookinginitialValues.referral_clinic_id
                                    ? "selected"
                                    : ""
                                }
                                key={i}
                                value={clinic.mc_id}
                              >
                                {clinic.mc_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      : null
                  }

                  {/* Logged In User Location */}
                  {

                    locationList && locationList.length > 0 ?
                      <div className="inline-flex items-center justify-center text-xs text-gray-500 font-medium sm:w-auto mr-2">
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <select
                            // disabled={bookinginitialValues.TBB_role_id == 8 ? false : true}
                            disabled={true}
                            className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-xs"
                            name="loc_id"
                            id="loc_id"
                            value={collectionLocId}
                            onChange={(e) => {
                              (e.target.value == null ? setValidateFlag(true) : setValidateFlag(false));
                              setCollectionLocId(e.target.value);
                              bookinginitialValues.fk_collection_loc_id = e.target.value;
                              (bookinginitialValues.fk_collection_loc_id != '' && bookinginitialValues.visit_date != '' && bookinginitialValues.visit_time != '') ? setValidateFlag(false) : setValidateFlag(true)
                            }
                            }
                          >
                            <option value={0}> Select Location </option>
                            {locationList.map((location, i) => (
                              <option
                                selected={
                                  location.loc_id ===
                                    bookinginitialValues.loc_id
                                    ? "selected"
                                    : ""
                                }
                                key={i}
                                value={location.loc_id}
                              >
                                {location.loc_name_en}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      : null
                  }

                  {/* Date Time Picker */}
                  <div className="inline-flex items-center justify-center text-xs text-gray-500 font-medium sm:w-auto">
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-xs"
                        type="date"
                        name="filterFrom"
                        id="filterFrom"
                        value={dateValue}
                        min={currentDate}
                        onChange={(e) => {
                          setDateValue(e.target.value);
                          bookinginitialValues.visit_date = e.target.value;
                          (bookinginitialValues.visit_date != '' && bookinginitialValues.visit_time != '') ? setValidateFlag(false) : setValidateFlag(true)
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className="ml-2 inline-flex items-center justify-center text-xs text-gray-500 font-medium sm:w-auto">
                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                      <input
                        className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-xs"
                        type="time"
                        name="filterFrom"
                        id="filterFrom"
                        min={currentTime}
                        value={timeValue}
                        onChange={(e) => {
                          setTimeValue(e.target.value);
                          bookinginitialValues.visit_time = e.target.value;
                          (bookinginitialValues.visit_date != '' && bookinginitialValues.visit_time != '') ? setValidateFlag(false) : setValidateFlag(true)
                        }
                        }
                      />
                    </div>
                  </div>

                  {
                    !loading && addStep === 1 && selectedBooking?.name == "Home" ? (
                      <Link
                        onClick={() => {
                          setOpenAddressDialog(true);
                          setSelectedAddress(false)
                        }}
                        type="button"
                        className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
                      >
                      Add Address
                      </Link>
                    ) : null
                  }

                  <div className="mt-2 ml-2 items-center w-full justify-center text-xs text-orange-400 font-medium sm:w-auto">
                    Please select the Date and Time for Visit
                  </div>
                </div>
              ) : null
            }

            {/* Addresses Listing */}
          </div>
          {
            !loading && addStep === 1 && userAddresses && selectedBooking?.name == "Home" && userAddresses.length > 0 ? (
              <AddressRadioGroup userAddresses={userAddresses} selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} setOpenAddressDialog={setOpenAddressDialog} />
            ) : null
          }

          <div
            className={`content mt-5 ${addStep === 2 ? "content mt-5" : "content mt-5 hidden"
              }`}
          >
            <div className="add-test-family-main">
              <div className="basis-2/3 items-center">
                <Combobox as="div">
                  {!showOutsourceClinicList ? (
                  <div className="flex items-center">
                    <div className="relative mt-1 basis-3/4">
                      <Combobox.Label className="block text-xs font-medium text-gray-700">
                        Family Members
                      </Combobox.Label>
                    </div>
                    <div className="sm:mt-0 sm:ml-auto sm:flex-none basis-1/4">
                      <Link
                        onClick={() => { setOpenPatient(true); setUserType('Patient') }}
                        type="button"
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
                      >
                        Add Patient
                      </Link>
                    </div>
                  </div>
                  ) : (
                  <div className="flex items-center">
                  <div className="relative mt-1 basis-3/4">
                    <Combobox.Label className="block text-xs font-medium text-gray-700">
                      Patients
                    </Combobox.Label>
                  </div>
                  <div className="sm:mt-0 sm:ml-auto sm:flex-none basis-1/4">
                    <Link
                      onClick={() => { setOpenPatientOC(true); }}
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
                    >
                      Add Patient
                    </Link>
                  </div>
                </div> 
                )}
                </Combobox>
              </div>
            </div>
            {testPackageLoading ? (
              <FallingLinesLoader />
            ) :
              <div className="mt-8 flow-root">
                <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Name</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Relation</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Gender</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Nationality</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Mobile</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Tests/Packages</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(patientList.length > 0) ? (patientList.map((p_patient, p_key) => (
                            <tr>
                              <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                                {p_patient?.patient_first_name}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                                {p_patient?.relationship}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                                {p_patient?.m_gender_relation?.gender_name_en}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                                {p_patient?.m_country_relation?.country_name}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                                {p_patient.patient_mobile}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                                {p_patient.testPackNameArr.length > 0 ? (
                                  <div class="tooltip">View
                                    <span class="tooltiptext">{`${p_patient.testPackNameArr ? p_patient.testPackNameArr.toString() : '....'}`}</span>
                                  </div>
                                  // <span data-toggle="tooltip" data-placement="top" title={`${p_patient.testPackNameArr ? p_patient.testPackNameArr.toString() : '....'}`} className="cursor-pointer"> View</span>
                                ) : (<></>)}
                                {/* {p_patient.testPackNameArr ? p_patient.testPackNameArr.toString() : '....'} */}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-xs text-gray-500">
                                <Link
                                  type="button"
                                  onClick={() => { setOpenAddTest(true); setPackageStatus(false); setOpenTestPatientId(p_patient?.patient_id); TestPackPatientSet(p_patient, p_patient?.patient_id); }}
                                  // className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 sm:w-auto"
                                  className={
                                    p_patient.testPackArr.length > 0 ?
                                      'inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto bg-green-600 hover:bg-green-700 focus:ring-green-500' :
                                      'inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto bg-cyan-600 hover:bg-cyan-700 focus:ring-cyan-500'}
                                >
                                  {p_patient.testPackArr.length > 0 ? 'Added' : 'Add Tests'}
                                </Link>
                              </td>
                            </tr>
                          ))) : <FallingLinesLoader />
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div
            className={`content mt-5 ${addStep === 3 ? "content mt-5" : "content mt-5 hidden"
              }`}
          >
            <div className="px-4 sm:px-6 lg:px-8">
              <h1>Questionnaire</h1>
              {loading ? (<FallingLinesLoader />) : <>
                {
                  bookinginitialValues?.questionsArr.length > 0 ? (<ResQuestionnaire
                    questionsArr={bookinginitialValues?.questionsNewArr.length > 0 ? bookinginitialValues?.questionsNewArr : []}
                    setQuestionsArr={setQuestionsArr}
                  />) : null}
                {formBuilder?.formData}
              </>}
            </div>
          </div>
          <div
            className={`content mt-5 ${addStep === 4 ? "content mt-5" : "content mt-5 hidden"
              }`}
          >
            <div className="add-test-family-main">
              <div className="basis-2/3 items-center">
                <Combobox as="div">
                  <div className="flex items-center">
                    <div className="relative mt-1 basis-3/4">
                      <Combobox.Label className="block text-xs font-medium text-gray-700">
                        Included Patients
                      </Combobox.Label>
                    </div>
                  </div>
                </Combobox>
              </div>
            </div>
            {loading ? (
              <FallingLinesLoader />
            ) :
              <div className="mt-8 flow-root">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>

                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Action</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Type</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Test</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Name</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Mobile/Email</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Identity</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Id No.</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Nationality</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">DOB</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Age</th>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6">Gender</th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {(patientList.length > 0) ? patientList.map((test_pack_ele, test_pack_key) => {
                            return (test_pack_ele.testPackArr.length > 0 ? test_pack_ele.testPackArr.map((test_pack_ele2, test_pack_key2) => (
                              <tr>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
                                  <a href="#" className="text-cyan-600 hover:text-cyan-900">
                                    <span className="sr-only"></span>
                                    <Link
                                      type="button"
                                      // onClick={() => { removeTestPack(test_pack_ele2, test_pack_key2); }}
                                      onClick={() => { removeTestPackPatient(test_pack_ele2, test_pack_key2, test_pack_ele); }}
                                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                                    >
                                      Remove
                                    </Link>
                                  </a>
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {test_pack_ele2?.type}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {test_pack_ele2.test_name_en ? test_pack_ele2?.test_name_en : test_pack_ele2?.package_name_en}<br />
                                  {(test_pack_ele2?.package_name_en) ? ("Tests : " + test_pack_ele2?.test_names) : null}
                                  <br />
                                  {test_pack_ele2.hos_price ? test_pack_ele2?.hos_price : test_pack_ele2?.package_price}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {test_pack_ele?.patient_first_name}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {test_pack_ele?.patient_mobile}<br />{test_pack_ele?.patient_email}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {test_pack_ele?.patient_id_type}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {test_pack_ele?.patient_id_no}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {test_pack_ele?.m_country_relation?.country_name}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {moment(convertToAnotherTimezone(test_pack_ele?.patient_dob)).format("DD-MM-YYYY")}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {!isNaN(calculateAge(moment(test_pack_ele?.patient_dob).format("YYYY-MM-DD"))) ? calculateAge(moment(test_pack_ele?.patient_dob).format("YYYY-MM-DD")) : 0}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                  {test_pack_ele?.m_gender_relation?.gender_name_en}
                                </td>
                              </tr>
                            )) : (<></>))
                          }
                          ) : (<></>)}
                        </tbody>
                        {/* <tbody className="divide-y divide-gray-200 bg-white">
                          {(bookinginitialValues.test_pack_memberArr.length > 0) ? bookinginitialValues.test_pack_memberArr.map((test_pack_ele, test_pack_key) => (
                            <tr>

                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-6">
                                <a href="#" className="text-cyan-600 hover:text-cyan-900">
                                  <span className="sr-only"></span>
                                  <Link
                                    type="button"
                                    onClick={() => { removeTestPack(test_pack_ele, test_pack_key); }}
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                                  >
                                    Remove
                                  </Link>
                                </a>
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {test_pack_ele.type}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {test_pack_ele.test_name_en ? test_pack_ele.test_name_en : test_pack_ele.package_name_en}<br />
                                {(test_pack_ele?.package_name_en) ? ("Tests : " + test_pack_ele.test_names) : null}
                                <br />
                                {test_pack_ele.hos_price ? test_pack_ele.hos_price : test_pack_ele.package_price}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {test_pack_ele.patient_first_name + ' ' + test_pack_ele.patient_last_name}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {test_pack_ele.patient_mobile}<br />{test_pack_ele.patient_email}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {test_pack_ele.patient_id_type}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {test_pack_ele.patient_id_no}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {test_pack_ele.country_name}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {moment(test_pack_ele.patient_dob).format("DD-MM-YYYY")}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {test_pack_ele.patient_age}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                {test_pack_ele.gender_name_en}
                              </td>
                            </tr>
                          )) : (<tr>
                            <td colSpan={10} className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                              <center>Data Not Found</center>
                            </td></tr>)}
                        </tbody> */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="p-5 fixed bottom-5 right-12">
          <div className="flex justify-end">
            <button
              disabled={addStep == 1}
              onClick={() => {
                nextStep("previous");
                // getAllPatientList();
                stepCount("minus");
              }}
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
            >
              Previous
              {/* {bookinginitialValues.booking_type}
              {bookinginitialValues.selectedAddress} */}
              {/* {bookinginitialValues.fk_collection_loc_id} */}
            </button>
            <button
              disabled={((addStep == 2 && bookinginitialValues.test_pack_memberArr.length <= 0) ? true : (bookinginitialValues.fk_collection_loc_id == 0 ? true : bookinginitialValues.booking_type == 'Home' && userAddresses.length <= 0 ? true : ((bookinginitialValues.visit_time != '' && bookinginitialValues.visit_date != '' && bookinginitialValues.fk_collection_loc_id != '' && bookinginitialValues.user_name != '') ? false : (validateFlag == true))))}
              onClick={() => {
                nextStep("next");
                BookingValues();
                // getAllPatientList();
                stepCount("add");
              }}
              type="submit"
              className={`${((addStep == 2 && bookinginitialValues.test_pack_memberArr.length <= 0) || (bookinginitialValues.fk_collection_loc_id == 0) || (bookinginitialValues.booking_type == 'Home' && userAddresses.length <= 0)) ? " bg-gray-600 py-2 px-4  hover:bg-gray-700 focus:outline-none  focus:ring-gray-500 focus:ring-offset-2" : ((bookinginitialValues.visit_time != '' && bookinginitialValues.visit_date != '' && bookinginitialValues.fk_collection_loc_id != '' && bookinginitialValues.user_name != '')) ? 'bg-cyan-600 py-2 px-4  hover:bg-cyan-700 focus:outline-none  focus:ring-cyan-500 focus:ring-offset-2' : validateFlag ? " bg-gray-600 py-2 px-4  hover:bg-gray-700 focus:outline-none  focus:ring-gray-500 focus:ring-offset-2" : " bg-cyan-600 py-2 px-4  hover:bg-cyan-700 focus:outline-none  focus:ring-cyan-500 focus:ring-offset-2"
                } ml-3 inline-flex justify-center rounded-md border border-transparent  text-xs font-medium text-white shadow-sm'`}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <Transition.Root show={openPatient} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => {  }}
          static
        >

          <Transition.Child
            afterLeave={() => {
              closeModal()
            }}
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Formik
            enableReinitialize={true}
            enableReinitializing
            onSubmit={(values, action) => {
              if ((!mobileNumberValid || mobileNumber == '') && userData?.m_role_relation?.role_id != 13) {
                setMobileNumberValid(false)
                return false
              }
              if (!(new Date(values.user_dob) > (new Date('1949-01-01'))) || !(new Date(values.user_dob) <= (new Date(currentDate)))) {
                console.log('Invalid Date')
                setInvalidDate(true)
                return false
              } else {
                setInvalidDate(false)
              }
              let addObj = {
                user_first_name: values.user_first_name,
                user_last_name: values.user_last_name,
                user_email: values.user_email,
                user_mobile: userMobileNumber,
                patient_dial_code: mobileCountryCode,
                user_dob: values.user_dob,
                user_id_type: values.user_id_type,
                user_id_no: values.user_id_no,
                user_passport_no: values.user_passport_no,
                user_reasion_test: values.user_reasion_test,
                fk_gender_id: parseInt(values.fk_gender_id),
                fk_country_id: parseInt(values.fk_country_id),
                relationship: values.relationship,
                fk_questionnaire_id: parseInt(values.fk_questionnaire_id),
                userType: userType,
                Type: "Add",
                loc_branch_code: userLocDataState[0]?.loc_branch_code || '',
                loc_id: userLocDataState[0]?.value,
              }
              if (userType === "User") {
                addObj.relationship = "Self";
              } else if (userType === "Patient") {
                addObj.user_id = user_id;
              }
              addUserPatient(addObj, action);
              setSelectedUserObject(addObj);
              closeModal();
            }}
            initialValues={modalData}
            validationSchema={userData?.m_role_relation?.role_id != 13 ? uuserSchema : uuserSchema2}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setValues,
              values,
              touched,
              isValid,
              isSubmitting,
              errors,
              handleReset
            }) => (
              <div className="fixed inset-0 z-50 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                      <div>
                        <form noValidate
                          onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                              <div className="flex">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                  Add {userType == 'Patient' && userType} Account
                                </h3>
                                <CloseOutlined onClick={() => {  setOpenPatient(false); closeModal();  }}
 className="ml-auto cursor-pointer"/>
                              </div>
                              <div className="space-y-6 sm:space-y-5">
                                <div className="grid grid-cols-2 gap-4">
                                  <div className="">
                                    <label
                                      htmlFor="patient-name"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Full Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="text"
                                        name="user_first_name"
                                        id="user_first_name"
                                        value={values.user_first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter Full Name`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {
                                        errors.user_first_name && touched.user_first_name ? (
                                          <p className="text-red-600 text-xs">{errors.user_first_name}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>
                                  {/* <div className="">
                                    <label
                                      htmlFor="patient-name"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      {userType} Last Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="text"
                                        name="user_last_name"
                                        id="user_last_name"
                                        value={values.user_last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter ${userType} Name`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {
                                        errors.user_last_name && touched.user_last_name ? (
                                          <p className="text-red-600 text-xs">{errors.user_last_name}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div> */}

                                  {(userType === 'Patient') ?

                                    (<div className="" >
                                      <label
                                        htmlFor="patient-name"
                                        className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                      >
                                        Relationship
                                      </label>
                                      <div className="mt-1 sm:col-span-2 sm:mt-0">
                                        <select
                                          value={values.relationship}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                          name="relationship"
                                          id="relationship"
                                        >
                                          <option >Select Relationship</option>
                                          {relationshipList.map((relationship_ele, i) => (
                                            <option key={i} value={relationship_ele.value}>
                                              {relationship_ele.value}
                                            </option>
                                          ))}
                                        </select>
                                        {
                                          errors.relationship && touched.relationship ? (
                                            <p className="text-red-600 text-xs">{errors.relationship}</p>
                                          ) : null
                                        }
                                      </div>
                                    </div>) : null
                                  }

                                  <div className="">
                                    <label
                                      htmlFor="natinality"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Nationality
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      {filteredCountryList && filteredCountryList.length > 0 && (

                                        <select
                                            value={values.fk_country_id}
                                            // onChange={handleChange}
                                            onChange={(e) => handleNationalityChange(e, setFieldValue)}
                                            onBlur={handleBlur}
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                            name="fk_country_id"
                                            id="fk_country_id"
                                          >
                                            <option value="">Select Nationality</option>
                                            {filteredCountryList.map((country, i) => (
                                              <option key={i} value={country.country_id}>
                                                {country.country_name}
                                              </option>
                                            ))}
                                          </select>

                                      )}
                                      {
                                        errors.fk_country_id && touched.fk_country_id ? (
                                          <p className="text-red-600 text-xs">{errors.fk_country_id}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="identity-type"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Identity Type
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <div className="flex">
                                      <select
                                        value={values.user_id_type}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="user_id_type"
                                        name="user_id_type"
                                        autoComplete="off"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs mr-3"
                                      >
                                        <option value="">Select ID Type</option>
                                        {values.fk_country_id === "203" ?
                                          identityTypeList.map((identity, i) => (
                                            <option key={i} value={identity}>
                                              {identity}
                                            </option>
                                          )) :
                                          identityTypeList.filter(identity => identity !== 'National ID').map((identity, i) => (
                                            <option key={i} value={identity}>
                                              {identity}
                                            </option>
                                          ))
                                        }
                                      </select>

                                        <input
                                          type="text"
                                          disabled={values.user_id_type == '' ? true : false}
                                          value={values.user_id_no}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          name="user_id_no"
                                          id="user_id_no"
                                          autoComplete="off"
                                          placeholder="Please Enter ID No"
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                        />
                                      </div>
                                      {
                                        errors.user_id_type && touched.user_id_type ? (
                                          <p className="text-red-600 text-xs">{errors.user_id_type}</p>
                                        ) : null
                                      }
                                      {
                                        errors.user_id_no && touched.user_id_no ? (
                                          <p className="text-red-600 text-xs">{errors.user_id_no}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="passport"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Passport No.
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.user_passport_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        name="user_passport_no"
                                        id="user_passport_no"
                                        autoComplete="off"
                                        placeholder="Please Enter Passport No (Optional)"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="mobile"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Mobile No.
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <PhoneInputWithCountry
                                        defaultCountry="SA"
                                        value={mobileNumber}
                                        placeholder="Enter phone number"
                                        onChange={(e) => {
                                          setMobileNumber(e);
                                          console.log(e, values.user_mobile, errors.user_mobile)
                                          values.user_mobile = e
                                        }
                                        }
                                      />
                                      {/* {
                                        errors.user_mobile || mobileNumberValid ? (
                                          <p className="text-red-600 text-xs">{errors.user_mobile}</p>
                                        ) : null
                                      }  */}
                                      {
                                        !mobileNumberValid && userData?.m_role_relation?.role_id != 13 ? (
                                          <p className="text-red-600 text-xs">Mobile Number is invalid.</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="email"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Email address
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.user_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="user_email"
                                        name="user_email"
                                        type="email"
                                        autoComplete="off"
                                        placeholder="Please Enter Email ID (Optional)"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {
                                        errors.user_email && touched.user_email ? (
                                          <p className="text-red-600 text-xs">{errors.user_email}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="gender"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Gender
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        value={values.fk_gender_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                        name="fk_gender_id"
                                        id="fk_gender_id"
                                      >
                                        <option >Select Gender</option>
                                        {genderList.map((gender, i) => (
                                          <option key={i} value={gender.gender_id}>
                                            {gender.gender_name_en}
                                          </option>
                                        ))}
                                      </select>
                                      {
                                        errors.fk_gender_id && touched.fk_gender_id ? (
                                          <p className="text-red-600 text-xs">{errors.fk_gender_id}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="birth-date"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Date of Birth
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.user_dob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="date"
                                        name="user_dob"
                                        id="user_dob"
                                        autoComplete="off"
                                        max={currentDate}
                                        min={"1949-01-01"}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {
                                        errors.user_dob && touched.user_dob ? (
                                          <p className="text-red-600 text-xs">{errors.user_dob}</p>
                                        ) : null
                                      }
                                      {
                                        invalidDate && (
                                          <p className="text-red-600 text-xs">Invalid DOB</p>
                                        )
                                      }
                                    </div>
                                  </div>
                                  <div className="">
                                    <label
                                      htmlFor="testing"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Reason of testing
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        value={values.user_reasion_test}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="user_reasion_test"
                                        name="user_reasion_test"
                                        autoComplete="off"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      >
                                        <option >Select Reason of testing (Optional)</option>
                                        {reasonOfTestingList.map((reasonOfTesting, i) => (
                                          <option key={i} value={reasonOfTesting}>
                                            {reasonOfTesting}
                                          </option>
                                        ))}

                                      </select>
                                      {
                                        errors.user_reasion_test && touched.user_reasion_test ? (
                                          <p className="text-red-600 text-xs">{errors.user_reasion_test}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                onClick={() => { setOpenPatient(false); closeModal(); }}
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                onClick={() => { mobileNumber == '' && setMobileNumberValid(false) }}
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>

            )}
          </Formik>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openPatientOC} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={() => {  }}
          static
        >

          <Transition.Child
            afterLeave={() => {
              closeModal()
            }}
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Formik
            enableReinitialize={true}
            enableReinitializing
            onSubmit={(values, action) => {
              let addObj = {
                user_first_name: values.user_first_name,
                fk_country_id: parseInt(values.fk_country_id),
                user_age: values.user_age.toString(),
                fk_gender_id: parseInt(values.fk_gender_id),
                user_file_no: values.user_file_no,
                user_id: user_id,
                fk_oc_id: osClinicId,
                Type: "Add",
                loc_branch_code: userLocDataState[0]?.loc_branch_code || '',
                loc_id: userLocDataState[0]?.value,
              }
              addUserPatientOC(addObj, action);
              closeModal();
            }}
            initialValues={modalDataOC}
            validationSchema={patientDataSchemaOC}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              setValues,
              setFieldValue,
              values,
              touched,
              isValid,
              isSubmitting,
              errors,
              handleReset
            }) => (
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                      <div>
                        <form noValidate
                          onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                            <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                              <div className="flex">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                  Add {userType == 'Patient' && userType} Account
                                </h3>
                                <CloseOutlined onClick={() => {  setOpenPatient(false); closeModal();  }}
 className="ml-auto cursor-pointer"/>
                              </div>
                              <div className="space-y-6 sm:space-y-5">
                                <div className="grid grid-cols-2 gap-4">
                                  <div className="">
                                    <label
                                      htmlFor="patient-name"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Full Name
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="text"
                                        name="user_first_name"
                                        id="user_first_name"
                                        value={values.user_first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter Full Name`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {
                                        errors.user_first_name && touched.user_first_name ? (
                                          <p className="text-red-600 text-xs">{errors.user_first_name}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>
                             

                                  <div className="">
                                    <label
                                      htmlFor="natinality"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Nationality
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      {filteredCountryList && filteredCountryList.length > 0 && (

                                        <select
                                          value={values.fk_country_id}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                          name="fk_country_id"
                                          id="fk_country_id"
                                        >
                                          <option selected value="">Select Nationality</option>
                                          {filteredCountryList && filteredCountryList.length > 0 && filteredCountryList.map((country, i) => (
                                            <option key={i} value={country.country_id}>
                                              {country.country_name}
                                            </option>
                                          ))}
                                        </select>
                                      )}
                                      {
                                        errors.fk_country_id && touched.fk_country_id ? (
                                          <p className="text-red-600 text-xs">{errors.fk_country_id}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="gender"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Gender
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <select
                                        value={values.fk_gender_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                        name="fk_gender_id"
                                        id="fk_gender_id"
                                      >
                                        <option >Select Gender</option>
                                        {genderList.map((gender, i) => (
                                          <option key={i} value={gender.gender_id}>
                                            {gender.gender_name_en}
                                          </option>
                                        ))}
                                      </select>
                                      {
                                        errors.fk_gender_id && touched.fk_gender_id ? (
                                          <p className="text-red-600 text-xs">{errors.fk_gender_id}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>

                                  <div className="">
                                    <label
                                      htmlFor="patient-age"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      Age
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="number"
                                        name="user_age"
                                        id="user_age"
                                        value={values.user_age}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter Age`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {
                                        errors.user_age && touched.user_age ? (
                                          <p className="text-red-600 text-xs">{errors.user_age}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>
                                  <div className="">
                                    <label
                                      htmlFor="patient-file-no"
                                      className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                    >
                                      File No.
                                    </label>
                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                      <input
                                        type="text"
                                        name="user_file_no"
                                        id="user_file_no"
                                        value={values.user_file_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={`Please Enter File No.`}
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-xs"
                                      />
                                      {
                                        errors.user_file_no && touched.user_file_no ? (
                                          <p className="text-red-600 text-xs">{errors.user_file_no}</p>
                                        ) : null
                                      }
                                    </div>
                                  </div>
                                
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                onClick={() => { setOpenPatientOC(false); closeModal(); }}
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>

            )}
          </Formik>
        </Dialog>
      </Transition.Root>


      <Transition.Root show={openAddTest} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={setOpenAddTest}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 add-test-table-w sm:p-6">
                  <div style={{height: '90vh'}}>
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                          <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Add {packageStatus === true ? 'Packages' : 'Tests'}
                            </h3>
                          </div>
                          <div className="grid grid-cols-3 gap-6 space-y-6 sm:space-y-5 sm:border-t sm:border-gray-200 pt-2 mt-2">

                            {
                              !packageStatus ? (
                                <div className="sm:items-start sm:gap-4 sm:pt-5">
                                  <label
                                    htmlFor="patient-name"
                                    className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    Select Category
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <Select
                                      disabled={packageStatus == true ? true : false}
                                      defaultValue={false}
                                      isMulti
                                      name="category_id"
                                      id="category_id"
                                      value={multiCategoryId}
                                      onChange={(e) => { multiCategory(e); filterTest(e) }}
                                      options={selectMutiCategory}
                                      className="basic-multi-select mt-2"
                                      classNamePrefix="Select Test"
                                    />
                                  </div>
                                </div>
                              ) : null
                            }

                            <div className="justify-between items-center">
                              <div className="">
                                <label
                                  htmlFor="patient-name"
                                  className="block text-xs font-medium text-gray-700 sm:mt-px sm:pt-2 mr-2"
                                >
                                  Search :
                                </label>
                                <input
                                  value={searchQuery}
                                  onChange={handleSearchInputChange}
                                  type="text"
                                  name="patient-name"
                                  id="patient-name"
                                  autoComplete="patient-name"
                                  placeholder="Search Test Name"
                                  className="mt-2 block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-xs"
                                />
                              </div>
                              
                            </div>
                            <div className="align-center">
                                <div className="form-check form-switch text-center">
                                <label
                                    htmlFor="patient-name"
                                    className="block text-xs font-medium text-gray-700 sm:mt-px"
                                  >
                                    Only Packages {packageStatus}
                                  </label>
                                <Switch
                                  checked={packageStatus}
                                  onChange={() => setPackageStatus(packageStatus == false ? true : false)}
                                  name="vc_is_active"
                                  className={testStatusToggle(
                                    packageStatus ? 'bg-cyan-600' : 'bg-gray-200',
                                    'mt-2 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
                                  )}
                                >
                                  <span
                                    aria-hidden="true"
                                    className={testStatusToggle(
                                      packageStatus ? 'translate-x-5' : 'translate-x-0',
                                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                    )}
                                  />
                                </Switch>

                                  
                                </div>
                              </div>

                          </div>
                          <div>
                            {patientList && Array.isArray(patientList) && patientList.length > 0 && (
                              patientList.filter(v => patientId == v?.patient_id).map(pat => (
                                pat?.testPackArr && Array.isArray(pat?.testPackArr) && (
                                  pat?.testPackArr.map(pp => (
                                    <BadgeAddRemove text={pp.label} removeBadge={() => remove_TestPackMemberArr(pp, "Test", '', patientId)} />
                                    ))
                                )
                                ))
                                ) 
                              }
                          </div>

                          <div style={{height: '60vh'}} className="mt-8 flow-root">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 h-screen">
                                <div className="overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg h-50 overflow-x-auto">
                                  <table className="reservation-popup-table min-w-full divide-y divide-gray-300 table-auto">
                                    <thead className="reservation-popup-head bg-gray-50">
                                      <tr>
                                        <th scope="col" className="reservation-popup-th py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                          Name EN
                                        </th>
                                        <th scope="col" className="reservation-popup-th py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                         Categories
                                        </th>
                                        {
                                          packageStatus ? (
                                            <th scope="col" className="reservation-popup-th py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                              Included Tests
                                            </th>
                                          ) : null
                                        }
                                        <th scope="col" className="reservation-popup-th py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                          Price
                                        </th>
                                        <th scope="col" className="reservation-popup-th py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                          <span className="sr-only"></span>Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="reservation-popup-body divide-y divide-gray-200 bg-white">
                                      {(packageStatus == false) ? ((filteredTestList.length > 0) ? filteredTestList.map((test_ele, test_key) => (
                                        <tr>
                                          <td className="reservation-popup-td whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                            {test_ele.test_name_en}
                                          </td>
                                          <td className="reservation-popup-td whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                            {test_ele.t_category_relation.category_name_en}
                                          </td>
                                          <td className="reservation-popup-td whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                            {test_ele.hos_price}
                                          </td>

                                          <td className="reservation-popup-td whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6">
                                            <Link
                                              type="button"
                                              onClick={() => { ((test_ele.addKey === "Add") ? add_TestPackMemberArr(test_ele, "Test", test_key, patientId) : remove_TestPackMemberArr(test_ele, "Test", test_key, patientId)) }}
                                              className={
                                                test_ele.addKey === 'Add' ?
                                                  'inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto bg-cyan-600 hover:bg-cyan-700 focus:ring-cyan-500' :
                                                  'inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto bg-red-600 hover:bg-red-700 focus:ring-red-500'}
                                            >
                                              {test_ele.addKey}
                                            </Link>
                                          </td>
                                        </tr>
                                      )) : (
                                        <tr>
                                          <td colSpan={4} className="reservation-popup-td whitespace-nowrap p-6 text-center text-xs font-medium text-gray-900 sm:pl-6">
                                            Data Not Found
                                          </td>
                                        </tr>
                                      )) : ((filteredPackageList.length > 0) ? filteredPackageList.map((pack_ele, pack_key) => (
                                        <tr>
                                          <td className="reservation-popup-td whitespace-nowrap py-4 px-2 text-xs font-medium text-gray-900 sm:pl-6">
                                            {pack_ele.package_name_en}
                                          </td>
                                          <td className="reservation-popup-td whitespace-nowrap py-4 px-2 text-xs font-medium text-gray-900 sm:pl-6">
                                            {pack_ele.package_sub_category}
                                          </td>
                                          <td style={{width: 200 ,whiteSpace:"pre-line"}} className="reservation-popup-td whitespace-nowrap py-4 px-2 text-xs font-xs text-gray-900 sm:pl-6">
                                            {getTestNames(pack_ele)}
                                          </td>
                                          <td className="reservation-popup-td whitespace-nowrap py-4 px-2 text-xs font-medium text-gray-900 sm:pl-6">
                                            
                                            {pack_ele.package_price}  
                                            
                                            <Link
                                              type="button"
                                              onClick={() => {
                                                ((pack_ele.addKey === "Add") ? add_TestPackMemberArr(pack_ele, "Test", pack_key, patientId)
                                                  :
                                                  remove_TestPackMemberArr(pack_ele, "Package", pack_key, patientId))
                                              }}
                                              className={pack_ele.addKey === 'Add' ?
                                                'ml-7 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto bg-cyan-600 hover:bg-cyan-700 focus:ring-cyan-500' :
                                                'ml-7 inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto bg-red-600 hover:bg-red-700 focus:ring-red-500'}
                                            >
                                              {pack_ele.addKey}
                                            </Link>
                                          </td>

                                          {/* <td className="reservation-popup-td whitespace-nowrap py-4 px-2 text-xs font-medium text-gray-900 sm:pl-6">
                                            <Link
                                              type="button"
                                              onClick={() => {
                                                ((pack_ele.addKey === "Add") ? add_TestPackMemberArr(pack_ele, "Test", pack_key, patientId)
                                                  :
                                                  remove_TestPackMemberArr(pack_ele, "Package", pack_key, patientId))
                                              }}
                                              className={pack_ele.addKey === 'Add' ?
                                                'inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto bg-cyan-600 hover:bg-cyan-700 focus:ring-cyan-500' :
                                                'inline-flex items-center justify-center rounded-md border border-transparent px-4 py-2 text-xs font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto bg-red-600 hover:bg-red-700 focus:ring-red-500'}
                                            >
                                              {pack_ele.addKey}
                                            </Link>
                                          </td> */}
                                        </tr>
                                      )) : (
                                        <tr>
                                          <td colSpan={4} className="reservation-popup-td whitespace-nowrap p-6 text-center text-xs font-medium text-gray-900 sm:pl-6">
                                            Data Not Found
                                          </td>
                                        </tr>
                                      ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="" style={{ position: "fixed", top: "15px", right: "10px", border: '0px' }}>
                        <div className="flex justify-end">
                          <button
                            onClick={() => { setOpenAddTest(false); SaveTestPackList(); }}
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={() => { setOpenAddTest(false); SaveTestPackList(); }}
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={openBookingQuotation} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          initialFocus={cancelButtonRef}
          onClose={setOpenBookingQuotation}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative p-8 bg-white w-full max-w-4xl mx-auto my-6 rounded-lg shadow-lg ">
                  <div>
                    <form className="space-y-8 divide-y divide-gray-200">
                      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                        <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                          <div>
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Booking Quotation
                            </h3>
                          </div>
                          <div className="space-y-6 sm:space-y-5">
                            {(testCount.length > 0 || packCount.length > 0) ? (<table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th scope="col" className="py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                    Type
                                  </th>
                                  <th scope="col" className="py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                    ID
                                  </th>
                                  <th scope="col" className="py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                    Name EN
                                  </th>
                                  <th scope="col" className="py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                    Categories
                                  </th>
                                  <th scope="col" className="py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                    Price
                                  </th>
                                  <th scope="col" className="py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                    Discount
                                  </th>
                                  <th scope="col" className="py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                    Vat Amt
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {((testCount.length > 0) ? testCount.map((test_ele, test_key) => (
                                  <tr>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left">Test</td>
                                    <th scope="col" className="py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                      {test_ele.test_id}
                                    </th>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left">
                                      {test_ele.test_name_en}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left">
                                      {test_ele.test_name_ar}
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left">
                                      {test_ele.hos_price}
                                    </td>
                                    <td className="a_discount whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left">
                                      {/* {console.log("test_ele=>", test_ele)} */}
                                      {showOutsourceClinicList ? '' :  handleCalculateDiscount('test', test_ele.test_id, test_ele.hos_price) }
                                    </td>
                                    <td className=" whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left">{showOutsourceClinicList ? '' : test_ele.vatAmt}</td>
                                  </tr>
                                )) : (<></>
                                ))}{
                                  ((packCount.length > 0) ? packCount.map((pack_ele, pack_key) => (
                                    <tr>
                                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left">Package</td>
                                      <th scope="col" className="py-3.5 px-2 text-left text-xs font-semibold text-gray-900 sm:pl-6">
                                        {pack_ele.package_id}
                                      </th>
                                      <td className="whitespace-nowrap py-4 px-2 text-xs font-medium text-gray-900 sm:pl-6 text-left">
                                        {pack_ele.package_name_en}
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-2 text-xs font-medium text-gray-900 sm:pl-6 text-left">
                                        {pack_ele.package_name_ar}
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-2 text-xs font-medium text-gray-900 sm:pl-6 text-left">
                                        {pack_ele.package_price}
                                      </td>
                                      <td className="a_discount whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left">
                                        {showOutsourceClinicList ? '' : handleCalculateDiscount('package', pack_ele.package_id, pack_ele.package_price)}

                                      </td>
                                      <td className=" whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6 text-left">{showOutsourceClinicList ? '' : pack_ele.vatAmt}</td>
                                    </tr>
                                  )) : (<></>
                                  ))
                                }
                              </tbody>
                            </table>) : null}

                            <div className="inline-flex">
                              <input type="text" className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-xs"
                                value={couponCode ? couponCode : ""} onChange={(e) => {
                                  setCouponCode(e.target.value.toUpperCase())
                                }} name="cpc_code" id="cpc_code" placeholder="COUPON CODE" />
                              <button className={`ml-3 inline-flex justify-center rounded-md border border-transparent ${couponCode && couponCode != "" ? "focus:ring-cyan-500 bg-cyan-600 text-white hover:bg-cyan-700" : "bg-gray-300 text-gray-400"} py-2 px-4 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                                type="button"
                                disabled={couponCode && couponCode != "" ? false : true}
                                onClick={() => {
                                  handleApplyCoupon()
                                  setTimeout(() => {
                                    let all_discounts = document.getElementsByClassName("a_discount")
                                    if (all_discounts?.length > 0) {
                                      let totalDiscount = 0;
                                      for (let i = 0; i < all_discounts.length; i++) {
                                        totalDiscount = parseInt(totalDiscount) + parseInt(all_discounts[i].innerHTML)
                                      }
                                      setTotalDiscount(totalDiscount);
                                      calculateVatAmount();
                                    }
                                  }, 2000)
                                }}>Apply Coupon
                              </button>
                            </div>
                            {
                              couponDetails?.data ? (
                                <>
                                  {
                                    !couponDetails.data?.isValid ? (
                                      <p className="text-red-600 text-xs">{couponDetails.message}</p>
                                    ) : <p className="text-green-600 text-xs">{couponDetails.message}</p>
                                  }
                                </>
                              ) :
                                null
                            }


                            <div className="flex justify-between items-center">
                              <div>(
                                {(testCount ? (
                                  <b>{testCount.length} Test</b>
                                ) : null)}
                                {packCount ? (
                                  <b> {packCount.length} Package</b>
                                ) : null})
                                <br />Sub-Total
                              </div>
                              <div>
                                <p>SAR {totAmount}.00</p>
                                {/* <p>SAR {totAmount - parseInt(totalDiscount)}.00</p> */}
                              </div>

                            </div>

                            {showOutsourceClinicList && (

                              <div className="flex justify-between items-center">
                                <div>
                                    <p>Discount ({selectedOCClinic?.mc_oc_discount}%)</p>
                                </div>
                                <div>
                                  {/* <p>SAR {(vatRateType == 'FIXED' ? parseInt(vatRate) : ((parseInt(totAmount) * parseInt(vatRate)) / 100))},</p> */}
                                  <p>SAR {isNaN(clinicDiscountAmt)? 0 : clinicDiscountAmt}</p>
                                </div>
                              </div>
                            )}

                            <div className="flex justify-between items-center">
                              <div>
                                <p>Total Discount</p>
                              </div>
                              <div>
                                <p>SAR {totalDiscount}.00</p>
                              </div>
                            </div>

                            <div className="flex justify-between items-center">
                              <div>
                                {showOutsourceClinicList ? (
                                  <p>VAT ({selectedOCClinic?.mc_vat}%)</p>
                                ) : (
                                  <p>VAT (15%)</p>
                                )}
                              </div>
                              <div>
                                {/* <p>SAR {(vatRateType == 'FIXED' ? parseInt(vatRate) : ((parseInt(totAmount) * parseInt(vatRate)) / 100))},</p> */}
                                <p>SAR {totVatRate}</p>
                              </div>
                            </div>

                            <div className="flex justify-between items-center font-medium">
                              <div>
                                <p>Grand Total</p>
                              </div>
                              <div>
                                <p>SAR {eval(totAmount + "+" + totVatRate + "-" + totalDiscount).toFixed(2)}</p>
                                {/* <p>SAR {parseInt(totAmount) + (vatRateType == 'FIXED' ? parseInt(vatRate) : ((parseInt(totAmount) * parseInt(vatRate)) / 100)) - parseInt(totalDiscount)}</p> parseFloat(amt).toFixed(2);*/}
                              </div>
                            </div>

                            <div className="item-center">
                              {
                                creditLimit != "" ? (
                                  <div>
                                    <div className="rounded-md bg-yellow-50 p-4">
                                      <div className="flex justify-center">
                                        <div className="ml-3">
                                          <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                                          <div className="mt-2 text-sm text-yellow-700">
                                            <p>
                                              {creditLimit}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null
                              }
                            </div>

                          </div>

                        </div>
                      </div>

                      <div className="pt-5">
                        <div className="flex justify-end">
                          <button
                            onClick={() => setOpenBookingQuotation(false)}
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            Cancel
                          </button>
                          <button
                            disabled={
                              isNaN(eval(totAmount + "+" + totVatRate + "-" + totalDiscount).toFixed(2)) || eval(totAmount + "+" + totVatRate + "-" + totalDiscount).toFixed(2) == ''
                            }
                            type="button"
                            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-xs font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => BookingConfirmRedirection()}
                          >
                            Proceed
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <AddEditAddressDialog
        openAddressDialog={openAddressDialog}
        setOpenAddressDialog={setOpenAddressDialog}
        closeAddressModal={closeAddressModal}
        addUserAddress={addUserAddress}
        cancelButtonRef={cancelButtonRef}
        selectedAddress={selectedAddress}
        latLong={latLong}
        setLatLong={setLatLong}
        updateUserAddress={updateUserAddress}
      /> </>) :
      <FallingLinesLoader />
      }

    </div>
  );
};

// export default Reservation;
