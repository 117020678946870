import React, { Fragment, useRef, useState, useEffect } from "react";
import moment from "moment";
import {
  BuildingOfficeIcon,
  ClockIcon,
  PhoneIcon,
  MapPinIcon,
  CalendarDaysIcon,
  CalendarIcon,
  XMarkIcon,
  CheckIcon,
} from "@heroicons/react/24/solid";
import momentTz from 'moment-timezone';

//22-07-2023
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { res_sample_process_column } from "../../../components/tables/tableheader";
import { Formik } from "formik";
import * as Yup from "yup";
import Modal from "../../../components/Modal";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
//22-07-2023

import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import MasterServices from "../../../ApiServices/MasterServices";
import ReservationServices from "../../../ApiServices/ReservationServices";
import ReportServices from "../../../ApiServices/ReportServices";
import { uploadFiles } from "../../../helper/multiUploadHelper";
import {
  convertToAnotherTimezone,
  exportToExcel,
  calculateAge,
} from "../../../helper/commonHelper";
import { toast } from "react-toast";
import { Await, Link, useNavigate } from "react-router-dom";
// Images Import
import ManIcon from "../../../assets/images/man.png";
import ExpenseIcon from "../../../assets/images/expense.png";
import PaymentIcon from "../../../assets/images/debit-card.png";
import CreditIcon from "../../../assets/images/credit-card.png";
import InvoiceIcon from "../../../assets/images/invoice.png";
import CashIcon from "../../../assets/images/dollar-bill.png";
import RefundIcon from "../../../assets/images/cashback.png";
import { getAllPaymentModes } from "../../../redux/paymentMode/actions";
import { read, utils, writeFile } from "xlsx";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import htmlToPdf from "html-to-pdf-js";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Barcode from "react-barcode";
import { Switch } from "@headlessui/react";
import ReactSwitch from "react-switch";
import { Items_colums } from "../../../components/tables/tableheader";
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import Breadcrumb from "../../../components/Breadcrumb";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import EditPatient from "./EditPatient";
import { CheckCircleIcon, PencilIcon } from "@heroicons/react/20/solid";
import SampleServices from "../../../ApiServices/SampleServices";

import { getAllGenders } from "../../../redux/gender/actions";
import Timer from "../../../components/Timer";
import OTPComponent from "../../../components/OTPComponent";
import { FaSpinner } from "react-icons/fa";
import { reservation_status_colors } from "./reservationStatusColorCodes";
import BarcodePrint from "./BarcodePrint";
import { MoneyRounded } from "@mui/icons-material";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const initialModalStateTestParamSample = {
  type: "",
  state: false,
  index: null,
  data: [
    {
      test_id: null,
      test_name_en: "",
    },
  ],
};

function urgentNames(...urgent) {
  return urgent.filter(Boolean).join(" ");
}
function isPostpondNames(...Postpone) {
  return Postpone.filter(Boolean).join(" ");
}
function isUrgentNames(...urgent) {
  return urgent.filter(Boolean).join(" ");
}

const BookingConfirmation = () => {
  const pages = [{ title: "Reservation Details", href: "/bookingConfirm" }];
  const navigate = useNavigate();
  const printRef = React.useRef();
  const printsampleRef = React.useRef();
  const SingleprintsampleRef = React.useRef();
  const [singleSampleData, setSingleSampleData] = useState([]);
  const [multiSampleDataForPrint, setMultiSampleDataForPrint] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [multifiles, setFiles] = useState([]);
  const [multiAttachmentArr, setMultiAttachment] = useState([]);
  const [afterPayment, setAfterPayment] = useState(false);
  const [payAll, setPayAll] = useState(false);
  const [generateInvoiceLoading, setGenerateInvoiceLoading] = useState(false);
  const [generateCreditInvoiceLoading, setGenerateCreditInvoiceLoading] = useState(false);
  const [generateSampleLoading, setGenerateSampleLoading] = useState(false);
  const [printSampleBarCodeFlag, setPrintSampleBarCodeFlag] = useState(false);
  const [printSingleSampleBarCodeFlag, setPrintSingleSampleBarCodeFlag] =
    useState(false);
  const [barcodeFlag, setBarcodeFlag] = useState(false);
  const [viewSampleFlag, setViewSample] = useState(false);
  const [sampleList, setSampleList] = useState(false);
  const [discountAll, setDiscountAll] = useState(false);
  const [refundAll, setRefundAll] = useState(false);
  const [cancelAll, setCancelAll] = useState(false);
  const [discountInp, setDiscountInp] = useState(false);
  const [disComments, setDisComments] = useState(false);
  const [rev_discount_comments, setResDisComment] = useState();
  const [rev_discount_per, setDisPer] = useState(false);
  const [rev_discounted_amount, setDisAmt] = useState(false);
  const [attachFileModal, setAttachFileModal] = useState(false);
  const [viewAttachFileModal, setViewAttachFileModal] = useState(false);
  const [openSampleProcessModal, setOpenSampleProcessModal] = useState(
    initialModalStateTestParamSample
  ); //22-07-2023AllSampleProcessList
  const [allSampleProcessList, setAllSampleProcessList] = useState([]); //22-07-2023AllSampleProcessList
  // Initialize the button flag state to true
  const [collectbuttonFlag, setCollectButtonFlag] = useState(true);
  const [collectSampleButtonClickLoad, setCollectSampleButtonClickLoad] = useState(true);
  const [collectSampleReceieButtonClickLoad, setCollectSampleReceieButtonClickLoad] = useState(true);

  const [fileName, setFile] = useState(false);
  const [fileNamePath, setFilePath] = useState(false);
  const [attachmentArr, setAttachment] = useState(false);
  const [attachmentList, setAttachmentList] = useState(false);
  const [commentsModal, setCommentsModal] = useState(false);
  const [saveComment, setSaveComment] = useState(false);
  const [reservationHomeAddress, setReservationHomeAdress] = useState(false);
  const [commentData, setCommentData] = useState();
  const [payMode, setPayMode] = useState();
  const [cashAmt, setCashAmt] = useState();
  const [creditAmt, setCreditAmt] = useState();
  const [bankTransAmt, setBankTransAmt] = useState();
  const [visaAmt, setVisaAmt] = useState();
  const [reservationData, setReservationData] = useState([]);
  const [testPackageList, setTestPackageList] = useState([]);
  const [reservationTestData, setReservationTestData] = useState(false);
  const [reservationID, setReservationId] = useState(false);
  const [userDetails, setUserDetails] = useState(false);
  const [vatRate, setVatRate] = useState(false);
  const [finalAmt, setFinalAmt] = useState(false); //AJAY
  const [vatRateId, setVatRateID] = useState(false);
  const [urgent, setUrgent] = useState(false);
  const [postPone, setPostPone] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);
  const [validateFlag, setValidateFlag] = useState(true);
  const [clinicRefferalList, setClinicRefferalList] = useState([]);
  const [clinicRefferalIdObj, setClinicRefferalIdObj] = useState(null);
  const fileTypes = ["JPEG", "PNG", "GIF"];
  const {
    paymentMode: { list: paymentModeList },
    category: { list: categoryList },
    test: { list: testList },
    package: { list: packageList },
  } = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [labTechnicianList, setLabTechnicianList] = useState(false);
  const [selectedLabTech, setSelectedLabTech] = useState(null);

  const [rejectSelected, setRejectSelected] = useState(false);
  const [rejectedReason, setRejectedReason] = useState(false);
  const [discountReqUser, setDiscountReqUser] = useState("");
  const [mUserList, setMuserList] = useState([]);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpID, setOtpID] = useState(null);
  const [isDiscountValueGreater, setIsDiscountValueGreater] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState();
  const [showSendOtpButton, setShowSendOtpButton] = useState(false);
  const [allSampleRecived, setAllSampleRecived] = useState(false);
  const [isInvestigationIsloading, setInvestigationIsLoading] = useState([])
  const [startTime, setStartTime] = useState('');
  const [endDateGenerateInvoice, setEndDateGenerateInvoice] = useState('');
  const [endTimeGenerateInvoice, setEndTimeGenerateInvoice] = useState('');
  const [remainingTimeGenerateInvoice, setRemainingTimeGenerateInvoice] = useState(false);
  const [endDateSampleReceiveReject, setEndDateSampleReceiveReject] = useState('');
  const [endTimeSampleReceiveReject, setEndTimeSampleReceiveReject] = useState('');
  const [remainingTimeSampleReceiveReject, setRemainingTimeSampleReceiveReject] = useState(true);
  const [timerInterval, setTimerInterval] = useState(null);
  const [timerIntervalSampleReceiveReject, setTimerIntervalSampleReceiveReject] = useState(null);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isCreditPaymentShow, setIsCreditPaymentShow] = useState(false);
  const [creditPayDate, setCreditPayDate] = useState(`${moment().format('YYYY-MM-DD hh:mm:ss')}`);
  const [paymentDetailsObj, setPaymentDetailsObj] = useState(null);
  const [paymentDetailsArr, setPaymentDetailsArr] = useState([]);
  const [isOcButNotCredit, setIsOcButNotCredit] = useState(false)

  const {
    loginUserInfo: { userData },
  } = useSelector((state) => state);
  // Abhi - Edit Patient Start
  let initialValues = {
    login_user_id: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")).user_id
      : 0,
    user_first_name: "",
    user_last_name: "",
    user_email: "",
    user_mobile: "",
    user_dob: "",
    user_id_type: "",
    user_id_no: "",
    user_passport_no: "",
    user_reasion_test: "",
    user_ref_mrn_no: "",
    fk_gender_id: "",
    fk_country_id: "",
    relationship: "",
    fk_questionnaire_id: "",
    userType: "",
    Type: "Add",
  };

  const [selectedPatient, setSelectedPatient] = useState(false);
  const [loading, setLoading] = useState(true);
  const [patientList, setPatientList] = useState(false);
  const [userList, setUserList] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [filteredCountryList, setFilteredCountryList] = useState(true);
  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  let [user_id, setUserId] = useState(false);
  const [parentId, setParentId] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");
  const [mobileCountryCode, setMobileCountryCode] = useState("");
  let [userType, setUserType] = useState(false);
  const [filteredReservationData, setFilteredReservationData] = useState([]);
  const [openPatient, setOpenPatient] = useState(false);
  const [modalData, setModalData] = useState(initialValues);
  const [isCloseModel, setIsCloseModel] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]); // Priyanshu SIngh 09-08-2023

  const [showLocChangeDD, setShowLocChangeDD] = useState(false);
  const [locationList, setLocationList] = useState(false);
  const [selectedLoc, setSelectedLoc] = useState(false);
  const [getPatientWiseTPDetailsPlayload, setPatientWiseTPDetailsPayload] = useState({});
  const [refundLogs, setRefundLogs] = useState(false)
  const [OCParentDetails, setOCParentDetails] = useState(false)
  const [isAllSampleAccepted, setIsAllSampleAccepeted] = useState(false);

  //Timer Code for Generate Invoice
  useEffect(() => {
    if(endDateGenerateInvoice && endTimeGenerateInvoice) {
      startCountdownGenerateInvoice();
    }
  }, [ endDateGenerateInvoice, endTimeGenerateInvoice])

  const startCountdownGenerateInvoice = () => {
    // Parse start and end times
    const currentUtcDate = momentTz.utc();
    const currentDate = currentUtcDate.format('YYYY-MM-DD');
    const currentTime = currentUtcDate.format('hh:mm:ss');
    const startTimeDate = new Date(`${currentDate}T${currentTime}`);
    const endTimeDate = new Date(`${endDateGenerateInvoice}T${endTimeGenerateInvoice}`);

    // Calculate the time difference in milliseconds
    const timeDifference = endTimeDate - startTimeDate;

    if (isNaN(timeDifference) || timeDifference < 0) {
      console.log('Invalid times. Make sure the end time is later than the start time.');
      setRemainingTimeGenerateInvoice(false);
      return;
    }

    // Start the countdown
    const timerInterval = setInterval(() => {
      const currentUtcDate = momentTz.utc();
      const currentTime = new Date(currentUtcDate.format('YYYY-MM-DD hh:mm:ss'));
      const remainingTimeGenerateInvoice = endTimeDate - currentTime;
      

      if (remainingTimeGenerateInvoice <= 0) {
        setRemainingTimeGenerateInvoice(false);
        setCollectButtonFlag(false);
        setRemainingTimeGenerateInvoice(false);
        setIsAllSampleAccepeted(false);
        clearInterval(timerInterval);
      } else {
        const hours = Math.floor((remainingTimeGenerateInvoice % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((remainingTimeGenerateInvoice % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        let seconds = Math.floor((remainingTimeGenerateInvoice % (1000 * 60)) / 1000).toString().padStart(2, '0');

        // minutes = formatTime(`${minutes}`);
        // seconds = formatTime(`${seconds}`);

        setRemainingTimeGenerateInvoice(`${minutes}:${seconds}`);
      }
    }, 1000); // Update every 1 second

    setTimerInterval(timerInterval);
  };

  //end here
  //Timer Code for Sample Receive Reject
  useEffect(() => {
    if(endDateSampleReceiveReject && endTimeSampleReceiveReject) {
      startCountdownSampleReceiveReject();
    }
  }, [ endDateSampleReceiveReject, endTimeSampleReceiveReject])

  function formatTime(time) {
    let [hours, minutes] = time.split(":").map((val) => val.padStart(2, '0'));
    return `${hours}:${minutes}`;
  }
  

  const startCountdownSampleReceiveReject = () => {
    // Parse start and end times
    const currentUtcDate = momentTz.utc();
    const currentDate = currentUtcDate.format('YYYY-MM-DD');
    const currentTime = currentUtcDate.format('hh:mm:ss');
    const startTimeDate = new Date(`${currentDate}T${currentTime}`);
    const endTimeDate = new Date(`${endDateSampleReceiveReject}T${endTimeSampleReceiveReject}`);

    // Calculate the time difference in milliseconds
    const timeDifference = endTimeDate - startTimeDate;

    if (isNaN(timeDifference) || timeDifference < 0) {
      console.log('Invalid times. Make sure the end time is later than the start time.');
      setRemainingTimeSampleReceiveReject(false);
      return;
    }

    // Start the countdown
    const timerInterval = setInterval(() => {
      const currentUtcDate = momentTz.utc();
      const currentTime = new Date(currentUtcDate.format('YYYY-MM-DD hh:mm:ss'));
      const remainingTimeSampleReceiveReject = endTimeDate - currentTime;
      

      if (remainingTimeSampleReceiveReject <= 0) {
        clearInterval(timerInterval);
        setRemainingTimeSampleReceiveReject(false);
      } else {
        const hours = Math.floor((remainingTimeSampleReceiveReject % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((remainingTimeSampleReceiveReject % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0');
        let seconds = Math.floor((remainingTimeSampleReceiveReject % (1000 * 60)) / 1000).toString().padStart(2, '0');

        // minutes = formatTime(`${minutes}`);
        // seconds = formatTime(`${seconds}`);

        setRemainingTimeSampleReceiveReject(`${minutes}:${seconds}`);
      }
    }, 1000); // Update every 1 second

    setTimerIntervalSampleReceiveReject(timerInterval);
  };

  //end here

  useEffect(() => {
    if (Array.isArray(sampleList) && sampleList?.length > 0) {
      let filteredSamples = sampleList?.filter((sp) =>
        sp.fk_status_id == 7
      );
      if(filteredSamples.length === 0) {
        setAllSampleRecived(true)
      } else {
        setAllSampleRecived(false)
      }


      if(sampleList.length == 0) {
        setIsAllSampleAccepeted(true); 
      }

      if(sampleList.length > 0 && sampleList.filter(v => v.fk_status_id == 3).length == sampleList.length) {
        setIsAllSampleAccepeted(true) 
      } else {
        setIsAllSampleAccepeted(false);
      }

    }
  }, [sampleList])

  const [openSendReport, setOpenSendReport] = useState(false)
  const [selectedReportRes, setSelectedReportRes] = useState(false)
  const [reportSmsNumber, setReportSmsNumber] = useState("")

  useEffect(() => {
    if (sampleList?.length > 0) {
      let filteredSamples = sampleList?.filter((sp) =>
        selectedRows.includes(sp.sample_id)
      );
      // console.log("filteredSamples", filteredSamples)
      setMultiSampleDataForPrint(filteredSamples);
    }
    return () => {
      setMultiSampleDataForPrint([]);
    };
  }, [selectedRows]);

  const toggleRowSelection = (rowId, row) => {
    // setShowLocChangeDD(false)
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedRows.length === sampleList.length) {
      setSelectedRows([]);
    } else {
      const allRowIds = sampleList.map((row) => row.sample_id);
      setSelectedRows(allRowIds);
    }
  };


  useEffect(() => {
    let tempArr = [];
    if (countryList.length > 0) {
      let indexOfSA = countryList.findIndex((country) => {
        return country.country_id == 203;
      });
      tempArr = array_move(countryList, indexOfSA, 0);
      setFilteredCountryList(tempArr);
    }
  }, countryList);
  const [testDetails, setTestDetails] = useState(false);
  const [samplePayloadDetails, setSamplePayloadDetails] = useState(false);

  useEffect(() => {
    if (payMode == "1" || payMode == "3") {
      setCashAmt(finalAmt);
      setVisaAmt(0);
    } else if (payMode == "2") {
      setVisaAmt(finalAmt);
      setCashAmt(0);
    } else if (payMode == "5") {
      setCreditAmt(finalAmt);
    } else if (payMode == "4") {
      setBankTransAmt(finalAmt);
    } else {
      setCashAmt(0);
      setVisaAmt(0);
    }
  }, [payMode]);

  const toggleModal = async (data) => {
    setTestDetails(data);
    setActiveIndex(-1);
    
    let payload = {
      patient_id: data?.resv_tx_patient_id
        ? data?.resv_tx_patient_id
        : testDetails.resv_tx_patient_id,
      res_id: data?.resv_tx_resv_id
        ? data?.resv_tx_resv_id
        : testDetails.resv_tx_resv_id,
      /* test_id: data?.resv_tx_test_id
        ? data?.resv_tx_test_id
        : testDetails.resv_tx_test_id,
      is_package: data?.resv_is_package
        ? data?.resv_is_package
        : testDetails.resv_is_package, */
      test_id: (data?.final_test_pack_ids && data?.final_test_pack_ids.length > 0) ? data?.final_test_pack_ids.toString() : 0, //data?.resv_tx_test_id ? data?.resv_tx_test_id : testDetails.resv_tx_test_id,
      is_package: 'N', //data?.resv_is_package ? data?.resv_is_package : testDetails.resv_is_package, bcoz now we are passing [1, 2, 3, 4] test_ids
      category_id: data?.resv_tx_category_id
        ? data?.resv_tx_category_id
        : testDetails.resv_tx_category_id,
      package_id: data?.resv_tx_package_id
        ? data?.resv_tx_package_id
        : testDetails.resv_tx_package_id,
    };
    
    setPatientWiseTPDetailsPayload(payload);
    getAllSampleList(payload);
    console.log(".");
    setOpenSampleProcessModal((prev) => ({ ...prev, state: true }));
  };

  const renderSampleModel = () => {
    const { data, index, state, type } = openSampleProcessModal;

    return (
      <Modal
        title={`Test Params List - ${
          testDetails?.resv_tx_test_name_en
            ? testDetails?.resv_tx_test_name_en
            : testDetails?.resv_tx_package_name_en +
              " (" +
              testDetails?.resv_tx_name +
              ")"
        }`} //${}
        open={state}
        size="max-w-5xl relative"
        setOpen={() =>
          setOpenSampleProcessModal((prev) => ({ ...prev, state: false }))
        }
      >
        {loading ? (
          <FallingLinesLoader />
        ) : allSampleProcessList.length > 0 ? (
          <Table
            columns={res_sample_process_column({
              handleUpdateComment,
              handleSaveResult,
              handleValidateResult,
              handleApproveResult,
              handleUpdateResult,
              generateReport,
              viewReport,
              handleUpdateLocChange,
              handleMixResultSaveUpdate,
              activeIndex
            })}
            data={allSampleProcessList}
          />
        ) : (
          <TableEmpty />
        )}
      </Modal>
    );
  };
  const handleUpdateComment = async (payload) => {
    // setLoading(true)
    let res = await SampleServices.updateSampleTestResultComment(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      let payload = {
        patient_id: testDetails?.resv_tx_patient_id,
        res_id: testDetails?.resv_tx_resv_id,
        test_id: testDetails?.resv_tx_test_id,
        is_package: testDetails?.resv_is_package,
        category_id: testDetails?.resv_tx_category_id,
        package_id: testDetails?.resv_tx_package_id,
      };
      // setSamplePayloadDetails(payload);
      // getAllTestList(qcId);
      getAllSampleList(getPatientWiseTPDetailsPlayload);
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };

  const handleUpdateResult = async (payload) => {
    
    let res = await SampleServices.updateSampleTestResult(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      let payload = {
        patient_id: testDetails?.resv_tx_patient_id,
        res_id: testDetails?.resv_tx_resv_id,
        test_id: testDetails?.resv_tx_test_id,
        is_package: testDetails?.resv_is_package,
        category_id: testDetails?.resv_tx_category_id,
        package_id: testDetails?.resv_tx_package_id,
      };
      // setSamplePayloadDetails(payload);
      // getAllTestList(qcId);
      // getAllSampleList(getPatientWiseTPDetailsPlayload);
    } else {
      toast.error(res.data.message);
    }

    const result_index = payload?.inputIndex;
    const result_value = payload?.result_val.toString();
    const updatedData = [...allSampleProcessList];
    if (updatedData[result_index]?.test_result.length > 0) {
      updatedData[result_index].test_result[0].ssp_value = result_value;
      setAllSampleProcessList(updatedData);
    }
  
    if (activeIndex < allSampleProcessList.length - 1) {
      setActiveIndex(payload?.inputIndex + 1);
    }

  };

  const handleSaveResult = async (payload) => {
    
    let res = await SampleServices.saveSampleTestResult(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      let payload = {
        patient_id: testDetails?.resv_tx_patient_id,
        res_id: testDetails?.resv_tx_resv_id,
        test_id: testDetails?.resv_tx_test_id,
        is_package: testDetails?.resv_is_package,
        category_id: testDetails?.resv_tx_category_id,
        package_id: testDetails?.resv_tx_package_id,
      };
      // setSamplePayloadDetails(payload);
      // getAllTestList(qcId);
      // getAllSampleList(getPatientWiseTPDetailsPlayload);
    } else {
      toast.error(res.data.message);
    }

    const result_index = payload?.inputIndex;
    const result_value = payload?.result_val.toString();
    const updatedData = [...allSampleProcessList];
    if (updatedData[result_index]?.test_result.length > 0) {
      updatedData[result_index].test_result[0].ssp_value = result_value;
      setAllSampleProcessList(updatedData);
    }
  
    if (activeIndex < allSampleProcessList.length - 1) {
      setActiveIndex(payload?.inputIndex + 1);
    }

  };

  const handleValidateResult = async (payload) => {
    console.log("handleValidateResult=>", payload);
    // return false;
    payload.ssp_pos_neg_value =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_pos_neg_value
        : null;
    payload.ssp_text_value =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_text_value
        : null;
    payload.ssp_attachment =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_attachment
        : null;
    payload.ssp_comment =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_comment
        : null;
    payload.ssp_correction_value =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_correction_value
        : null;
    payload.ssp_id =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_id
        : null;
    let res = await SampleServices.validateResult(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      let payload = {
        patient_id: testDetails?.resv_tx_patient_id,
        res_id: testDetails?.resv_tx_resv_id,
        test_id: testDetails?.resv_tx_test_id,
        is_package: testDetails?.resv_is_package,
        category_id: testDetails?.resv_tx_category_id,
        package_id: testDetails?.resv_tx_package_id,
      };
      // setSamplePayloadDetails(payload);
      // getAllTestList(qcId);
      getAllSampleList(getPatientWiseTPDetailsPlayload);
    } else {
      toast.error(res.data.message);
    }
  };

  const handleApproveResult = async (payload) => {
    payload.ssp_pos_neg_value =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_pos_neg_value
        : null;
    payload.ssp_text_value =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_text_value
        : null;
    payload.ssp_attachment =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_attachment
        : null;
    payload.ssp_comment =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_comment
        : null;
    payload.ssp_correction_value =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_correction_value
        : null;
    payload.ssp_id =
      payload.test_result && payload.test_result.length > 0
        ? payload.test_result[0].ssp_id
        : null;
    let res = await SampleServices.approveResult(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      let payload = {
        patient_id: testDetails?.resv_tx_patient_id,
        res_id: testDetails?.resv_tx_resv_id,
        test_id: testDetails?.resv_tx_test_id,
        is_package: testDetails?.resv_is_package,
        category_id: testDetails?.resv_tx_category_id,
        package_id: testDetails?.resv_tx_package_id,
      };
      // setSamplePayloadDetails(payload);
      // getAllTestList(qcId);
      getAllSampleList(getPatientWiseTPDetailsPlayload);
    } else {
      toast.error(res.data.message);
    }
  };

  const getSingleReservationSampleDetails = async (payload) => {
    console.log("payload=>", payload);
    // return false;
    return await SampleServices.getAllReservationSamplesResult({
      reservation_id: payload?.reservation_id,
      fk_patient_id: payload?.fk_patient_id,
      sp_gen_id: payload?.sp_gen_id,
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          return data.data;
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "getSingleReservationDetails catch Error"; //error.response.data.message;
        // toast.error(msg);
      });
  };
  const generateReport = async (payload) => {
    let singleReservationDetails = await getSingleReservationSampleDetails(
      payload
    );
    payload.samplecreatedby = "";
    payload.paymentcollectedbyusername = "";
    payload.payment_collected_timestamp = "";
    payload.all_Test_lists = singleReservationDetails.all_Test_lists;
    payload.all_Pack_lists = singleReservationDetails.all_Pack_lists;
    if (
      Array.isArray(singleReservationDetails?.all_Test_lists) &&
      singleReservationDetails?.all_Test_lists[0] &&
      Array.isArray(
        singleReservationDetails?.all_Test_lists[0]?.all_subtest_lists
      ) &&
      singleReservationDetails?.all_Test_lists[0]?.all_subtest_lists.length > 0
    ) {
      console.log("under the if condition...");
      let tempArr = [
        ...singleReservationDetails?.all_Test_lists[0]?.all_subtest_lists,
      ];
      let outputTemp = tempArr.map((v) => {
        let tprrlabInputs = [];
        let test_result = [];
        let is_sub_rr;
        let sub_ref_range = [];
        let tprrlab = [];
        let mrr_default_min = "";
        let mrr_default_max = "";
        let fk_rr_id = "";
        let mrr_type = "";
        if (Array.isArray(sampleList)) {
          sampleList.forEach((sl) => {
            if (
              sl?.ttp_name_en == v?.ttp_name_en &&
              sl?.sample_id == payload?.fk_sample_id
            ) {
              console.log("sl===>", sl);
              tprrlabInputs = sl?.tprrlabInputs || [];
              if (
                Array.isArray(
                  sl?.tprrlab?.m_reference_ranges_relation?.mmr_inputs_value
                ) &&
                sl?.tprrlab?.m_reference_ranges_relation?.mmr_inputs_value
                  .length > 0
              ) {
                v.mmr_inputs_value =
                  sl?.tprrlab?.m_reference_ranges_relation?.mmr_inputs_value;
              }
              tprrlab = sl?.tprrlab || [];
              test_result = sl?.test_result || [];
              is_sub_rr = sl?.is_sub_rr;
              sub_ref_range = sl?.sub_ref_range || [];
              mrr_default_min = sl?.mrr_default_min || "";
              mrr_default_max = sl?.mrr_default_max || "";
              fk_rr_id = sl?.fk_rr_id || "";
              mrr_type = sl?.mrr_type || "";
            }
          });
        }
        return {
          ...v,
          tprrlabInputs,
          test_result,
          is_sub_rr,
          sub_ref_range,
          tprrlab,
          mrr_default_min,
          mrr_default_max,
          fk_rr_id,
          mrr_type,
        };
      });
      payload.all_Test_lists[0].all_subtest_lists = outputTemp;
    }
    if (singleReservationDetails.all_lists.length > 0) {
      payload.samplecreatedby =
        singleReservationDetails.all_lists[0].samplecreatedby;
      payload.paymentcollectedbyusername =
        singleReservationDetails.all_lists[0].paymentcollectedbyusername;
      payload.payment_collected_timestamp = moment(
        singleReservationDetails.all_lists[0].payment_collected_timestamp
      ).format("DD-MM-YYYY HH:MM:SS");
    }
    payload.sample_report_type = "Reservation_sample";
    let res = await SampleServices.generateReport(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      let payload = {
        patient_id: testDetails?.resv_tx_patient_id,
        res_id: testDetails?.resv_tx_resv_id,
        test_id: testDetails?.resv_tx_test_id,
        is_package: testDetails?.resv_is_package,
        category_id: testDetails?.resv_tx_category_id,
        package_id: testDetails?.resv_tx_package_id,
      };
      // setSamplePayloadDetails(payload);
      // getAllTestList(qcId);
      getAllSampleList(getPatientWiseTPDetailsPlayload);
    } else {
      toast.error(res.data.message);
    }
  };
  const viewReport = async (payload) => {};
  const handleUpdateLocChange = async (payload, lab) => {
    let LabDetails = JSON.parse(lab);
    console.log("payloadHSISD=>", payload);
    // if (LabDetails?.lab_analyzer_id && LabDetails?.loc_id) {
    if (LabDetails?.lab_analyzer_id) {
      payload.lab_analyzer_id = LabDetails?.lab_analyzer_id;
      payload.lab_analyzer_loc_id = LabDetails?.fk_loc_id;
      let labPayload = {
        lab_analyzer_id: LabDetails?.lab_analyzer_id,
        lab_analyzer_loc_id: LabDetails?.fk_loc_id,
        lab_analyzer_fk_titem_id: LabDetails?.fk_titem_id,
        // ssp_value: payload?.ssp_value,
        fk_sample_id: payload?.fk_sample_id,
        fk_test_id: payload?.fk_test_id,
        fk_reservation_id: payload?.fk_reservation_id,
        ssp_id: payload?.test_result?.ssp_id,
        ssp_value: payload?.test_result?.ssp_value,
        fk_subtest_id: payload?.ttp_id,
        fk_patient_id: payload?.patient_id,
      };

      setLoading(true);
      let res = await SampleServices.updateSampleTestResultLabAnalygerLoc(
        labPayload
      );
      if (res.status == 200) {
        toast.success(res.data.message);
        let payload = {
          patient_id: testDetails?.resv_tx_patient_id,
          res_id: testDetails?.resv_tx_resv_id,
          test_id: testDetails?.resv_tx_test_id,
          is_package: testDetails?.resv_is_package,
          category_id: testDetails?.resv_tx_category_id,
          package_id: testDetails?.resv_tx_package_id,
        };
        // setSamplePayloadDetails(payload);
        // getAllTestList(qcId);
        getAllSampleList(getPatientWiseTPDetailsPlayload);
      } else {
        toast.error(res.data.message);
      }
      setLoading(false);
    }
  };
  const handleMixResultSaveUpdate = () => {
    console.log("handleMixResultSaveUpdate called");
    let payload = {
      patient_id: testDetails?.resv_tx_patient_id,
      res_id: testDetails?.resv_tx_resv_id,
      test_id: testDetails?.resv_tx_test_id,
      is_package: testDetails?.resv_is_package,
      category_id: testDetails?.resv_tx_category_id,
      package_id: testDetails?.resv_tx_package_id,
    };
    // setSamplePayloadDetails(payload);
    // getAllTestList(qcId);
    getAllSampleList(getPatientWiseTPDetailsPlayload);
  };

  // Initial Load Sample List
  const getAllSampleList = (payload) => {
    SampleServices.getAllResSamplesForProcessing(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.all_lists?.length > 0) {
            setAllSampleProcessList(data?.data?.all_lists);
          } else {
            toast.error("SampleList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setLoading(false);
      })
      .catch((error) => {
        const msg = "getAllSampleList catch Error"; //error.response.data.message;
        toast.error(msg);
        setLoading(false);
      });
  };

  const generateInvestigationReport = async (sampleData, patient_id, sample_id_load) => {
    setInvestigationIsLoading([...isInvestigationIsloading, sample_id_load]);
    let InvestigationPayload = [];
    console.log(reservationData)
    console.log("shsdhdhd,hddj===", sampleData)
    let singleReservationDetails = await getSingleReservationDetails({
      reservation_id: reservationData?.getAllReservation?.reservation_id,
    });
    let currentpatient = [];
    if(Array.isArray(reservationData?.getAllReservation?.parentOfPatient) && reservationData?.getAllReservation?.parentOfPatient.length > 0 ){
      currentpatient = reservationData?.getAllReservation?.parentOfPatient.filter(v => v?.patient_id == patient_id)
    }

    let paitentDetails = {
      reservation_id: reservationData?.getAllReservation?.reservation_id,
      patientID: null,
      patientName: null,
      patientEmail: null,
      patientMob: null,
      patientDOB: null,
      patientGender: null,
      patientCountry: null,
      Hospital: null,
      Location: null,
      Address: null,
      Pincode: null,
      City: null,
      collection_loc_relation: null,
      patientAge: null,
      patientCountryCode: null,
      patient_file_number: currentpatient?.length > 0 ? currentpatient[0]?.patient_file_number : '-',
      patient_report_mrn: currentpatient?.length > 0 ? currentpatient[0]?.patient_report_mrn : '-',
      referalClinic: reservationData?.getAllReservation?.m_ot_as_ref_clinic_relation ? reservationData?.getAllReservation?.m_ot_as_ref_clinic_relation?.mc_name : '-'
    };
    if (singleReservationDetails) {
      paitentDetails.Hospital = singleReservationDetails?.getAllReservation
        ?.collection_loc_relation?.m_hospital_relation?.hos_name_en
        ? singleReservationDetails?.getAllReservation?.collection_loc_relation
            ?.m_hospital_relation?.hos_name_en
        : null;
      paitentDetails.Location = singleReservationDetails?.getAllReservation
        ?.collection_loc_relation?.loc_name_en
        ? singleReservationDetails?.getAllReservation?.collection_loc_relation
            ?.loc_name_en
        : null;
      paitentDetails.Address = singleReservationDetails?.getAllReservation
        ?.collection_loc_relation?.loc_add
        ? singleReservationDetails?.getAllReservation?.collection_loc_relation
            ?.loc_add
        : null;
      paitentDetails.Pincode = singleReservationDetails?.getAllReservation
        ?.collection_loc_relation?.loc_pincode
        ? singleReservationDetails?.getAllReservation?.collection_loc_relation
            ?.loc_pincode
        : null;
      paitentDetails.City = singleReservationDetails?.getAllReservation
        ?.collection_loc_relation?.city_relation?.city_name_en
        ? singleReservationDetails?.getAllReservation?.collection_loc_relation
            ?.city_relation?.city_name_en
        : null;
      paitentDetails.collection_loc_relation = singleReservationDetails
        ?.getAllReservation?.collection_loc_relation
        ? singleReservationDetails?.getAllReservation?.collection_loc_relation
        : null;
      paitentDetails.rev_patient_parent_id_no = singleReservationDetails
        ?.getAllReservation?.rev_patient_parent_id_no
        ? singleReservationDetails?.getAllReservation?.rev_patient_parent_id_no
        : null;
    }
    let tempfilteredReservationData = filteredReservationData.map((v) => {
      for(let i=0; i< sampleData.length; i++){
        if(sampleData[i]?.test_names?.includes(v?.resv_tx_test_name_en) && sampleData[i]?.patient_id == v?.resv_tx_patient_id) {
          v.sample_id = sampleData[i]?.sample_id
          return v
        }
      }
    })
    console.log("sampleData,:", sampleData)
    sampleData.forEach((val, key) => {
      if (val?.patient_id == patient_id) {
        paitentDetails.patientID = val?.patient_id;
        paitentDetails.patientName = val?.patient_first_name;
        paitentDetails.patientEmail = val?.patient_email;
        paitentDetails.patientMob = val?.patient_mobile;
        paitentDetails.patientDOB = val?.patient_dob
          ? moment(val?.patient_dob).format("DD-MM-YYYY")
          : "";
        paitentDetails.patientGender = val?.gender_name_en;
        paitentDetails.patientCountry = val?.country_name;
        paitentDetails.patientCountryCode = val?.country_isd_code;
        paitentDetails.patientAge = val?.patient_dob
          ? calculateAge(moment(val?.patient_dob).format("YYYY-MM-DD"))
          : 0;
        let filterVal = tempfilteredReservationData.filter(v => v?.resv_tx_patient_id == val?.patient_id);
        console.log("filteredReservationData", filteredReservationData)
        console.log("tempfilteredReservationData", tempfilteredReservationData)
          if(Array.isArray(filterVal) && filterVal.length >1) {
          for(let i=0; i< filterVal.length; i++) {
            let newVal = {...val};
            newVal.test_codes = filterVal[i]?.resv_tx_test_id ? filterVal[i]?.resv_tx_test_id : filterVal[i]?.resv_tx_package_id;
            newVal.test_names =  (filterVal[i]?.resv_tx_test_name_en
            ? filterVal[i]?.resv_tx_test_name_en?.toUpperCase()
            : (filterVal[i]?.resv_tx_package_name_en)?.toUpperCase() +
            " (" +
            (filterVal[i]?.resv_tx_name)?.toUpperCase() +
            ")");
            if(InvestigationPayload.filter(v => v?.test_names == newVal?.test_names && v?.patient_id == newVal?.patient_id).length == 0) {
              newVal.sample_id = filterVal[i]?.sample_id
              InvestigationPayload.push(newVal);
            }
          }
        } else {
          InvestigationPayload.push(val);
        }
        // InvestigationPayload.push(newVal);
      }
    });
    let payload = {
      InvestigationPayload: InvestigationPayload,
      paitentDetails: paitentDetails,
    };
    console.log(payload);
    console.log(payload);
    // return false;
    let res = await ReportServices.generateInvestigationReport(payload);
    if (res.status == 200) {
      setInvestigationIsLoading([...isInvestigationIsloading].filter(v => v != sample_id_load));
      toast.success(res.data.message);
      window.open(res.data.data, "_blank");
    } else {
      toast.error(res.data.message);
    }
  };

  const getCountryList = () => {
    MasterServices.getCountryList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setCountryList(data.data);
            let tempArr = [];
            if (data.data.length > 0) {
              let indexOfSA = data.data.findIndex((country) => {
                return country.country_id == 203;
              });
              tempArr = array_move(data.data, indexOfSA, 0);
              setFilteredCountryList(tempArr);
            }
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getCountryList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  };

  function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }

  const addUserPatient = (body, action) => {
    ReservationServices.saveUserPatient(body)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success(data.message);
          if (userType === "Patient") {
            getAllPatientList();
            getAllUserResList();
          } else if (userType === "User") {
            getAllUserList();
            getAllUserResList();
          }
          setOpenPatient(false);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "saveUserPatient Catch Error"; //error.response.data.message;
        toast.error(msg);
      });
    action.resetForm();
  };

  const getAllUserList = () => {
    ReservationServices.getAllUserList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setUserList(data.data);
          } else {
            toast.error("UserList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getAllUserList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  };

  const getAllPatientList = () => {
    if (user_id) {
      setLoading(true);
      ReservationServices.getSingleUserBasedPatient(user_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            if (data?.data?.length > 0) {
              let PatientListArr = [];
              data.data.forEach((val, key) => {
                val.testPackArr = [];
                val.testPackNameArr = [];
                PatientListArr.push(val);
              });
              setPatientList(PatientListArr);
            } else {
              toast.error("Fatal Error Please Contact Admin");
            }
          } else {
            toast.error("Fatal Error Please Contact Admin");
          }
          setLoading(false);
        })
        .catch((error) => {
          const msg = "getAllPatientList catch Error"; //error.response.data.message;
          toast.error(msg);
          setLoading(false);
        });
    }
  };

  // Abhi - Edit Patient End

  // console.log(reservationData, "reservationData >>>>>>>")

  const CalculatePayment = () => {
    let total = 0;
    let rev_amount = reservationData?.getAllReservation?.rev_amount
      ? parseFloat(reservationData?.getAllReservation?.rev_amount)
      : 0;
    let rev_vat_amount = reservationData?.getAllReservation?.rev_vat_amount
      ? parseFloat(reservationData?.getAllReservation?.rev_vat_amount)
      : 0;
    let rev_total_discount = reservationData?.getAllReservation
      ?.rev_total_discount
      ? parseFloat(reservationData?.getAllReservation?.rev_total_discount)
      : 0;
    let rev_discounted_amount = reservationData?.getAllReservation
      ?.rev_discounted_amount
      ? parseFloat(reservationData?.getAllReservation?.rev_discounted_amount)
      : 0;
    total = parseFloat(
      rev_amount + rev_vat_amount - (rev_total_discount + rev_discounted_amount)
    ).toFixed(2);

    setFinalAmt(total);
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  // ((item.resv_tx_patient_fname_en && item.resv_tx_patient_fname_en.toLowerCase().includes(searchQuery.toLowerCase()))
  // || (item.resv_tx_test_name_en && item.resv_tx_test_name_en.toLowerCase().includes(searchQuery.toLowerCase())) || (item.resv_tx_test_code && item.resv_tx_test_code.includes(searchQuery)) || (item.resv_tx_package_name_en && item.resv_tx_package_name_en.toLowerCase().includes(searchQuery.toLowerCase())) || (item.resv_tx_name && item.resv_tx_name.toLowerCase().includes(searchQuery.toLowerCase())) || (item.resv_tx_patient_phone && item.resv_tx_patient_phone.includes(searchQuery)) || (item.resv_tx_patient_email && item.resv_tx_patient_email.includes(searchQuery)) || (item.resv_tx_patient_id && item.resv_tx_patient_id.includes(searchQuery)) || (item.resv_tx_test_id && item.resv_tx_test_id.includes(searchQuery)) || (item.resv_tx_package_id && item.resv_tx_package_id.includes(searchQuery))

  const [progress, setProgress] = useState();
  const dispatch = useDispatch();
  const tableRef = useRef();
  const handleSubmitBak = (e) => {
    e.preventDefault();
    // console.log("handleSubmit");
    const formData = new FormData();
    // let ext = fileName.split('.');
    // console.log("ext=>",ext);

    formData.append("file", fileName);
    //addAttachmentToResv
    ReservationServices.addAttachmentToResv(formData)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("File Uploaded Successfully!");
          setFilePath(data.data);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "addAttachment catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  };

  //calculate percentage
  const calculatePercentage = (value) => {
    let totalAmount =
      parseFloat(reservationData?.getAllReservation?.rev_amount) +
      parseFloat(reservationData?.getAllReservation?.rev_vat_amount) -
      parseFloat(
        reservationData?.getAllReservation?.rev_total_discount
          ? reservationData?.getAllReservation?.rev_total_discount
          : 0
      );
    let currentValue = value;
    let percentage = (currentValue / totalAmount) * 100;
    return percentage;
  };

  const calculateDisAmount = (rev_discount_per) => {
    setDisAmt(
      (
        (parseFloat(reservationData?.getAllReservation?.rev_amount) +
          parseFloat(reservationData?.getAllReservation?.rev_vat_amount) -
          parseFloat(
            reservationData?.getAllReservation?.rev_total_discount
              ? reservationData?.getAllReservation?.rev_total_discount
              : 0
          )) *
        (rev_discount_per ? parseFloat(rev_discount_per) / 100 : 0)
      ).toFixed(2)
    );
    if (reservationData?.getAllReservation) {
      CalculatePayment();
    }
  };
  // useEffect(() => {
  //   // rev_discounted_amount calculated on value by -> adding vat amount to the rev_amount and substracting rev_total_discount
  //   setDisAmt(
  //     (
  //       (parseInt(reservationData?.getAllReservation?.rev_amount) +
  //         parseInt(
  //           reservationData?.getAllReservation?.rev_vat_amount
  //         ) -
  //         parseInt(
  //           reservationData?.getAllReservation?.rev_total_discount
  //             ? reservationData?.getAllReservation
  //               ?.rev_total_discount
  //             : 0
  //         )) *
  //       (rev_discount_per ? parseInt(rev_discount_per) / 100 : 0)
  //     )
  //   );
  //   if (reservationData?.getAllReservation) {
  //     CalculatePayment();
  //   }
  // }, [rev_discount_per]);

  const getAllGlobalSettingList = () => {
    MasterServices.getAllGlobalSettings(1)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            console.log("setvatRate=>", data?.data);
            setVatRate(data?.data[0]?.gs_vat);
            setVatRateID(data?.data[0]?.gs_id);
            console.log("vatRate=>", vatRate);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getAllGlobalSettingList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  };
  const handleFileChangeBak = (e) => {
    setFile(e.target.files[0]);
  };
  const viewQuestionar = (questionnaire) => {
    localStorage.setItem(
      "edit_booking_reservation_questionnaire",
      questionnaire
    );
    console.log("questionnaire HS=>", reservationData?.getAllReservation);
    navigate(
      "/res-questionnaire/" + reservationData?.getAllReservation?.reservation_id
    );
  };
  const handleSubmit = async (e) => {
    let myFiles = [];
    if (multifiles.length > 0) {
      myFiles = multifiles;
    } else {
      myFiles = [multifiles];
    }
    const response = await uploadFiles(myFiles);
    // setProgress();
    // console.log("Happy Singh=>", response)
    // console.log("Happy Singh progress=>", response.progress)
    setMultiAttachment(response.data);
    setProgress(response.progress);
  };
  const handleFileChange = (newFiles) => {
    // const newFiles = [...e.target.files];
    console.log("newFiles=>", newFiles);
    setFiles([...multifiles, ...newFiles]);
    if (!newFiles) {
      setValidateFlag(true);
    } else {
      setValidateFlag(false);
    }
  };

  const onCancelTP = (data) => {
    let payload = {
      resv_tx_id: data?.resv_tx_id,
      type: data?.resv_is_package == "Y" ? "P" : "T", //T or P
      type_id:
        data?.resv_is_package == "Y"
          ? data?.resv_tx_package_id
          : data?.resv_tx_test_id,
      res_id: data?.resv_tx_resv_id,
    };
    ReservationServices.cancelTestPack(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          console.log("ReservationServices", data.data);
          refreshOnCancel();
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        toast.error("cancelTestPack catch Error");
      });
  };

  //remove file from attachment
  const removeFile = (index) => {
    let newArr = multifiles.filter((f, i) => i !== index);
    console.log(newArr);
    setFiles(multifiles.filter((f, i) => i !== index));
    console.log(multifiles);
  };

  const getAllUserResList = () => {
    let payload = {
      user_id: localStorage.getItem("edit_booking_user_id"),
      reservation_id: localStorage.getItem("edit_booking_reservation_id"),
      reservationData: localStorage.getItem("edit_booking_reservationData"),
      user_details: localStorage.getItem(
        "edit_booking_reservation_user_details"
      ),
    };
    ReservationServices.getSingleReservation(
      localStorage.getItem("edit_booking_reservation_id")
    )
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data) {
            
            const revData = formatResv(data?.data?.reservationDetails);
            data.data.reservationDetails = revData;
            setReservationData(data.data);
            setOCParentDetails(data.data.getAllReservation?.oc_parent_details ?? false)
            data.data.getAllReservation?.payment_details?.forEach((v, index) => {
              if(v.fk_pay_mode_id == 5 && index == 0) {
                setIsCreditPaymentShow(true);
              } else {
                setIsCreditPaymentShow(false);
              }
            })
            if(data.data.getAllReservation?.payment_details.length > 0) {
              setPaymentDetailsObj(data.data.getAllReservation?.payment_details?.filter(v => v.fk_pay_mode_id != 5)[0]);
              setPaymentDetailsArr(data.data.getAllReservation?.payment_details);
              if(data.data.getAllReservation?.payment_details?.length === 1) {
                if(data.data.getAllReservation?.payment_details[0].fk_pay_mode_id != 5) {
                  setIsOcButNotCredit(true);
                }
              }
            }
            setRefundLogs(data.data.getAllReservation.refund_logs)
            if (data?.data?.getAllReservation) {
              setUrgent(data.data.getAllReservation.is_urgent);
              setEndDateGenerateInvoice(momentTz.utc(data.data.getAllReservation?.generate_invoice_timer).format('YYYY-MM-DD'));
              setEndTimeGenerateInvoice(momentTz.utc(data.data.getAllReservation?.generate_invoice_timer).format('hh:mm:ss'));
              setEndDateSampleReceiveReject(momentTz.utc(data.data.getAllReservation?.sample_receive_reject_timer).format('YYYY-MM-DD'));
              setEndTimeSampleReceiveReject(momentTz.utc(data.data.getAllReservation?.sample_receive_reject_timer).format('hh:mm:ss'));
              if (
                data.data.getAllReservation.reservation_referral_relation
                  .resv_ref_name
              ) {
                setClinicRefferalIdObj({
                  value:
                    data.data.getAllReservation.reservation_referral_relation
                      .resv_ref_id,
                  label:
                    data.data.getAllReservation.reservation_referral_relation
                      .resv_ref_name,
                });
              }
            }
            setTestPackageList(data.data.totTestPackList);
            setGenerateInvoiceLoading(false);
            setGenerateCreditInvoiceLoading(false);
            setGenerateSampleLoading(false);
          } else {
            // toast.error("getSingleReservation Not Found")
          }
        } else {
          setGenerateSampleLoading(false);
          setGenerateInvoiceLoading(false);
          setGenerateCreditInvoiceLoading(false);
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getSingleReservation catch Error"; //error.response.data.message;
        // toast.error(msg);
      });
  };

  const refreshOnCancel = () => {
    ReservationServices.getSingleReservation(
      localStorage.getItem("edit_booking_reservation_id")
    )
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data) {
            // console.log("getAllUserResList=>", data.data)
            setReservationData(data.data);
            console.log(data.data.totTestPackList, "ata.data.totTestPackList");
            if (data?.data?.getAllReservation) {
              setUrgent(data.data.getAllReservation.is_urgent);
            }
            setTestPackageList(data.data.totTestPackList);
          } else {
            toast.error("getSingleReservation Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getSingleReservation catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  };

  const handleLabTechChange = async (e) => {
    console.log("handleLabTechChange payload", e);
    setIsLoading(true);
    let payload = {
      user_id: e.value,
      reservation_id: reservationData?.getAllReservation?.reservation_id,
    };
    let res = await ReservationServices.setLabTechnicianForReservation(payload);
    console.log("e.target.value 1", e);

    // let newObj = {}
    // newObj.label = e.label;
    // newObj.value = e.value;
    //setSelectedLabTech(e);

    if (res.status === 200) {
      console.log("e.target.value 2", e.value);
      //setSelectedLabTech(7)
      setIsLoading(false);
      toast.success(res.data.message);
    }
  };

  const generateBarcode = (e) => {
    setBarcodeFlag(barcodeFlag ? false : true);
    e.preventDefault();
  };
  const printSampleBarCode = async (value, e) => {
    // setSingleSampleData(false);
    // setSingleSampleData([value]);
    setPrintSingleSampleBarCodeFlag(
      printSingleSampleBarCodeFlag ? false : true
    );
    // console.log("value Single=>", value)
    // setSingleSampleData([value]);
    setPrintSampleBarCodeFlag(printSampleBarCodeFlag ? false : true);
    setTimeout(handleSinglePrint, 500);

    e.preventDefault();
  };

  const handleExport = (excel_type) => {
    if (reservationData?.reservationDetails?.length > 0) {
      let TestPackList = reservationData?.reservationDetails;
      let newSheetdata = [];
      TestPackList.map((obj, index) => {
        const newData = {
          Name: obj.resv_tx_patient_fname_en,
          Email: obj.resv_tx_patient_email,
          Mobile: obj.resv_tx_patient_phone,
          DOB: obj.resv_tx_patient_dob,
          Age: obj.resv_tx_patient_age,
          Gender: obj.resv_tx_patient_gender_name_en,
          Country: obj.resv_tx_patient_country_name_en,
          Relationship: obj.resv_tx_patient_relationsip,
          BookingType: reservationData?.getAllReservation?.booking_type,
          ReservationID: obj.resv_tx_resv_id,
          Amount: reservationData?.getAllReservation?.rev_amount,
          Status:
            reservationData?.getAllReservation?.is_payment_completed == true
              ? "Completed"
              : "Pending",
          Type: obj?.resv_is_package === "Y" ? "Package" : "Test",
          TestName: obj.resv_tx_test_name_en ? obj.resv_tx_test_name_en : "NA",
          TestCode: obj.resv_tx_test_code,
          PackageName: obj?.resv_tx_package_name_en
            ? obj?.resv_tx_package_name_en + " (" + obj?.resv_tx_name + ")"
            : "NA", //resv_tx_name
          Price: obj.resv_tx_price,
          Incentive: reservationData?.getAllReservation?.user_incentive,
        };
        newSheetdata.push(newData);
      });
      if (excel_type != "copy") {
        let fileName =
          "Booking_Reservation_Test_Pack_List_ResID_" +
          reservationData?.getAllReservation?.reservation_id +
          "." +
          excel_type;

        const headings = [
          [
            "Name",
            "Email",
            "Mobile",
            "DOB",
            "Age",
            "Gender",
            "Country",
            "Relationship",
            "BookingType",
            "ReservationID",
            "Amount",
            "Status",
            "Type",
            "TestName",
            "TestCode",
            "PackageName",
            "Price",
            "Incentive",
          ],
        ];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, newSheetdata, {
          origin: "A2",
          skipHeader: true,
        });
        utils.book_append_sheet(wb, ws, "Report");
        writeFile(wb, fileName);
      } else {
        let newCopySheetdata = [];
        let head = {
          Name: "Name",
          Email: "Email",
          Mobile: "Mobile",
          DOB: "DOB",
          Age: "Age",
          Gender: "Gender",
          Country: "Country",
          Relationship: "Relationship",
          BookingType: "BookingType",
          ReservationID: "ReservationID",
          Amount: "Amount",
          Status: "Status",
          Type: "Type",
          TestName: "TestName",
          TestCode: "TestCode",
          PackageName: "PackageName",
          Price: "Price",
          Incentive: "Incentive",
        };
        newCopySheetdata.push(head);
        TestPackList.map((obj, index) => {
          const newData = {
            Name: obj.resv_tx_patient_fname_en,
            Email: obj.resv_tx_patient_email,
            Mobile: obj.resv_tx_patient_phone,
            DOB: obj.resv_tx_patient_dob,
            Age: obj.resv_tx_patient_age,
            Gender: obj.resv_tx_patient_gender_name_en,
            Country: obj.resv_tx_patient_country_name_en,
            Relationship: obj.resv_tx_patient_relationsip,
            BookingType: reservationData?.getAllReservation?.booking_type,
            ReservationID: obj.resv_tx_resv_id,
            Amount: reservationData?.getAllReservation?.rev_amount,
            Status:
              reservationData?.getAllReservation?.is_payment_completed == true
                ? "Completed"
                : "Pending",
            Type: obj?.resv_is_package === "Y" ? "Package" : "Test",
            TestName: obj.resv_tx_test_name_en
              ? obj.resv_tx_test_name_en
              : "NA",
            TestCode: obj.resv_tx_test_code,
            PackageName: obj?.resv_tx_package_name_en
              ? obj?.resv_tx_package_name_en + " (" + obj?.resv_tx_name + ")"
              : "NA", //resv_tx_name
            Price: obj.resv_tx_price,
            Incentive: reservationData?.getAllReservation?.user_incentive,
          };
          newCopySheetdata.push(newData);
        });
        navigator.clipboard.writeText(JSON.stringify(newCopySheetdata));
      }
    } else {
      toast.error("Data Not Found!");
    }
  };

  const handleSinglePrint = () => {
    // alert(singleSampleData.length);
    if (multiSampleDataForPrint.length > 0) {
      const printStyles = `
    <style>
      /* Add custom CSS styles for printing */

      @page{
        size: 300px 140px; /* Width Height */
        margin: 0px;
      }

      @media print {
        .print-container {
          display: block;
          page-break-before: always;
          page-break-inside: avoid;
          page-break-after: always;
          width: 100%;
          margin: 0;
          padding: 0;
        }
      }

      body { 
        margin: 0px;
        size: 300px 140px; /* Width Height */
        font-family: Helvetica, Arial, sans-serif;
        font-weight:bold; 
        // line-height: 1.2;
      }

      // .print-container{
      // }

      .print-barcode-full {
        // border: 1px solid black;
        width: 300px;
        height: 140px;
      }
      
      .print-barcode-full > div{
        // border: 1px solid black;
      }

      // .print-barcode-full > div:not(:first-child) {
      // }
      
      .barcode-container {
        // border: 1px solid red;
        padding: 0px 0px 4px 4px;
      }
      
      .print-barcode-details { 
        // border: 1px solid red;
        margin: -2px 20px 0px 10px; 
        font-size: 12px;
      }

      .flex-space-between {
        display: flex;
        justify-content: space-between;
      }

      /* Add any other styles as needed */
    </style>
  `;

      let fileName =
        "Booking_Reservation_Test_Pack_List_ResID_" +
        reservationData?.getAllReservation?.reservation_id +
        ".pdf";
      const printWindow = window.open("", fileName);
      const tableHtml = SingleprintsampleRef.current.outerHTML;

      const tableData = getDataFromTable(SingleprintsampleRef.current);
      console.log("tableData", tableData);

      console.log(
        "SingleprintsampleRef",
        SingleprintsampleRef.current.outerHTML
      );
      printWindow.document.write(
        `<html><head><title>${fileName}</title>${printStyles}</head><body>${tableHtml}</body></html>`
      );
      // printWindow.document.write(`
      //   <html>
      //     <head>
      //       <title>${fileName}</title>
      //       ${printStyles}
      //     </head>

      //     <body>
      //       ${tableData[1][6]}
      //     </body>
      //   </html>`
      // );
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      // printWindow.close();
    }
  };

  const getDataFromTable = (tableElement) => {
    const tableData = [];

    const rows = tableElement.querySelectorAll("tr");
    rows.forEach((row) => {
      const rowData = [];
      const cells = row.querySelectorAll("td, th");
      cells.forEach((cell, key) => {
        // if(key == 6){
        //   rowData.push(cell.innerHTML)
        // }else{
        //   rowData.push(cell.innerText.trim());
        // }
        rowData.push(cell.innerHTML);
        console.log("cell", cell, key);
      });
      tableData.push(rowData);
    });

    return tableData;
  };

  const handlePrint = () => {
    let fileName =
      "Booking_Reservation_Test_Pack_List_ResID_" +
      reservationData?.getAllReservation?.reservation_id +
      ".pdf";

    const printWindow = window.open("", fileName);
    const tableHtml = printRef.current.outerHTML;
    printWindow.document.write(
      `<html><head><title>${fileName}</title></head><body>${tableHtml}</body></html>`
    );
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const handleSamplePrint = () => {
    let fileName =
      "Sample_List_ResID_" +
      reservationData?.getAllReservation?.reservation_id +
      ".pdf";

    const printWindow = window.open("", fileName);
    const tableHtml = printsampleRef.current.outerHTML;
    printWindow.document.write(
      `<html><head><title>${fileName}</title></head><body>${tableHtml}</body></html>`
    );
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };
  const handleSetPostPone = (id) => {
    // console.log(reservationData, "reservationData >>>>>>.")
    // console.log(testPackageList, "testPackageList")
    let updatedIsPostpond = reservationData?.totTestPackList?.map(
      (item, index) => {
        if (item.res_patient_test_pack_id == id) {
          if (item.is_postponed == true) {
            item.is_postponed = false;
          } else {
            item.is_postponed = true;
          }
          return item;
        } else {
          return item;
        }
      }
    );

    setTestPackageList(updatedIsPostpond);
  };

  const handleSetUrgent = (id) => {
    let updatedIsPostpond = reservationData?.totTestPackList?.map(
      (item, index) => {
        if (item.res_patient_test_pack_id == id) {
          if (item.is_urgent == true) {
            item.is_urgent = false;
          } else {
            item.is_urgent = true;
          }
          return item;
        } else {
          return item;
        }
      }
    );

    setTestPackageList(updatedIsPostpond);
  };

  const handlePdfGenerate = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);

    let fileName =
      "Booking_Reservation_Test_Pack_List_ResID_" +
      reservationData?.getAllReservation?.reservation_id +
      ".pdf";
    pdf.save(fileName);
  };
  

  useEffect(() => {
    document.title = "Tabib | Reservation Details";

    getAllGlobalSettingList();
    setReservationId(localStorage.getItem("edit_booking_reservation_id"));
    setUserDetails(
      JSON.parse(localStorage.getItem("edit_booking_reservation_user_details"))
    );
    getAllUserResList();
    dispatch(getAllPaymentModes("active"));
    getClinicRefferalList();
    getCountryList();
    getDiscountOTPUsers();
    dispatch(getAllGenders());
    // viewSample();
    if (localStorage.getItem("edit_booking_reservation_id")) {
      viewSample();
    }
    getAllLocationList();
    setTimeout(() => {
      setCollectSampleButtonClickLoad(false);
    }, 5000)
  }, []);

  const handleRecollectSample = async (s_id) => {
    setRemainingTimeSampleReceiveReject(30);
    setCollectSampleButtonClickLoad(true)
    try {
      SampleServices.recollectSampleAtLab(s_id).then((res) => {
        if (res.status == 200) {
          toast.success(res.data.message);
          viewSample();
        }
      });
    } catch (e) {
      console.log(e);
      toast.error(e);
    }
  };

  const handleReceiveSample = async (s_id) => {
    try {
      SampleServices.receiveSampleAtLab(s_id).then((res) => {
        // console.log(res)
        if (res.status == 200) {
          toast.success(res.data.message);
          viewSample();
        }
      });
    } catch (e) {
      console.log(e);
      toast.error(e);
    }
  };

  const handleRejectSample = async (s_id) => {
    console.log("Priyanshu SIngh=>", s_id);
    try {
      SampleServices.rejectSampleByLab(s_id).then((res) => {
        if (res.status == 200) {
          toast.success(res.data.message);
          setRejectedReason(false);
          setRejectSelected(false);
          viewSample();
        }
      });
    } catch (e) {
      console.log(e);
      toast.error(e);
    }
  };

  const handleReceiveSampleAll = async () => {
    if (selectedRows.length > 0) {
      let payload = {
        sample_ids: selectedRows,
      };
      try {
        SampleServices.receiveSampleAtLabAll(payload).then((res) => {
          // console.log(res)
          if (res.status == 200) {
            toast.success(res.data.message);
            setSelectedRows([]);
            viewSample();
          }
        });
      } catch (e) {
        console.log(e);
        toast.error(e);
      }
    } else {
      toast.error("Please Selecct Atleast one Sample");
    }
  };

  const handleRejectSampleAll = async () => {
    if (selectedRows.length > 0) {
      let payload = {
        sample_ids: selectedRows,
      };
      console.log("Priyanshu SIngh=>");
      try {
        SampleServices.rejectSampleByLabAll(payload).then((res) => {
          if (res.status == 200) {
            toast.success(res.data.message);
            setSelectedRows([]);
            setRejectedReason(false);
            setRejectSelected(false);
            viewSample();
          }
        });
      } catch (e) {
        console.log(e);
        toast.error(e);
      }
    } else {
      toast.error("Please Selecct Atleast one Sample");
    }
  };
  const cancelButtonRef = useRef(null);

  const getSingleReservationDetails = async (payload) => {
    console.log("payload=>", payload);
    return await ReservationServices.getSingleReservation(
      payload?.reservation_id
    )
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          return data.data;
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "getAllPatientList catch Error"; //error.response.data.message;
        // toast.error(msg);
      });
  };

  const getDiscountOTPUsers = async () => {
    return await MasterServices.getDiscountOTPUsers()
      .then((response) => {
        const { data, status } = response;
        if (status == 200 && data.data) {
          if (Array.isArray(data.data) && data.data.length > 0) {
            let tempArr = data.data.map((v) => ({
              value: v.user_id,
              label: v.user_first_name,
            }));
            setMuserList(tempArr);
          }
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "getDiscountOTPUsers catch Error";
        toast.error(msg);
      });
  };

  //send otp to m_user.
  const sendOTP = async () => {
    //
    setOtpSent(true);
    let payload = {
      otp_type: "Discount OTP",
      fk_master_user_id: userData.user_id,
    };
    console.log("payload=>", payload);
    return await MasterServices.sendOtp(payload)
      .then((response) => {
        const { data, status } = response;
        console.log(data);
        if (status == 200 && data.data) {
          setOtpID(data.data.otp_id);
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "sendOTP catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  };

  const verifyOTP = async (otpinput) => {
    //
    setOtpSent(true);
    let payload = {
      otp_id: otpID,
      otp: otpinput,
    };
    console.log("payload=>", payload);
    return await MasterServices.verifyOtp(payload)
      .then((response) => {
        const { data, status } = response;
        console.log(data);
        if (status == 200 && data?.status) {
          setIsOtpVerified(true);
          return true;
        } else {
          setIsOtpVerified(false);
          toast.error(data.message);
          return false;
        }
      })
      .catch((error) => {
        const msg = "verifyOTP catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  };

  const generateInvoiceReport = async (reservationID) => {
    let payload = {};
    payload = { ...reservationData?.getAllReservation };
    payload.reservation_id = reservationID;
    payload.patient_ref_mrn_no =
    Array.isArray(reservationData?.getAllReservation?.parentOfPatient) ? reservationData?.getAllReservation?.parentOfPatient[0]?.patient_ref_mrn_no : '-';
    payload.patient_report_mrn =
    Array.isArray(reservationData?.getAllReservation?.parentOfPatient) ? reservationData?.getAllReservation?.parentOfPatient[0]?.patient_report_mrn : '-';
    payload.patient_first_name =
      reservationData?.getAllReservation?.rev_patient_parent_fname;
    payload.patient_id_no =
      reservationData?.getAllReservation?.rev_patient_parent_id_no;
    payload.patient_mobile =
      reservationData?.getAllReservation?.rev_patient_parent_phone;
    payload.payment_status = reservationData?.getAllReservation
      ?.is_payment_completed
      ? "Paid"
      : "Pending";
    payload.rev_vat_amount = reservationData?.getAllReservation?.rev_vat_amount;
    payload.rev_amount = reservationData?.getAllReservation?.rev_amount;
    payload.rev_total_discount =
      reservationData?.getAllReservation?.rev_total_discount;
    let singleReservationDetails = await getSingleReservationDetails(payload);
    payload.singleReservationDetails = singleReservationDetails;
    let isCreditInvoice = OCParentDetails ? true : false;
    payload.isCreditInvoice = isCreditInvoice;
    // console.log("singleReservationDetails=>", singleReservationDetails); return false;
    let res = await ReservationServices.generateInvoiceReport(payload);
    if (res.status == 200) {
      toast.success(res.data.message);
      getAllUserResList();
      setGenerateInvoiceLoading(false);
      setGenerateCreditInvoiceLoading(false);
    } else {
      setGenerateInvoiceLoading(false);
      setGenerateCreditInvoiceLoading(false);
      toast.error(res.data.message);
    }
  };

  const getAllReservationAttachmentList = () => {
    ReservationServices.getAllReservationAttachmentList(
      localStorage.getItem("edit_booking_reservation_id")
    )
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data) {
            // console.log("getAllReservationAttachmentList=>", data.data.reservationAttachment)
            setAttachmentList(data.data.reservationAttachment);
          } else {
            toast.error("getAllReservationAttachmentList Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = "getAllReservationAttachmentList catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  };

  const updateComment = () => {
    if (commentData) {
      ReservationServices.updateReservationCommment({
        reservation_id: localStorage.getItem("edit_booking_reservation_id"),
        rev_comments: commentData,
      })
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            toast.success("Comment Added Successfully!");
            setCommentsModal(false);
            getAllUserResList();
          } else {
            toast.error("Fatal Error Please Contact Admin");
          }
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg);
        });
    } else {
      toast.error("Please Enter Comment");
    }
  };

  const addPaymentCredit = () => {
      let is_payment_completed = false;
      let rev_payable_amount = 0;
      if (payMode == 1) {
        if (parseFloat(cashAmt).toFixed(2) > 0) {
          // if (parseInt(cashAmt) === (parseInt(reservationData?.getAllReservation?.rev_amount) + parseInt(reservationData?.getAllReservation?.rev_vat_amount))) {
          if (parseFloat(cashAmt).toFixed(2) === finalAmt) {
            is_payment_completed = true;
            rev_payable_amount = parseFloat(cashAmt).toFixed(2);
          }
        } else {
          toast.error("Cash Amount should Be Greater Than Zero");
        }
      } else if (payMode == 2) {
        if (parseFloat(visaAmt).toFixed(2) > 0) {
          if (parseFloat(visaAmt).toFixed(2) === finalAmt) {
            is_payment_completed = true;
            rev_payable_amount = parseFloat(visaAmt).toFixed(2);
          }
        } else {
          toast.error("Vista Amount should Be Greater Than Zero");
        }
      } else if (payMode == 3) {
        if (
          parseFloat(cashAmt).toFixed(2) > 0 &&
          parseFloat(visaAmt).toFixed(2) > 0
        ) {
          // alert(eval(visaAmt + '+' + cashAmt))
          // alert(finalAmt)
          if (eval(visaAmt + "+" + cashAmt) == finalAmt) {
            // if (eval(visaAmt + '+' + cashAmt) === finalAmt) {
            is_payment_completed = true;
            rev_payable_amount = eval(visaAmt + "+" + cashAmt);
          }
        } else {
          if (parseFloat(cashAmt).toFixed(2)) {
            toast.error("Cash Amount should Be Greater Than Zero");
          }
          if (parseFloat(visaAmt).toFixed(2)) {
            toast.error("Vista Amount should Be Greater Than Zero");
          }
        }
      } else if (payMode == 4) {
        if (parseFloat(bankTransAmt).toFixed(2) > 0) {
          if (parseFloat(bankTransAmt).toFixed(2) === finalAmt) {
            is_payment_completed = true;
            rev_payable_amount = parseFloat(bankTransAmt).toFixed(2);
          }
        } else {
          toast.error("Bank Transfer Amount should Be Greater Than Zero");
        }
      } 
      if (is_payment_completed) {
        let payment_data;
        if(payMode == 4) {
          payment_data = {
            bankTransAmt: bankTransAmt
          }
        } else {
          payment_data = {
            cashAmt: cashAmt,
            visaAmt: visaAmt,
          }
        }
        payment_data.timestamp = creditPayDate
        ReservationServices.addReservationPayment({
          payment_id: "",
          fk_reservation_id: localStorage.getItem("edit_booking_reservation_id"),
          payment_data,
          rev_payable_amount: rev_payable_amount ? rev_payable_amount : 0,
          fk_pay_mode_id: payMode,
          payment_note: "",
          login_user_id: localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")).user_id
            : 0,
          is_payment_completed: is_payment_completed,
        })
          .then((response) => {
            const { data, status } = response;
            if (status == 200) {
              toast.success("Payment Added Successfully!");
              setPayAll(false);
              getAllUserResList();
            } else {
              toast.error("Fatal Error Please Contact Admin");
            }
          })
          .catch((error) => {
            const msg = error;
            toast.error(msg);
          });
      } else {
        toast.error("You Have to pay Full Amount");
        return false;
      }
  }

  const addPayment = () => {
    let is_payment_completed = false;
    let rev_payable_amount = 0;
    let is_credit_payment = false;
    let is_first_credit_pay = false;
    if (payMode == 1) {
      if (parseFloat(cashAmt).toFixed(2) > 0) {
        // if (parseInt(cashAmt) === (parseInt(reservationData?.getAllReservation?.rev_amount) + parseInt(reservationData?.getAllReservation?.rev_vat_amount))) {
        if (parseFloat(cashAmt).toFixed(2) === finalAmt) {
          is_payment_completed = true;
          rev_payable_amount = parseFloat(cashAmt).toFixed(2);
        }
      } else {
        toast.error("Cash Amount should Be Greater Than Zero");
      }
    } else if (payMode == 2) {
      if (parseFloat(visaAmt).toFixed(2) > 0) {
        if (parseFloat(visaAmt).toFixed(2) === finalAmt) {
          is_payment_completed = true;
          rev_payable_amount = parseFloat(visaAmt).toFixed(2);
        }
      } else {
        toast.error("Vista Amount should Be Greater Than Zero");
      }
    } else if (payMode == 3) {
      if (
        parseFloat(cashAmt).toFixed(2) > 0 &&
        parseFloat(visaAmt).toFixed(2) > 0
      ) {
        // alert(eval(visaAmt + '+' + cashAmt))
        // alert(finalAmt)
        if (eval(visaAmt + "+" + cashAmt) == finalAmt) {
          // if (eval(visaAmt + '+' + cashAmt) === finalAmt) {
          is_payment_completed = true;
          rev_payable_amount = eval(visaAmt + "+" + cashAmt);
        }
      } else {
        if (parseFloat(cashAmt).toFixed(2)) {
          toast.error("Cash Amount should Be Greater Than Zero");
        }
        if (parseFloat(visaAmt).toFixed(2)) {
          toast.error("Vista Amount should Be Greater Than Zero");
        }
      }
    } else if (payMode == 5) {
      if (parseFloat(creditAmt).toFixed(2) > 0) {
        if (parseFloat(creditAmt).toFixed(2) === finalAmt) {
          //credit variable declare..
          // is_credit_payment= true,
          is_payment_completed = true;
          rev_payable_amount = parseFloat(creditAmt).toFixed(2);
          is_first_credit_pay = true;
        }
      } else {
        toast.error("Credit Amount should Be Greater Than Zero");
      }
    } else if (payMode == 4) {
      if (parseFloat(bankTransAmt).toFixed(2) > 0) {
        if (parseFloat(bankTransAmt).toFixed(2) === finalAmt) {
          is_payment_completed = true;
          rev_payable_amount = parseFloat(bankTransAmt).toFixed(2);
        }
      } else {
        toast.error("Bank Transfer Amount should Be Greater Than Zero");
      }
    } 
    if (is_payment_completed) {
      let payment_data;
      if(payMode == 4) {
        payment_data = {
          bankTransAmt: bankTransAmt
        }
      } else {
        payment_data = {
          cashAmt: cashAmt,
          visaAmt: visaAmt,
        }
      }
      ReservationServices.addReservationPayment({
        payment_id: "",
        fk_reservation_id: localStorage.getItem("edit_booking_reservation_id"),
        payment_data,
        rev_payable_amount: rev_payable_amount ? rev_payable_amount : 0,
        fk_pay_mode_id: payMode,
        payment_note: "",
        login_user_id: localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user")).user_id
          : 0,
        is_payment_completed: is_payment_completed,
      })
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            toast.success("Payment Added Successfully!");
            setPayAll(false);
            getAllUserResList();
          } else {
            toast.error("Fatal Error Please Contact Admin");
          }
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg);
        });
    } else if(is_first_credit_pay) {
        ReservationServices.addReservationPayment({
          payment_id: "",
          fk_reservation_id: localStorage.getItem("edit_booking_reservation_id"),
          payment_data: {
            creditAmt: creditAmt
          },
          rev_payable_amount: rev_payable_amount ? rev_payable_amount : 0,
          fk_pay_mode_id: payMode,
          payment_note: "",
          login_user_id: localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user")).user_id
            : 0,
          is_payment_completed: is_payment_completed,
          credit_payment_details: null
        })
          .then((response) => {
            const { data, status } = response;
            if (status == 200) {
              toast.success("Payment Added Successfully!");
              setPayAll(false);
              getAllUserResList();
            } else {
              toast.error("Fatal Error Please Contact Admin");
            }
          })
          .catch((error) => {
            const msg = error;
            toast.error(msg);
          });
    } else {
      toast.error("You Have to pay Full Amount");
      return false;
    }
  };

  const updateRefund = () => {
    ReservationServices.updateRefund({
      reservation_id: localStorage.getItem("edit_booking_reservation_id"),
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Refunded Successfully!");
          setRefundAll(false);
          getAllUserResList();
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
      });
  };
  // Send Urgent Notification
  const onUrgentMark = (e) => {
    setUrgent(e);
    const payload = {
      reservation_id: localStorage.getItem("edit_booking_reservation_id"),
      is_urgent: e,
    };
    ReservationServices.updateUrgentStatus(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (
            Array.isArray(data.data.getAllReservationnDetails) &&
            data.data.getAllReservationnDetails.length > 0
          ) {
            setFilteredReservationData(data.data.getAllReservationnDetails);
          }
          toast.success("Test mark urgent successfully!");
        } else {
          setUrgent(e);
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
      });
  };

  // Send Single Urgent Notification
  const onSingleUrgentMark = (resv_tx_id, resv_tx_urgent_status) => {
    // setUrgent(e);
    const payload = {
      reservation_id: localStorage.getItem("edit_booking_reservation_id"),
      resv_tx_id,
      resv_tx_urgent_status,
    };
    ReservationServices.updateSingleUrgentStatus(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data.data) {
            console.log("onSingleUrgentMark", data.data);
            setFilteredReservationData(data.data);
          }
          toast.success("Test mark urgent successfully!");
        } else {
          // setUrgent(e);
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
      });
  };

  const cancellReservation = () => {
    ReservationServices.updateCancell({
      reservation_id: localStorage.getItem("edit_booking_reservation_id"),
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Cancelled Successfully!");
          setCancelAll(false);
          navigate(-1);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
      });
  };

  const updateDiscount = () => {
    console.log("function calling outside iff");
    if (rev_discount_per > 0 && rev_discounted_amount > 0) {
      console.log("function calling inside iff");
      ReservationServices.updateReservationDiscount({
        reservation_id: localStorage.getItem("edit_booking_reservation_id"),
        rev_discount_per: rev_discount_per,
        rev_discounted_amount: rev_discounted_amount,
        rev_payable_amount:
          parseInt(reservationData?.getAllReservation?.rev_payable_amount) -
          parseInt(rev_discounted_amount),
        // rev_amount: `${(parseInt(reservationData?.getAllReservation?.rev_amount) - parseInt(rev_discounted_amount))}`,
        rev_discount_comments: rev_discount_comments,
      })
        .then((response) => {
          const { data, status } = response;
          if (status == 200 && data.status) {
            toast.success(data.message);
            setDiscountAll(false);
            setDisComments(false);
            setDiscountAll(false);
            setOtpSent(false);
            setIsOtpVerified(false);
            getAllUserResList();
            setDiscountReqUser("");
            setShowSendOtpButton(false);
          } else {
            toast.error(data.message);
            // toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg);
        });
    } else {
      if (rev_discount_per < 0) {
        toast.error("Discount Percentage should Be Greater Than Zero");
      }

      if (rev_discounted_amount < 0) {
        toast.error("Discount Amount should Be Greater Than Zero");
      }
    }
  };

  const uploadAttachment = () => {
    if (multiAttachmentArr.length > 0) {
      ReservationServices.updateAttachmentToResv({
        reservation_id: localStorage.getItem("edit_booking_reservation_id"),
        attachment: multiAttachmentArr,
        deleteFlag: 1,
        // attachment: fileNamePath,
      })
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            toast.success("File Saved Successfully!");
            setAttachFileModal(false);
            getAllUserResList();
            setFiles("");
          } else {
            toast.error("Fatal Error Please Contact Admin");
          }
        })
        .catch((error) => {
          const msg = error;
          toast.error(msg);
        });
    } else {
      toast.error("Please Choose a File");
    }
  };

  const generateSample = () => {
    setCollectSampleButtonClickLoad(true);
    setTimeout(() => {
      setCollectSampleButtonClickLoad(false);
    }, 50000)
    setGenerateSampleLoading(true);
    ReservationServices.generateSample({
      reservation_id: localStorage.getItem("edit_booking_reservation_id"),
      reservationDetails: reservationData?.samplegeneratearr,
      fk_loc_id: JSON.parse(localStorage.getItem("userLocData"))[0]?.value
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Sample Generated Successfully!");
          viewSample();
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
      });
  };
  const viewSample = () => {
    ReservationServices.viewSample(
      localStorage.getItem("edit_booking_reservation_id")
    )
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          // toast.success("Sample View Successfully!");
          console.log("firstdata.data", data.data)
          setSampleList(data.data);
          // generateSampleLoading(false);
          let sampleListData = data.data;
          // Check if any fk_status_id is not equal to 7
          for (const item of sampleListData) {
            // alert("Sudhir")
            if (item.fk_status_id !== 7) {
              
              setCollectButtonFlag(false);
              break; // No need to continue checking once we find a non-7 status
            }
          }
          getAllUserResList();
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
      });
  };

  const HandlePayAll = () => {
    addPayment();
    setPayAll(false);
    setAfterPayment(true);
  };

  const deleteAttachment = (obj, index) => {
    let data = [...attachmentList];
    data.splice(index, 1);
    console.log(data, "send data");
    const sendData = {
      reservation_id: localStorage.getItem("edit_booking_reservation_id"),
      attachment: data,
      deleteFlag: 2,
    };
    console.log(sendData);
    ReservationServices.updateAttachmentToResv(sendData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    setAttachmentList(data);
  };

  const getSingleUserAddress = async (ua_id) => {
    try {
      const { data, status } = await ReservationServices.getSingleUserAddress(
        ua_id
      );
      if (status === 200) {
        setReservationHomeAdress(data?.data);
        console.log(reservationHomeAddress);
      } else {
        setReservationHomeAdress({});
      }
    } catch (error) {
      const msg = error;
      toast.error(msg);
    }
  };

  const handleSampleCollection = () => {
    setTimeout(() => {
      setCollectSampleReceieButtonClickLoad(false);
    }, 5000)
    ReservationServices.allSampleCollecitonByResId(reservationID)
      .then((res) => {
        const { data, status } = res;
        if (status == 200) {
          viewSample();
          toast.success("All Samples Collected Successfully");
          setCollectButtonFlag(true)
        } else {
          toast.error("Error in Collecting All Samples");
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  useEffect(() => {
    // console.log("reservationData=>", reservationData);
    setReservationTestData(reservationData?.samplegeneratearr);
    //setLabTechnicianList(reservationData.labTechnicians);
    //setSelectedLabTech(reservationData?.getAllReservation?.fk_lab_technician_id);
    if (reservationData?.getAllReservation?.lab_tech_user_relation != null) {
      //lab_tech_user_relation
      let preObj = reservationData?.getAllReservation?.lab_tech_user_relation;
      preObj.label = preObj.user_first_name + " " + preObj.user_last_name;
      preObj.value = preObj.user_id;
      setSelectedLabTech(preObj);
    }
    const filteredReservationDataVar =
      reservationData?.reservationDetails?.filter(
        (item) =>
          (item.resv_tx_patient_fname_en &&
            item.resv_tx_patient_fname_en
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (item.resv_tx_test_name_en &&
            item.resv_tx_test_name_en
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (item.resv_tx_package_name_en &&
            item.resv_tx_package_name_en
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (item.resv_tx_name &&
            item.resv_tx_name.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    setFilteredReservationData(filteredReservationDataVar);
    if (reservationData?.getAllReservation?.attachment) {
      setAttachment(reservationData?.getAllReservation?.attachment);
    } else {
      setAttachment(false);
    }
  }, [reservationData]);

  async function getClinicRefferalList() {
    setLoading(true);
    try {
      const { data, status } = await MasterServices.getClinicRefferal();
      if (status === 200) {
        if (Array.isArray(data.data) && data.data.length > 0) {
          let tempRefferalList = data.data.map((v) => ({
            value: v.resv_ref_id,
            label: v.resv_ref_name,
            discount: v.resv_ref_discount_number,
          }));
          setClinicRefferalList(tempRefferalList);
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (reservationData?.getAllReservation?.fk_address_id) {
      getSingleUserAddress(reservationData?.getAllReservation?.fk_address_id);
    }
    if (reservationData?.labTechnicians) {
      let new_list = [...reservationData?.labTechnicians];
      new_list.forEach((el) => {
        el.label = el.user_first_name + " " + el.user_last_name;
        el.value = el.user_id;
      });
      setLabTechnicianList(new_list);
    }
    // console.log("ReservationData", reservationData)

    if (
      reservationData?.getAllReservation?.rev_discounted_amount &&
      reservationData?.getAllReservation?.rev_discounted_amount != null
    ) {
      setDisAmt(reservationData?.getAllReservation?.rev_discounted_amount);
      setResDisComment(
        reservationData?.getAllReservation?.rev_discount_comments
      );
      setDisPer(reservationData?.getAllReservation?.rev_discount_per);
      calculateDisAmount(reservationData?.getAllReservation?.rev_discount_per);
    }
    CalculatePayment();
  }, [reservationData]);

  const closeModal = () => {
    setIsCloseModel(true);
    setMobileNumber("");
    setMobileNumberValid(true);
    let resetModal = {
      user_first_name: "",
      user_last_name: "",
      user_email: "",
      user_mobile: "",
      patient_dial_code: "966",
      user_dob: "",
      user_id_type: "",
      user_id_no: "",
      user_passport_no: "",
      user_reasion_test: "",
      user_ref_mrn_no: "",
      fk_gender_id: "",
      fk_country_id: "",
      relationship: "",
      fk_questionnaire_id: "",
      userType: "",
      Type: "Add",
    };

    setModalData((prev) => ({
      ...prev,
      resetModal,
    }));
    setIsCloseModel(false);
  };

  const checkCouponAndShowDiscount = () => {
    let dis = 0;
    if (
      reservationData?.reservationDetails &&
      reservationData?.reservationDetails?.length > 0
    ) {
      reservationData.reservationDetails?.forEach((transaction) => {
        dis += transaction.resv_tx_discount_cp;
      });
    }
    return dis > 0 ? true : false;
  };

  function getHspLocIdForPrint(id) {
    let print_id = "";
    if (id > 0) {
      print_id = id.toString().padStart(4, "0");
    }
    return print_id;
  }

  const getAllLocationList = (sample_id) => {
    setIsLoading(true);
    MasterServices.getLabs()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setLocationList(data.data);
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getAllLocationList catch Error";
        toast.error(msg);
        setIsLoading(false);
      });
  };

  const handleLocChange = async (e) => {
    setIsLoading(true);
    let payload = {
      loc_id: e.target.value,
      sample_ids: selectedRows,
    };
    setSelectedLoc(e.target.value);
    let res = await SampleServices.updateProcessingLocationForMultipleSamples(
      payload
    );
    if (res.status == 200) {
      setIsLoading(false);
      toast.success(res.data.message);
      viewSample();
    }
    console.log("res", res);

    setTimeout(() => {
      setShowLocChangeDD(false);
    }, 1000);
  };

  const sendReportLinkSMS = async (reservation_data)=>{

    let payload = {
      report_link: reservation_data.sample_report_link,
      // phone: reservation_data.resv_tx_patient_dial_code + reservation_data.resv_tx_patient_phone, 
      phone: reservation_data.resv_tx_patient_dial_code + (reportSmsNumber+""), 
      fk_patient_id : reservation_data.resv_tx_patient_id,
      fk_reservation_id: reservation_data.resv_tx_resv_id,
    };

    return await SampleServices.sendReportLinkSMS(payload)
      .then((response) => {
        const { data, status } = response;
        console.log(data);
        if (status == 200 && data.data) {
          toast.success(data.message);
          console.log(data.data)
          closeReportSMSModal()
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const msg = "sendReportLinkSMS catch Error"; //error.response.data.message;
        toast.error(msg);
      });
  }


  const handleReportSmsModelOpen = (r_res) =>{
    setSelectedReportRes(r_res)
    setOpenSendReport(true)
console.log(r_res)
    setReportSmsNumber(r_res.resv_tx_patient_phone ? r_res.resv_tx_patient_phone : "")
  }

  const handleSendReportUrlSms = () =>{
    sendReportLinkSMS(selectedReportRes)
  }

  const closeReportSMSModal = () =>{
    setOpenSendReport(false)
    setReportSmsNumber("")
		setSelectedReportRes(false)
  }

  const formatResv = (resv_data) => {
    let newArr = [];
    resv_data.map((data, index) => {
        //console.log("main", index);
        if(hasTestId(newArr, data.resv_tx_patient_id)){
            
            let testIds = [];
            let packIds = [];
            
            if(data.resv_is_package == 'N'){
                //This is test
                testIds = getTestIdsWithName(resv_data, data.resv_tx_patient_id);
            }
            
            if(data.resv_tx_package_details != ''){
                //This is package
                const getJSON = JSON.parse(data.resv_tx_package_details);
                const testIdsFromJson = getJSON.map(item => item.fk_test_id);
                packIds = testIdsFromJson;
            }
            
            data.already = "Y";
            data.test_id_from_test = testIds;
            data.test_id_from_pack = packIds;
            newArr.push(data);
        }else{
            
            let testIds = [];
            let packIds = [];
            
            if(data.resv_is_package === 'N'){
                //This is test
                testIds = getTestIdsWithName(resv_data, data.resv_tx_patient_id);
            }
            
            if(data.resv_tx_package_details != ''){
                //This is package
                const getJSON = JSON.parse(data.resv_tx_package_details);
                const testIdsFromJson = getJSON.map(item => item.fk_test_id);
                packIds = testIdsFromJson;
            }
            
            data.test_id_from_test = testIds;
            data.test_id_from_pack = packIds;
            data.already = "N";
            newArr.push(data);
        }
    })

    let mergedDataMap = {};

    newArr.forEach(entry => {
        const patientId = entry.resv_tx_patient_id;
        const mergedArray = [
            ...entry.test_id_from_test,
            ...entry.test_id_from_pack
        ].filter(id => id !== null);

        if (!mergedDataMap[patientId]) {
            mergedDataMap[patientId] = mergedArray;
        } else {
            mergedDataMap[patientId] = [
                ...new Set([...mergedDataMap[patientId], ...mergedArray])
            ];
        }
    });

    // Update each entry with the merged data
    const updatedData = newArr.map(entry => {
        const patientId = entry.resv_tx_patient_id;
        const mergedArray = mergedDataMap[patientId];

        return {
            ...entry,
            final_test_pack_ids: mergedArray
        };
    });

    // console.log("updatedData", updatedData);
    return updatedData;
  }

  // Get all test_ids where name is "Test 3"
  const getTestIdsWithName = (array, pid) => {
    return array
      .filter(item => item.resv_tx_patient_id === pid)
      .map(item => item.resv_tx_test_id);
  }

  const hasTestId = (array, patient_id) => {
    return array.some(item => item.resv_tx_patient_id === patient_id);
  }

  const handleRefundOne = (r_res) => {
    let ref_data = {
      reservation_id: r_res.resv_tx_resv_id,
      test_id: r_res.resv_tx_test_id,
      package_id: r_res.resv_tx_package_id,
      price: r_res.resv_tx_price,
      patient_id: r_res.resv_tx_patient_id,
      patient_fname: r_res.resv_tx_patient_fname_en,
    };

    ReservationServices.refundOne(ref_data)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Refunded Successfully!");
          getAllUserResList();
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
      });
  };

  const checkRefundBtnDisable = (r_res) => {
    if (
      refundLogs && refundLogs.filter(
        (el) =>
          el.reservation_id == r_res.resv_tx_resv_id &&
          el.test_id == r_res.resv_tx_test_id &&
          el.package_id == r_res.resv_tx_package_id &&
          el.price == r_res.resv_tx_price &&
          el.patient_id == r_res.resv_tx_patient_id &&
          el.patient_fname == r_res.resv_tx_patient_fname_en
      ).length > 0
    ) {
      return true;
    }
    return false;
  };


  return (
    <>
      <div>
        <div className="px-5 py-5">
          <Breadcrumb pages={pages} />
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          >
            <span className="w-5 mr-2">
              <ArrowUturnLeftIcon size={18} />
            </span>{" "}
            Back
          </button>
          <div className="sales-booking-head">
            <div className="booking-confirm-main flex justify-between items-center">
              <h1 className="text-xl font-semibold text-gray-900">
                Reservation No. {reservationID} -{" "}
                <span
                  className={`text-base confirm-text ${
                    reservation_status_colors[
                      reservationData?.getAllReservation
                        ?.m_reservation_status_relation?.res_status_name_en
                    ] == undefined
                      ? "text-gray-500"
                      : reservation_status_colors[
                          reservationData?.getAllReservation
                            ?.m_reservation_status_relation?.res_status_name_en
                        ]
                  }`}
                >
                  {reservationData?.getAllReservation
                    ?.m_reservation_status_relation?.res_status_name_en ??
                    "N/A"}
                </span>
              </h1>
              <div>
                <div className="attachments-btn-main my-4 text-right">
                  <button
                    onClick={() => {
                      getAllReservationAttachmentList();
                      setViewAttachFileModal(true);
                    }}
                    className="mr-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    View Attachments
                  </button>
                  {renderSampleModel()}
                  <button
                    onClick={() => {
                      setAttachFileModal(true);
                      setProgress(0);
                    }}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    Add Attachments
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-5 pb-5">
          <div className="sales-booking-head">
            <div className="grid grid-flow-row-dense gap-4 grid-cols-5 ...">
              <div className="col-span-3">
                <div className="booking-user-details flex justify-between">
                  <div>
                    <div className="font-semibold mb-2">
                      {userDetails?.user_first_name
                        ? userDetails?.user_first_name
                        : userDetails?.patient_first_name}
                    </div>
                    <div className="font-semibold text-sm mb-2 text-gray-600">
                      {!OCParentDetails && (
                        <div className="flex items-center">
                          <div className="flex items-center booking-key-data">
                            <CalendarIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                            <p className="ml-2">
                              <span className="text-gray-400">
                                Date of Birth :
                              </span>
                            </p>
                          </div>
                          <div>
                            <p>
                              {userDetails?.user_dob
                                ? moment(
                                    convertToAnotherTimezone(
                                      userDetails?.user_dob
                                    )
                                  ).format("DD-MM-YYYY")
                                : moment(
                                    convertToAnotherTimezone(
                                      userDetails?.patient_dob
                                    )
                                  ).format("DD-MM-YYYY")}
                            </p>
                          </div>
                        </div>
                      )}

                      {!OCParentDetails && (
                        <div className="flex items-center">
                          <div className="flex items-center booking-key-data">
                            <CalendarDaysIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                            <p className="ml-2">
                              <span className="text-gray-400">
                                Booking Type :
                              </span>
                            </p>
                          </div>
                          <div>
                            <p>
                              {reservationData?.getAllReservation
                                ?.booking_type + " Booking"}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="text-gray-600 text-sm leading-6 font-semibold">
                      <div className="flex items-center">
                        <div className="flex items-center booking-key-data">
                          <BuildingOfficeIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          <p className="ml-2">
                            <span className="text-gray-400">Lab :</span>{" "}
                          </p>
                        </div>
                        <div>
                          <p>
                            {
                              reservationData?.getAllReservation
                                ?.collection_loc_relation?.loc_name_en
                            }
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <div className="flex items-center booking-key-data">
                          <ClockIcon className="h-5 w-5" aria-hidden="true" />
                          <p className="ml-2">
                            <span className="text-gray-400">Time :</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            {reservationData?.getAllReservation
                              ?.booking_type === "Home"
                              ? moment(
                                  convertToAnotherTimezone(
                                    reservationData?.getAllReservation
                                      ?.date_time
                                  )
                                ).format("DD-MM-YYYY, h:mm:ss a")
                              : moment(
                                  convertToAnotherTimezone(
                                    reservationData?.getAllReservation
                                      ?.reservation_created_at
                                  )
                                ).format("DD-MM-YYYY, h:mm:ss a")}
                          </p>
                        </div>
                      </div>

                      {/* Add Address Here with Modal */}

                      <div className="flex items-center">
                        <div className="flex items-center booking-key-data">
                          <PhoneIcon className="h-5 w-5" aria-hidden="true" />
                          <p className="ml-2">
                            <span className="text-gray-400">Mobile :</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            {" "}
                            {userDetails?.user_mobile
                              ? userDetails?.user_mobile
                              : userDetails?.patient_mobile}
                          </p>
                        </div>
                      </div>
                      {OCParentDetails && !isOcButNotCredit &&
                        <div className="flex items-center">
                        <div className="flex items-center booking-key-data">
                          <MoneyRounded className="h-5 w-5" aria-hidden="true" />
                          <p className="ml-2">
                            <span className="text-gray-400">Payment Status :</span>
                          </p>
                        </div>
                        <div>
                            <p>
                              {
                                paymentDetailsArr.length === 0 ? "Unpaid" : (
                                  (paymentDetailsArr.length === 1 && (moment().diff(moment(paymentDetailsArr[0].payment_created_at), 'days')) <= 90) ? "Credit Payment Pending" : (
                                    (paymentDetailsArr.length === 1 && (moment().diff(moment(paymentDetailsArr[0].payment_created_at), 'days')) > 90)) ? "Credit Payment Overdue" : (
                                    (paymentDetailsArr.length === 2 && moment(paymentDetailsArr[1]?.payment_data?.timestamp).diff(moment(paymentDetailsArr[0]?.payment_created_at), 'days') <= 90) ? "Credit Paid" : "Credit Overdue Paid"
                                  ))
                              }
                            </p>
                        </div>
                      </div>
                      }
                      {!OCParentDetails && (
                        <div className="flex items-center">
                          <div className="flex items-center booking-key-data">
                            <MapPinIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                            <p className="ml-2">
                              <span className="text-gray-400">
                                Nationality :
                              </span>
                            </p>
                          </div>
                          <div>
                            <p>
                              {userDetails?.country_name
                                ? userDetails?.country_name
                                : userDetails?.m_country_relation?.country_name}
                            </p>
                          </div>
                        </div>
                      )}

                      {!OCParentDetails && (
                        <div className="flex items-center">
                          <div className="flex items-center booking-key-data">
                            <MapPinIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                            <p className="ml-2">
                              <span className="text-gray-400">Gender :</span>
                            </p>
                          </div>
                          <div>
                            <p>
                              {userDetails?.gender_name_en
                                ? userDetails?.gender_name_en
                                : userDetails?.m_gender_relation
                                    ?.gender_name_en}
                            </p>
                          </div>
                        </div>
                      )}

                      {!OCParentDetails && (
                        <div className="flex items-center">
                          <div className="flex items-center booking-key-data">
                            <MapPinIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                            <p className="ml-2">
                              <span className="text-gray-400">MRN :</span>
                            </p>
                          </div>

                          <div>
                            <p>
                              {Array.isArray(
                                reservationData?.getAllReservation
                                  ?.parentOfPatient
                              ) &&
                              reservationData?.getAllReservation
                                ?.parentOfPatient[0]?.patient_report_mrn
                                ? reservationData?.getAllReservation
                                    ?.parentOfPatient[0]?.patient_report_mrn
                                : null}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="booking-user-details flex justify-between">
                  <div>
                    <div className="text-gray-600 text-sm leading-6 font-semibold">
                      {reservationHomeAddress ? (
                        <>
                          <div className="flex items-center">
                            <div className="flex items-center booking-key-data">
                              <MapPinIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />

                              <p className="ml-2">
                                <span className="text-gray-400">
                                  Building No.
                                </span>
                              </p>
                            </div>
                            <div>
                              <p>{reservationHomeAddress.ua_building}</p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="flex items-center booking-key-data">
                              <MapPinIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />

                              <p className="ml-2">
                                <span className="text-gray-400">Country</span>
                              </p>
                            </div>
                            <div>
                              <p>{reservationHomeAddress.ua_country}</p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="flex items-center booking-key-data">
                              <MapPinIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              <p className="ml-2">
                                <span className="text-gray-400">State</span>
                              </p>
                            </div>
                            <div>
                              <p>{reservationHomeAddress.ua_state}</p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="flex items-center booking-key-data">
                              <MapPinIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              <p className="ml-2">
                                <span className="text-gray-400">City</span>
                              </p>
                            </div>
                            <div>
                              <p>{reservationHomeAddress.ua_city}</p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="flex items-center booking-key-data">
                              <MapPinIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              <p className="ml-2">
                                <span className="text-gray-400">Street</span>
                              </p>
                            </div>
                            <div>
                              <p>{reservationHomeAddress.ua_street}</p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="flex items-center booking-key-data">
                              <MapPinIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />

                              <p className="ml-2">
                                <span className="text-gray-400">
                                  Postal Code
                                </span>
                              </p>
                            </div>
                            <div>
                              <p>{reservationHomeAddress.ua_postalCode}</p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="flex items-center booking-key-data">
                              <BuildingOfficeIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              <p className="ml-2">
                                <span className="text-gray-400">Recipient</span>
                              </p>
                            </div>
                            <div>
                              <p>{reservationHomeAddress.ua_recipient}</p>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <div className="flex items-center booking-key-data">
                              <BuildingOfficeIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                              <p className="ml-2">
                                <span className="text-gray-400">
                                  Neighborhood
                                </span>
                              </p>
                            </div>
                            <div>
                              <p>{reservationHomeAddress.ua_neighborhood}</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="flex items-center">
                        <div className="flex items-center booking-key-data">
                          <ClockIcon className="h-5 w-5" aria-hidden="true" />
                          <p className="ml-2">
                            <span className="text-gray-400">Time :</span>
                          </p>
                        </div>
                        <div>
                          <p>
                            {reservationData?.getAllReservation
                              ?.booking_type === "Home"
                              ? moment(
                                  convertToAnotherTimezone(
                                    reservationData?.getAllReservation
                                      ?.date_time
                                  )
                                ).format("DD-MM-YYYY, h:mm:ss a")
                              : moment(
                                  convertToAnotherTimezone(
                                    reservationData?.getAllReservation
                                      ?.reservation_created_at
                                  )
                                ).format("DD-MM-YYYY, h:mm:ss a")}
                          </p>
                        </div>
                      </div>

                      {/* Lab technician DD */}
                      {reservationData?.getAllReservation
                        ?.is_payment_completed &&
                      reservationData?.getAllReservation?.booking_type ==
                        "Home" ? (
                        <div className="flex items-center">
                          <div className="flex items-center booking-key-data">
                            <ClockIcon className="h-5 w-5" aria-hidden="true" />
                            <p className="ml-2">
                              <span className="text-gray-400">
                                Lab Technician
                              </span>
                            </p>
                          </div>
                          <div>
                            {labTechnicianList &&
                            labTechnicianList.length > 0 ? (
                              <div className="mt-6">
                                <p className="font-semibold text-sm mb-2 text-gray-600">
                                  Assign Lab Technician of LOCATION
                                </p>
                                <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                  <Select
                                    name="user_id"
                                    id="user_id"
                                    defaultValue={
                                      selectedLabTech ? selectedLabTech : null
                                    }
                                    // value={selectedLabTech}
                                    onChange={(e) => handleLabTechChange(e)}
                                    options={labTechnicianList}
                                    className="basic-single-select"
                                    classNamePrefix="Select PriceList"
                                  />

                                  {/* <select
                                              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                              name="user_id"
                                              id="user_id"
                                              onChange={(e) => {
                                                  handleLabTechChange(e)
                                              }}
                                          >
                                              <option> Select Technician </option>
                                              {labTechnicianList.map((user, i) => (
                                                  <option
                                                      selected={
                                                        user.user_id ==
                                                              selectedLabTech
                                                              ? "selected"
                                                              : ""
                                                      }
                                                      key={i}
                                                      value={user.user_id}
                                                  >
                                                      {user.user_first_name + ' ' + user.user_last_name}
                                                  </option>
                                              ))}
                                          </select> */}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4 grid grid-flow-row-dense gap-4 grid-cols-10 ...">
              <div className="col-span-2">
                <div className="booking-method-details text-sm">
                  {/* <div className="booking-method-icon">
                    <img className="h-10 w-10" src={PaymentIcon} alt="" />
                  </div> */}
                  <div className="booking-method-text ml-4 font-semibold">
                    <p className="text-gray-400">Payment Method : </p>
                    <p>
                      {
                        reservationData?.getAllReservation
                          ?.getAllReservationPayment?.m_payment_mode_relation
                          ?.pay_mode_name_en
                      }{" "}
                      On Delivery
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-span-2">
                <div className="booking-method-details text-sm">
                  {/* <div className="booking-method-icon">
                    <img className="h-10 w-10" src={PaymentIcon} alt="" />
                  </div> */}
                  <div className="booking-method-text ml-4 font-semibold">
                    <p className="text-gray-400">Discount Amount : </p>
                    <p>
                      {parseFloat(
                        reservationData?.getAllReservation
                          ?.rev_discounted_amount
                          ? reservationData?.getAllReservation
                              ?.rev_discounted_amount
                          : 0
                      )}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-span-2">
                <div className="booking-method-details text-sm">
                  {/* <div className="booking-method-icon">
                    <img className="h-10 w-10" src={InvoiceIcon} alt="" />
                  </div> */}
                  <div className="booking-method-text ml-4 font-semibold">
                    <p className="text-gray-400">Paid Amount :</p>
                    <p>
                      {parseFloat(
                        reservationData?.getAllReservation?.rev_payable_amount
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>

              {!reservationData?.getAllReservation?.is_payment_completed ? (
                <div className="col-span-2">
                  <div className="booking-method-details text-sm">
                    <div className="booking-method-text ml-4 font-semibold">
                      <p className="text-gray-400">Payable Amount :</p>
                      <p>
                        {finalAmt ? finalAmt : 0}
                        {/* {parseFloat(reservationData?.getAllReservation?.rev_amount ? reservationData?.getAllReservation?.rev_amount : 0)
                          + parseFloat(reservationData?.getAllReservation?.rev_vat_amount ? reservationData?.getAllReservation?.rev_vat_amount : 0)
                          - parseFloat(reservationData?.getAllReservation?.rev_total_discount ? reservationData?.getAllReservation?.rev_total_discount : 0)
                          - parseFloat(reservationData?.getAllReservation?.rev_discounted_amount ? reservationData?.getAllReservation?.rev_discounted_amount : 0)
                        } */}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {reservationData?.getAllReservation?.is_payment_completed ? (
                <div className="col-span-2">
                  <div className="booking-method-details text-sm">
                    {/* <div className="booking-method-icon">
                      <img className="h-10 w-10" src={CashIcon} alt="" />
                    </div> */}
                    <div className="booking-method-text ml-4 font-semibold">
                      <p className="text-gray-400">Cash Amount :</p>
                      <p>
                        {paymentDetailsObj?.payment_data?.cashAmt
                          ? paymentDetailsObj?.payment_data?.cashAmt
                          :"00.00"}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {reservationData?.getAllReservation?.is_payment_completed ? (
                <div className="col-span-2">
                  <div className="booking-method-details text-sm">
                    {/* <div className="booking-method-icon">
                      <img className="h-10 w-10" src={CreditIcon} alt="" />
                    </div> */}
                    <div className="booking-method-text ml-4 font-semibold">
                      <p className="text-gray-400">Card Amount :</p>
                      <p>
                      {paymentDetailsObj?.payment_data?.visaAmt
                          ? paymentDetailsObj?.payment_data?.visaAmt
                          :"00.00"}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}


              {reservationData?.getAllReservation?.is_payment_completed ? (
                <div className="col-span-2">
                  <div className="booking-method-details text-sm">
                    {/* <div className="booking-method-icon">
                      <img className="h-10 w-10" src={CreditIcon} alt="" />
                    </div> */}
                    <div className="booking-method-text ml-4 font-semibold">
                      <p className="text-gray-400">Bank Transfer :</p>
                      <p>
                      {paymentDetailsObj?.payment_data?.bankTransAmt
                          ? paymentDetailsObj?.payment_data?.bankTransAmt
                          :"00.00"}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}

              {reservationData?.getAllReservation?.is_payment_completed ? (
                <div className="col-span-2">
                  <div className="booking-method-details text-sm">
                    {/* <div className="booking-method-icon">
                      <img className="h-10 w-10" src={RefundIcon} alt="" />
                    </div> */}
                    <div className="booking-method-text ml-4 font-semibold">
                      <p className="text-gray-400">Refund Amount :</p>
                      <p>
                        {reservationData?.getAllReservation?.is_refunded
                          ? reservationData?.getAllReservation
                              ?.rev_payable_amount
                            ? reservationData?.getAllReservation
                                ?.rev_payable_amount
                            : "00.00"
                          : "00.00"}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="mt-3">
              <div className="flex justify-between mt-3">
                <div className="flex items-center gap-5">
                  Comments:
                  <input
                    disabled
                    type="text"
                    value={
                      reservationData?.getAllReservation?.rev_comments
                        ? reservationData?.getAllReservation?.rev_comments
                        : "N/A"
                    }
                    className="block w-min max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  <button
                    onClick={() => setCommentsModal(true)}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-xs font-medium text-white shadow-xs hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    Add Comment
                  </button>
                </div>
              </div>
              {/* <span
                  className="text-orange-600 cursor-pointer"
                  onClick={() => setCommentsModal(true)}
                >
                  {((reservationData?.getAllReservation?.rev_comments) ? (reservationData?.getAllReservation?.rev_comments) : 'Empty')}
                </span> */}
            </div>

            <div>
              <div className="flex">
                <Switch
                  id="urgentAll"
                  // disabled={urgent}
                  checked={urgent}
                  onChange={(e) => onUrgentMark(e)}
                  className={urgentNames(
                    urgent ? "bg-cyan-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-offset-2"
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={urgentNames(
                      urgent ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <label
                  htmlFor="patient-name"
                  className="block text-sm ml-3 font-medium text-gray-700 sm:mt-px"
                >
                  Urgent
                </label>
              </div>
            </div>

            <div className="mt-4">
              <h2 className="text-xl font-semibold">Test List</h2>
              <div className="flex justify-between mt-3">
                <div className="flex">
                  <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mr-2">
                    Search :
                  </label>
                  <input
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    type="text"
                    name="patient-name"
                    id="patient-name"
                    autoComplete="patient-name"
                    className="block w-min max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
                <div>
                  <button
                    onClick={() => handleExport("copy")}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mr-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    Copy
                  </button>
                  <button
                    onClick={() => handleExport("csv")}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    CSV
                  </button>

                  <button
                    onClick={() => handleExport("xlsx")}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    Excel
                  </button>
                  <button
                    onClick={() => handlePdfGenerate()}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    PDF
                  </button>
                  <button
                    onClick={() => handlePrint()}
                    className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                  >
                    Print
                  </button>
                </div>
              </div>
            </div>

            <div>{/* Space For Table */}</div>
            {!reservationData?.getAllReservation?.is_refunded && (
              <div>
                <div className="text-center">
                  <h2 className="text-xl font-semibold">
                    ------ Actions ------
                  </h2>
                  <div className="mt-3">
                    {reservationData?.getAllReservation?.questionnaire?.length >
                    0 ? (
                      <button
                        onClick={() => {
                          viewQuestionar(
                            reservationData?.getAllReservation?.questionnaire
                          );
                        }}
                        className={`inline-flex justify-center rounded-md border border-transparent bg-cyan-500 text-white hover:bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                      >
                        View Questionnaire
                      </button>
                    ) : (
                      <></>
                    )}
                    <button
                      onClick={() => {
                        setDisPer(
                          reservationData?.getAllReservation?.rev_discount_per
                        );
                        calculateDisAmount(
                          reservationData?.getAllReservation?.rev_discount_per
                        );
                        setDiscountAll(true);
                      }}
                      disabled={
                        reservationData?.getAllReservation
                          ?.is_payment_completed || checkCouponAndShowDiscount()
                      }
                      className={`inline-flex justify-center rounded-md border border-transparent ${
                        reservationData?.getAllReservation
                          ?.is_payment_completed || checkCouponAndShowDiscount()
                          ? "bg-gray-300 text-gray-400"
                          : "bg-yellow-500 text-white hover:bg-yellow-600"
                      } py-2 px-4 mx-1 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                    >
                      Discount
                    </button>
                    {!isCreditPaymentShow ? (
                    <button
                      onClick={() => {
                        setPayAll(true);
                        CalculatePayment();
                        setCashAmt(0);
                        setVisaAmt(0);
                        setBankTransAmt(0);
                      }}
                      disabled={
                        reservationData?.getAllReservation?.is_payment_completed
                      }
                      className={`inline-flex justify-center rounded-md border border-transparent ${
                        reservationData?.getAllReservation?.is_payment_completed
                          ? "bg-gray-300 text-gray-400"
                          : "bg-cyan-600 text-white hover:bg-cyan-700"
                      } py-2 px-4 mx-1 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                    >
                      Pay All
                    </button>
                     ) :(
                      <button
                        onClick={() => {
                          setPayAll(true);
                          CalculatePayment();
                          setCashAmt(0);
                          setVisaAmt(0);
                          setBankTransAmt(0);
                        }}
                        disabled={
                          setPaymentDetailsArr.length > 1
                        }
                        className={`inline-flex justify-center rounded-md border border-transparent ${
                          setPaymentDetailsArr.length > 1
                            ? "bg-gray-300 text-gray-400"
                            : "bg-cyan-600 text-white hover:bg-cyan-700"
                        } py-2 px-4 mx-1 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                      >
                        Make Credit Payment
                      </button>
                    )}
                    {reservationData?.getAllReservation
                      ?.is_payment_completed && OCParentDetails && !reservationData?.getAllReservation?.credit_invoice_url ? (
                      <button
                        disabled={
                          reservationData?.getAllReservation?.credit_invoice_url ||
                          generateCreditInvoiceLoading
                            ? true
                            : false
                        }
                        className={`inline-flex justify-center rounded-md border border-transparent py-2 px-4 mx-1 text-sm font-medium shadow-sm focus:ring-offset-2 ${
                          reservationData?.getAllReservation?.credit_invoice_url ||
                          generateCreditInvoiceLoading
                            ? "bg-gray-300 text-gray-400"
                            : "text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 "
                        }`}
                        // className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        onClick={() => {
                          setGenerateCreditInvoiceLoading(true);
                          generateInvoiceReport(reservationID);
                        }}
                      >
                        Generate Credit Invoice{" "}
                        {generateCreditInvoiceLoading ? (
                          <FaSpinner icon="spinner" className="spinner ml-2" />
                        ) : null}
                      </button>
                    ) : (
                      <></>
                    )}
                    {reservationData?.getAllReservation?.credit_invoice_url && OCParentDetails &&
                    reservationData?.getAllReservation?.is_payment_completed ? (
                      <a
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        href={`${reservationData?.getAllReservation?.credit_invoice_url}`}
                        target="_blank"
                      >
                        Credit Invoice PDF
                      </a>
                    ) : (
                      <></>
                    )}
                    {reservationData?.getAllReservation
                      ?.is_payment_completed && !reservationData?.getAllReservation?.invoice_url ? (
                      <button
                        disabled={
                          reservationData?.getAllReservation?.invoice_url || isCreditPaymentShow ||
                          generateInvoiceLoading
                            ? true
                            : false
                        }
                        className={`inline-flex justify-center rounded-md border border-transparent py-2 px-4 mx-1 text-sm font-medium shadow-sm focus:ring-offset-2 ${
                          reservationData?.getAllReservation?.invoice_url || isCreditPaymentShow ||
                          generateInvoiceLoading
                            ? "bg-gray-300 text-gray-400"
                            : "text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 "
                        }`}
                        // className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        onClick={() => {
                          setGenerateInvoiceLoading(true);
                          generateInvoiceReport(reservationID);
                        }}
                      >
                        Generate Invoice{" "}
                        {generateInvoiceLoading ? (
                          <FaSpinner icon="spinner" className="spinner ml-2" />
                        ) : null}
                      </button>
                    ) : (
                      <></>
                    )}
                    {reservationData?.getAllReservation?.invoice_url &&
                    reservationData?.getAllReservation?.is_payment_completed ? (
                      <a
                        className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        href={`${reservationData?.getAllReservation?.invoice_url}`}
                        target="_blank"
                      >
                        Invoice PDF
                      </a>
                    ) : (
                      <></>
                    )}
                    {reservationData?.getAllReservation?.is_payment_completed &&
                    sampleList?.length > 0 ? (
                      <button
                        disabled={
                          collectSampleButtonClickLoad ||
                          collectbuttonFlag ||
                          remainingTimeGenerateInvoice ||
                          isAllSampleAccepted
                            ? true
                            : false
                        }
                        onClick={handleSampleCollection}
                        className={`inline-flex justify-center rounded-md border border-transparent py-2 px-4 mx-1 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                          collectSampleButtonClickLoad ||
                          collectbuttonFlag ||
                          remainingTimeGenerateInvoice ||
                          isAllSampleAccepted
                            ? "bg-gray-300 text-gray-400"
                            : "bg-cyan-600 hover:bg-cyan-700 text-white focus:ring-cyan-500"
                        }`}
                      >
                        Collect All Samples {remainingTimeGenerateInvoice}
                      </button>
                    ) : (
                      <></>
                    )}

                    {/* <button
                      onClick={(e) => generateBarcode(e)}
                      className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                      Print Barcodes
                    </button>{" "} */}
                    {/* <button className="inline-flex justify-center rounded-md border border-transparent bg-yellow-500 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                      Incentives
                    </button> */}
                    <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                      {/* {barcodeFlag ? (<Barcode displayValue={false} value={JSON.stringify({ reservation_id: reservationData?.getAllReservation?.reservation_id, booking_type: reservationData?.getAllReservation?.booking_type, rev_payable_amount: reservationData?.getAllReservation?.rev_payable_amount })} width={200} height={100} />) : (<></>)} */}
                    </div>
                  </div>
                  {reservationData?.getAllReservation?.is_payment_completed ? (
                    <>
                      <div className="mt-2">
                        {/* <div className="flex justify-between mt-3"> */}
                        {/* <div className="flex"> */}
                        {sampleList?.length > 0 ? (
                          <></>
                        ) : (
                          <button
                            disabled={generateSampleLoading ? true : false}
                            onClick={() => generateSample()}
                            className={`inline-flex justify-center rounded-md border border-transparent py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${
                              generateSampleLoading
                                ? "bg-gray-300 text-gray-400 hover:bg-gray-600"
                                : "bg-yellow-500"
                            }`}
                          >
                            Sample Generation{" "}
                            {generateSampleLoading ? (
                              <FaSpinner
                                icon="spinner"
                                className="spinner ml-2"
                              />
                            ) : null}
                          </button>
                        )}
                        <button
                          onClick={() => {
                            setSelectedRows([]);
                            viewSample();
                            setViewSample(true);
                          }}
                          className="inline-flex justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        >
                          View Samples
                        </button>

                        {/* <button
                          onClick={() => setCancelAll(true)}
                          className="inline-flex justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                        >
                          Cancel
                        </button> */}
                        {!reservationData?.getAllReservation?.is_refunded ? (
                          <button
                            onClick={() => setRefundAll(true)}
                            className="inline-flex justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            Refund
                          </button>
                        ) : null}
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            )}
            <div
              ref={printRef}
              className="rounded-t-xl overflow-hidden bg-gradient-to-r from-grey-50 to-cyan-100 p-10"
            >
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden border rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Patient ID
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Patient Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Test/Pack ID
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Test/Package
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Mark Urgent
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Actions
                          </th>
                          {/* <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Action</th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Cancel</th> */}
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {filteredReservationData?.length > 0 ? (
                          filteredReservationData?.map((r_res, indx) => {
                            const index = filteredReservationData.findIndex(
                              (obj) =>
                                obj.resv_tx_patient_id ===
                                  r_res.resv_tx_patient_id &&
                                obj.resv_tx_resv_id === r_res.resv_tx_resv_id
                            );

                            return (
                              <tr>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {r_res?.resv_tx_patient_id}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 flex">
                                  {r_res?.resv_tx_patient_fname_en}
                                  <PencilIcon
                                    className="h-4 w-4 ml-2 text-cyan-600 cursor-pointer"
                                    aria-hidden="true"
                                    onClick={() => {
                                      setSelectedPatient(
                                        r_res?.resv_tx_patient_id
                                      );
                                      setParentId(
                                        r_res?.resv_tx_patient_parent_id
                                      );
                                      if (
                                        r_res?.resv_tx_patient_relationsip ==
                                        "Self"
                                      ) {
                                        setUserType("User");
                                      } else {
                                        setUserType("Patient");
                                      }
                                      setOpenPatient(true);
                                    }}
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {r_res?.resv_tx_test_id
                                    ? r_res?.resv_tx_test_id
                                    : r_res?.resv_tx_package_id}
                                </td>
                                <td className="px-3 py-4 text-sm text-gray-500">
                                  {r_res?.resv_tx_test_name_en
                                    ? r_res?.resv_tx_test_name_en?.toUpperCase()
                                    : r_res?.resv_tx_package_name_en?.toUpperCase() +
                                      " (" +
                                      r_res?.resv_tx_name?.toUpperCase() +
                                      ")"}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {r_res?.resv_is_package === "Y"
                                    ? "Package"
                                    : "Test"}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {r_res?.resv_tx_price
                                    ? r_res?.resv_tx_price
                                    : 0}
                                </td>
                                {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {(reservationData?.getAllReservation?.is_postponed)}
                              </td> */}
                                {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {(reservationData?.getAllReservation?.is_urgent)}
                                <button
                                  onClick={() => onCancelTP(r_res)}
                                  className="inline-flex justify-center rounded-md border border-transparent bg-red-500 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                                  Cancel
                                </button>
                              </td> */}
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <div>
                                    <div class="flex">
                                      <ReactSwitch
                                        id="urgentSingle"
                                        // disabled={true}
                                        checked={r_res?.resv_tx_urgent_status}
                                        onChange={(e) => {
                                          onSingleUrgentMark(
                                            r_res?.resv_tx_id,
                                            e
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>
                                {/* <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {r_res.sample_report_link && index === r_key ? (
                                  <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                                    <a href={`${r_res.sample_report_link}`} target="_blank">Result PDF</a>

                                  </span>) : (<></>)}
                              </td>
                              */}
                                <td>
                                  {r_res?.already === "N" ? (
                                    <button
                                      onClick={() => {
                                        toggleModal(r_res);
                                      }}
                                      className="mr-3 justify-center rounded-md border border-transparent bg-cyan-600 py-1 px-1 text-xs text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                    >
                                      Add Result
                                    </button>
                                  ) : null}
                                  {r_res.sample_report_link ? (
                                    <button
                                      onClick={() => {
                                        console.log("r_res", r_res);
                                        handleReportSmsModelOpen(r_res);
                                      }}
                                      className="mr-3 justify-center rounded-md border border-transparent bg-cyan-600 py-1 px-1 text-xs text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                    >
                                      Send Report
                                    </button>
                                  ) : null}
                                  {r_res?.sample_report_link ? (
                                    <a
                                      className="mr-3 justify-center rounded-md border border-transparent bg-cyan-600 py-1 px-1 text-xs text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                      href={`${r_res?.sample_report_link}`}
                                      target="_blank"
                                    >
                                      Report PDF
                                    </a>
                                  ) : (
                                    <></>
                                  )}

                                  {reservationData?.getAllReservation
                                    ?.is_payment_completed ? (
                                    <button
                                      onClick={() => {
                                        handleRefundOne(r_res);
                                      }}
                                      disabled={checkRefundBtnDisable(r_res)}
                                      className={`mr-3 justify-center rounded-md border border-transparent  py-1 px-1 text-xs shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${
                                        checkRefundBtnDisable(r_res)
                                          ? "bg-gray-300 text-gray-400"
                                          : "bg-red-600 text-white hover:bg-red-700"
                                      }`}
                                    >
                                      {checkRefundBtnDisable(r_res)
                                        ? "Refunded"
                                        : "Refund"}
                                    </button>
                                  ) : null}
                                </td>
                                <td
                                  className={`border border-grey-500 px-4 py-2 font-medium 
                                   ${
                                     reservation_status_colors[
                                       reservationData?.getAllReservation
                                         ?.m_reservation_status_relation
                                         ?.res_status_name_en
                                     ] == undefined
                                       ? "text-gray-500"
                                       : reservation_status_colors[
                                           reservationData?.getAllReservation
                                             ?.m_reservation_status_relation
                                             ?.res_status_name_en
                                         ]
                                   }`}
                                >
                                  {reservationData?.getAllReservation
                                    ?.m_reservation_status_relation
                                    ?.res_status_name_en ?? "N/A"}
                                  {/* 22-07-2023 */}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan={11}
                              className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                            >
                              <center>Data Not Found</center>
                            </td>
                          </tr>
                        )}

                        {/* {(reservationData?.reservationDetails?.length > 0) ? (reservationData?.reservationDetails?.map((r_res, r_key) => (
                    <tr>
                      <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                        {r_res?.m_patient_relation?.patient_first_name} + ({r_res?.m_patient_relation?.patient_id})
                      </td>
                      <td colSpan={10} className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                        {(r_res?.m_patient_relation?.testPackList?.length > 0) ? (r_res?.m_patient_relation?.testPackList?.map((r_test, rt_key) => (
                          <tr>
                            <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                              {(r_test.test_id)}
                            </td>
                            <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                              {(r_test.test_name_en)}
                            </td>
                            <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                              {(r_test.Type)}
                            </td>
                            <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                              {(r_test.hos_price)}
                            </td>
                            <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                              {(reservationData?.getAllReservation?.is_postponed)}
                            </td>
                            <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                              {(reservationData?.getAllReservation?.is_urgent)}
                            </td>
                          </tr>
                        ))) : null}
                      </td>
                    </tr>
                  ))) : (<tr>
                    <td colSpan={6} className="border border-grey-500 px-4 py-2 text-grey-600 font-medium">
                      <center>Data Not Found</center>
                    </td>
                  </tr>)
                  } */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Transition.Root show={payAll} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            initialFocus={cancelButtonRef}
            onClose={setPayAll}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6">
                    <div>
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                          <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                            <div>
                              <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Order Payment
                              </h3>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                              <p className="font-semibold">
                                Amount : {finalAmt}
                                {/* {parseFloat(reservationData?.getAllReservation?.rev_amount ? reservationData?.getAllReservation?.rev_amount : 0)
                                + parseFloat(reservationData?.getAllReservation?.rev_vat_amount ? reservationData?.getAllReservation?.rev_vat_amount : 0)
                                - parseFloat(reservationData?.getAllReservation?.rev_total_discount ? reservationData?.getAllReservation?.rev_total_discount : 0)
                                - parseFloat(reservationData?.getAllReservation?.rev_discounted_amount ? reservationData?.getAllReservation?.rev_discounted_amount : 0)
                              }  */}
                                SAR
                              </p>

                              <select
                                value={payMode}
                                onChange={(e) => {
                                  setPayMode(e.target.value);
                                }}
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                name="fk_pay_mode_id"
                                id="fk_pay_mode_id"
                              >
                                <option>Select Payment Mode</option>
                                {OCParentDetails && !isCreditPaymentShow && paymentModeList.map((paymentMode, i) => (
                                  <option
                                    key={i}
                                    value={paymentMode.pay_mode_id}
                                  >
                                    {paymentMode.pay_mode_name_en}
                                  </option>
                                ))}
                                {OCParentDetails && isCreditPaymentShow && paymentModeList.filter(v => v.pay_mode_id != 5).map((paymentMode, i) => (
                                  <option
                                    key={i}
                                    value={paymentMode.pay_mode_id}
                                  >
                                    {paymentMode.pay_mode_name_en}
                                  </option>
                                ))}
                                {!OCParentDetails && paymentModeList.filter(v => v.pay_mode_id != 5).map((paymentMode, i) => (
                                  <option
                                    key={i}
                                    value={paymentMode.pay_mode_id}
                                  >
                                    {paymentMode.pay_mode_name_en}
                                  </option>
                                ))}
                              </select>
                              {/* <select
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                name="price_type"
                                id="price_type"
                              >
                                <option>Select Type</option>
                                <option>Credit Card</option>
                                <option>Debit Card</option>
                                <option>Cash</option>
                              </select> */}

                              {payMode === "1" || payMode === "3" ? (
                                <p className="font-semibold">Cash</p>
                              ) : null}
                              {payMode === "1" || payMode === "3" ? (
                                <>
                                  <input
                                    value={cashAmt}
                                    onInput={(e) => {
                                      setCashAmt(e.target.value);
                                    }}
                                    id="cash_amount"
                                    name="cash_amount"
                                    type="number"
                                    autoComplete="off"
                                    placeholder="Please Enter Cash Amount"
                                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                  />
                                  {isCreditPaymentShow && (<>
                                  
                                  <p className="mt-4">Credit Pay Date</p>
                                  <input
                                    className={`block rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm `}
                                    type="datetime-local"
                                    name="credit_pay_date"
                                    id="credit_pay_date"
                                    value={creditPayDate}
                                    onChange={(e) => setCreditPayDate(e.target.value)}
                                    />
                                    </>)}
                                </>
                              ) : null}
                              {payMode === "2" || payMode === "3" ? (
                                <p className="font-semibold">Card</p>
                              ) : null}
                              {payMode === "2" || payMode === "3" ? (
                                <>
                                <input
                                  value={visaAmt}
                                  onInput={(e) => {
                                    setVisaAmt(e.target.value);
                                  }}
                                  id="visa_amount"
                                  name="visa_amount"
                                  type="number"
                                  autoComplete="off"
                                  placeholder="Please Enter Card Amount"
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                                  {isCreditPaymentShow && (<>
                                  
                                  <p className="mt-4">Credit Pay Date</p>
                                  <input
                                    className={`block rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 w-full sm:text-sm `}
                                    type="datetime-local"
                                    name="credit_pay_date"
                                    id="credit_pay_date"
                                    value={creditPayDate}
                                    onChange={(e) => setCreditPayDate(e.target.value)}
                                    />
                                    </>)}
                              </>
                              ) : null}
                              {payMode === "5" ? (
                                <input
                                  disabled
                                  value={creditAmt}
                                  onInput={(e) => {
                                    setCreditAmt(e.target.value);
                                  }}
                                  id="credit_amount"
                                  name="credit_amount"
                                  type="number"
                                  autoComplete="off"
                                  placeholder="Please Enter Credit Amount"
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                              ) : null}
                              {payMode === "4" ? (
                                <p className="font-semibold">Bank Transfer</p>
                              ) : null}
                              {payMode === "4" ? (
                                <input
                                  disabled
                                  value={bankTransAmt}
                                  onInput={(e) => {
                                    setBankTransAmt(e.target.value);
                                  }}
                                  id="bank_transfer_amount"
                                  name="bank_transfer_amount"
                                  type="number"
                                  autoComplete="off"
                                  placeholder="Please Enter Bank Transfer Amount"
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              onClick={() => setPayAll(false)}
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              disabled={cashAmt == 0 && visaAmt == 0 && creditAmt == 0 && bankTransAmt == 0}
                              className={`${
                                cashAmt == 0 && visaAmt == 0 && creditAmt == 0 && bankTransAmt == 0
                                  ? "bg-gray-500"
                                  : "focus:ring-cyan-500 bg-cyan-600 hover:bg-cyan-700"
                              } ml-3 inline-flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                              onClick={() => {
                                if(!isCreditPaymentShow) { 
                                  addPayment()
                                  console.log('normal payment')
                                } else {
                                  console.log('second credit payment')
                                  addPaymentCredit()
                                }
                              } 
                            }
                            >
                              Pay All
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* View Samples Modal - Receive, Reject, Print Barcode */}
        <Transition.Root show={viewSampleFlag} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            initialFocus={cancelButtonRef}
            onClose={setViewSample}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative p-8 bg-white w-full max-w-6xl mx-auto my-6 rounded-lg shadow-lg ">
                    {/* <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6"> */}
                    <div>
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                          <div className="space-y-6">
                            <div className="flex justify-between items-center">
                              {/* <div> */}
                              <h3 className="text-2xl font-bold">
                                Sample List
                              </h3>

                              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                {multiSampleDataForPrint.length > 0 &&
                                selectedRows?.length > 0 ? (
                                  <button
                                    disabled={
                                      multiSampleDataForPrint.length <= 0
                                    }
                                    onClick={() => {
                                      // handleReceiveSampleAll();
                                      // console.log("print multisamples clicked")
                                      printSampleBarCode();
                                    }}
                                    type="button"
                                    className={`inline-flex justify-center rounded-md border border-transparent bg-cyan-600  py-2 px-4 mx-1 text-xs font-medium text-white shadow-xs hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                                  >
                                    Print Barcodes
                                  </button>
                                ) : (
                                  <></>
                                )}

                                {selectedRows.length > 0 ? (
                                  <>
                                    <button
                                      disabled={
                                        selectedRows.length <= 0 ||
                                        remainingTimeSampleReceiveReject
                                      }
                                      onClick={(e) => {
                                        handleRejectSampleAll();
                                      }}
                                      type="button"
                                      className={`inline-flex justify-center rounded-md border border-transparen  py-2 px-4 mx-1 text-xs font-medium text-white shadow-xs focus:outline-none ${
                                        remainingTimeSampleReceiveReject
                                          ? "bg-gray-300 text-gray-400"
                                          : "bg-cyan-600 hover:bg-cyan-700 text-white focus:ring-cyan-500"
                                      }`}
                                    >
                                      Reject {remainingTimeSampleReceiveReject}
                                    </button>
                                    <button
                                      disabled={
                                        selectedRows.length <= 0 ||
                                        allSampleRecived ||
                                        remainingTimeSampleReceiveReject
                                      }
                                      onClick={() => {
                                        handleReceiveSampleAll();
                                      }}
                                      type="button"
                                      className={`inline-flex justify-center rounded-md border border-transparen  py-2 px-4 mx-1 text-xs font-medium text-white shadow-xs focus:outline-none ${
                                        allSampleRecived ||
                                        remainingTimeSampleReceiveReject
                                          ? "bg-gray-300 text-gray-400"
                                          : "bg-cyan-600 hover:bg-cyan-700 text-white focus:ring-cyan-500"
                                      }`}
                                    >
                                      Receive {remainingTimeSampleReceiveReject}
                                    </button>
                                    {showLocChangeDD &&
                                    locationList &&
                                    locationList.length > 0 ? (
                                      <select
                                        className="ml-1 mr-4 rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                        name="loc_id"
                                        id="loc_id"
                                        value={selectedLoc ? selectedLoc : ""}
                                        onChange={(e) => {
                                          handleLocChange(e);
                                        }}
                                      >
                                        <option> Select Location </option>
                                        {locationList.map((location, i) => (
                                          <option
                                            selected={
                                              location.loc_id === selectedLoc
                                                ? "selected"
                                                : ""
                                            }
                                            key={i}
                                            value={location.loc_id}
                                          >
                                            {location.loc_name_en}
                                          </option>
                                        ))}
                                      </select>
                                    ) : (
                                      <button
                                        disabled={selectedRows.length <= 0}
                                        onClick={() => {
                                          // handleReceiveSampleAll();
                                          // change show loc_change_dropdown
                                          setSelectedLoc("");
                                          setShowLocChangeDD(true);
                                        }}
                                        type="button"
                                        className={`inline-flex justify-center rounded-md border border-transparent bg-cyan-600  py-2 px-4 mx-1 text-xs font-medium text-white shadow-xs hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                                      >
                                        Change Location
                                      </button>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}

                                <button
                                  type="button"
                                  className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                  onClick={() => setViewSample(false)}
                                >
                                  <span className="sr-only">Close</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                              {/* <button
                                type="button"
                                className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2" onClick={() => handleSamplePrint()}>Print</button> */}
                              {/* <button
                                type="button"
                                className="text-gray-500 hover:text-gray-700 focus:outline-none" onClick={() => setViewSample(false)}>X</button> */}
                            </div>
                            <div className="px-5">
                              <div className="flow-root">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div
                                      className="overflow-hidden border rounded-lg"
                                      ref={printsampleRef}
                                    >
                                      <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                          <tr>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              <input
                                                type="checkbox"
                                                checked={
                                                  selectedRows.length ===
                                                  sampleList.length
                                                }
                                                onChange={toggleSelectAll}
                                              />
                                              SN
                                            </th>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              Sample ID
                                            </th>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              Paitent ID
                                            </th>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              Reservation ID
                                            </th>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              Test
                                            </th>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              Sample Type
                                            </th>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              Barcode
                                            </th>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              Date
                                            </th>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              Assigned Location
                                            </th>
                                            <th className="px-4 py-2 text-grey-600 text-xs">
                                              Action
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {sampleList?.length > 0 ? (
                                            sampleList?.map(
                                              (rs_sample, rs_key) => {
                                                const index =
                                                  sampleList.findIndex(
                                                    (obj) =>
                                                      obj.patient_id ===
                                                        rs_sample.patient_id &&
                                                      obj.reservation_id ===
                                                        rs_sample.reservation_id
                                                  );
                                                console.log(
                                                  "rs_sample",
                                                  rs_sample
                                                );
                                                return (
                                                  <tr>
                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                      <input
                                                        type="checkbox"
                                                        checked={selectedRows.includes(
                                                          rs_sample.sample_id
                                                        )}
                                                        onChange={() =>
                                                          toggleRowSelection(
                                                            rs_sample.sample_id,
                                                            rs_sample
                                                          )
                                                        }
                                                      />
                                                      {parseInt(rs_key + 1)}.
                                                    </td>
                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium  text-xs">
                                                      <Link
                                                        to={`../samplelist/${rs_sample.fk_sample_id}`}
                                                        className="inline-flex px-2 ml-3 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full"
                                                      >
                                                        {rs_sample.fk_sample_id}
                                                      </Link>
                                                      {/* <span>{rs_sample.fk_sample_id}</span> */}
                                                    </td>
                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                      <span>
                                                        {rs_sample.patient_id}
                                                      </span>
                                                      {/* {index == rs_key ? ( */}
                                                      {rs_sample.buttonFlag ? (
                                                        <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                                                          <button
                                                            type="button"
                                                            onClick={() => {
                                                              generateInvestigationReport(
                                                                sampleList,
                                                                rs_sample.patient_id,
                                                                rs_sample.fk_sample_id
                                                              );
                                                            }}
                                                            size="default"
                                                            variant="outlined"
                                                          >
                                                            <div className="flex gap-1 items-center">
                                                              Investigation{" "}
                                                              {isInvestigationIsloading.includes(
                                                                rs_sample.fk_sample_id
                                                              ) ? (
                                                                <FaSpinner
                                                                  icon="spinner"
                                                                  className="spinner ml-2"
                                                                />
                                                              ) : null}
                                                            </div>
                                                          </button>
                                                        </span>
                                                      ) : null}

                                                      {/* ) : (
                                                        <></>
                                                      )} */}
                                                    </td>
                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                      <span>
                                                        {
                                                          rs_sample.reservation_id
                                                        }
                                                      </span>
                                                    </td>
                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                      {rs_sample?.test_names}
                                                      {/* {rs_sample?.test_name_en} */}
                                                    </td>
                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                      {
                                                        rs_sample?.sample_type_name_en
                                                      }
                                                    </td>
                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                      <div className="space-y-6">
                                                        {/* {printSampleBarCodeFlag ? ( */}
                                                        <Barcode
                                                          height={60}
                                                          displayValue={false}
                                                          value={
                                                            rs_sample.fk_sample_id
                                                          }
                                                        />
                                                        {/* // ) : (<></>)} */}
                                                      </div>
                                                    </td>
                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                      {moment(
                                                        convertToAnotherTimezone(
                                                          rs_sample?.sample_created_at
                                                        )
                                                      ).format(
                                                        "DD-MM-YYYY, h:mm:ss a"
                                                      )}
                                                    </td>
                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                      {
                                                        rs_sample?.assigned_loc_name_en
                                                      }{" "}
                                                      ({rs_sample?.fk_loc_id})
                                                    </td>

                                                    <td className="border border-grey-500 px-4 py-2 text-grey-600 font-medium text-xs">
                                                      {/* <button
                                               onClick={() => handleSinglePrint()}
                                              className="inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 mx-1 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                                              Print
                                            </button> */}
                                                      <button
                                                        type="button"
                                                        className={`inline-flex justify-center rounded-md border border-transparent bg-cyan-600  py-2 px-4 mx-1 text-xs font-medium text-white shadow-xs hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                                                        onClick={(e) => {
                                                          setMultiSampleDataForPrint(
                                                            [rs_sample]
                                                          );
                                                          printSampleBarCode(
                                                            rs_sample,
                                                            e
                                                          );
                                                        }}
                                                      >
                                                        Print
                                                      </button>

                                                      {/* {(reservationData?.getAllReservation?.collectStatus == true) ? ( */}
                                                      <button
                                                        disabled={
                                                          rs_sample.fk_status_id ==
                                                            7 &&
                                                          !remainingTimeSampleReceiveReject
                                                            ? false
                                                            : true
                                                        }
                                                        onClick={(e) => {
                                                          handleRejectSample(
                                                            rs_sample.fk_sample_id
                                                          );
                                                        }}
                                                        type="button"
                                                        className={`inline-flex mt-1 justify-center rounded-md border border-transparent ${
                                                          rs_sample.fk_status_id ==
                                                            7 &&
                                                          !remainingTimeSampleReceiveReject
                                                            ? "bg-red-600 hover:bg-red-700 focus:ring-cyan-500 text-white"
                                                            : "bg-gray-600 text-gray-400"
                                                        }  py-2 px-4 mx-1 text-xs font-medium shadow-xs focus:outline-none focus:ring-2 focus:ring-offset-2`}
                                                      >
                                                        {/* className={`inline-flex mt-1 justify-center rounded-md border border-transparent ${rs_sample.fk_status_id ==
                                                          7
                                                          ? "bg-red-600"
                                                          : "bg-gray-600"
                                                          }  py-2 px-4 mx-1 text-xs font-medium text-white shadow-xs hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`} */}
                                                        {rejectSelected
                                                          ? "Confirm Rejection"
                                                          : "Reject"}{" "}
                                                        {
                                                          remainingTimeSampleReceiveReject
                                                        }
                                                      </button>
                                                      {/* ) : (<></>)} */}
                                                      {/* {(reservationData?.getAllReservation?.collectStatus == true) ? ( */}
                                                      <button
                                                        disabled={
                                                          rs_sample.fk_status_id ==
                                                            7 &&
                                                          !remainingTimeSampleReceiveReject
                                                            ? false
                                                            : true
                                                        }
                                                        onClick={() => {
                                                          handleReceiveSample(
                                                            rs_sample.fk_sample_id
                                                          );
                                                        }}
                                                        type="button"
                                                        className={`inline-flex mt-1 justify-center rounded-md border border-transparent ${
                                                          rs_sample.fk_status_id ==
                                                            7 &&
                                                          !remainingTimeSampleReceiveReject
                                                            ? "bg-cyan-600 hover:bg-cyan-700 focus:ring-cyan-500 text-white"
                                                            : "bg-gray-600 text-gray-400"
                                                        }  py-2 px-4 mx-1 text-xs font-medium shadow-xs focus:outline-none focus:ring-2 focus:ring-offset-2`}
                                                      >
                                                        Receive{" "}
                                                        {
                                                          remainingTimeSampleReceiveReject
                                                        }
                                                      </button>
                                                      {/* ) : (<></>)} */}
                                                      {rs_sample.fk_status_id ==
                                                      8 ? (
                                                        <button
                                                          onClick={() => {
                                                            handleRecollectSample(
                                                              rs_sample.fk_sample_id
                                                            );
                                                          }}
                                                          type="button"
                                                          className={`inline-flex mt-1 justify-center rounded-md border border-transparent bg-cyan-600  py-2 px-4 mx-1 text-xs font-medium text-white shadow-xs hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                                                        >
                                                          Recollect
                                                        </button>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                            <tr>
                                              <td
                                                colSpan={2}
                                                className="border border-grey-500 px-4 py-2 text-grey-600 font-medium"
                                              >
                                                <center>Data Not Found</center>
                                              </td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>

                                  {/* Abhi - Multi Samples Barcode Print - please connect before modificaitons */}
                                  {multiSampleDataForPrint ? (
                                    <BarcodePrint
                                      multiSampleDataForPrint={
                                        multiSampleDataForPrint
                                      }
                                      SingleprintsampleRef={
                                        SingleprintsampleRef
                                      }
                                      getHspLocIdForPrint={getHspLocIdForPrint}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              onClick={() => setViewSample(false)}
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Close
                            </button>
                          </div>
                        </div> */}
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={discountAll} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            initialFocus={cancelButtonRef}
            onClose={() => {
              setDiscountReqUser("");
              setDiscountAll(false);
              setOtpSent(false);
              setIsOtpVerified(false);
              setShowSendOtpButton(false);
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6">
                    <div>
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                          <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                            <div>
                              <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Order Discount
                              </h3>
                            </div>
                            <div className="space-y-6 sm:space-y-5">
                              <button
                                onClick={() =>
                                  setDiscountInp(discountInp ? false : true)
                                }
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              >
                                Discount By %
                              </button>

                              {discountInp ? (
                                <input
                                  disabled={otpSent}
                                  value={rev_discount_per}
                                  onInput={(e) => {
                                    setDisPer(e.target.value);
                                    calculateDisAmount(e.target.value);
                                  }}
                                  id="dis_amount"
                                  name="dis_amount"
                                  type="number"
                                  autoComplete="off"
                                  placeholder="Please Enter Discount Percentage"
                                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                />
                              ) : null}
                              <p className="font-semibold">Discount Amount</p>
                              <input
                                disabled={otpSent}
                                value={rev_discounted_amount}
                                onInput={(e) => {
                                  setDisPer(
                                    calculatePercentage(e.target.value)
                                  );
                                  setDisAmt(e.target.value);
                                }}
                                id="sar_dis_amount"
                                name="sar_dis_amount"
                                type="number"
                                autoComplete="off"
                                placeholder="Please Enter SAR Discount Amount"
                                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                              />
                              {rev_discounted_amount >
                                userData?.m_role_relation
                                  ?.role_max_discount_value && (
                                <div>
                                  <label
                                    htmlFor="fk_package_ids"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                  >
                                    Select User
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0 cursor-pointer flex gap-5 mb-5">
                                    <div className="mt-1 basis-1/2">
                                      <Select
                                        name="user_id"
                                        isDisabled={otpSent}
                                        id="user_id"
                                        value={discountReqUser}
                                        placeholder="Select User"
                                        onChange={(e) => {
                                          setShowSendOtpButton(true);
                                          setDiscountReqUser(e);
                                        }}
                                        options={mUserList}
                                        className="basic-multi-select"
                                      />
                                    </div>
                                    {/* {errors.fk_package_ids && touched.fk_package_ids ? (
                                    <p className="text-red-600 text-sm">{errors.fk_package_ids}</p>
                                ) : null} */}
                                    {showSendOtpButton && (
                                      <div className="mt-1 basis-2/2">
                                        <button
                                          onClick={(e) => {
                                            setShowSendOtpButton(false);
                                            sendOTP();
                                          }}
                                          type="button"
                                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                        >
                                          Send OTP
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                  {otpSent && (
                                    <OTPComponent
                                      sendOTP={sendOTP}
                                      verifyOTP={verifyOTP}
                                    />
                                  )}
                                </div>
                              )}
                              <p>
                                Comments:{" "}
                                <span
                                  className="text-orange-600 cursor-pointer"
                                  onClick={() =>
                                    setDisComments(disComments ? false : true)
                                  }
                                >
                                  {reservationData?.getAllReservation
                                    ?.rev_discount_comments
                                    ? reservationData?.getAllReservation
                                        ?.rev_discount_comments
                                    : "Empty"}
                                </span>
                              </p>
                              {/* <Timer/> */}
                              {disComments ? (
                                <textarea
                                  type="text"
                                  value={rev_discount_comments}
                                  placeholder="Please Enter Discount Comment"
                                  className="input-w-class block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                  onInput={(e) =>
                                    setResDisComment(e.target.value)
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>

                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              onClick={() => {
                                setDiscountReqUser("");
                                setDiscountAll(false);
                                setOtpSent(false);
                                setIsOtpVerified(false);
                                setShowSendOtpButton(false);
                              }}
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Cancel
                            </button>
                            <button
                              disabled={
                                rev_discounted_amount >
                                  userData?.m_role_relation
                                    ?.role_max_discount_value && !isOtpVerified
                                  ? true
                                  : false
                              }
                              type="button"
                              className={`ml-3 inline-flex justify-center rounded-md border border-transparent ${
                                rev_discounted_amount >
                                  userData?.m_role_relation
                                    ?.role_max_discount_value && !isOtpVerified
                                  ? "bg-gray-300 text-gray-400"
                                  : "bg-cyan-600 text-white hover:bg-cyan-700 "
                              } py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
                              onClick={() => {
                                // console.log("Discount Clicked")
                                updateDiscount();
                              }}
                            >
                              Request Discount
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={attachFileModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            initialFocus={cancelButtonRef}
            onClose={setAttachFileModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6">
                    <div>
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                          <div className="space-y-6">
                            <div>
                              <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Add Attachment
                              </h3>
                            </div>

                            <div>
                              <FileUploader
                                multiple={true}
                                types={fileTypes}
                                handleChange={handleFileChange}
                                Files={multifiles}
                                name="file"
                              />
                              {multifiles &&
                                multifiles.length > 0 &&
                                multifiles.map((file, index) => (
                                  <div key={`${file.lastModified}${index}`}>
                                    <h6>{file.name}</h6>
                                    <span
                                      onClick={(e) => removeFile(index)}
                                      style={{
                                        color: "red",
                                        cursor: "pointer",
                                      }}
                                    >
                                      remove
                                    </span>
                                  </div>
                                ))}
                              {validateFlag == false ? (
                                <button
                                  onClick={handleSubmit}
                                  type="button"
                                  className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                >
                                  Upload
                                </button>
                              ) : null}
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              onClick={() => setAttachFileModal(false)}
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Cancel
                            </button>
                            {progress == 100 ? (
                              <button
                                type="button"
                                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                onClick={() => {
                                  uploadAttachment();
                                  setAttachFileModal(false);
                                }}
                              >
                                Done
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={viewAttachFileModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            initialFocus={cancelButtonRef}
            onClose={setViewAttachFileModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  {/* <Dialog.Panel className="relative transform overflow-hidden bg-white px-4 pt-5 pb-4 text-left shadow-lg transition-all sm:my-8 booking-quotation-table-w sm:p-6 "> */}
                  <Dialog.Panel className="relative p-8 bg-white w-full max-w-4xl mx-auto my-6 rounded-lg shadow-lg ">
                    <div>
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                          <div className="space-y-6 sm:space-y-5">
                            <div className="flex justify-between items-center">
                              <h3 className="text-2xl font-bold">
                                View Attachment List
                              </h3>
                              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                <button
                                  type="button"
                                  className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                  onClick={() => setViewAttachFileModal(false)}
                                >
                                  <span className="sr-only">Close</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="flex items-center justify-between">
                              <div className="p-1.5 w-full inline-block align-middle">
                                <div className="overflow-hidden border rounded-lg">
                                  <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                      <tr>
                                        <th
                                          scope="col"
                                          className="py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                          SN
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-sm font-semibold text-gray-900"
                                        >
                                          Name
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-sm font-semibold text-gray-900"
                                        >
                                          Size
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-sm font-semibold text-gray-900"
                                        >
                                          Link
                                        </th>
                                        <th
                                          scope="col"
                                          className="px-3 py-3.5 text-sm font-semibold text-gray-900"
                                        >
                                          Action
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {attachmentList?.length > 0 ? (
                                        attachmentList?.map(
                                          (ra_attach, ra_key) => (
                                            <tr>
                                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {parseInt(ra_key + 1)}.
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {ra_attach?.name}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {ra_attach?.size}
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span className="inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full">
                                                  <Link
                                                    to={`${ra_attach?.file_path}`}
                                                    target={"_blank"}
                                                    className="text-cyan-900 "
                                                    size="default"
                                                    variant="outlined"
                                                  >
                                                    Download
                                                  </Link>
                                                </span>
                                              </td>
                                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span className="inline-flex px-2 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                                                  <Link
                                                    onClick={() =>
                                                      deleteAttachment(
                                                        ra_attach,
                                                        ra_key
                                                      )
                                                    }
                                                    className="text-red-900 "
                                                    size="default"
                                                    variant="outlined"
                                                  >
                                                    Delete
                                                  </Link>
                                                </span>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan={4}
                                            className="border border-grey-500 px-4 py-2 text-grey-600 font-medium"
                                          >
                                            <center>Data Not Found</center>
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              onClick={() => setViewAttachFileModal(false)}
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Close
                            </button>
                          </div>
                        </div> */}
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <Transition.Root show={commentsModal} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            initialFocus={cancelButtonRef}
            onClose={setCommentsModal}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6">
                    <div>
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                          <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                            <div>
                              <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Add Comment
                              </h3>
                            </div>
                            <div className="flex items-center justify-between">
                              {saveComment ? (
                                <>
                                  <div className="mx-2 font-semibold">
                                    {commentData}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <textarea
                                    type="text"
                                    value={commentData}
                                    className="input-w-class block rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
                                    onInput={(e) =>
                                      setCommentData(e.target.value)
                                    }
                                  />
                                </>
                              )}

                              <div className="flex">
                                <button
                                  type="button"
                                  onClick={() => setSaveComment(false)}
                                  className="ml-3 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                >
                                  <XMarkIcon className="w-5" />
                                </button>
                                <button
                                  type="button"
                                  disabled={!commentData}
                                  onClick={() => {
                                    setSaveComment(true);
                                  }}
                                  className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                >
                                  ADD
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              onClick={() => setCommentsModal(false)}
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              onClick={() => {
                                updateComment();
                                setCommentsModal(false);
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Refund Modal */}
        <Transition.Root show={refundAll} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            initialFocus={cancelButtonRef}
            onClose={setRefundAll}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6">
                    <div>
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                          <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                            <div>
                              <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Are you sure want to refund?
                              </h3>
                            </div>
                          </div>
                        </div>

                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              onClick={() => setRefundAll(false)}
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              onClick={() => updateRefund()}
                            >
                              Refund All
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Refund Modal */}
        <Transition.Root show={cancelAll} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            initialFocus={cancelButtonRef}
            onClose={setCancelAll}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 booking-quotation-table-w sm:p-6">
                    <div>
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                          <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                            <div>
                              <h3 className="text-lg font-medium leading-6 text-gray-900">
                                Are you sure want to cancell the reservation?
                              </h3>
                            </div>
                          </div>
                        </div>

                        <div className="pt-5">
                          <div className="flex justify-end">
                            <button
                              onClick={() => setCancelAll(false)}
                              type="button"
                              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                              onClick={() => cancellReservation()}
                            >
                              Cancell Reservation
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Edit Patient Modal */}
        <EditPatient
          reservation_id={reservationData?.getAllReservation?.reservation_id}
          patient_id={selectedPatient}
          openPatient={openPatient}
          setSelectedPatient={setSelectedPatient}
          cancelButtonRef={cancelButtonRef}
          setOpenPatient={setOpenPatient}
          closeModal={closeModal}
          mobileNumberValid={mobileNumberValid}
          mobileNumber={mobileNumber}
          userMobileNumber={userMobileNumber}
          mobileCountryCode={mobileCountryCode}
          userType={userType}
          addUserPatient={addUserPatient}
          modalData={modalData}
          user_id={parentId}
          filteredCountryList={filteredCountryList}
          setMobileNumber={setMobileNumber}
          setMobileNumberValid={setMobileNumberValid}
        />
      </div>

      <div>
        <Transition.Root show={openSendReport} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={() => closeReportSMSModal()}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                      <div className="space-y-6 pt-5 sm:space-y-5 sm:pt-5">
                        <div>Number to Send Report URL </div>
                        <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                          <input
                            value={reportSmsNumber}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setReportSmsNumber(e.target.value);
                            }}
                            type="number"
                            placeholder="Enter Valid Number"
                            name="sms_number"
                            autoComplete="off"
                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                          />
                          {!reportSmsNumber ||
                          +reportSmsNumber < 500000000 ||
                          +reportSmsNumber >= 600000000 ? (
                            <p className="mt-1 ml-1 text-xs text-red-500">
                              Please enter a vaild number without country code
                            </p>
                          ) : null}
                        </div>
                        <div className="flex justify-end">
                          <button
                            onClick={handleSendReportUrlSms}
                            disabled={
                              reportSmsNumber < 500000000 ||
                              reportSmsNumber >= 600000000
                            }
                            className={`${
                              reportSmsNumber >= 500000000 &&
                              reportSmsNumber < 600000000
                                ? "hover:bg-cyan-700 bg-cyan-600 text-white focus:ring-cyan-500"
                                : "bg-gray-300 text-gray-500"
                            } ml-4 inline-flex rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                          >
                            Send SMS
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export default BookingConfirmation;
