import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { regionLocSchema } from "../../../schemas";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { region_location_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";

function RegionLabList() {
  const { rgn_id: rgn_id } = useParams();
  const pages = [{ title: "Region Lab List", href: `/region-loc/${rgn_id}`, module_id: 1 }];
  const [modalOpenFlage, setmodalOpenFlage] = useState(false);
  const [rgn_loc_id, setRegionLocID] = useState("");
  const [locName, setLocName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [regionLabList, setRegionLabList] = useState(false)
  const [locationList, setLocationList] = useState(false)
  const [regionList, setRegionList] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    rgn_loc_id: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (rgn_id) {
      MasterServices.getSingleLocation(rgn_id)
        .then((response) => {
          const { data, status } = response;
          if (status == 200) {
            // setRegionName(data?.data?.m_hospital_relation?.hos_name_en);
          } else {
            toast.error("Fatal Error Please Contact Admin")
          }
        })
        .catch((error) => {
          const msg = error.response.data.message;
          toast.error(msg)
        });
    }
  }, [rgn_id]);

  useEffect(() => {
    document.title = "Tabib | Region Labs";
    if (rgn_id) {
      getAllLocationList();
    }
    getAllRegionLocations(rgn_id);
    getAllRegions();
    getSingleRegion(rgn_id);
  }, [rgn_id]);

  const getAllRegionLocations = (rgn_id) => {
    setIsLoading(true)
    let payload = {
      rgn_id: rgn_id,
      type: 3,
    }
    MasterServices.getAllRegionLocations(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setRegionLabList(data.data);
          } else {
            toast.error("Region Lab List Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getAllRegionLocations catch Error";
        toast.error(msg);
        setIsLoading(false);
      });
  }

  const getAllLocationList = () => {
    MasterServices.getLocationList()
      // getLabs
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            let locListData = data.data;
            if (locListData.length > 0) {
              locListData.map((location, i) => {
                if (location.rgn_id == rgn_id) {
                  setLocName(location.loc_name_en);
                }
              });
            }
            setLocationList(data.data);
          } else {
            toast.error("Location List Not Found");
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getAllRegionLocations catch Error";
        toast.error(msg);
        setIsLoading(false);
      });
  }

  const getAllRegions = async () => {
    MasterServices.getRegionList(3)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if (data?.data?.length > 0) {
            setRegionList(data.data);
          } else {
            toast.error("getRegionList Not Found")
          }
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getRegionList catch Error";
        toast.error(msg);
        setIsLoading(false);
      });
  };

  const getSingleRegion = async (rgn_id) => {
    MasterServices.getSingleRegion(rgn_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setRegionName(data?.data?.rgn_name_en);

        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "getRegionList catch Error";
        toast.error(msg);
        setIsLoading(false);
      });
  };

  useEffect(() => {
  }, []);

  const initialValues = {
    rgn_loc_id: "",
    fk_location_id: "",
    fk_region_id: rgn_id
  };

  const [formRegionLoc, setFormRegionLoc] = useState(initialValues);

  const handleDrawer = (type, id, obj) => {
    if ((type === "edit", id)) {
      setRegionLocID(id);
      let initialValuesobj = {
        rgn_loc_id: obj.rgn_loc_id,
        fk_location_id: obj.fk_location_id,
        fk_region_id: obj.fk_region_id,
      };
      setFormRegionLoc(initialValuesobj);
    } else {
      setRegionLocID("");
      setFormRegionLoc(initialValues);
    }
    if (modalOpenFlage === false) {
      setmodalOpenFlage(true);
    }
  };

  const onDeleteOpen = (rgn_loc_id, rgn_loc_is_active) => {
    setConfirmationModal({ rgn_loc_id, rgn_loc_is_active, status: true });
  };

  const onDeleteRegionLoc = async (rgn_loc_id, rgn_loc_is_active) => {
    let Newrgn_loc_is_active = false;
    Newrgn_loc_is_active = rgn_loc_is_active == true ? false : true;
    let text = "Activated";
    if (Newrgn_loc_is_active == true) {
      text = "Activated";
    } else {
      text = "In-Activated";
    }
    MasterServices.setRegionLocationStatus({
      rgn_loc_id,
      rgn_loc_is_active: Newrgn_loc_is_active
    })
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Region Location " + text + " Successfully!")
          setConfirmationModal({ rgn_loc_id: null, status: false });
          getAllRegionLocations(rgn_id);
          navigate(`/region-loc/${rgn_id}`);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
      })
      .catch((error) => {
        const msg = "Fatal Error Please Contact Admin";
        toast.error(msg)
      });
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formRegionLoc,
      validationSchema: regionLocSchema,
      onSubmit: async (values, action) => {
        let body = {
          rgn_loc_id: rgn_loc_id,
          fk_location_id: values.fk_location_id,
          fk_region_id: values.fk_region_id,
        };
      
        if (body.fk_region_id != undefined || body.fk_region_id != null || body.fk_region_id != "" || body.fk_location_id != undefined || body.fk_location_id != null || body.fk_location_id != "") {
          let res = await MasterServices.saveRegionLocation(body);

          if (res.status) {
            // if (res.data.data = "Exist") {
            //   toast.warn(res.data.message);
            // } else {
            toast.success(res.data.message);
            // }
            if (modalOpenFlage === true) {
              setmodalOpenFlage(false);
              action.resetForm();
              dispatch(getAllRegionLocations(rgn_id));

            }
          }
        }
        else
          action.resetForm();
        if (modalOpenFlage === true) {
          dispatch(getAllRegionLocations(rgn_id));
          setmodalOpenFlage(false);
        }
        navigate(`/region-loc/${rgn_id}`);
      },
    });
  return (
    <div className="px-4 sm:px-6 lg:px-8">

      <ConfirmationModal
        title={"Are you sure you want to " + (confirmationModal.rgn_loc_is_active ? "Disable " : "Activate") + " this Region Location?"}
        confirmationButtonText="Yes"
        open={confirmationModal.status}
        onDelete={() => onDeleteRegionLoc(confirmationModal.rgn_loc_id, confirmationModal.rgn_loc_is_active)}
        setOpen={setConfirmationModal}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Region Lab List (Region Name : {regionName})
        </h1>
      </div>
      <div className="mt-4 flex">
        <Link
          onClick={() => handleDrawer("add", "", {})}
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
        >
          Add
        </Link>
      </div>

      {
        isLoading ? (
          <FallingLinesLoader />
        ) : ((regionLabList.length > 0) ? (
          <Table
            columns={region_location_columns({ onDeleteOpen, handleDrawer })}
            data={regionLabList}
          />
        ) : <TableEmpty />)
      }


      <div>
        <Transition.Root show={modalOpenFlage} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-30"
            onClose={setmodalOpenFlage}
          >
            <div className="fixed inset-0" />
            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit(e);
                        }}
                        className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                      >
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {rgn_loc_id ? "Update" : "Add"}
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                  onClick={() => setmodalOpenFlage(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-1 flex-col justify-between">
                            <div className="divide-y divide-gray-200 p-4 sm:p-6">
                              <div>
                                <label
                                  htmlFor="fk_region_id"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Region
                                </label>
                              </div>
                              {

                                regionList && regionList.length > 0 ?
                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <select
                                      disabled
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                      name="fk_region_id"
                                      id="fk_region_id"
                                      value={values.fk_region_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option> Select Region </option>
                                      {regionList.map((region, i) => (
                                        <option
                                          selected={
                                            region.rgn_id ===
                                              values.fk_region_id
                                              ? "selected"
                                              : ""
                                          }
                                          key={i}
                                          value={region.rgn_id}
                                        >
                                          {region.rgn_name_en}
                                        </option>
                                      ))}
                                    </select>

                                    {errors.fk_region_id &&
                                      touched.fk_region_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_region_id}
                                      </div>
                                    ) : null}
                                  </div>

                                  : null
                              }
                              <div>
                                <label
                                  htmlFor="fk_location_id"
                                  className="block text-sm font-medium text-gray-900 mb-1"
                                >
                                  Location
                                </label>
                              </div>
                              {
                                locationList && locationList.length > 0 ?

                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <select
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                                      name="fk_location_id"
                                      id="fk_location_id"
                                      value={values.fk_location_id}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    >
                                      <option value={null}> Select Location </option>
                                      {locationList?.map((loc, i) => (
                                        <option
                                          selected={
                                            loc.loc_id === values.fk_location_id
                                              ? "selected"
                                              : ""}
                                          key={i}
                                          value={loc.loc_id}
                                        >
                                          {loc.loc_name_en}
                                        </option>
                                      ))}
                                    </select>

                                    {errors.fk_location_id && touched.fk_location_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_location_id}
                                      </div>
                                    ) : null}
                                  </div>
                                  : null
                              }

                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                          >
                            {rgn_loc_id ? "Update" : "Add"}
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </div>
  );
}
export default RegionLabList;