import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { hospitalSchema } from "../../../schemas";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import { getAllHospitals } from "../../../redux/hospital/actions";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { out_source_reservation_columns, outsource_clinic_patients_columns } from "../../../components/tables/tableheader";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { Switch } from "@headlessui/react";
import MasterServices from '../../../ApiServices/MasterServices';
import ReservationServices from '../../../ApiServices/ReservationServices';
import { toast } from 'react-toast'
import queryString from 'query-string';
import Select from "react-select";

import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import moment from "moment";
function className(...location) {
  return location.filter(Boolean).join(" ");
}

const bookingType = [
  { name: "Normal", id: "1" },
  { name: "OPD", id: "2" },
  { name: "Emergancy", id: "3" },
  { name: "ICU", id: "4" },
];
const filterInitialValues = {
  mc_id: '',
}
const OutSourceClinicPatientsList = () => {
  const pages = [{ title: "Outsource Resrvation List", href: "/outsourced-clinic-logs", module_id: 8 }];

  const [filterFlag, setFilterFlag] = useState(false);
  const [patientsList, setPatientsList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clinicList, setClinicList] = useState([])
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [reportFilters, setReportFilters] = useState(filterInitialValues)

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {hospital: { list: hospitalList }} = useSelector((state) => state);

  useEffect(() => {
    document.title = "Outsource Resrvation List";
    dispatch(getAllHospitals());
  }, []);
  useEffect(() => {
    console.log("reportFilters", reportFilters);
    getData(reportFilters.mc_id)
  }, [reportFilters]);

  const generateInvoiceReport = async (payload) => {
    let res = await ReservationServices.generateInvoiceReport(payload)
    if (res.status == 200) {
      toast.success(res.data.message)
      getData();
    } else {
      toast.error(res.data.message)
    }
  }

  useEffect(() => {
    // getData();
    getClinicList();
  }, []);

  const getClinicList = (payload) => {
    // setIsLoading(true);
    console.log("params payload=>", payload)
    MasterServices.getClinicList(payload)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Clinic List Found!");
          setClinicList(data?.data);
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setIsLoading(false);
      });
  }

  const getData = (clinic_id) => {
    setIsLoading(true);
    console.log("params payload=>", clinic_id)
    ReservationServices.getOutsourceClinicPatientList(clinic_id)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          toast.success("Reservation List Found!");
          console.log(data)        
        } else {
          toast.error("Fatal Error Please Contact Admin")
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setIsLoading(false);
      });
  }

  const getFilterdData = async () => {
    // setStorageLocations((prev) => ({ ...prev, loading: true }));
    // let payload = {
    //   start_date: reportFilters.start_date || '1949-05-26',
    //   end_date: reportFilters.end_date || (moment().add(1,'days').format('YYYY-MM-DD')),
    //   mc_id: Array.isArray(reportFilters.mc_id) ? reportFilters.mc_id.map(v => v.value) : []
    // }
    // console.log(payload);
    // console.log("params payload=>", payload)
    // ReservationServices.getOutSourceReservationFilteredList(payload)
    //   .then((response) => {
    //     const { data, status } = response;
    //     if (status == 200) {
    //       toast.success("Reservation List Found!");
    //       if(Array.isArray(data?.data)) {
    //         setReservationList(data?.data);
    //         const sum = data?.data.reduce((accumulator, object) => {
    //           return accumulator + parseFloat(object.rev_payable_amount);
    //         }, 0);
    //         setTotalAmountPaid(sum)
    //       } else {
    //         setReservationList([]);
    //       }
    //     } else {
    //       toast.error("Fatal Error Please Contact Admin")
    //     }
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     const msg = error;
    //     toast.error(msg);
    //     setIsLoading(false);
    //   });
  }

  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    reservation_id: null,
    reservation_is_active: false,
  });

  const onDeleteOpen = () => {
  };

  const onDeleteReservation = () => {
    
  };

  const editReservation = (rs_val) => {
    console.log("rs_val=>", rs_val);
    localStorage.setItem("edit_booking_reservation_id", rs_val.reservation_id);
    localStorage.setItem("edit_booking_reservation_user_details", JSON.stringify(rs_val));
    navigate("/bookingConfirm");
  };


  return (
    <>
      <div className="px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none mb-2">
        <div className="sales-booking-head">
          <Breadcrumb pages={pages} />
        </div>
      </div>
      <div className="mt-5 flex gap-3 items-center">
      {/* start date */}
      <div className="">
          <label
              htmlFor="mc_id"
              className="w-60 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Select Location</label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
              <Select
                  // defaultValue={testList[0]}
                  // isMulti
                  name="mc_id"
                  id="mc_id"
                  value={reportFilters.mc_id}
                  onChange={(e) => setReportFilters((prev) => ({...prev, mc_id: e}))}
                  className="basic-multi-select"
                  classNamePrefix="Select Test"
                  options={Array.isArray(clinicList) && clinicList?.map((sin) => {
                    return { value: sin.mc_id, label: sin.mc_name };
                  })}
              />
          </div>
      </div>
      {/* <button
          onClick={() => {
              getFilterdData()
          }}
          className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2`}
      >
          Filter
      </button> */}
      </div>
      {/* <div className="mt-4 flex">
          <p
              // onClick={() => handleDrawer("add", "", {})}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-sm font-medium text-dark shadow-sm  ml-auto"
          >
              Total Paid Amount: {totalAmountPaid}
          </p>
      </div> */}
      <div className= "px-4 py-5 sm:px-6 rounded-md bg-white shadow-lg ring-1 ring-white ring-opacity-5 focus:outline-none">
        <div className="flex flex-col">
          <div>

            {
              isLoading ? (
                <FallingLinesLoader />
              ) : ((patientsList.length > 0) ? (<></>
                // <Table
                //   zoom='zoom-075'
                //   columns={outsource_clinic_patients_columns({ onDeleteOpen, editReservation, generateInvoiceReport })}
                //   data={reservationList}
                // />
              ) : <TableEmpty />)
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default OutSourceClinicPatientsList;
