import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { regionSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import { region_columns } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from 'react-toast'
import MasterServices from '../../../ApiServices/MasterServices'

function RegionList() {
    const pages = [{ title: "Region List", href: "/region", module_id: 21 }];
    const [regionList, setRegionList] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [modalOpenFlage, setmodalOpenFlage] = useState(false);
    const [rgn_id, setRegionid] = useState("");
    const [confirmationModal, setConfirmationModal] = useState({
        status: false,
        rgn_id: null,
    });
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Tabib | Regions";
        getAllRegions()
    }, []);

    const getAllRegions = async () => {
        MasterServices.getRegionList(3)
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    if (data?.data?.length > 0) {
                        setRegionList(data.data);
                    } else {
                        toast.error("getRegionList Not Found")
                    }
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
                setIsLoading(false);
            })
            .catch((error) => {
                const msg = "getRegionList catch Error"; //error.response.data.message;
                toast.error(msg);
                setIsLoading(false);
            });
    };

    const addRegion = async () => {
        try {
            setIsLoading(true)
            console.log("Add Region")
            let body = {
                rgn_name_en: values.rgn_name_en,
                rgn_name_ar: values.rgn_name_ar,
            };
            MasterServices.saveRegion(body)
                .then((response) => {
                    const { data, status } = response;
                    if (status == 200) {
                        if (rgn_id != 0 || rgn_id != '') {
                            toast.success("Region Updated Successfully");
                        } else {
                            toast.success("Region Added Successfully");
                        }
                        getAllRegions();
                    } else {
                        toast.error("Fatal Error Please Contact Admin")
                    }
                    setIsLoading(false)
                })
                .catch((error) => {
                    const msg = "saveRegion Catch Error" //error.response.data.message;
                    toast.error(msg)
                    setIsLoading(false)
                });
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };

    const initialValues = {
        rgn_id: "",
        rgn_name_en: "",
        rgn_name_ar: ""
    };

    const [formRegion, setFormRegion] = useState(initialValues);

    const handleDrawer = (type, id, obj) => {
        if ((type === "edit", id)) {
            setRegionid(id);
            const initialValues = {
                rgn_id: obj.rgn_id,
                rgn_name_en: obj.rgn_name_en,
                rgn_name_ar: obj.rgn_name_ar
            };
            setFormRegion(initialValues);
        } else {
            setRegionid("");
            setFormRegion(initialValues);
        }
        if (modalOpenFlage === false) {
            setmodalOpenFlage(true);
        }
    };

    const onDeleteOpen = (rgn_id, rgn_is_active) => {
        setConfirmationModal({ rgn_id, rgn_is_active, status: true });
    };

    const onDeleteRegion = (rgn_id, rgn_is_active) => {
        let Newrgn_is_active = false;
        Newrgn_is_active = rgn_is_active == true ? false : true;
        let text = "Activated";
        if (Newrgn_is_active == true) {
            text = "Activated";
        } else {
            text = "In-Activated";
        }
        MasterServices.setRegionStatus({
            rgn_id,
            rgn_is_active: Newrgn_is_active
        })
            .then((response) => {
                const { data, status } = response;
                if (status == 200) {
                    toast.success("Region " + text + " Successfully!")
                    setConfirmationModal({ rgn_id: null, status: false });
                    getAllRegions();
                } else {
                    toast.error("Fatal Error Please Contact Admin")
                }
            })
            .catch((error) => {
                const msg = error.response.data.message;
                toast.error(msg)
            });
        setConfirmationModal({ rgn_id: null, status: false });
    };


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        useFormik({
            enableReinitialize: true,
            initialValues: formRegion,
            validationSchema: regionSchema,
            onSubmit: (values, action) => {
                addRegion()
                action.resetForm();
                if (modalOpenFlage === true) {
                    getAllRegions()
                    setmodalOpenFlage(false);
                }
                navigate("/region");
            },
        });
    return (
        <div className="px-4 sm:px-6 lg:px-8">

            <ConfirmationModal
                title={"Are you sure you want to " + (confirmationModal.rgn_is_active ? "Disable " : "Activate") + " This Region?"}
                confirmationButtonText="Yes"
                open={confirmationModal.status}
                setOpen={setConfirmationModal}
                onDelete={() => onDeleteRegion(confirmationModal.rgn_id, confirmationModal.rgn_is_active)}
            />
            <Breadcrumb pages={pages} />
            <div className="">
                <h1 className="text-xl font-semibold text-gray-900">Region</h1>
            </div>
            <div className="mt-4 flex">
                <Link
                    onClick={() => handleDrawer("add", "", {})}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
                >
                    Add Region
                </Link>
            </div>

            {
                isLoading ? (
                    <FallingLinesLoader />
                ) : ((regionList.length > 0) ? (
                    <Table
                        columns={region_columns({ onDeleteOpen, handleDrawer })}
                        data={regionList}
                    />
                ) : <TableEmpty />)
            }

            {isLoading ? null :
                (<div>
                    <Transition.Root show={modalOpenFlage} as={Fragment}>
                        <Dialog
                            as="div"
                            className="relative z-30"
                            onClose={setmodalOpenFlage}
                        >
                            <div className="fixed inset-0" />
                            <div className="fixed inset-0 overflow-hidden">
                                <div className="absolute inset-0 overflow-hidden">
                                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                                            enterFrom="translate-x-full"
                                            enterTo="translate-x-0"
                                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                                            leaveFrom="translate-x-0"
                                            leaveTo="translate-x-full"
                                        >
                                            <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                                                <form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        handleSubmit(e);
                                                    }}
                                                    className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                                                >
                                                    <div className="h-0 flex-1 overflow-y-auto">
                                                        <div className="bg-cyan-700 py-6 px-4 sm:px-6">
                                                            <div className="flex items-center justify-between">
                                                                <Dialog.Title className="text-lg font-medium text-white">
                                                                    {rgn_id ? "Update" : "Add"} Region
                                                                </Dialog.Title>
                                                                <div className="ml-3 flex h-7 items-center">
                                                                    <button
                                                                        type="button"
                                                                        className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
                                                                        onClick={() => setmodalOpenFlage(false)}
                                                                    >
                                                                        <span className="sr-only">Close panel</span>
                                                                        <XMarkIcon
                                                                            className="h-6 w-6"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-1 flex-col justify-between">
                                                            <div className="p-4 sm:p-6">
                                                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="rgn_name_en"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            Region Name(EN)
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.rgn_name_en}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter Region Name En"
                                                                            name="rgn_name_en"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.rgn_name_en &&
                                                                            touched.rgn_name_en ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.rgn_name_en}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                                <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
                                                                    <div>
                                                                        <label
                                                                            htmlFor="rgn_name_ar"
                                                                            className="block text-sm font-medium text-gray-900"
                                                                        >
                                                                            Region Name(AR)
                                                                        </label>
                                                                    </div>
                                                                    <div className="mt-1 sm:col-span-2 sm:mt-0">
                                                                        <input
                                                                            value={values.rgn_name_ar}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            type="text"
                                                                            placeholder="Enter Region Name Ar"
                                                                            name="rgn_name_ar"
                                                                            autoComplete="off"
                                                                            className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                                                        />
                                                                        {errors.rgn_name_ar &&
                                                                            touched.rgn_name_ar ? (
                                                                            <p className="text-red-600 text-sm">
                                                                                {errors.rgn_name_ar}
                                                                            </p>
                                                                        ) : null}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-shrink-0 justify-end px-4 py-4">
                                                        <button
                                                            type="button"
                                                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                            onClick={() => {
                                                                setmodalOpenFlage(false);
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                                        >
                                                            {rgn_id ? "Update" : "Add"}
                                                        </button>
                                                    </div>
                                                </form>
                                            </Dialog.Panel>
                                        </Transition.Child>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>
                </div>)
            }
        </div>
    );
}
export default RegionList;
