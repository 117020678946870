import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { commentsSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { prog_comment_columns, } from "../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import Pagination from "../../../components/Pagination";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Api from "../../../ApiServices/Api";

const API = process.env.REACT_APP_API_URL;

const commentTypeList = [
	{
		value: 1,
		label: "Type 1"
	},
	{
		value: 2,
		label: "Type 2"
	}
];


function ProgrammableComments() {
	const pages = [{ title: "Comments", href: "/prog-comments", module_id: 27 }];
	const [commentList, setCommentList] = useState(false)
	const [testList, setTestList] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [comment_id, setCommentId] = useState("");
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		comment_id: null,
	});
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Tabib | Comments";
		getAllTests()
		getAllComments()
	}, []);

	const getAllTests = async () => {
		try {
			setIsLoading(true)
			const { data } = await Api().get(`/api/dashboard/masters/test`);
			// console.log("All Test Data", data)
			if (data.status) {
				setTestList(data?.data);
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error);
			setIsLoading(false)
		}
	};

	const getAllComments = async () => {
		try {
			setIsLoading(true)
			const { data } = await Api().get(`/api/dashboard/tests/comment`);
			// console.log("All Comments Data", data)
			if (data.status) {
				setCommentList(data.data);
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error);
			setIsLoading(false)
		}
	};

	const addComment = async () => {
		try {
			setIsLoading(true)
			// console.log("Add Comment")
			let body = {
				comment_text: values.comment_text,
				comment_type: values.comment_type,
				fk_test_id: +values.fk_test_id
			};
			const res = await Api().post(`/api/dashboard/tests/comment`, comment_id !== undefined || comment_id !== null || comment_id !== "" ? { ...body, comment_id } : body);
			if (res.status) {
				getAllComments()
			}
			// console.log("Add Comment Response", res)
			setIsLoading(false)
		} catch (error) {
			console.log(error);
			setIsLoading(false)
		}
	};

	const initialValues = {
		comment_id: "",
		comment_text: "",
		comment_type: "",
		fk_test_id: ""
	};

	const [formComment, setFormComment] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setCommentId(id);
			const initialValues = {
				comment_id: obj.comment_id,
				comment_text: obj.comment_text,
				fk_test_id: obj.t_test_relation.test_id,
				comment_type: obj.comment_type
			};
			setFormComment(initialValues);
		} else {
			setCommentId("");
			setFormComment(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	const onDeleteOpen = (comment_id) => {
		setConfirmationModal({ comment_id, status: true });
	};

	const onDeleteComment = (comment_id) => {
		setConfirmationModal({ comment_id: null, status: false });
	};
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formComment,
			validationSchema: commentsSchema,
			onSubmit: (values, action) => {
				// console.log("Submit Clicked", values);
				addComment()
				action.resetForm();
				if (modalOpenFlage === true) {
					getAllComments()
					setmodalOpenFlage(false);
				}
				navigate("/prog-comments");
			},
		});
	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<ConfirmationModal
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteComment(confirmationModal.comment_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Comment</h1>
				<p className="mt-2 text-sm text-gray-700">A list of all the Comments.</p>
			</div>
			<div className="mt-4 flex">
				<Link
					onClick={() => handleDrawer("add", "", {})}
					type="button"
					className="inline-flex items-center justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
				>
					Add Comment
				</Link>
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={prog_comment_columns({ onDeleteOpen, handleDrawer })}
					data={commentList?.length > 0 && !isLoading ? commentList : []}
				/>
			)}

			{isLoading ? null :
				(<div>
					<Transition.Root show={modalOpenFlage} as={Fragment}>
						<Dialog
							as="div"
							className="relative z-30"
							onClose={setmodalOpenFlage}
						>
							<div className="fixed inset-0" />
							<div className="fixed inset-0 overflow-hidden">
								<div className="absolute inset-0 overflow-hidden">
									<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
										<Transition.Child
											as={Fragment}
											enter="transform transition ease-in-out duration-500 sm:duration-700"
											enterFrom="translate-x-full"
											enterTo="translate-x-0"
											leave="transform transition ease-in-out duration-500 sm:duration-700"
											leaveFrom="translate-x-0"
											leaveTo="translate-x-full"
										>
											<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
												<form
													onSubmit={(e) => {
														e.preventDefault();
														handleSubmit(e);
													}}
													className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
												>
													<div className="h-0 flex-1 overflow-y-auto">
														<div className="bg-cyan-700 py-6 px-4 sm:px-6">
															<div className="flex items-center justify-between">
																<Dialog.Title className="text-lg font-medium text-white">
																	{comment_id ? "Update" : "Add"} Comment
																</Dialog.Title>
																<div className="ml-3 flex h-7 items-center">
																	<button
																		type="button"
																		className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
																		onClick={() => setmodalOpenFlage(false)}
																	>
																		<span className="sr-only">Close panel</span>
																		<XMarkIcon
																			className="h-6 w-6"
																			aria-hidden="true"
																		/>
																	</button>
																</div>
															</div>
														</div>
														<div className="flex flex-1 flex-col justify-between">
															<div className="p-4 sm:p-6">
																<div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
																	<label htmlFor="comment_text" className="block text-sm font-medium text-gray-700">Comment Text</label>
																	<div className="mt-1 sm:col-span-2 sm:mt-0">
																		<textarea
																			placeholder="Enter Comment Text"
																			className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
																			type="text"
																			name="comment_text"
																			id="comment_text"
																			value={values.comment_text}
																			onChange={handleChange}
																			onBlur={handleBlur}
																		/>
																		{errors.comment_text && touched.comment_text ? (
																			<p className="text-red-600 text-sm">{errors.comment_text}</p>
																		) : null}
																	</div>
																</div>
																<div className="mt-1 mb-2 sm:col-span-2 sm:mt-0">
																	<label htmlFor="comment_type" className="block text-sm font-medium text-gray-700">Comment Type</label>
																	<select
																		className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
																		name="comment_type"
																		id="comment_type"
																		value={values.comment_type}
																		onChange={handleChange}
																		onBlur={handleBlur}
																	>
																		<option value={null}> Select Comment Type </option>
																		{commentTypeList?.map((type, i) => (
																			<option
																				defaultValue={
																					comment_id ? (type.label === values.comment_type ? type.value : "") : ""}

																				key={type.value}
																				value={type.label}
																			>
																				{type.label}
																			</option>
																		))}
																	</select>

																	{errors.comment_type && touched.comment_type ? (
																		<div className="text-sm text-red-600">
																			{errors.comment_type}
																		</div>
																	) : null}
																</div>

																<div className="mt-1 mb-2 sm:col-span-2 sm:mt-0">
																	<label htmlFor="fk_test_id" className="block text-sm font-medium text-gray-700">Test</label>
																	<select
																		className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
																		name="fk_test_id"
																		id="fk_test_id"
																		value={values.fk_test_id}
																		onChange={handleChange}
																		onBlur={handleBlur}
																	>
																		<option value={null}> Select Test </option>
																		{testList?.map((s, i) => (
																			<option
																				defaultValue={comment_id ? values.fk_test_id : ""
																				}
																				// selected={
																				//     state.state_id === values.fk_state_id
																				//         ? "selected"
																				//         : ""}
																				key={s.test_id}
																				value={s.test_id}
																			>
																				{s.test_name_en}
																			</option>
																		))}
																	</select>

																	{errors.fk_test_id && touched.fk_test_id ? (
																		<div className="text-sm text-red-600">
																			{errors.fk_test_id}
																		</div>
																	) : null}
																</div>

															</div>
														</div>
													</div>
													<div className="flex flex-shrink-0 justify-end px-4 py-4">
														<button
															type="button"
															className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
															onClick={() => {
																setmodalOpenFlage(false);
															}}
														>
															Cancel
														</button>
														<button
															type="submit"
															className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														>
															{comment_id ? "Update" : "Add"}
														</button>
													</div>
												</form>
											</Dialog.Panel>
										</Transition.Child>
									</div>
								</div>
							</div>
						</Dialog>
					</Transition.Root>
				</div>)
			}
		</div>
	);
}
export default ProgrammableComments;
