import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowUturnLeftIcon } from "@heroicons/react/20/solid";
import * as Yup from "yup";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import Select from "react-select";
const AddProgram = () => {
  const pages = [{ title: "Purchase Order", href: "/purchase_order" }];
  const loading = false;
  const navigate = useNavigate();
  const [vedorList, setVendorList] = useState({
    data: [],
    loading: false,
  });
  const [showError, setShowError] = useState(false);

  const { id: OrderId } = useParams();
  const initialValues = {
    vendor_id: "",
    loc_id: "",
    po_id: "",
    draft_flag: "",
    purchase_order_items: [],
  };
  useEffect(() => {
    getAllVendorList();
    getAllLabs();
    getAllUnits();
    // getAllItems();
    document.title = "New Tabib | Add Purchase Order";
  }, []);
  const getAllVendorList = async () => {
    setVendorList({ ...vedorList, loading: true });
    await MasterServices.getVendorList()
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          if(Array.isArray(data.data) && data.data.length > 0) {
            
            setVendorList({
              ...vedorList,
              data: data.data.map(v => ({value: v.vendor_id, label: v.vendor_legal_name })),
              loading: false,
            });
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((error) => {
        const msg = error;
        toast.error(msg);
        setVendorList({ ...vedorList, loading: false });
      });
  };

  const [labs, setLabs] = useState({
    loading: false,
    data: [],
  });

  const [formState, setFormState] = useState(initialValues);
  const [inputList, setInputList] = useState([{
    fk_unit_id: "",
    fk_witem_id: "",
    wpoi_cost: "",
    wpoi_quantity: "",
  },]);
  const [venderItems, setvenderItems] = useState({
    data: [],
    loading: false,
  });
  const [unitList, setUnitList] = useState({
    data: [],
    loading: false,
  });
  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        fk_unit_id: "",
        fk_witem_id: "",
        wpoi_cost: "",
        wpoi_quantity: "",
      },
    ]);
  };
  const getAllUnits = async () => {
    setUnitList({
      ...unitList,
      loading: true,
    });
    await MasterServices.getUnitsList()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          if(Array.isArray(data.data) && data.data.length > 0) {
            setUnitList({
              ...unitList,
              data: data.data.map(v => ({value: v.unit_id, label: v.unit_name_en})),
              loading: false,
            });
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
      });
  };
  const handleInputChange = (e, key, index) => {
    // console.log(e.target.name, e.target.value, "e.target");
    let list = [...inputList];
    if(e.value) {
      list[index][key] = e;
    } else {
      list[index][e.target.name] = e.target.value;
    }
    setInputList(list);
  };

  const getAllLabs = async () => {
    setLabs((prev) => ({ ...prev, loading: true }));
    await MasterServices.getLabs()
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          if(Array.isArray(data.data) && data.data.length > 0) {
            setLabs({
              loading: false,
              data: data.data.map(v => ({value: v.loc_id, label: v.loc_name_en})),
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllItems = async (id) => {
    setvenderItems({
      ...venderItems,
      loading: true,
    });
    await MasterServices.getVendorItemListById(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          setvenderItems({
            ...venderItems,
            data: data.data.map(v => ({value: v.fk_item_id, label: `${v.item_relation.witem_name_en} ${v.item_relation.witem_item_code ? `- ${v.item_relation.witem_item_code}` : ''}`})),
            loading: false,
          });
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
      });
  };

  const getSinglePurchaseOrder = async (id) => {
    await MasterServices.getSinglePurchaseOrder(id)
      .then((res) => {
        const { data, status } = res;
        if (status === 200) {
          if(data.data && data.data.wpo_id) {
            setFormState({
              vendor_id: {label: data.data.wpo_vendor_relation.vendor_legal_name, value: data.data.fk_vendor_id},
              loc_id: {label: data.data.wpo_hsp_loc_relation.loc_name_en , value: data.data.fk_loc_id },
              po_id: "",
              draft_flag: "",
              purchase_order_items: [],
            })
            if( Array.isArray(data.data.w_purchase_order_items) && data.data.w_purchase_order_items.length > 0 ) { 
              let tempInputList = data.data.w_purchase_order_items.map(v => ({
                wpoi_id: v.wpoi_id,
                fk_unit_id: { label:v.w_unit_relation.unit_name_en , value: v.fk_unit_id },
                fk_witem_id: { label: v.w_item_relation.witem_name_en, value: v.fk_witem_id },
                wpoi_cost: v.wpoi_cost,
                wpoi_quantity: v.wpoi_quantity,
              }))
              setInputList(tempInputList)
            }
          }
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
      })
      .catch((err) => {
        const msg = err;
        toast.error(msg);
      });
  };

  const addNewPurchaseOrder = async (payload) => {
    try{

      await MasterServices.addPurchaseOrder(payload).then((response) => {
        const { data, status } = response;
        if (status == 200 && data.status) {
          toast.success(data.message);
        } else {
          toast.error(data.message)
        }
      });
    } catch (error) {
      console.log(error)
    }
  };

  const handleRemoveClick = (index) => {
    let list = inputList;
    const finalList = inputList.filter((ele, i) => i !== index);
    console.log(finalList, "finalList");
    // list.splice(index, 0);
    setInputList([...finalList]);
  };

  useEffect(() => {
    console.log("OrderId: ", OrderId);
    if (OrderId) {
      getSinglePurchaseOrder(OrderId);
    }
  }, [OrderId]);

  const handleSubmitClick = () => {
    if(!formState.loc_id) {
      // errors.loc_id = 'Please select location'
    }
    if(!formState.vendor_id) {
      // errors.vendor_id = 'Please select Vendor'
    }
  }

  const handleSubmit = async () => {
    console.log(inputList)
    for(let i=0; i<inputList.length; i++) {
      let purchase_order_item = inputList[i];
      console.log(purchase_order_item)
      if(!purchase_order_item.fk_unit_id || !purchase_order_item.fk_witem_id || !purchase_order_item.wpoi_cost || !purchase_order_item.wpoi_quantity) {
        setShowError(true);
        console.log('handled from purchase items')
        return false
      }
    }

    if(!formState.vendor_id.value || !formState.loc_id.value) {
      setShowError(true);
      console.log('handled from form')
      return false
    }
    setShowError(false)

    let body = {
      vendor_id: parseInt(formState.vendor_id.value),
      loc_id: parseInt(formState.loc_id.value),
      draft_flag: 0,
      purchase_order_items: inputList.map( v => ({...v, fk_unit_id: v.fk_unit_id.value, fk_witem_id: v.fk_witem_id.value})),
    };
    if (OrderId) {
      body.po_id = OrderId;
      await addNewPurchaseOrder(body);
      navigate("/purchase_order");
    } else {
      await addNewPurchaseOrder(body);
      navigate("/purchase_order");
    }
  }
  
  return (
    <div className="px-4 sm:px-6 lg:px-8 h-screen">
      <Breadcrumb pages={pages} />
      <div>
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="inline-flex mb-3 justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
        >
          <span className="w-5 mr-2">
            <ArrowUturnLeftIcon size={18} />
          </span>{" "}
          Back
        </button>
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">
            {OrderId ? "Update Purchase Order" : "Add Purchase Order"}
          </h1>
        </div>
      </div>
      {loading ? (
        <FallingLinesLoader />
      ) : (
        <form
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5 sm:pt-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="vendor_id"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Vendor
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                    <Select
                      name="vendor_id"
                      id="vendor_id"
                      defaultValue={formState.vendor_id}
                      value={formState.vendor_id}
                      onChange={ e => {
                        getAllItems(e.value);
                        setFormState({...formState, vendor_id: e});
                      }}
                      placeholder="Select Vendor"
                      options={vedorList.data}
                      className="block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    />
                    {!formState.vendor_id?.value && showError ? (
                      <p className="text-red-700">Please select vendor</p>
                    ) : null}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="vendor_id"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Location
                  </label>
                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <Select
                      name="loc_id"
                      id="loc_id"
                      defaultValue={formState.loc_id}
                      value={formState.loc_id}
                      placeholder="Select Location"
                      onChange={ e => {setFormState({...formState, loc_id: e})}}
                      options={labs.data}
                      className="block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                    />
                    {!formState.loc_id?.value && showError ? (
                      <p className="text-red-700">Please select location</p>
                    ) : null}
                  </div>
                </div>

                {inputList.map((x, index) => (
                  <div
                    key={index}
                    className="mt-1 w-full flex sm:col-span-2 sm:mt-0"
                  >
                    <div className="block w-full mr-4 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm">
                      <Select
                        id="fk_unit_id"
                        name="fk_unit_id"
                        placeholder="Select Unit"
                        value={x["fk_unit_id"]}
                        onChange={(e) => handleInputChange(e, "fk_unit_id", index)}
                        options={unitList.data}
                        className="block w-full mr-4 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                      />
                      {!inputList[index].fk_unit_id && showError ? (
                        <p className="text-red-700">Please select unit</p>
                      ) : null}
                    </div>
                    <div className="block w-full mr-4 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm">
                      <Select
                        id="fk_witem_id"
                        name="fk_witem_id"
                        value={x["fk_witem_id"]}
                        placeholder="Select Item"
                        onChange={(e) => handleInputChange(e, "fk_witem_id", index)}
                        options={venderItems.data}
                        className="block w-full mr-4 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                      />
                      {!inputList[index].fk_witem_id && showError ? (
                        <p className="text-red-700">Please select item</p>
                      ) : null}
                    </div>
                    <div className="block w-full mr-4 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm">
                      <input
                        type="number"
                        name="wpoi_cost"
                        id="wpoi_cost"
                        value={x.wpoi_cost}
                        placeholder="Cost"
                        autoComplete="off"
                        onChange={(e) => handleInputChange(e, "wpoi_cost", index)}
                        className="block w-full mr-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      /> 
                        {!inputList[index].wpoi_cost && showError ? (
                          <p className="text-red-700">Please enter cost</p>
                        ) : null}
                    </div>
                    <div className="block w-full mr-4 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm">
                      <input
                        type="number"
                        name="wpoi_quantity"
                        id="wpoi_quantity"
                        value={x.wpoi_quantity}
                        placeholder="Quantity"
                        autoComplete="off"
                        onChange={(e) => handleInputChange(e, "wpoi_quantity", index)}
                        className="ml-2 block w-full mr-4 max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                      />
                        {!inputList[index].wpoi_quantity && showError ? (
                          <p className="text-red-700">Please enter quantity</p>
                        ) : null}
                    </div>
                    <button
                      type="button"
                      className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-"
                      onClick={() => handleRemoveClick(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  className="w-1/3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-"
                  onClick={handleAddClick}
                >
                  Add more
                </button>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={e => handleSubmit()}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default AddProgram;
