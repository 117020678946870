import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Table from "../../../components/tables/table";
import TableEmpty from "../../../components/tables/tableEmpty";
import {
  crm_lab_test_list_columns,
  crm_clinic_test_list_columns,
  crm_tests_test_params_columns,
} from "../../../components/tables/tableheader";
import { toast } from "react-toast";
import MasterServices from "../../../ApiServices/MasterServices";
import Modal from "../../../components/Modal";

const initialFilters = {
  filterClinic: "",
  filterHosLoc: "",
};

const initialModalStateTestParams = {
  state: false,
  data: [],
  type: "Package Test List"
}

const ReferralTestList = () => {
  // earlier only showed referral test list - now shows all tests
  const pages = [{ title: "Test Package List", href: "/test-package-list" }];

  const [isLoading, setIsLoading] = useState(false);

  const [clinicList, setClinicList] = useState(false);

  const [locList, setLocList] = useState(false);

  const [cLoading, setCLoading] = useState(true);

  const [filterHosLoc, setFilterHosLoc] = useState(initialFilters.filterHosLoc);
  const [filterClinic, setFilterClinic] = useState(initialFilters.filterClinic);

  const [testList, setTestList] = useState([]);

  const [modalData, setModalData] = useState(initialModalStateTestParams);

  useEffect(() => {
    getAllLocations();
  }, []);

  const getCRMTestList = () => {
    setIsLoading(true);
    // MasterServices.getRefClinicTestList(filterRefClinic)
    MasterServices.getCRMTestList(filterClinic, filterHosLoc)
      .then((response) => {
        const { data, status } = response;
        if (status == 200) {
          setTestList(data.data);
        } else {
          toast.error("Fatal Error Please Contact Admin");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        const msg = "Fatal Error RC Test List";
        toast.error(msg);
        setIsLoading(false);
      });
  };

  async function getLocBasedClinicList(loc_id) {
    setCLoading(true);
    try {
      const { data } = await MasterServices.getClinicsBasedOnLocation(loc_id);
      if (data.status) {
        let newRCs = data.data;
        let RCs = newRCs.map((el) => {
          let obj = { ...el, label: el.mc_name, value: el.mc_id };
          return obj;
        });
        setClinicList(RCs);
      }
      setCLoading(false);
    } catch (error) {
      console.log(error);
      setCLoading(false);
    }
  }

  async function getAllLocations() {
    // setRCLoading(true);
    try {
      const { data } = await MasterServices.getAllLocationList();
      if (data.status) {
        console.log("getAllLocations", data.data);
        let loc = data.data;
        let Locs = loc.map((el) => {
          let obj = { ...el, label: el.loc_name_en, value: el.loc_id };
          return obj;
        });
        setLocList(Locs);
      }
      // setRCLoading(false);
    } catch (error) {
      console.log(error);
      // setRCLoading(false);
    }
  }

  const openViewModal = (data, type) => {
    setModalData({
      data: data,
      state: true,
      type: type,
    });
  };

  const renderViewTestsModal = () => {
    const { state, data, type } = modalData;

    return (
      <Modal
        title={type}
        open={state}
        size="max-w-3xl relative"
        setOpen={() => setModalData((prev) => ({ ...prev, state: false }))}
      >
        <Table
          columns={crm_tests_test_params_columns({type: type})}
          data={data && data.length > 0 ? data : []}
        />
      </Modal>
    );
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">
          Test and Package List
        </h1>
        <p className="mt-2 text-sm text-gray-700">
          A list of all the tests and packages.
        </p>
      </div>

      <div className="mt-5 flex gap-3 items-center">
        <div className="basis-1/4">
          <label
            htmlFor=""
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Location
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <select
              value={filterHosLoc}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
              name="hsp_loc_id"
              id="hsp_loc_id"
              onInput={(e) => {
                setFilterHosLoc(e.target.value);
                getLocBasedClinicList(e.target.value);
                setTestList([]);
                setFilterClinic("");
              }}
            >
              <option value={0}>Select Location</option>
              {locList.length > 0 ? (
                locList.map((loc, i) => (
                  <option
                    selected={
                      loc.loc_id === initialFilters.filterHosLoc
                        ? "selected"
                        : ""
                    }
                    key={i}
                    value={loc.loc_id}
                  >
                    {loc.loc_name_en}
                  </option>
                ))
              ) : (
                <></>
              )}
            </select>
          </div>
        </div>

        {!cLoading && filterHosLoc && clinicList?.length > 0 ? (
          <div className="basis-1/4">
            <label
              htmlFor=""
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Clinic
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <select
                value={filterClinic}
                className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                name="mc_id"
                id="mc_id"
                onInput={(e) => {
                  // setIsLoading(true)
                  setTestList([]);
                  setFilterClinic(e.target.value);
                }}
              >
                <option value={0}>Select Clinic</option>
                {clinicList.length > 0 ? (
                  clinicList.map((clinic, i) => (
                    <option
                      selected={
                        clinic.mc_id === initialFilters.filterClinic
                          ? "selected"
                          : ""
                      }
                      key={i}
                      value={clinic.mc_id}
                    >
                      {clinic.mc_name}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>
          </div>
        ) : null}

        <button
          disabled={isLoading}
          onClick={() => {
            getCRMTestList();
          }}
          className={`ml-3 h-10 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 py-2 mt-7 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${
            isLoading ? "opacity-40" : "opacity-100"
          }`}
        >
          Get Data
        </button>
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : testList.length > 0 ? (
        <Table
          columns={
            filterHosLoc != "" && filterClinic != ""
              ? crm_clinic_test_list_columns({openViewModal})
              : crm_lab_test_list_columns({openViewModal})
          }
          data={testList}
        />
      ) : (
        <TableEmpty />
      )}
      {renderViewTestsModal()}
    </div>
  );
};

export default ReferralTestList;
