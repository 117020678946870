import moment from 'moment';
import { Link } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';
import { GrCheckmark } from 'react-icons/gr';
import { capitalise } from '../../utilities/utilities';
import { useEffect, useRef, useState } from 'react';
import { Switch } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import EditTooptip from '../inlineEdit';
import FileResultUpload from '../fileResultUpload';
import DropDownResult from '../dropdownResult';
import ColorDropDownResult from '../dropdownResult/colorResultDD';
import EditQCCorrectionValTooptip from '../inlineQCCorrectionVal';
import { EditResultTooptip } from '../inlineEditResult';
import { PencilIcon } from '@heroicons/react/24/outline';
import { convertToAnotherTimezone, convertUTStoIST } from '../../helper/commonHelper';
import Barcode from 'react-barcode';
import './style.css'; // import CSS file
import { FaDownload, FaSpinner } from 'react-icons/fa';
import { SlEnvolope } from "react-icons/sl";
import { useDispatch } from 'react-redux';
import spCheckBoxReducer from '../../redux/spCheckBox/reducers';
import { setSPCheckBoxes } from '../../redux/spCheckBox/actions';
import { useSelector } from 'react-redux';
import CustomResultDropDownResult from '../dropdownResult/customSelectResult';
import Modal from '../Modal';
import { Button } from './paginationButtons';


function testStatusToggle(...testStatus) {
  return testStatus.filter(Boolean).join(' ');
}
function q_testStatusToggle(...q_testStatus) {
  return q_testStatus.filter(Boolean).join(' ');
}
function hospitalStatusToggle(...hospitalStatus) {
  return hospitalStatus.filter(Boolean).join(' ');
}
function categoryStatusToggle(...categoryStatus) {
  return categoryStatus.filter(Boolean).join(' ');
}
function packageStatusToggle(...packageStatus) {
  return packageStatus.filter(Boolean).join(' ');
}
function priceStatusToggle(...priceStatus) {
  return priceStatus.filter(Boolean).join(' ');
}
function outclinicStatusToggle(...outClinic) {
  return outClinic.filter(Boolean).join(' ');
}
function vatStatusToggle(...vatStatus) {
  return vatStatus.filter(Boolean).join(' ');
}
function sampleTypeStatusToggle(...sampleTypeStatus) {
  return sampleTypeStatus.filter(Boolean).join(' ');
}

function getSampleParameterResultStatus(db_sample_status, result_value, ssp_approved_by){

  let sample_parameter_status = db_sample_status
  // console.log("Abhi:", {db_sample_status, result_value, ssp_approved_by})
  if(result_value){
    if(ssp_approved_by){
      sample_parameter_status = "Results Approved"
    }else{
      sample_parameter_status = "Pending Approval"
    }
  }else{
    sample_parameter_status = "Under Processing"
  }
  
  return sample_parameter_status
}

export const hospital_columns = ({ onDeleteOpen, handleDrawer }) => [
  // {
  //   Header: "Hospital Id",
  //   // accessor: (d) => capitalise(d.hos_id),
  //   Cell: (cell) => {
  //     const cellId = cell.row.original.hos_id;
  //     return (
  //       <>
  //         <Link
  //           to={`/location/${cellId}`}
  //           className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
  //         >
  //           {cellId}
  //         </Link>
  //       </>
  //     );
  //   },
  // },
  {
    Header: 'Lab EN Name',
    accessor: (d) => capitalise(d.hos_name_en),
  },
  {
    Header: 'Lab AR Name',
    accessor: (d) => capitalise(d.hos_name_ar),
  },
  {
    Header: 'Logo',
    // accessor: "hos_logo_url",
    Cell: (cell) => {
      return cell.row.original.hos_logo_url ? (
        <>
          <img
            width={40}
            height={20}
            src={cell.row.original.hos_logo_url}
            alt={cell.row.original.hos_name_en}
          />
        </>
      ) : (
        <></>
      );
    },
  },

  {
    Header: 'Status',
    accessor: (d) => (d.hos_is_active == true ? 'Active' : 'Inactive'),
    Cell: (cell) => {
      const cellId = cell.row.original.hos_id;
      const enabled = cell.row.original.hos_is_active;
      // const [enabled, setEnabled] = useState()
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.hos_is_active);
          }}
          className={hospitalStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={hospitalStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/hospital-tab/${cell.row.original.hos_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          {/* <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
						<button
							onClick={() => {
								onDeleteOpen(cell.row.original.hos_id, cell.row.original.hos_is_active);
							}}
							size="default"
							variant="outlined"
						>
							{cell.row.original.hos_is_active == true ? 'In-Activate' : 'Activate'}
							<span className="sr-only">, </span>
						</button>
					</span> */}
        </>
      );
    },
  },
];

export const outsource_clinic_columns = (id) => [
  {
    Header: "Clinic Name",
    accessor: (d) => capitalise(d.mc_name),
  },
  {
    Header: "Mobile",
    accessor: (d) => capitalise(d.mc_phone),
  },
  {
    Header: "Email",
    accessor: (d) => capitalise(d.mc_email),
  },
  {
    Header: "Postal Code",
    accessor: (d) => capitalise(d.mc_postalCode),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-outsourced-clinic/${cell.row.original.mc_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <Link
            to={`/outsourced-clinic-credit-spend-details/${cell.row.original.mc_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-purple-600 bg-purple-100"
          >
            <button
              className="text-purple-900 "
              size="default"
              variant="outlined"
            >
              View Credit Spend Logs<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
];

export const clinic_refferal_reservations = ({ calculateFinalPayment }) => [
  {
    Header: "Reservation ID",
    accessor: (d) => d.reservation_id,
  },
  {
    Header: "Name",
    accessor: (d) => capitalise(d.rev_patient_parent_fname),
  },
  {
    Header: "Reservation Amount",
    accessor: (d) => d.rev_amount,
  },
  {
    Header: "Discount Percentage",
    accessor: (d) => `${d.rev_discount_per}%`,
  },
  {
    Header: "Discount Price",
    accessor: (d) => d.rev_discounted_amount,
  },
  {
    Header: "Final Payable Amount",
    accessor: (d) => calculateFinalPayment(d),
  }
]

export const warehouse_item_category = ({ onDeleteOpen }) => [
  {
    Header: "ID",
    accessor: (d) => d.wic_id,
  },
  {
    Header: "Name",
    accessor: (d) => capitalise(d.wic_name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-warehouse-item-category/${cell.row.original.wic_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <div className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              size="default"
              variant="outlined"
              onClick={e => onDeleteOpen({ wic_id: cell.row.original.wic_id })}
            >
              Delete<span className="sr-only">, </span>
            </button>
          </div>
        </>
      );
    },
  },
  // {
  //   Header: "Delete",
  //   accessor: "delete",
  //   Cell: (cell) => {
  //     return (
  //       <>
  //         <button
  //           className="text-red-500 "
  //           size="default"
  //           variant="outlined"
  //           onClick={e => onDeleteOpen({ wic_id: cell.row.original.wic_id })}
  //         >
  //           Remove
  //         </button>
  //       </>
  //     );
  //   },
  // },
];

export const methodology = ({ onDeleteOpen }) => [
  {
    Header: "ID",
    accessor: (d) => d.methodology_id,
  },
  {
    Header: "Name",
    accessor: (d) => capitalise(d.methodology_name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-methodology/${cell.row.original.methodology_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <div className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              size="default"
              variant="outlined"
              onClick={e => onDeleteOpen({ methodology_id: cell.row.original.methodology_id })}
            >
              Delete<span className="sr-only">, </span>
            </button>
          </div>
        </>
      );
    },
  },
  // {
  //   Header: "Delete",
  //   accessor: "delete",
  //   Cell: (cell) => {
  //     return (
  //       <>
  //         <button
  //           className="text-red-500 "
  //           size="default"
  //           variant="outlined"
  //           onClick={e => onDeleteOpen({ wic_id: cell.row.original.wic_id })}
  //         >
  //           Remove
  //         </button>
  //       </>
  //     );
  //   },
  // },
];


export const coupon_code_list = ({ openViewModal }) => [
  {
    Header: "Coupon Code",
    accessor: (d) => d.cpc_code,
  },
  {
    Header: "Test / Package",
    accessor: "view",
    Cell: (cell) => {
      return (
        <>
          {Array.isArray(cell.row.original.resv_trnx_logs) && cell.row.original.resv_trnx_logs.length > 0 ?
            <button
              className="text-red-500 "
              size="default"
              variant="outlined"
              onClick={e => openViewModal(cell.row.original.resv_trnx_logs)}
            >
              View
            </button>
            :
            <span>Not Used</span>
          }
        </>
      );
    },
  },
  {
    Header: "Coupon amount received",
    accessor: (d) => d.resv_trnx_logs.reduce((accumulator, object) => {
      return accumulator + object.resv_tx_discount_cp_value;
    }, 0)
  },
  {
    Header: "User",
    accessor: (d) => d.cpc_cu_relation.user_first_name,
  },
  {
    Header: "Date",
    accessor: (d) => d.cpc_created_at && moment(convertToAnotherTimezone(d.cpc_created_at)).format('MM-DD-YYYY hh:mm A'),
  },
]

export const coupon_code_res_logs = ({ }) => [
  {
    Header: "Reservation ID",
    accessor: (d) => d.resv_tx_resv_id,
  },
  {
    Header: "Patient Name",
    accessor: (d) => capitalise(d.resv_tx_patient_fname_en),
  },
  {
    Header: "Test Name",
    accessor: (d) => d.resv_tx_test_name_en,
  },
  {
    Header: "Discount Percentage",
    accessor: (d) => d.resv_tx_discount_cp == 0 ? '0' : d.resv_tx_discount_cp_value,
  },
  {
    Header: "Discount Amount",
    accessor: (d) => d.resv_tx_discount_cp,
  },
  {
    Header: "Amount",
    accessor: (d) => d.resv_tx_price,
  },
  {
    Header: "Final Amount",
    accessor: (d) => (parseFloat(d.resv_tx_price) - parseFloat(d.resv_tx_discount_cp)),
  },
  {
    Header: "Date",
    accessor: (d) => moment(convertToAnotherTimezone(d.resv_tx_created_at)).format('DD-MM-YYYY hh:mm A'),
  },
]


export const clinic_refferal = ({ onDeleteOpen, openViewModal }) => [
  {
    Header: "Name",
    accessor: (d) => capitalise(d.resv_ref_name),
  },
  {
    Header: "Ref Phone",
    accessor: (d) => capitalise(d.resv_ref_phone),
  },
  {
    Header: "Contact Address",
    accessor: (d) => capitalise(d.resv_ref_contact_address),
  },
  {
    Header: "Discount(%)",
    accessor: (d) => `${d.resv_ref_discount_number}%`,
  },
  {
    Header: "View Logs",
    accessor: "viewLogs",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/clinic-refferal/${cell.row.original.resv_ref_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              View<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
  // {
  //   Header: "view",
  //   accessor: "view",
  //   Cell: (cell) => {
  //     return (
  //       <>
  //          <button
  //           className="text-red-500 "
  //           size="default"
  //           variant="outlined"
  //           onClick={e => openViewModal(cell.row.original.t_reservation)}
  //         >
  //           View
  //         </button>
  //       </>
  //     );
  //   },
  // },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-clinic-refferal/${cell.row.original.resv_ref_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
  {
    Header: "Delete",
    accessor: "delete",
    Cell: (cell) => {
      return (
        <>
          <button
            className="text-red-500 "
            size="default"
            variant="outlined"
            onClick={e => onDeleteOpen({ resv_ref_id: cell.row.original.resv_ref_id })}
          >
            Remove
          </button>
        </>
      );
    },
  },
];


export const test_color_code = (id) => [
  {
    Header: "Color Name",
    accessor: (d) => capitalise(d.mclr_name),
  },
  {
    Header: "Color Hex Code",
    accessor: "color",
    Cell: (cell) => {
      return (
        <>
          {cell.row.original.mclr_hex_code}
          <input
            type="color"
            name="mclr_hex_code"
            id="mclr_hex_code"
            autoComplete="color hex code"
            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:text-sm"
            value={cell.row.original.mclr_hex_code}
            disabled
          />
        </>
      )
    }
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-test-color/${cell.row.original.mclr_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
];

export const pricelist_columns = () => [
  {
    Header: 'Package',
    accessor: (d) => capitalise(d.package),
  },
  {
    Header: 'Type',
    accessor: (d) => capitalise(d.type),
  },
  {
    Header: 'Price',
    accessor: (d) => capitalise(d.price),
  },
];

export const result_list_columns = (onDeleteOpen, updateQres_correction_value) => [
  {
    Header: 'Qc Name',
    accessor: (d) => capitalise(d.q_qc_relation?.qc_name_en),
  },
  {
    Header: 'Lot Name',
    accessor: (d) => capitalise(d.q_lot_relation?.lot_name_en),
  },
  {
    Header: 'Test Name',
    accessor: (d) => capitalise(d.t_test_relation?.test_name_en),
  },
  {
    Header: 'Params Name',
    accessor: (d) => capitalise(d.t_test_params_relation?.ttp_name_en),
  },
  {
    Header: 'Mean',
    accessor: (d) => capitalise(d.mean),
  },
  {
    Header: 'Sd',
    accessor: (d) => capitalise(d.sd),
  },
  {
    Header: 'Normal Range',
    accessor: (d) => capitalise(d.normal_range),
  },
  {
    Header: 'Result',
    accessor: (d) => capitalise(d.qres_value),
  },
  {
    Header: 'Result Time',
    accessor: (d) => capitalise(d.qres_time_of_result),
  },
  {
    Header: "Status",
    accessor: (d) => (d.qres_is_active == true ? "Active" : "Inactive"),
    Cell: (cell) => {
      const cellId = cell.row.original.qres_id;
      const enabled = cell.row.original.qres_is_active;
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.qres_is_active);
          }}
          className={testStatusToggle(
            enabled ? "bg-cyan-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={testStatusToggle(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Created By',
    accessor: (d) => capitalise(d.qres_cu_relation?.user_first_name + ' ' + d.qres_cu_relation?.user_last_name),
  },
  {
    Header: 'Created At',
    accessor: (d) => moment(convertToAnotherTimezone(d.qres_created_at)).format('DD-MM-YYYY'),
  },
  // {
  //   Header: 'Results Log',
  //   accessor: '',
  //   Cell: (cell) => {
  //     return (
  //       <span
  //         className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
  //       >
  //         <button
  //           className="text-cyan-900 "
  //           size="default"
  //           variant="outlined"
  //         >
  //           View
  //         </button>
  //       </span>
  //     );
  //   },
  // },
  {
    Header: 'Action',
    accessor: 'qres_correction_value',
    Cell: (cell) => {
      return (
        <p className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
          {cell.row.original.qres_correction_value && cell.row.original.qres_correction_value > 0 ? (
            cell.row.original.qres_correction_value
          ) : (
            <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditQCCorrectionValTooptip
                  fieldName="qres_correction_value"
                  CellId={cell.row.original.qres_id}
                  qres_id={cell.row.original.qres_id}
                  func={updateQres_correction_value}
                  Nfunc="updateQres_correction_value"
                />
              }
            >
              <button className="text-cyan-900 "
                size="default"
                variant="outlined">Add Correction Result</button>
            </Tippy>
          )}
        </p>
      );
    },

    // Cell: (cell) => {
    //   return (
    //     <span
    //       className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
    //     >
    //       <button
    //         className="text-cyan-900 "
    //         size="default"
    //         variant="outlined"
    //       >
    //         Add Correction Result
    //       </button>
    //     </span>
    //   );
    // },
  },
];

export const patient_data_columns = () => [
  {
    Header: 'Test',
    accessor: 'test',
  },
  {
    Header: 'Name',
    accessor: 'p_name',
  },
  {
    Header: 'Mobile',
    accessor: 'phone',
  },
  {
    Header: 'Identity',
    accessor: 'identity',
  },
  {
    Header: 'ID No.',
    accessor: 'id_no',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'DOB',
    accessor: 'dob',
  },
  {
    Header: 'Gender',
    accessor: 'gender',
  },
  {
    Header: 'Questionnaire',
    accessor: 'questionnaire',
  },

  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <span
          className="inline-flex px-2 text-xs leading-5 text-red-600 bg-red-100 rounded-full"
        >
          <button
            className="text-red-900 "
            size="default"
            variant="outlined"
          >
            Remove
          </button>
        </span>
      );
    },
  },
];

export const outclinic_invoice_columns = ({ generateOSInvoiceReport }) => [
  {
    Header: 'Hospital',
    accessor: 'mc_name',
    Cell: (cell) => {
      return (
        <Link
        to={`/invoice/${cell.row.original.mc_id}`}
        className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
      >
        {cell.row.original.mc_name}
      </Link>
      );
    },
  },
  {
    Header: 'Interval',
    accessor: (d) => capitalise(d.mc_interval),
  },
  {
    Header: 'Date',
    // accessor: 'date',
    accessor: (d) => moment(convertToAnotherTimezone(d.StartDateF)).format('DD-MM-YYYY') + " / " + moment(convertToAnotherTimezone(d.EndDateF)).format('DD-MM-YYYY'),
  },
  {
    Header: 'Invoice No',
    // accessor: 'invoice_no',
    accessor: (d) => "NA",
  },
  {
    Header: 'Invoice Amount',
    // accessor: 'invoice_amount',
    accessor: (d) => capitalise(d.Total_rev_amount),
  },
  {
    Header: 'Paid Amount',
    accessor: (d) => capitalise(d.rev_payable_amount),
  },
  {
    Header: 'Pending Amount',
    accessor: (d) => "NA",
  },
  {
    Header: 'Credit Amount',
    accessor: (d) => capitalise(d.mc_credit_limit),
  },
  {
    Header: 'Status',
    accessor: (d) => "NA",
  },

  {
    Header: 'Action',
    accessor: 'download',
    Cell: (cell) => {
      return (
        <span
          className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
        >
          {cell.row.original ? (
            <button
              type="button"
              onClick={() => {
                generateOSInvoiceReport(cell.row.original);
              }}
              size="default"
              variant="outlined"
            >
              Download
            </button>
          ) : (
            <></>
          )}
        </span>
      );
    },
  },
];

export const outclinic_invoice_monthwise_columns = ({ downloadMonthlyReport, openMakePaymentModal }) => [
  {
    Header: 'Month Year',
    // accessor: 'hospital',
    accessor: (d) => capitalise(d.monthYear),
  },
  {
    Header: 'Reservation Count',
    // accessor: 'hospital',
    accessor: (d) => d.reservations.length,
  },
  {
    Header: 'Total Amount',
    // accessor: 'hospital',
    accessor: (d) => parseFloat(d.totalAmount).toFixed(2),
  },
  {
    Header: 'Payment Status',
    // accessor: 'hospital',
    accessor: (d) => d?.payment_status ? d?.payment_status : 'Pending' ,
  },
  {
    Header: 'Action',
    accessor: 'download',
    Cell: (cell) => {
      return (
        <>
        <span
          className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
        >
          <button
              type="button"
              onClick={() => {
                downloadMonthlyReport(cell.row.original);
              }}
              size="default"
              variant="outlined"
            >
              Download
            </button>
          </span>
          {!cell.row.original?.is_payment_done ? (
          <span
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100 ml-2"
          >
            <button
              type="button"
              onClick={() => {
                openMakePaymentModal(cell.row.original);
              }}
              size="default"
              variant="outlined"
            >
              Make Payment
            </button>
            </span>
          ) : (
            <></>
          )}
        </>
      );
    },
  },
];


export const price_list_columns = ({
  onDeleteOpen,
  handleDrawer,
  priceListDetails,
  showDuplicatePL
}) => [
    {
      Header: 'PriceList ID',
      accessor: 'price_id',
      Cell: (cell) => {
        return (
          <>
            <Link
              to={`/price-list/${cell.row.original.price_id}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              {cell.row.original.price_id}
            </Link>
          </>
        );
      },
    },
    {
      Header: 'Name (EN)',
      accessor: (d) => capitalise(d.price_name_en),
    },
    {
      Header: 'Name (AR)',
      accessor: (d) => capitalise(d.price_name_ar),
    },
    {
      Header: 'Price List Type',
      accessor: (d) => capitalise(d.price_type),
    },
    {
      Header: 'Status',
      accessor: (d) => (d.m_price_is_active == true ? 'Active' : 'Inactive'),
      Cell: (cell) => {
        const cellId = cell.row.original.price_id;
        const enabled = cell.row.original.m_price_is_active;
        // const [enabled, setEnabled] = useState()
        return (
          <Switch
            checked={enabled}
            onChange={() => {
              onDeleteOpen(cellId, cell.row.original.m_price_is_active);
            }}
            className={priceStatusToggle(
              enabled ? 'bg-cyan-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
            )}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={priceStatusToggle(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              )}
            />
          </Switch>
        );
      },
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (cell) => {
        return (
          <>
            <span
              onClick={() =>
                handleDrawer(
                  'edit',
                  cell.row.original.price_id,
                  cell.row.original
                )
              }
              className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </span>
            <button
            onClick={() => {
              // showDetails(cell.row.original);
              showDuplicatePL(cell.row.original)
            }}
            className="ml-2 inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
            Duplicate PL
          </button>
            {/* <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
						<button
							onClick={() => {
								onDeleteOpen(cell.row.original.price_id, cell.row.original.m_price_is_active);
							}}
							size="default"
							variant="outlined"
						>
							{cell.row.original.m_price_is_active == true ? 'In-Activate' : 'Activate'}
							<span className="sr-only">, </span>
						</button>
					</span> */}
          </>
        );
      },
    },
  ];

export const sample_type_columns = ({ handleDrawer, onDeleteOpen }) => [
  {
    Header: "Sample Type Name (EN)",
    accessor: (d) => capitalise(d.sample_type_name_en),
  },
  {
    Header: "Sample Type Name (AR)",
    accessor: (d) => capitalise(d.sample_type_name_ar),
  },
  {
    Header: "Status",
    accessor: (d) => (d.st_is_active == true ? 'Active' : 'Inactive'),
    Cell: (cell) => {
      const cellId = cell.row.original.sample_type_id;
      const enabled = cell.row.original.st_is_active;
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.st_is_active);
          }}
          className={sampleTypeStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={sampleTypeStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.sample_type_id;
      return (
        <>
          <span
            onClick={() => {
              handleDrawer(
                "edit",
                cellId,
                cell.row.original
              )
            }
            }
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    }
  },
];

export const price_list_details_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Name',
    accessor: (d) =>
      d.fk_test_id != null
        ? capitalise(d.t_test_relation.test_name_en)
        : capitalise(d.t_package_relation.package_name_en),
  },
  {
    Header: 'Cost Price',
    accessor: (d) => d.tp_price,
  },
  {
    Header: 'Selling Price',
    accessor: (d) => d.tp_selling_price ? d.tp_selling_price : 'N/A',
  },
  {
    Header: 'Home/Normal',
    accessor: (d) => (d.tp_visit_type == 'H' ? 'Home' : 'Normal'),
  },
  {
    Header: 'Test/Package',
    accessor: (d) => (d.tp_type == 'T' ? 'Test' : 'Package'),
  },
  {
    Header: 'Status',
    accessor: (d) => (d.m_price_item_is_active == true ? 'Active' : 'Inactive'),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(
                  cell.row.original.price_item_id,
                  cell.row.original.m_price_item_is_active
                );
              }}
              size="default"
              variant="outlined"
            >
              {/* {cell.row.original.m_price_item_is_active == true ? 'In-Activate' : 'Activate'} */}
              Remove
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const reference_range_columns = ({ onDeleteOpen }) => [
  {
    Header: 'Ref. ID',
    accessor: 'mrr_id',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-reference-ranges/${cell.row.original.mrr_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            {cell.row.original.mrr_id}
          </Link>
        </>
      );
    },
  },
  {
    Header: 'Ref. Name (EN)',
    accessor: (d) => capitalise(d.mrr_name_en),
  },
  {
    Header: 'Ref. Name (AR)',
    accessor: (d) => capitalise(d.mrr_name_ar),
  },
  {
    Header: 'Ref. Normal MIN',
    accessor: (d) => d.mrr_default_min,
  },
  {
    Header: 'Ref. Normal MAX',
    accessor: (d) => d.mrr_default_max,
  },
  {
    Header: 'Ref. Unit',
    accessor: (d) => capitalise(d.mrr_unit),
  },
  {
    Header: 'Action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.mrr_id);
              }}
              size="default"
              variant="outlined"
            >
              Remove
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const sub_reference_range_columns = ({ onDeleteOpen, onViewClick }) => [
  {
    Header: 'Gender',
    accessor: (d) => capitalise(d.mrr_gender),
  },
  {
    Header: 'Age From',
    accessor: (d) => d.mrr_age_from,
  },
  {
    Header: 'Age To',
    accessor: (d) => d.mrr_age_to,
  },
  {
    Header: 'Normal MIN',
    accessor: (d) => d.mrr_sub_normal_min,
  },
  {
    Header: 'Normal MAX',
    accessor: (d) => d.mrr_sub_normal_max,
  },
  {
    Header: 'Comment',
    accessor: (d) => capitalise(d.mrr_comments),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-gray-600 bg-gray-100 rounded-full">
            <button
              onClick={() => {
                onViewClick(cell.row.original);
              }}
              size="default"
              variant="outlined"
            >
              View
              <span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.mrr_id);
              }}
              size="default"
              variant="outlined"
            >
              Remove
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const location_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Hospital EN Name',
    accessor: (d) => capitalise(d.m_hospital_relation?.hos_name_en),
  },
  {
    Header: 'Region',
    accessor: (d) => capitalise(d.allRegionLocMap?.m_region_relation?.rgn_name_en),
  },
  {
    Header: 'Country',
    accessor: (d) => capitalise(d.city_relation?.m_state_relation?.m_country_relation?.country_name),
  },
  {
    Header: 'State',
    accessor: (d) => capitalise(d.city_relation?.m_state_relation?.state_name_en),
  },
  {
    Header: 'City',
    accessor: (d) => capitalise(d.city_relation?.city_name_en)
  },
  {
    Header: 'Address',
    accessor: (d) => capitalise(d.loc_add)
  },
  {
    Header: 'Pincode',
    accessor: (d) => capitalise(d.loc_pincode)
  },
  {
    Header: 'Location EN Name',
    accessor: (d) => capitalise(d.loc_name_en),
  },
  {
    Header: 'Location AR Name',
    accessor: (d) => capitalise(d.loc_name_ar),
  },
  {
    Header: 'Logo',
    // accessor: "loc_logo_url",
    Cell: (cell) => {
      return cell.row.original.loc_logo_url ? (
        <>
          <img
            width={40}
            height={20}
            src={cell.row.original.loc_logo_url}
            alt={cell.row.original.loc_name_en}
          />
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Status',
    accessor: (d) => (d.loc_is_active == true ? 'Active' : 'Inactive'),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/lab-test/${cell.row.original.loc_id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            View Test
          </Link>
          <span
            onClick={() =>
              handleDrawer('edit', cell.row.original.loc_id, cell.row.original)
            }
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(
                  cell.row.original.loc_id,
                  cell.row.original.loc_is_active
                );
              }}
              size="default"
              variant="outlined"
            >
              {cell.row.original.loc_is_active == true
                ? 'In-Activate'
                : 'Activate'}
            </button>
          </span>
        </>
      );
    },
  },
];

export const globalSetting_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Country',
    accessor: (d) => capitalise(d.gs_country_relation?.country_name),
  },

  {
    Header: 'Vat Type',
    accessor: (d) => capitalise(d.gs_vatType),
  },
  {
    Header: 'Vat Value',
    accessor: 'gs_vat',
  },
  {
    Header: 'Vat Number',
    accessor: 'gs_vat_number',
  },
  {
    Header: 'Status',
    accessor: (d) => (d.gs_is_active == true ? 'Active' : 'Inactive'),
    Cell: (cell) => {
      const cellId = cell.row.original.gs_id;
      const enabled = cell.row.original.gs_is_active;
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.gs_is_active);
          }}
          className={hospitalStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={hospitalStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <span
            onClick={() =>
              handleDrawer('edit', cell.row.original.gs_id, cell.row.original)
            }
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const location_test_columns = ({ onDeleteOpen, handleDrawer }) => [
  // {
  //   Header: 'Hospital (EN)',
  //   accessor: (d) =>
  //     capitalise(d.t_loc_relation?.m_hospital_relation?.hos_name_en),
  // },
  // {
  //   Header: 'Location (EN)',
  //   accessor: (d) => capitalise(d.t_loc_relation?.loc_name_en),
  // },
  {
    Header: 'Test (EN)',
    accessor: (d) => capitalise(d.t_test_relation?.test_name_en),
  },
  {
    Header: 'Test (AR)',
    accessor: (d) => capitalise(d.t_test_relation?.test_name_ar),
  },
  {
    Header: 'Img (EN)',
    Cell: (cell) => {
      return cell.row.original.t_test_relation?.test_img_en ? (
        <>
          <img
            width={40}
            height={20}
            src={cell.row.original.t_test_relation?.test_img_en}
            alt={cell.row.original.t_test_relation?.test_name_en}
          />
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Img (AR)',
    Cell: (cell) => {
      return cell.row.original.test_img_ar ? (
        <>
          <img
            width={40}
            height={20}
            src={cell.row.original.t_test_relation?.test_img_ar}
            alt={cell.row.original.t_test_relation?.test_name_ar}
          />
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Lab Analyzers',
    Cell: (cell) => {
      const cellId = cell.row.original
      let la = []
      if (cellId?.lab_analyzers.length > 0) {
        cellId.lab_analyzers.forEach((l) => {
          la.push(l.name)
        })
      }
      return (
        <div className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>{la.join(", ")}</div>
      )
    }
    // accessor: (d) => d.lab_analyzers[0]?.name,
  },
  // {
  //   Header: 'Status',
  //   accessor: (d) => (d.loc_test_is_active == true ? 'Active' : 'Inactive'),
  //   Cell: (cell) => {
  //     const cellId = cell.row.original.loc_test_id;
  //     const enabled = cell.row.original.loc_test_is_active;
  //     // const [enabled, setEnabled] = useState()
  //     return (
  //       <Switch
  //         checked={enabled}
  //         onChange={() => {
  //           onDeleteOpen(cellId, cell.row.original.loc_test_is_active);
  //         }}
  //         className={testStatusToggle(
  //           enabled ? 'bg-cyan-600' : 'bg-gray-200',
  //           'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
  //         )}
  //       >
  //         <span className="sr-only">Use setting</span>
  //         <span
  //           aria-hidden="true"
  //           className={testStatusToggle(
  //             enabled ? 'translate-x-5' : 'translate-x-0',
  //             'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
  //           )}
  //         />
  //       </Switch>
  //     );
  //   },
  // },
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  //   Cell: (cell) => {
  //     return (
  //       <>
  //         <span
  //           onClick={() =>
  //             handleDrawer(
  //               'edit',
  //               cell.row.original.loc_test_id,
  //               cell.row.original
  //             )
  //           }
  //           className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
  //         >
  //           <button
  //             className="text-cyan-900 "
  //             size="default"
  //             variant="outlined"
  //           >
  //             Edit<span className="sr-only">, </span>
  //           </button>
  //         </span>
  //       </>
  //     );
  //   },
  // },
];

export const user_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Name',
    accessor: (d) =>
      capitalise(d.user_first_name) + ' ' + capitalise(d.user_last_name),
  },
  {
    Header: 'Email',
    accessor: 'user_email',
  },
  {
    Header: 'Mobile',
    accessor: 'user_mobile',
  },
  // {
  // 	Header: "Role",
  // 	accessor: (d) => capitalise(d.m_role.role_name),
  // },
  // {
  // 	Header: "Location",
  // 	accessor: (d) => capitalise(d.location.location_name),
  // },
  {
    Header: 'Status',
    accessor: (d) => (d.user_is_active == true ? 'Active' : 'Inactive'),
    Cell: (cell) => {
      const cellId = cell.row.original.user_id;
      const enabled = cell.row.original.user_is_active;
      // const [enabled, setEnabled] = useState()
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.user_is_active);
          }}
          className={testStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={testStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.user_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          {/* <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.user_id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span> */}
        </>
      );
    },
  },
];

export const patient_list_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Name',
    // accessor: 'name',
    accessor: (d)=> capitalise(d.user_first_name) + ' ' + capitalise(d.user_last_name),
    // Cell: (cell) => {
    //   const name = capitalise(cell.row.original.user_first_name) + ' ' + capitalise(cell.row.original.user_last_name);
    //   return (
    //     <>
    //       <span

    //       >
    //         {name}
    //       </span>
    //     </>
    //   );
    // }
  },
  {
    Header: 'Email',
    accessor: 'user_email',
  },
  {
    Header: 'Mobile',
    accessor: 'user_mobile',
  },
  {
    Header: 'Gender',
    accessor: 'm_gender_relation.gender_name_en',
  },
  {
    Header: 'Date of Birth',
    // accessor: 'user_dob',
    accessor: (d) => d.user_dob ? moment(d.user_dob).format('DD-MM-YYYY') : '',
  },
  {
    Header: 'MRN No.',
    accessor: 'patient_report_mrn',
  },
  {
    Header: 'Patient Creation Date',
    // accessor: 'user_created_at',
    accessor: (d) => moment(convertToAnotherTimezone(d.user_created_at)).format('DD-MM-YYYY'),
  },
  {
    Header: 'Details',
    accessor: 'ID',
    Cell: (cell) => {
      const user_id = cell.row.original.user_id;
      return (
        <>
          <Link
            to={`/all-member-list/${user_id}`}
            className="inline-flex px-2 text-sm leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            View
          </Link>
        </>
      );
    }
  },
];

export const patient_member_list_columns = ({ showDetails, showEditPatient }) => [
  {
    Header: 'Name',
    accessor: 'name',
    Cell: (cell) => {
      const name = capitalise(cell.row.original.patient_first_name) + ' ' + capitalise(cell.row.original.patient_last_name);
      return (
        <>
          <span
            className="inline-flex px-2 text-sm leading-5 text-gray-600"
          >
            {name}
          </span>
        </>
      );
    }
  },
  {
    Header: "Relation",
    accessor: "relationship",
  },
  {
    Header: 'Email',
    accessor: 'patient_email',
  },
  {
    Header: 'Mobile',
    accessor: 'patient_mobile',
  },
  {
    Header: 'Gender',
    accessor: 'm_gender_relation.gender_name_en',
  },
  {
    Header: 'Date of Birth',
    accessor: 'patient_dob',
  },
  {
    Header: 'MRN No.',
    accessor: 'patient_report_mrn',
  },
  {
    Header: 'File Number',
    accessor: 'patient_file_number',
  },
  {
    Header: 'Details',
    accessor: 'details',
    Cell: (cell) => {
      const patient_id = cell.row.original.patient_id;
      return (
        <>
          <button
            onClick={() => {
              showDetails(cell.row.original);
            }}
            className="inline-flex px-2 text-sm leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            View
          </button>
          <button
            onClick={() => {
              showEditPatient(cell.row.original);
            }}
            className="ml-2 inline-flex px-2 text-sm leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            Edit
          </button>
        </>
      );
    }
  },

];

export const role_columns = ({ onDeleteOpen, handleDrawer, handleSaveResult, handleUpdateResult, saveMaxDiscountValue }) => [
  {
    Header: "Role Id",
    accessor: "role_id",
  },
  {
    Header: "Role Name English",
    accessor: (d) => capitalise(d.role_name_en),
  },
  {
    Header: 'Role Name Arabic',
    accessor: (d) => capitalise(d.role_name_ar),
  },
  {
    Header: 'Max Discount Value',
    accessor: 'role_max_discount_value',
    Cell: (cell) => {
      let originalCell = cell.row.original
      let result_value = originalCell?.role_max_discount_value

      return (
        <p className="text-sm text-gray-500">
          <Tippy
            theme="light"
            placement="left"
            trigger="click"
            allowHTML={true}
            interactive={true}
            content={
              <EditResultTooptip
                fieldName="role_max_discount_value"
                originalCell={originalCell}
                saveFunc={saveMaxDiscountValue}
                updateFunc={handleUpdateResult}
                prevValue={result_value}
              />

            }
          >
            <button>
              <div className="inline-flex p-2 text-xs underline underline-offset-2">
                {result_value}
                <span><PencilIcon className="w-4 h-4 ml-2" /></span>
              </div>
            </button>
          </Tippy>
        </p>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.role_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit
            </button>
          </Link>

          <Link
            to={`/role/${cellId}`}
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit Permissions
            </button>
          </Link>

          <Link
            to={`/incentive/${cellId}`}
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 text-indigo-600 bg-indigo-100 rounded-full"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              View Incentives
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
            </button>
          </span>
        </>
      );
    },
  },
];

export const country_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Country',
    accessor: (d) => capitalise(d.country_name),
  },
  {
    Header: 'Country Code',
    accessor: 'country_code',
  },

  {
    Header: 'Country ISD Code',
    accessor: 'country_isd_code',
  },

  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.country_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const gender_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Gender English',
    accessor: (d) => capitalise(d.gender_name_en),
  },
  {
    Header: 'Gender Arabic',
    accessor: (d) => capitalise(d.gender_name_ar),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.gender_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const state_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'State Name English',
    accessor: (d) => capitalise(d.state_name_en),
  },
  {
    Header: 'State Name Arabic',
    accessor: (d) => capitalise(d.state_name_ar),
  },
  {
    Header: 'Country Name',
    accessor: (d) => capitalise(d.m_country_relation.country_name),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.state_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const city_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'City Name English',
    accessor: (d) => capitalise(d.city_name_en),
  },
  {
    Header: 'City Name Arabic',
    accessor: (d) => capitalise(d.city_name_ar),
  },
  {
    Header: 'State En',
    accessor: (d) => capitalise(d.m_state_relation.state_name_en),
  },
  {
    Header: 'State Ar',
    accessor: (d) => capitalise(d.m_state_relation.state_name_ar),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.city_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const lab_analyzer_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Name English",
    accessor: (d) => capitalise(d.lab_analyzer_name_en),
  },
  {
    Header: 'Name Arabic',
    accessor: (d) => capitalise(d.lab_analyzer_name_ar),
  },
  {
    Header: 'Analyzer Type',
    accessor: (d) => capitalise(d.lab_analyzer_type),
  },
  // {
  // 	Header: "Test",
  // 	accessor: (d) => capitalise(d.t_test_relation.test_name_en),
  // },
  {
    Header: 'Location',
    accessor: (d) => d?.lab_analyzer_loc_map && Array.isArray(d?.lab_analyzer_loc_map) && d.lab_analyzer_loc_map.map(v => v.lalm_relation.loc_name_en).toString(),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.lab_analyzer_id;
      return (
        <>
          <p
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </p>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const prog_comment_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Text',
    accessor: (d) => capitalise(d.comment_text),
  },
  {
    Header: 'Type',
    accessor: (d) => capitalise(d.comment_type),
  },

  {
    Header: 'Test',
    accessor: (d) => capitalise(d.t_test_relation.test_name_en),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.comment_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const medicine_columns = ({ removeMedicine, handleDrawer }) => [
  {
    Header: 'Medicine Name',
    accessor: 'medicine_name',
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const dataobj = cell.row.original;
      const cellData = cell.row.original.id;
      return (
        <>
          <span
            onClick={() => handleDrawer('add', cellData, dataobj)}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                removeMedicine(cellData);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const lab_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Lab',
    accessor: (d) => capitalise(d.lab_name),
  },
  {
    Header: 'Location',
    accessor: (d) => capitalise(d.location.location_name),
  },
  {
    Header: 'City',
    accessor: (d) => capitalise(d.m_city.city_name),
  },
  {
    Header: 'State',
    accessor: (d) => capitalise(d.m_state.state_name),
  },
  {
    Header: 'Country',
    accessor: (d) => capitalise(d.m_country.country_name),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.lab_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const unit_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Unit Name',
    accessor: (d) => capitalise(d.unit_name),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.unit_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const category_columns = ({ onDeleteOpen, handleDrawer, onStatusChange }) => [
  {
    Header: 'Category EN Name',
    accessor: (d) => capitalise(d.category_name_en),
  },
  {
    Header: 'Category AR Name',
    accessor: (d) => capitalise(d.category_name_ar),
  },
  {
    Header: 'Status',
    accessor: (d) => (d.category_is_active == true ? 'Active' : 'Inactive'),
    Cell: (cell) => {
      const cellId = cell.row.original.category_id;
      const enabled = cell.row.original.category_is_active;
      // const [enabled, setEnabled] = useState()
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onStatusChange(cellId, enabled);
          }}
          className={categoryStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={categoryStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.category_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>

          {/* <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
						<button
							onClick={() => {
								onDeleteOpen(cellId, cell.row.original.category_is_active);
							}}
							size="default"
							variant="outlined"
						>
							{cell.row.original.category_is_active == true ? 'In-Activate' : 'Activate'}
							<span className="sr-only">, </span>
						</button>
					</span> */}
        </>
      );
    },
  },
];

export const agenttype_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Agent Type',
    accessor: (d) => capitalise(d.agent_type_name),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.agent_type_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const test_columns = ({ onDeleteOpen, onTestDeleteOpen, handleDrawer }) => [
  {
    Header: 'Test Id',
    accessor: "test_id",
  },
  {
    Header: 'Test Code',
    accessor: (d) => capitalise(d?.test_code),
  },
  {
    Header: 'Test Name(EN)',
    accessor: (d) => capitalise(d.test_name_en),
  },
  {
    Header: 'Test Name(AR)',
    accessor: (d) => capitalise(d.test_name_ar),
  },
  {
    Header: 'Hospital Price',
    accessor: 'hos_price',
  },
  {
    Header: 'Category',
    accessor: (d) => capitalise(d.t_category_relation?.category_name_en),
  },
  {
    Header: "Color",
    accessor: (d) => capitalise(d.color_relation?.mclr_name),
  },
  {
    Header: "Sample Type",
    accessor: (d) => capitalise(d.m_sample_type_relation?.sample_type_name_en),
  },
  {
    Header: 'Img (EN)',
    Cell: (cell) => {
      return cell.row.original.test_img_en ? (
        <>
          <img
            width={40}
            height={20}
            src={cell.row.original.test_img_en}
            alt={cell.row.original.test_name_en}
          />
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Img (AR)',
    Cell: (cell) => {
      return cell.row.original.test_img_ar ? (
        <>
          <img
            width={40}
            height={20}
            src={cell.row.original.test_img_ar}
            alt={cell.row.original.test_name_ar}
          />
        </>
      ) : (
        <></>
      );
    },
  },
  /*   {
      Header: 'Min Price',
      accessor: 'test_min_price',
    }, */
  {
    Header: 'Hospital',
    accessor: (d) => capitalise(d.m_hospital_relation?.hos_name_en),
  },
  {
    Header: 'Has Multiple Parameters',
    accessor: (d) => (d.is_sub_test == true ? 'Yes' : 'No'),
  },
  {
    Header: 'Status',
    accessor: (d) => (d.test_is_active == true ? 'Active' : 'Inactive'),
    Cell: (cell) => {
      const cellId = cell.row.original.test_id;
      const enabled = cell.row.original.test_is_active;
      // const [enabled, setEnabled] = useState()
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.test_is_active);
          }}
          className={testStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={testStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.test_id;
      const test_name = cell.row.original.test_name_en;
      const questionId = cell.row.original.fk_question_id;
      return (
        <>
          <Link
            to={`/test-tab/${cell.row.original.test_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onTestDeleteOpen(cellId, test_name);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
          {
            questionId ? (
              <Link
                to={`/questionnaire/${cell.row.original.fk_question_id}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  See Questions
                </button>
              </Link>
            ) : (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">No Questions</span>
            )
          }

          <Link
            to={`/questionnaire/${cell.row.original.test_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Questionnaire
            </button>
          </Link>

          {/* <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
						<button
							onClick={() => {
								onDeleteOpen(cellId, cell.row.original.test_is_active);
							}}
							size="default"
							variant="outlined"
						>
							{cell.row.original.test_is_active == true ? 'In-Activate' : 'Activate'}
							<span className="sr-only">, </span>
						</button>
					</span> */}
        </>
      );
    },
  },
];

export const questionnaire_columns = () => [
  {
    Header: "Questionnaire",
    accessor: (d) => d.qstn_id,
  },
  {
    Header: "Test Name",
    accessor: (d) => capitalise(d.qstn_name),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.qstn_id;
      return (
        <>
          <Link
            to={`/questionnaire/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit
            </button>
          </Link>
        </>
      );
    },
  },
];

export const subtest_columns = ({ onDeleteOpen, onSubTestDeleteOpen, handleDrawer }) => [
  {
    Header: "Test-Parameter Name(EN)",
    accessor: (d) => capitalise(d.ttp_name_en),
  },
  {
    Header: "Test-Parameter Name(AR)",
    accessor: (d) => capitalise(d.ttp_name_ar),
  },
  {
    Header: 'Img (EN)',
    Cell: (cell) => {
      return cell.row.original.ttp_img_en ? (
        <>
          <img
            width={40}
            height={20}
            src={cell.row.original.ttp_img_en}
            alt={cell.row.original.ttp_name_en}
          />
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Img (AR)',
    Cell: (cell) => {
      return cell.row.original.ttp_img_ar ? (
        <>
          <img
            width={40}
            height={20}
            src={cell.row.original.ttp_img_ar}
            alt={cell.row.original.ttp_name_ar}
          />
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Test Name',
    accessor: (d) => capitalise(d.t_test_relation?.test_name_en),
  },

  {
    Header: "Status",
    accessor: (d) => d.ttp_is_active == true ? "Active" : "Inactive",
    Cell: (cell) => {
      const cellId = cell.row.original.ttp_id;
      const enabled = cell.row.original.ttp_is_active;
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.ttp_is_active);
          }}
          className={packageStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={packageStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      )
    }
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.ttp_id;
      const test_name = cell.row.original.ttp_name_en
      return (
        <>
          <Link
            to={`/sub-test-tab/${cell.row.original.fk_test_id}/${cellId}`}
            //to="/sub-test-tab/"
            //to={`/add-subtest/${cell.row.original.fk_test_id}/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onSubTestDeleteOpen(cellId, test_name);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const package_columns = ({ onDeleteOpen, onChangeStatusOpen }) => [
  {
    Header: 'Package Name (EN)',
    accessor: (d) => capitalise(d.package_name_en),
  },
  {
    Header: 'Package Name (AR)',
    accessor: (d) => capitalise(d.package_name_ar),
  },
  {
    Header: 'Price',
    accessor: 'package_price',
  },
  {
    Header: 'Sub-Category',
    accessor: 'package_sub_category',
  },
  {
    Header: 'Status',
    accessor: (d) => (d.package_is_active == true ? 'Active' : 'Inactive'),
    Cell: (cell) => {
      const cellId = cell.row.original.package_id;
      const enabled = cell.row.original.package_is_active;
      // const [enabled, setEnabled] = useState()
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onChangeStatusOpen(cellId, cell.row.original.package_is_active);
          }}
          className={packageStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={packageStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.package_id;
      return (
        <>
          <Link
            to={`/add-package/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
          {/* <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
						<button
							onClick={() => {
								onDeleteOpen(cellId, cell.row.original.package_is_active);
							}}
							size="default"
							variant="outlined"
						>
							{cell.row.original.package_is_active == true ? 'In-Activate' : 'Activate'}
							<span className="sr-only">, </span>
						</button>
					</span> */}
        </>
      );
    },
  },
];

export const announcement_columns = () => [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      // const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to="/add-patient"
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const goal_columns = () => [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      // const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to="/add-patient"
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const recommendation_columns = () => [
  {
    Header: 'Title',
    accessor: 'title',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      // const cellId = cell.row.original.id;
      return (
        <>
          <Link
            to="/add-patient"
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const userGroup_columns = () => [
  {
    Header: 'Group Name',
    accessor: 'ug_name',
  },
  {
    Header: 'Users',
    accessor: 'ug_users',
    Cell: (cell) => {
      // console.log(cell.row.original)
      // const userGroupId = cell.row.original.ug_id
      const userGroupUsers = cell.row.original.ug_users;

      // const cellId = cell.row.original.id;
      return (
        <div className="flex">
          {userGroupUsers.map((user) => (
            <div className="flex ml-1 mr-1">
              <Link
                to="/"
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  {user.user_id + ' - ' + user.user_email}
                  <span className="sr-only">, </span>
                </button>
              </Link>
            </div>
          ))}
        </div>
      );
    },
  },
];

/**** All payments columns *******/
export const payment_columns = ({ sendPaymentLinkConfirmation }) => [
  {
    Header: 'Doctor Name',
    accessor: (e) =>
      `Dr. ${e.appointment.doctor.first_name} ${e.appointment.doctor.last_name}`,
  },
  {
    Header: 'Patient Name',
    accessor: (e) =>
      `${e.appointment.patient.firstName} ${e.appointment.patient.lastName}`,
  },
  {
    Header: 'Status',
    accessor: 'payment-status',
    Cell: (cell) => {
      return (
        <>
          {cell && cell.row.original.success ? (
            <GrCheckmark className="text-green-700 " />
          ) : (
            <RxCross2 className="text-red-700" />
          )}
        </>
      );
    },
  },
  {
    Header: 'Payment Date',
    id: 'payment_date',
    accessor: (d) => moment(convertToAnotherTimezone(d.createdAt)).format('DD-MM-YYYY'),
  },
  {
    Header: 'Appointment Date',
    id: 'Appointment_date',
    accessor: (d) => moment(convertToAnotherTimezone(d.appointment.date)).format('DD-MM-YYYY'),
  },
  {
    Header: 'Amount',
    id: 'amount',
    accessor: (d) => `Rs. ${d.amount}`,
  },
  {
    Header: 'Start Time',
    accessor: (t) => moment(convertToAnotherTimezone(t.appointment.start_time)).format('HH:mm'),
  },
  {
    Header: 'End Time',
    accessor: (t) => moment(convertToAnotherTimezone(t.appointment.end_time)).format('HH:mm'),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.id;
      return (
        <>
          <a
            href={`${process.env.REACT_APP_API_URL}/${cell && cell.row.original.pdf
              }`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            target="_blank"
            rel="noreferrer"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Invoice<span className="sr-only">, </span>
            </button>
          </a>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-green-600 bg-green-100 rounded-full">
            <button
              onClick={() => {
                sendPaymentLinkConfirmation(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Send Link
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const opdReport_columns = () => [
  {
    Header: 'Name',
    accessor: (e) => `${e.patient.firstName} ${e.patient.lastName}`,
    // accessor: 'name',
  },
  { Header: 'Age', accessor: (e) => `${e.patient.age}` },
  {
    Header: 'Area',
    // accessor: (e) => (`${e.location.name}`),
    accessor: 'area',
    Cell: (cell) => {
      // console.log(cell.row.original.location.name)
      return (
        <p className="text-sm text-gray-500">
          {cell && cell.row.original.location && cell.row.original.location.name
            ? cell.row.original.location.name
            : ''}
        </p>
      );
    },
  },
  {
    Header: 'Type Of Consultation',
    accessor: 'is follow up',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell && cell.row.original.isFollowUp ? 'f/up' : 'new'}
        </p>
      );
    },
  },
  { Header: 'Contact Number', accessor: (e) => `${e.patient.phoneNo}` },
  {
    Header: 'Consultation time',
    accessor: (e) =>
      `${moment(e.start_time).format('HH:mm')} - ${moment(e.end_time).format(
        'HH:mm'
      )}`,
    // accessor: 'consultationTime',
  },
];

export const Referrals_columns = ({ onDeleteOpen }) => [
  { Header: 'Name', accessor: 'name' },
  { Header: 'Type', accessor: 'type' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-referral/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const forms_columns = () => [
  { Header: 'Sl No', accessor: 'id' },
  { Header: 'name', accessor: 'name' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/form-group/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Form Group<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
];

export const forms_group_columns = () => [
  { Header: 'Sl No', accessor: 'id' },
  { Header: 'name', accessor: 'name' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            // to={`/form-group/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Form Fields<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
];

export const goal_master_columns = ({ onDeleteOpen }) => [
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-goal/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  ,
];

export const department_master_columns = ({ onDeleteOpen }) => [
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-department/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const category_master_columns = ({ onDeleteOpen }) => [
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-category/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const age_group_master_columns = ({ onDeleteOpen }) => [
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-age-group/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const domain_master_columns = ({ onDeleteOpen }) => [
  { Header: 'Name', accessor: 'name' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-domain/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const medicine_master_columns = ({ onDeleteOpen }) => [
  { Header: 'Generic Name', accessor: 'generic_name' },
  { Header: 'Strength', accessor: 'strength' },
  { Header: 'Formulation', accessor: 'formulation' },
  { Header: 'Route of Administration', accessor: 'route_of_administration' },
  { Header: 'Frequency', accessor: 'frequency' },
  { Header: 'Medicine Time', accessor: 'medicine_time' },
  {
    Header: 'Start Time',
    accessor: (d) => moment(convertToAnotherTimezone(d.start_date)).format('YYYY-MM-DD'),
  },
  { Header: 'Duration', accessor: 'duration' },
  {
    Header: 'End date',
    accessor: (d) => moment(convertToAnotherTimezone(d.end_date)).format('YYYY-MM-DD'),
  },
  { Header: 'Instructions', accessor: 'instructions' },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-medicine/${cell && cell.row.original.id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const test_new_columns = () => [
  {
    Header: 'Test Name',
    accessor: (d) => capitalise(d.name),
  },
  {
    Header: 'Description',
    accessor: (d) => capitalise(d.description),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <Link className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const status_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Title',
    accessor: (d) => capitalise(d.status_title),
  },
  {
    Header: 'Description',
    accessor: 'status_short_descp',
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          {/* <span
						onClick={() =>
							handleDrawer(
								"edit",
								cell.row.original.status_id,
								cell.row.original
							)
						}
						className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
					>
						<button
							className="text-cyan-900 "
							size="default"
							variant="outlined"
						>
							Edit<span className="sr-only">, </span>
						</button>
					</span> */}

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.status_id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const payments_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Payment Mode',
    accessor: 'mode',
  },
  {
    Header: 'Transaction ID',
    accessor: 'trnx_id',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 bg-green-100 rounded-full text-cyan-600">
            <button
              onClick={() => handleDrawer(cell.row.original.id)}
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Success<span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const sample_columnsBak = ({
  onDeleteOpen,
  editReservation,
  handleDrawer,
  setCommentsModal,
  setAttachFileModal,
  setSampleid,
  setViewAttachFileModal,
  getAllSampleAttachmentList,
}) => [
    {
      Header: 'Reservation ID',
      Cell: (cell) => {
        const reservation_id = cell.row.original.fk_reservation_id;
        return (
          <>
            <Link
              onClick={() => editReservation(reservation_id)}
              to={`/bookingConfirm`}
              className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
               <div className="flex items-center flex-col p-1">
                <span>{reservation_id}{"  "}</span>
                <span>View</span>
              </div>
            </Link>
          </>
        );
      },
    },
    {
      Header: 'Sample ID',

      Cell: (cell) => {
        const sample_id = cell.row.original.t_sample_relation?.sample_id;
        return (
          <>
            <Link
              to={`${sample_id}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              {sample_id}
            </Link>
          </>
        );
      },
    },
    {
      Header: 'Test Name',
      accessor: (d) => capitalise(d.t_test_relation?.test_name_en),
    },
    {
      Header: 'Patient',
      accessor: (d) => capitalise(d.m_patient_relation?.patient_first_name),
    },
    {
      Header: 'Mobile No.',
      accessor: (d) => capitalise(d.m_patient_relation?.patient_mobile),
    },
    {
      Header: 'Identification No.',
      accessor: (d) => capitalise(d.m_patient_relation?.patient_id_no),
    },
    {
      Header: 'Nationality',
      accessor: (d) =>
        capitalise(d.m_patient_relation?.m_country_relation?.country_name),
    },
    {
      Header: 'Hospital',
      accessor: (d) =>
        capitalise(d.t_reservation_relation?.m_hospital_relation?.hos_name_en),
    },
    {
      Header: 'Status',
      accessor: (d) =>
        d.t_sample_relation?.sample_is_active == true ? 'Active' : 'Inactive',
    },
    {
      Header: 'Result',
      accessor: (d) => (d.sp_gen_is_active == true ? 'Active' : 'Inactive'),
    },
    {
      Header: 'HESN No.',
      accessor: (d) =>
        capitalise(d.t_reservation_relation?.m_hospital_relation?.hos_hesn_no),
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (cell) => {
        return (
          <>
            <button
              type="button"
              onClick={() => {
                setSampleid(cell.row.original.t_sample_relation?.sample_id);
                setCommentsModal(true);
              }}
              className="px-1 py-1 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
              size="default"
              variant="outlined"
            >
              Comment<span className="sr-only">, </span>
            </button>

            <button
              type="button"
              onClick={() => {
                setSampleid(cell.row.original.t_sample_relation?.sample_id);
                setAttachFileModal(true);
              }}
              className="px-1 py-1 mt-1 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
              size="default"
              variant="outlined"
            >
              Add Attachment<span className="sr-only">, </span>
            </button>
            <button
              onClick={() => {
                setSampleid(cell.row.original.t_sample_relation?.sample_id);
                setViewAttachFileModal(true);
                getAllSampleAttachmentList();
              }}
              type="button"
              className="px-1 py-1 mt-1 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
              size="default"
              variant="outlined"
            >
              View Attachment<span className="sr-only">, </span>
            </button>

            <button
              className={`${cell.row.original.t_sample_relation?.sample_is_active == true
                ? 'bg-green-500 hover:bg-green-700'
                : 'bg-red-500 hover:bg-red-700'
                } " text-white font-bold py-1 px-1 rounded mt-1"`}
              onClick={() => {
                onDeleteOpen(
                  cell.row.original.t_sample_relation?.sample_id,
                  cell.row.original.t_sample_relation?.sample_is_active
                );
              }}
              type="button"
              size="default"
              variant="outlined"
            >
              {cell.row.original.t_sample_relation?.sample_is_active == true
                ? 'In-Activate'
                : 'Activate'}
            </button>
          </>
        );
      },
    },
  ];
export const sample_columns = ({
  onDeleteOpen,
  editReservation,
  handleDrawer,
  setCommentsModal,
  setAttachFileModal,
  setSampleid,
  setViewAttachFileModal,
  getAllSampleAttachmentList,
  sample_status_colors
}) => [
    {
      Header: 'Reservation ID',
      Cell: (cell) => {
        const reservation_id = cell.row.original.fk_reservation_id;
        return (
          <>
            <Link
              onClick={() => editReservation(reservation_id)}
              to={`/bookingConfirm`}
              className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
               <div className="flex items-center flex-col p-1">
                <span>{reservation_id}{"  "}</span>
                <span>View</span>
              </div>
            </Link>
          </>
        );
      },
    },
    {
      Header: 'Sample ID',

      Cell: (cell) => {
        const sample_id = cell.row.original.sample_id;
        return (
          <>
            <Link
              to={`${sample_id}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              {sample_id}
            </Link>
          </>
        );
      },
    },
    {
      Header: 'Test Name',
      accessor: (d) => capitalise(d.test_name_en),
    },
    {
      Header: 'Patient',
      accessor: (d) => capitalise(d.patient_first_name),
    },
    {
      Header: 'Mobile No.',
      accessor: (d) => capitalise(d.patient_mobile),
    },
    {
      Header: 'Identification No.',
      accessor: (d) => capitalise(d.patient_id_no),
    },
    {
      Header: 'Nationality',
      accessor: (d) => capitalise(d.country_name),
    },
    {
      Header: 'Status',
      accessor: 'sp_status_name_en',
      Cell: (cell) => {
        return (
          <div className={`text-center ${sample_status_colors[cell.row.original.sp_status_name_en] == undefined ? "text-gray-500"
            :
            sample_status_colors[cell.row.original.sp_status_name_en]}`}>
            {capitalise(cell.row.original.sp_status_name_en)}
          </div>
        )
      }
    },
    // {
    //   Header: 'Status',
    //   accessor: (d) => (d.sample_is_active == true ? 'Active' : 'Inactive'),
    // },
    {
      Header: 'Result',
      accessor: (d) => (d.sp_gen_is_active == true ? 'Active' : 'Inactive'),
    },
    // {
    //   Header: "HESN No.",
    //   accessor: (d) => capitalise(d.hos_hesn_no),
    // },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (cell) => {
        return (
          <>
            <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
              <button
                type="button"
                onClick={() => {
                  setSampleid(cell.row.original.sample_id);
                  setCommentsModal(true);
                }}
                size="default"
                variant="outlined"
              >
                Comment
              </button>
            </span>
            <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
              <button
                type="button"
                onClick={() => {
                  setSampleid(cell.row.original.sample_id);
                  setAttachFileModal(true);
                }}
                size="default"
                variant="outlined"
              >
                Add Attachment
              </button>
            </span>
            <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
              <button
                onClick={() => {
                  setSampleid(cell.row.original.sample_id);
                  setViewAttachFileModal(true);
                  getAllSampleAttachmentList();
                }}
                type="button"
                size="default"
                variant="outlined"
              >
                View Attachment
              </button>
            </span>
            <span
              className={`${cell.row.original.sample_is_active == true
                ? 'text-cyan-600 bg-cyan-100 rounded-full'
                : 'text-red-600 bg-red-100 rounded-full'
                } "inline-flex px-2 ml-3 text-xs leading-5"`}
            >
              <button
                onClick={() => {
                  onDeleteOpen(
                    cell.row.original.sample_id,
                    cell.row.original.sample_is_active
                  );
                }}
                type="button"
                size="default"
                variant="outlined"
              >
                {cell.row.original.sample_is_active == true
                  ? 'In-Activate'
                  : 'Activate'}
              </button>
            </span>
          </>
        );
      },
    },
  ];
export const reservation_columnsBak = ({
  onDeleteOpen,
  editReservation,
  handleDrawer,
}) => [
    {
      Header: 'Reservation ID',
      Cell: (cell) => {
        const reservation_id =
          cell.row.original.t_reservation_relation?.reservation_id;
        return (
          <>
            <Link
              onClick={() => {
                console.log('PS=>', reservation_id);
                localStorage.setItem(
                  'edit_booking_reservation_id',
                  reservation_id
                );
                editReservation(reservation_id);
              }}
              to={`/bookingConfirm`}
              className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
               <div className="flex items-center flex-col p-1">
                <span>{reservation_id}{"  "}</span>
                <span>View</span>
              </div>
            </Link>
          </>
        );
      },
    },
    {
      Header: 'Patient',
      accessor: (d) => capitalise(d.m_patient_relation?.patient_first_name),
    },
    {
      Header: 'Mobile No.',
      accessor: (d) => capitalise(d.m_patient_relation?.patient_mobile),
    },

    {
      Header: 'Gender',
      accessor: (d) =>
        capitalise(d.m_patient_relation?.m_gender_relation?.gender_name_en),
    },

    {
      Header: 'Country',
      accessor: (d) =>
        capitalise(d.m_patient_relation?.m_country_relation?.country_name),
    },
    {
      Header: 'Time',
      accessor: (d) =>
        moment(convertToAnotherTimezone(d.t_reservation_relation?.reservation_created_at)).format(
          'DD-MM-YYYY, h:mm:ss a'
        ),
      // accessor: (d) => capitalise(d.t_reservation_relation?.reservation_created_at),
    },
    {
      Header: 'Paid Amount',
      accessor: (d) => capitalise(d.t_reservation_relation?.rev_payable_amount),
    },
    {
      Header: 'Status',
      accessor: (d) =>
        d.t_reservation_relation?.reservation_is_active == true
          ? 'Active'
          : 'Inactive',
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (cell) => {
        return (
          <>
            <button
              type="button"
              className={`${cell.row.original.t_reservation_relation?.reservation_is_active ==
                true
                ? 'bg-green-500 hover:bg-green-700'
                : 'bg-red-500 hover:bg-red-700'
                } " text-white font-bold py-1 px-1 rounded mt-1"`}
              onClick={() => {
                onDeleteOpen(
                  cell.row.original.t_reservation_relation?.reservation_id,
                  cell.row.original.t_reservation_relation?.reservation_is_active
                );
              }}
              size="default"
              variant="outlined"
            >
              {cell.row.original.t_reservation_relation?.reservation_is_active ==
                true
                ? 'In-Activate'
                : 'Activate'}
            </button>
          </>
        );
      },
    },
  ];
export const reservation_columns = ({
  onDeleteOpen,
  editReservation,
  generateInvoiceReport,
  reservation_status_colors
}) => [
    {
      Header: 'Reservation ID',
      accessor: 'reservation_id',
      Cell: (cell) => {
        const reservation_id = cell.row.original.reservation_id;
        return (
          <>
            <div>
              {cell.row.original.reservation_is_active == true ? (
                <Link
                  onClick={() => {
                    console.log('PS=>', reservation_id);
                    localStorage.setItem(
                      'edit_booking_reservation_user_details',
                      JSON.stringify(cell.row.original)
                    );
                    localStorage.setItem(
                      'edit_booking_reservation_id',
                      reservation_id
                    );
                    editReservation(cell.row.original);
                  }}
                  to={`/bookingConfirm`}
                  className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
               <div className="flex items-center flex-col p-1">
                <span>{reservation_id}{"  "}</span>
                <span>View</span>
              </div>
            </Link>
              ) : (
                <p>{reservation_id}</p>
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: 'MRN',
      accessor: (d) => capitalise(d.patient_report_mrn),
      // accessor: (d) => capitalise(d.patient_first_name),
    },
    {
      Header: 'User',
      accessor: (d) => capitalise(d.rev_patient_parent_fname),
      // accessor: (d) => capitalise(d.patient_first_name),
    },
    {
      Header: 'Mobile No.',
      // accessor: (d) => capitalise(d.patient_mobile),
      accessor: (d) => capitalise(d.rev_patient_parent_phone),
    },

    {
      Header: 'Booking Type',
      accessor: (d) => capitalise(d.booking_type),
    },

    {
      Header: 'Gender',
      accessor: (d) => capitalise(d.rev_patient_parent_gender_name),
      // accessor: (d) => capitalise(d.gender_name_en),
    },

    {
      Header: 'Country',
      accessor: (d) => capitalise(d.rev_patient_parent_country_name),
      // accessor: (d) => capitalise(d.country_name),
    },
    {
      Header: 'Date',
      accessor: (d) => moment(convertToAnotherTimezone(convertUTStoIST(d.reservation_created_at))).format('DD-MM-YYYY'),
      // accessor: (d) => capitalise(d.t_reservation_relation?.reservation_created_at),
    },
    {
      Header: 'Time',
      accessor: (d) => moment(convertToAnotherTimezone(convertUTStoIST(d.reservation_created_at))).format('h:mm:ss a'),
      // accessor: (d) => capitalise(d.t_reservation_relation?.reservation_created_at),
    },
    {
      Header: 'Paid Amount',
      accessor: (d) => capitalise(parseFloat(d.rev_payable_amount).toFixed(2)),
    },
    // {
    //   Header: 'Status',
    //   accessor: (d) => (d.reservation_is_active == true ? 'Active' : 'Cancelled'),
    // },
    {
      Header: 'Reservation Status',
      accessor: 'res_status_name_en',
      Cell: (cell) => {
        return (
          <div className={`text-center ${reservation_status_colors[cell.row.original.res_status_name_en] == undefined ? "text-gray-500"
            :
            reservation_status_colors[cell.row.original.res_status_name_en]}`}>
            {capitalise(cell.row.original.res_status_name_en)}
          </div>
        )
      }
    },
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (cell) => {
        return (
          <>
            {/* <button
              type="button"
              className={`${cell.row.original.reservation_is_active == true
                ? "bg-green-500 hover:bg-green-700"
                : "bg-red-500 hover:bg-red-700"
                } " text-white font-bold py-1 px-1 rounded mt-1"`}
              onClick={() => {
                onDeleteOpen(
                  cell.row.original.reservation_id,
                  cell.row.original.reservation_is_active
                );
              }}
              size="default"
              variant="outlined"
            >
              {cell.row.original.reservation_is_active == true
                ? "In-Activate"
                : "Activate"}
            </button> */}
            {cell.row.original.is_payment_completed && !cell.row.original.invoice_url ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                <button
                  type="button"
                  onClick={() => {
                    generateInvoiceReport(cell.row.original);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Invoice
                </button>
              </span>
            ) : (
              <></>
            )}
            {cell.row.original.invoice_url &&
              cell.row.original.is_payment_completed ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                <a href={`${cell.row.original.invoice_url}`} target="_blank">
                  View PDF
                </a>
              </span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
  ];


export const rejected_sample_columns = ({
  onDeleteOpen,
  editReservation,
  generateInvoiceReport,
  reservation_status_colors
}) => [
    {
      Header: 'Reservation ID',
      accessor: 'fk_revservation_id',
      Cell: (cell) => {
        const reservation_id = cell.row.original.fk_revservation_id;
        return (
          <>
            <div>
              <Link
                onClick={() => {
                  console.log('PS=>', reservation_id);
                  localStorage.setItem(
                    'edit_booking_reservation_user_details',
                    JSON.stringify(cell.row.original)
                  );
                  localStorage.setItem(
                    'edit_booking_reservation_id',
                    reservation_id
                  );
                  localStorage.setItem("edit_booking_reservation_id", reservation_id);
                  // editReservation(cell.row.original);
                }}
                to={`/bookingConfirm`}
                className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
               <div className="flex items-center flex-col p-1">
                <span>{reservation_id}{"  "}</span>
                <span>View</span>
              </div>
            </Link>
         
            </div>
          </>
        );
      },
    },
    {
      Header: 'Sample Id',
      accessor: (d) => d?.fk_sample_id,
      // accessor: (d) => capitalise(d.patient_first_name),
    },
    {
      Header: 'User',
      accessor: (d) => capitalise(d?.sl_reservation_relation?.rev_patient_parent_fname),
      // accessor: (d) => capitalise(d.patient_first_name),
    },
    {
      Header: 'Mobile No.',
      // accessor: (d) => capitalise(d.patient_mobile),
      accessor: (d) => capitalise(d?.sl_reservation_relation?.rev_patient_parent_phone),
    },

    {
      Header: 'Booking Type',
      accessor: (d) => capitalise(d?.sl_reservation_relation?.booking_type),
    },

    {
      Header: 'Gender',
      accessor: (d) => capitalise(d?.sl_reservation_relation?.rev_patient_parent_gender_name),
      // accessor: (d) => capitalise(d.gender_name_en),
    },

    {
      Header: 'Country',
      accessor: (d) => capitalise(d?.sl_reservation_relation?.rev_patient_parent_country_name),
      // accessor: (d) => capitalise(d.country_name),
    },
    {
      Header: 'Date',
      accessor: (d) => moment(convertToAnotherTimezone(d?.sl_reservation_relation?.reservation_created_at)).format('DD-MM-YYYY'),
      // accessor: (d) => capitalise(d.t_reservation_relation?.reservation_created_at),
    },
    {
      Header: 'Time',
      accessor: (d) => moment(convertToAnotherTimezone(d?.sl_reservation_relation?.reservation_created_at)).format('h:mm:ss a'),
      // accessor: (d) => capitalise(d.t_reservation_relation?.reservation_created_at),
    },
    // {
    //   Header: 'Paid Amount',
    //   accessor: (d) => capitalise(parseFloat(d.rev_payable_amount).toFixed(2)),
    // },
    // // {
    // //   Header: 'Status',
    // //   accessor: (d) => (d.reservation_is_active == true ? 'Active' : 'Cancelled'),
    // // },
    // {
    //   Header: 'Reservation Status',
    //   accessor: 'res_status_name_en',
    //   Cell: (cell)=>{
    //     return(
    //       <div className={`text-center ${reservation_status_colors[cell.row.original.res_status_name_en] == undefined ? "text-gray-500" 
    //       : 
    //       reservation_status_colors[ cell.row.original.res_status_name_en]}`}>
    //         {capitalise(cell.row.original.res_status_name_en)}
    //       </div>
    //     )
    //   }
    // },
    // {
    //   Header: 'Action',
    //   accessor: 'action',
    //   Cell: (cell) => {
    //     return (
    //       <>
    //         {/* <button
    //           type="button"
    //           className={`${cell.row.original.reservation_is_active == true
    //             ? "bg-green-500 hover:bg-green-700"
    //             : "bg-red-500 hover:bg-red-700"
    //             } " text-white font-bold py-1 px-1 rounded mt-1"`}
    //           onClick={() => {
    //             onDeleteOpen(
    //               cell.row.original.reservation_id,
    //               cell.row.original.reservation_is_active
    //             );
    //           }}
    //           size="default"
    //           variant="outlined"
    //         >
    //           {cell.row.original.reservation_is_active == true
    //             ? "In-Activate"
    //             : "Activate"}
    //         </button> */}
    //         {cell.row.original.is_payment_completed && !cell.row.original.invoice_url ? (
    //           <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
    //             <button
    //               type="button"
    //               onClick={() => {
    //                 generateInvoiceReport(cell.row.original);
    //               }}
    //               size="default"
    //               variant="outlined"
    //             >
    //               Invoice
    //             </button>
    //           </span>
    //         ) : (
    //           <></>
    //         )}
    //         {cell.row.original.invoice_url &&
    //           cell.row.original.is_payment_completed ? (
    //           <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
    //             <a href={`${cell.row.original.invoice_url}`} target="_blank">
    //               View PDF
    //             </a>
    //           </span>
    //         ) : (
    //           <></>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

export const reservation_trnx_columns = () => [
  {
    Header: 'Reservation ID',
    accessor: "reservation_id",

    // Cell: (cell) => {
    //   const reservation_id = cell.row.original.reservation_id;
    //   return (
    //     <>
    //       <div>
    //         {cell.row.original.reservation_is_active == true ? (
    //           <Link
    //             onClick={() => {
    //               console.log('PS=>', reservation_id);
    //               localStorage.setItem(
    //                 'edit_booking_reservation_user_details',
    //                 JSON.stringify(cell.row.original)
    //               );
    //               localStorage.setItem(
    //                 'edit_booking_reservation_id',
    //                 reservation_id
    //               );
    //               editReservation(cell.row.original);
    //             }}
    //             to={`/bookingConfirm`}
    //             className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
    //           >
    //             {reservation_id}
    //           </Link>
    //         ) : (
    //           <p>{reservation_id}</p>
    //         )}
    //       </div>
    //     </>
    //   );
    // },
  },
  {
    Header: 'User',
    accessor: (d) => capitalise(d.rev_patient_parent_fname),
    // accessor: (d) => capitalise(d.patient_first_name),
  },
  {
    Header: 'Mobile No.',
    // accessor: (d) => capitalise(d.patient_mobile),
    accessor: (d) => capitalise(d.rev_patient_parent_phone),
  },

  {
    Header: 'Gender',
    accessor: (d) => capitalise(d.rev_patient_parent_gender_name),
    // accessor: (d) => capitalise(d.gender_name_en),
  },
  {
    Header: 'Branch Name',
    accessor: (d) => capitalise(d.loc_name_en),
  },
  {
    Header: 'Referral Clinic',
    accessor: (d) => 
      capitalise(
        d.ot_clinic_details
          ? "OC - " + d.ot_clinic_details.mc_name
          : d.referral_clinic_details
          ? "RC - " + d.referral_clinic_details.mc_name
          : "Self"
      ),
  },
  {
    Header: 'Country',
    accessor: (d) => capitalise(d.rev_patient_parent_country_name),
    // accessor: (d) => capitalise(d.country_name),
  },
  {
    Header: 'Date',
    accessor: (d) => moment(convertToAnotherTimezone(d.reservation_created_at)).format('DD-MM-YYYY'),
    // accessor: (d) => capitalise(d.t_reservation_relation?.reservation_created_at),
  },
  {
    Header: 'Time',
    accessor: (d) => moment(convertToAnotherTimezone(d.reservation_created_at)).format('h:mm:ss a'),
    // accessor: (d) => capitalise(d.t_reservation_relation?.reservation_created_at),
  },
  {
    Header: 'Payment Status',
    accessor: (d) => d.is_payment_completed ? "Completed" : "Pending",
  },
  {
    Header: 'Payment Type',
    accessor: (d) => d.getAllReservationPayment?.pay_mode_name_en ? d.getAllReservationPayment?.pay_mode_name_en : "-",
  },
  {
    Header: 'Payable Amount',
    accessor: (d) => capitalise(parseFloat(d.rev_payable_amount).toFixed(2)),
  },
  {
    Header: 'Cash',
    accessor: (d) => parseFloat(d.getAllReservationPayment?.payment_data?.cashAmt ? d.getAllReservationPayment?.payment_data?.cashAmt : 0 ).toFixed(2),
  },
  {
    Header: 'Card',
    accessor: (d) => parseFloat(d.getAllReservationPayment?.payment_data?.visaAmt ? d.getAllReservationPayment?.payment_data?.visaAmt : 0 ).toFixed(2),
  },
  {
    Header: 'Bank Transfer',
    accessor: (d) => parseFloat(d.getAllReservationPayment?.payment_data?.bankTransAmt ? d.getAllReservationPayment?.payment_data?.bankTransAmt : 0 ).toFixed(2),
  },
  {
    Header: 'VAT',
    accessor: (d) => capitalise(parseFloat(d.rev_vat_amount).toFixed(2)),
  },
  {
    Header: 'Received By',
    accessor: (d) => capitalise(d.paymentcollectedbyusername),
  },
  {
    Header: 'Status',
    accessor: (d) => (d.reservation_is_active == true ? 'Active' : 'Cancelled'),
  },
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  //   Cell: (cell) => {
  //     return (
  //       <>
  //         {/* <button
  //             type="button"
  //             className={`${cell.row.original.reservation_is_active == true
  //               ? "bg-green-500 hover:bg-green-700"
  //               : "bg-red-500 hover:bg-red-700"
  //               } " text-white font-bold py-1 px-1 rounded mt-1"`}
  //             onClick={() => {
  //               onDeleteOpen(
  //                 cell.row.original.reservation_id,
  //                 cell.row.original.reservation_is_active
  //               );
  //             }}
  //             size="default"
  //             variant="outlined"
  //           >
  //             {cell.row.original.reservation_is_active == true
  //               ? "In-Activate"
  //               : "Activate"}
  //           </button> */}
  //         {cell.row.original.is_payment_completed ? (
  //           <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
  //             <button
  //               type="button"
  //               onClick={() => {
  //                 generateInvoiceReport(cell.row.original);
  //               }}
  //               size="default"
  //               variant="outlined"
  //             >
  //               Invoice
  //             </button>
  //           </span>
  //         ) : (
  //           <></>
  //         )}
  //         {cell.row.original.invoice_url &&
  //           cell.row.original.is_payment_completed ? (
  //           <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
  //             <a href={`${cell.row.original.invoice_url}`} target="_blank">
  //               View PDF
  //             </a>
  //           </span>
  //         ) : (
  //           <></>
  //         )}
  //       </>
  //     );
  //   },
  // },
];

export const out_source_reservation_columns = ({
  editReservation,
}) => [
    {
      Header: 'Reservation ID',
      Cell: (cell) => {
        const reservation_id = cell.row.original.reservation_id;
        return (
          <>
            <div>
              {cell.row.original.reservation_is_active == true ? (
                <Link
                  onClick={() => {
                    console.log('PS=>', reservation_id);
                    localStorage.setItem(
                      'edit_booking_reservation_user_details',
                      JSON.stringify(cell.row.original)
                    );
                    localStorage.setItem(
                      'edit_booking_reservation_id',
                      reservation_id
                    );
                    editReservation(cell.row.original);
                  }}
                  to={`/bookingConfirm`}
                  className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
               <div className="flex items-center flex-col p-1">
                <span>{reservation_id}{"  "}</span>
                <span>View</span>
              </div>
            </Link>
              ) : (
                <p>{reservation_id}</p>
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: 'Patient',
      //accessor: (d) =>{ console.log("ddddd", d) (d.t_res_patient_test_pack_map[0].m_patient_relation?.patient_first_name +' '+d.t_res_patient_test_pack_map[0].m_patient_relation?.patient_last_name) },
      Cell: (cell) => {
        return (
          <>
            {cell.row.original?.t_res_patient_test_pack_map[0]?.m_patient_relation?.patient_first_name}
            {/* {cell.row.original?.t_res_patient_test_pack_map[0].m_patient_relation?.patient_first_name + ' ' + cell.row.original.t_res_patient_test_pack_map[0].m_patient_relation?.patient_last_name} */}
          </>
        )
      }
    },
    {
      Header: 'Clinic',
      accessor: (d) => d?.m_outsourcing_clinic_relation?.mc_name,
    },
    {
      Header: 'Mobile No.',
      accessor: (d) => d?.t_res_patient_test_pack_map[0]?.m_patient_relation?.patient_mobile,
    },

    {
      Header: 'Gender',
      accessor: (d) => d?.t_res_patient_test_pack_map[0]?.m_patient_relation?.m_gender_relation?.gender_name_en,
    },
    {
      Header: 'Date',
      accessor: (d) => moment(convertToAnotherTimezone(d.reservation_created_at)).format('DD-MM-YYYY'),
    },
    {
      Header: 'Time',
      accessor: (d) => moment(convertToAnotherTimezone(d.reservation_created_at)).format('h:mm:ss a'),
    },
    {
      Header: 'Paid Amount',
      accessor: (d) => capitalise(d.rev_payable_amount),
    },
    {
      Header: 'Status',
      accessor: (d) => (d.reservation_is_active == true ? 'Active' : 'Cancelled'),
    }
  ];

export const outsource_clinic_patients_columns = ({
  editReservation,
}) => [
    {
      Header: 'Reservation ID',
      Cell: (cell) => {
        const reservation_id = cell.row.original.reservation_id;
        return (
          <>
            <div>
              {cell.row.original.reservation_is_active == true ? (
                <Link
                  onClick={() => {
                    console.log('PS=>', reservation_id);
                    localStorage.setItem(
                      'edit_booking_reservation_user_details',
                      JSON.stringify(cell.row.original)
                    );
                    localStorage.setItem(
                      'edit_booking_reservation_id',
                      reservation_id
                    );
                    editReservation(cell.row.original);
                  }}
                  to={`/bookingConfirm`}
                  className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
               <div className="flex items-center flex-col p-1">
                <span>{reservation_id}{"  "}</span>
                <span>View</span>
              </div>
            </Link>
              ) : (
                <p>{reservation_id}</p>
              )}
            </div>
          </>
        );
      },
    },
    {
      Header: 'Patient',
      //accessor: (d) =>{ console.log("ddddd", d) (d.t_res_patient_test_pack_map[0].m_patient_relation?.patient_first_name +' '+d.t_res_patient_test_pack_map[0].m_patient_relation?.patient_last_name) },
      Cell: (cell) => {
        return (
          <>
            {cell.row.original?.t_res_patient_test_pack_map[0]?.m_patient_relation?.patient_first_name}
            {/* {cell.row.original?.t_res_patient_test_pack_map[0].m_patient_relation?.patient_first_name + ' ' + cell.row.original.t_res_patient_test_pack_map[0].m_patient_relation?.patient_last_name} */}
          </>
        )
      }
    },
    {
      Header: 'Clinic',
      accessor: (d) => d?.m_outsourcing_clinic_relation?.mc_name,
    },
    {
      Header: 'Mobile No.',
      accessor: (d) => d?.t_res_patient_test_pack_map[0]?.m_patient_relation?.patient_mobile,
    },

    {
      Header: 'Gender',
      accessor: (d) => d?.t_res_patient_test_pack_map[0]?.m_patient_relation?.m_gender_relation?.gender_name_en,
    },
    {
      Header: 'Date',
      accessor: (d) => moment(convertToAnotherTimezone(d.reservation_created_at)).format('DD-MM-YYYY'),
    },
    {
      Header: 'Time',
      accessor: (d) => moment(convertToAnotherTimezone(d.reservation_created_at)).format('h:mm:ss a'),
    },
    {
      Header: 'Paid Amount',
      accessor: (d) => capitalise(d.rev_payable_amount),
    },
    {
      Header: 'Status',
      accessor: (d) => (d.reservation_is_active == true ? 'Active' : 'Cancelled'),
    }
  ];


export const invoice_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Invoice ID',
    accessor: 'invoice_id',
  },
  {
    Header: 'Invoice Title',
    accessor: 'invoice_title',
  },
  {
    Header: 'Invoice From',
    accessor: 'invoice_from',
  },
  {
    Header: 'Invoice To',
    accessor: 'invoice_to',
  },
  {
    Header: 'Organization',
    accessor: 'invoice_org',
  },
  {
    Header: 'Invoicen Number',
    accessor: 'invoice_number',
  },
  {
    Header: 'Date & Time',
    accessor: 'invoice_datetime',
  },
  {
    Header: 'Invoice Item',
    accessor: 'invoice_items',
  },
  {
    Header: 'Invoice Amount',
    accessor: 'invoice_amount',
  },
  {
    Header: 'Invoice Nite',
    accessor: 'invoice_note',
  },
  {
    Header: 'Invoice Status',
    accessor: 'invoice_status',
  },
  {
    Header: 'Invoice Created By',
    accessor: 'invoice_created_by',
  },
  {
    Header: 'Invoice Created At',
    accessor: 'invoice_created_at',
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <span
            onClick={() =>
              handleDrawer('edit', cell.row.original.id, cell.row.original)
            }
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
  // {
  //   Header: "Status",
  //   accessor: "status",
  //   Cell: (cell) => {
  //     return (
  //       <>
  //         <span className="inline-flex px-2 text-xs leading-5 bg-green-100 rounded-full text-cyan-600">
  //           <button
  //           onClick={() => handleDrawer(cell.row.original.id)}
  //             className="text-cyan-900 "
  //             size="default"
  //             variant="outlined"
  //           >
  //             Success<span className="sr-only">, </span>
  //           </button>
  //         </span>

  //       </>
  //     );
  //   },
  // },
];

export const masters_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: 'Master Name English',
    accessor: (d) => capitalise(d.mmstr_name_en),
  },
  {
    Header: 'Master Name Arabic',
    accessor: (d) => capitalise(d.mmstr_name_ar),
  },
  {
    Header: 'Master Type',
    accessor: (d) => capitalise(d.mmstr_type),
  },
  // {
  // 	Header: "Action",
  // 	accessor: "action",
  // 	Cell: (cell) => {
  // 		const cellId = cell.row.original.state_id;
  // 		return (
  // 			<>
  // 				<Link
  // 					onClick={() => handleDrawer("edit", cellId, cell.row.original)}
  // 					className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
  // 				>
  // 					<button
  // 						className="text-cyan-900 "
  // 						size="default"
  // 						variant="outlined"
  // 					>
  // 						Edit<span className="sr-only">, </span>
  // 					</button>
  // 				</Link>

  // 				<span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
  // 					<button
  // 						onClick={() => {
  // 							onDeleteOpen(cellId);
  // 						}}
  // 						size="default"
  // 						variant="outlined"
  // 					>
  // 						Delete
  // 						<span className="sr-only">, </span>
  // 					</button>
  // 				</span>
  // 			</>
  // 		);
  // 	},
  // },
];

export const qc_test_columns = ({ onDeleteOpen, qcId }) => [
  {
    Header: 'ID',
    accessor: 'test_id',
  },
  {
    Header: 'Test Name',
    accessor: 'test_name_en',
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.test_id;
      return (
        <>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(qcId, cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              {/* <span className="sr-only">, </span> */}
            </button>
          </span>
        </>
      );
    },
  },
];

export const qc_analyzer_columns = ({ onDeleteQCAnalyzer }) => [
  {
    Header: 'ID',
    accessor: 'qcla_id',
  },
  {
    Header: 'Name',
    accessor: (e) => e.lt_lab_analyzer_relation.lab_analyzer_name_en,
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.package_id;
      return (
        <>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button onClick={e => onDeleteQCAnalyzer(cell.row.original)} size="default" variant="outlined">
              Delete
            </button>
          </span>
        </>
      );
    },
  },
];

export const qc_lot_columns = ({ toggleStatus, toggleModal }) => [
  {
    Header: 'QC ID',
    accessor: 'fk_q_qc_id',
  },
  {
    Header: 'Lot ID',
    accessor: 'lot_id',
  },
  {
    Header: 'Name',
    accessor: 'lot_name_en',
  },
  {
    Header: 'QC Test',
    accessor: (d) => capitalise(d?.t_test_relation?.test_name_en),
    // accessor: 'qc_test',
  },
  {
    Header: 'Created By',
    accessor: 'created_by',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original?.lot_cu_relation?.user_first_name
            ? cell.row.original?.lot_cu_relation?.user_first_name
            : 'Admin'}
        </p>
      );
    },
  },
  {
    Header: 'Enable',
    accessor: 'enable',
    Cell: (cell) => {
      return (
        <Switch
          checked={cell.row.original.lot_is_active}
          onChange={() =>
            toggleStatus(
              cell.row.original.lot_id,
              cell.row.original.lot_is_active
            )
          }
          // onBlur={handleBlur}
          name="enable"
          className={
            cell.row.original.lot_is_active
              ? 'bg-cyan-600 relative inline-flex h-Switch6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
              : 'bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          }
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={
              cell.row.original.lot_is_active
                ? 'translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                : 'translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            }
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original;
      // const cellId = cell.row.original.qc_id;
      return (
        <>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              // onClick={() => {
              // 	onDeleteOpen(cellId, cell.row.original.package_is_active);
              // }}
              onClick={() => {
                toggleModal(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Test List
              {/* <span className="sr-only">, </span> */}
            </button>
          </span>
        </>
      );
    },
  },
];

export const qc_test_list_lot = ({ updateLotTestList, qcId }) => [
  // {
  //   Header: 'Test',
  //   accessor: (d) => capitalise(d?.t_test_relation?.test_name_en),
  //   // accessor: 'test_name_en',
  // },
  {
    Header: 'Test Parameter',
    accessor: (d) => capitalise(d.ttp_name_en),
    // accessor: 'test_name_en',
  },
  {
    Header: 'Mean',
    accessor: 'mean',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.mean ? (
            cell.row.original.mean
          ) : (
            <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditTooptip
                  fieldName="qcltv_mean"
                  CellId={cell.row.original.test_id}
                  func={updateLotTestList}
                  Nfunc="updateLotTestList"
                  lotId={qcId}
                  qc_data={cell.row.original}
                />
              }
            >
              <button>Empty</button>
            </Tippy>
          )}
        </p>
      );
    },
  },
  {
    Header: 'SD',
    accessor: 'sd',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.sd ? (
            cell.row.original.sd
          ) : (
            <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditTooptip
                  fieldName="qcltv_sd"
                  CellId={cell.row.original.test_id}
                  func={updateLotTestList}
                  lotId={qcId}
                  qc_data={cell.row.original}
                />
              }
            >
              <button>Empty</button>
            </Tippy>
          )}
        </p>
      );
    },
  },
  {
    Header: 'MIN',
    accessor: 'min',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.min ? (
            cell.row.original.min
          ) : (
            <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditTooptip
                  fieldName="qcltv_min"
                  CellId={cell.row.original.test_id}
                  func={updateLotTestList}
                  lotId={qcId}
                  qc_data={cell.row.original}
                />
              }
            >
              <button>Empty</button>
            </Tippy>
          )}
        </p>
      );
    },
  },
  {
    Header: 'MAX',
    accessor: 'max',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.max ? (
            cell.row.original.max
          ) : (
            <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditTooptip
                  fieldName="qcltv_max"
                  CellId={cell.row.original.test_id}
                  func={updateLotTestList}
                  lotId={qcId}
                  qc_data={cell.row.original}
                />
              }
            >
              <button>Empty</button>
            </Tippy>
          )}
        </p>
      );
    },
  },
];

export const control_list_columns = (onDeleteOpen) => [
  {
    Header: 'ID',
    accessor: 'id',
    Cell: (cell) => {
      return (
        <Link
          className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          to={`/qc/test/${cell.row.original.qc_id}`}
        >
          <button className="text-cyan-900 " size="default" variant="outlined">

            {cell.row.original.qc_id}
          </button>
        </Link>
      );
    },
  },
  {
    Header: 'QC Name',
    accessor: 'qc_name_ar',
  },
  {
    Header: 'Stability',
    accessor: 'qc_stability',
  },

  {
    Header: "Status",
    accessor: (d) => (d.qc_is_active == true ? "Active" : "Inactive"),
    Cell: (cell) => {
      const cellId = cell.row.original.qc_id;
      const enabled = cell.row.original.qc_is_active;
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.qc_is_active);
          }}
          className={testStatusToggle(
            enabled ? "bg-cyan-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={testStatusToggle(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Created By',
    accessor: 'qc_created_by',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500" to="/qc/test">
          {(cell.row?.original?.qc_cu_relation?.user_first_name)
            ? (cell.row?.original?.qc_cu_relation?.user_first_name)
            : 'Admin'}
        </p>
      );
    },
  },
  {
    Header: 'Created At',
    accessor: (e) => moment(convertToAnotherTimezone(e.qc_created_at)).format('DD/MM/YYYY'),
  },
];


export const Titems_colums = ({ }) => [
  {
    Header: 'ID',
    accessor: 'ti_id',
  },
  {
    Header: 'Barcode',
    accessor: 'ti_barcode_id',
  },
  {
    Header: 'Test Used',
    accessor: 'ti_test_used',
  },
]

export const Items_colums = ({ updateItemStatus, viewTItems, onDeleteOpen }) => [
  {
    Header: 'Item Name en',
    accessor: 'witem_name_en',
  },
  {
    Header: 'Item Name ar',
    accessor: 'witem_name_ar',
  },
  {
    Header: 'Item Price',
    accessor: 'witem_price',
  },
  // {
  //   Header: "Item Description",
  //   accessor: "item_description",
  // },
  // {
  //   Header: "Invertory Type",
  //   accessor: "inventory_type",
  // },
  {
    Header: 'Category',
    accessor: (e) => e?.wic_relation?.wic_name,
  },
  {
    Header: 'Item Code',
    accessor: (e) => e?.witem_item_code,
  },

  {
    Header: 'Has line item',
    accessor: 'witem_is_active',
    Cell: (cell) => {
      let payload = {
        witem_is_active:
          cell.row.original.witem_is_active === true ? false : true,
      };
      return (
        <Switch
          checked={cell.row.original.witem_is_active}
          onChange={() => {
            updateItemStatus(cell.row.original.witem_id, payload);
          }}
          className={`${cell.row.original.witem_is_active ? 'bg-cyan-600' : 'bg-gray-200'
            } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${cell.row.original.witem_is_active
              ? 'translate-x-5'
              : 'translate-x-0'
              } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'View Items',
    accessor: '',
    Cell: (cell) => {
      return (
        <p
          className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
        >
          <button className="text-cyan-900 " onClick={e => viewTItems(cell.row.original)} size="default" variant="outlined">
            View Items<span className="sr-only">, </span>
          </button>
        </p>
      );
    },
  },
  {
    Header: 'Actions',
    accessor: 'witem_id',
    Cell: (cell) => {
      return (
        <>
          <Link
            to={`/add-items/${cell.row.original.witem_id}`}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button className="text-cyan-900 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button onClick={e => onDeleteOpen(cell.row.original.witem_id)} size="default" variant="outlined">
              Delete
            </button>
          </span>
        </>
      );
    },
  },
];

export const M_Units_colums = ({ UpdateUnitStatus, UpdateUnits, openEdit, onDeleteOpen }) => [
  {
    Header: 'Id',
    accessor: 'mu_id',
  },

  {
    Header: 'name',
    accessor: 'mu_unit_name',
  },
  {
    Header: 'Symbol',
    accessor: 'mu_unit_symbol',
  },
  // {
  //   Header: 'Status',
  //   accessor: 'mu_is_active',
  //   Cell: (cell) => {
  //     let payload = {
  //       mu_is_active:
  //         cell.row.original.mu_is_active === true ? false : true,
  //     };
  //     return (
  //       <Switch
  //         checked={cell.row.original.mu_is_active}
  //         onChange={() => {
  //           UpdateUnitStatus(cell.row.original.mu_id, payload);
  //         }}
  //         className={`${cell.row.original.mu_is_active ? 'bg-cyan-600' : 'bg-gray-200'
  //           } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500`}
  //       >
  //         <span className="sr-only">Use setting</span>
  //         <span
  //           aria-hidden="true"
  //           className={`${cell.row.original.mu_is_active
  //             ? 'translate-x-5'
  //             : 'translate-x-0'
  //             } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
  //         />
  //       </Switch>
  //     );
  //   },
  // },
  {
    Header: 'Actions',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <div
            onClick={e => openEdit(cell.row.original)}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button className="text-cyan-900 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </div>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button onClick={e => onDeleteOpen(cell.row.original.mu_id)} size="default" variant="outlined">
              Delete
            </button>
          </span>
        </>
      );
    },
  },
];

export const Units_colums = ({ UpdateUnitStatus, UpdateUnits, openEdit, onDeleteOpen }) => [
  {
    Header: 'Id',
    accessor: 'unit_id',
  },

  {
    Header: 'name en',
    accessor: 'unit_name_en',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.unit_name_en && (
            <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditTooptip
                  fieldName={'unit_name_en'}
                  CellId={cell.row.original.unit_id}
                  func={UpdateUnits}
                  unitId={cell.row.original.unit_id}
                />
              }
            >
              <button>
                {cell.row.original.unit_name_en
                  ? cell.row.original.unit_name_en
                  : 'Empty'}
              </button>
            </Tippy>
          )}
        </p>
      );
    },
  },
  {
    Header: 'name ar',
    accessor: 'unit_name_ar',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.unit_name_ar && (
            <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditTooptip
                  fieldName={'unit_name_ar'}
                  CellId={cell.row.original.unit_id}
                  func={UpdateUnits}
                  unitId={cell.row.original.unit_id}
                />
              }
            >
              <button>
                {cell.row.original.unit_name_ar
                  ? cell.row.original.unit_name_en
                  : 'Empty'}
              </button>
            </Tippy>
          )}
        </p>
      );
    },
  },
  {
    Header: 'Capacity',
    accessor: 'unit_capacity',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.unit_capacity && (
            <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditTooptip
                  fieldName={'unit_capacity'}
                  CellId={cell.row.original.unit_id}
                  func={UpdateUnits}
                  unitId={cell.row.original.unit_id}
                />
              }
            >
              <button>
                {cell.row.original.unit_capacity
                  ? cell.row.original.unit_capacity
                  : 'Empty'}
              </button>
            </Tippy>
          )}
        </p>
      );
    },
  },
  {
    Header: 'Type',
    accessor: 'unit_type',
  },
  {
    Header: 'Status',
    accessor: 'unit_is_active',
    Cell: (cell) => {
      let payload = {
        unit_is_active:
          cell.row.original.unit_is_active === true ? false : true,
      };
      return (
        <Switch
          checked={cell.row.original.unit_is_active}
          onChange={() => {
            UpdateUnitStatus(cell.row.original.unit_id, payload);
          }}
          className={`${cell.row.original.unit_is_active ? 'bg-cyan-600' : 'bg-gray-200'
            } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${cell.row.original.unit_is_active
              ? 'translate-x-5'
              : 'translate-x-0'
              } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Actions',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <div
            onClick={e => openEdit(cell.row.original)}
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button className="text-cyan-900 " size="default" variant="outlined">
              Edit<span className="sr-only">, </span>
            </button>
          </div>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button onClick={e => onDeleteOpen(cell.row.original.unit_id)} size="default" variant="outlined">
              Delete
            </button>
          </span>
        </>
      );
    },
  },
];

export const vendors_list_colums = ({ onDeleteOpen, updteStatusVendor }) => [
  {
    Header: 'ID',
    accessor: 'vendor_id',
    Cell: (cell) => {
      return (
        <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
          <Link
            className="text-sm text-cyan-500"
            to={`/add-vendor/${cell.row.original.vendor_id}`}
            size="default"
            variant="outlined"
          >
            {cell.row.original.vendor_id}
          </Link>
        </span>
      );
    },
  },
  {
    Header: 'Account Name',
    accessor: 'vendor_account_name',
  },
  {
    Header: 'Legal Name',
    accessor: 'vendor_legal_name',
  },

  {
    Header: 'Phone Number',
    accessor: 'vendor_mobile',
  },
  {
    Header: 'status',
    accessor: 'status',
    Cell: (cell) => {
      let Cstatus = {
        vendor_is_active:
          cell.row.original.vendor_is_active === true ? false : true,
      };

      return (
        <Switch
          checked={cell.row.original.vendor_is_active}
          onChange={() => {
            updteStatusVendor(cell.row.original.vendor_id, Cstatus);
          }}
          name="vendor_is_active"
          className={
            cell.row.original.vendor_is_active
              ? 'bg-cyan-600 relative inline-flex h-Switch6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
              : 'bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          }
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={
              cell.row.original.vendor_is_active
                ? 'translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                : 'translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            }
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Actions',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <Link
          to={`/add-vendor/${cell.row.original.vendor_id}`}
          className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
        >
          <button className="text-cyan-900 " size="default" variant="outlined">
            Edit<span className="sr-only">, </span>
          </button>
        </Link>
      );
    },
  },
  {
    Header: 'delete',
    accessor: 'delete',
    Cell: (cell) => {
      const cellId = cell.row.original.vendor_id;
      return (
        <>
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button onClick={e => onDeleteOpen(cell.row.original.vendor_id)} size="default" variant="outlined">
              Delete
            </button>
          </span>
        </>
      );
    },
  },
];

export const Vendors_Items_colums = ({ getItemById }) => [
  {
    Header: 'Item English Name',
    accessor: 'item_relation.witem_name_en',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.item_relation.witem_name_en}
        </p>
      );
    },
  },
  {
    Header: 'Item Arabic Name',
    accessor: 'item_relation.witem_name_ar',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.item_relation.witem_name_ar}
        </p>
      );
    },
  },
  {
    Header: 'Price',
    accessor: 'vd_item_price',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.vd_item_price}
        </p>
      );
    },
  },

  {
    Header: 'Unit',
    accessor: 'unit',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {cell.row.original.unit_relation.unit_name_en}
        </p>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
            <button
              onClick={() => getItemById(cell.row.original.vd_item_id)}
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button onClick={() => { }} size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const Vendors_Contact_list_colums = ({
  setModal,
  UpdateVendorStatusContact,
  VId,
  getContactById,
}) => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (cell) => {
        return (
          <p className="text-sm text-gray-500">
            {`${cell.row.original.vc_first_name} ${cell.row.original.vc_last_name}`}
          </p>
        );
      },
    },
    {
      Header: 'Email',
      accessor: 'vc_email',
    },
    {
      Header: 'Mobile',
      accessor: 'vc_mobile',
    },
    {
      Header: 'Country',
      accessor: 'vc_country',
    },
    {
      Header: 'Status',
      accessor: '',
      Cell: (cell) => {
        let cellId = cell.row.original.vc_id;
        let Cstatus = {
          vc_is_active: cell.row.original.vc_is_active === true ? false : true,
          user_id: parseInt(VId),
        };
        return (
          <Switch
            checked={cell.row.original.vc_is_active}
            onChange={() => UpdateVendorStatusContact(cellId, Cstatus)}
            name="vc_is_active"
            className={
              cell.row.original.vc_is_active
                ? 'bg-cyan-600 relative inline-flex h-Switch6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
                : 'bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
            }
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={
                cell.row.original.vc_is_active
                  ? 'translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  : 'translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              }
            />
          </Switch>
        );
      },
    },
  ]

  export const sample__processing_columns = ({ handleUpdateComment, handleUpdateResult, handleSaveResult, handleValidateResult, handleApproveResult, selectedLocation,
    generateReport, viewReport, handleUpdateLocChange, handleMixResultSaveUpdate, handleSaveUnit, activeIndex, handleValidateCheckBoxes, handleApproveCheckBoxes, validateCheckBoxes, approveCheckBoxes, changeRefRange }) => [

    {
      Header: 'Patient',
      accessor: (d) => d.buttonFlag ? capitalise(d.patient_first_name) : '',
    },
    {
      Header: "Res. ID",
      Cell: (cell) => {
        const reservation_id = cell.row.original.reservation_id;
        return (
          <>
            <div>
              {
                cell.row.original.reservation_is_active == true ? (
                  <Link
                    onClick={() => {
                      // console.log("PS=>", reservation_id);
                      localStorage.setItem(
                        "edit_booking_reservation_user_details",
                        JSON.stringify(cell.row.original)
                      );
                      localStorage.setItem(
                        "edit_booking_reservation_id",
                        reservation_id
                      );
                      // editReservation(cell.row.original);
                    }}
                    to={`/bookingConfirm`}
                    className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
                  >
                    {reservation_id}
                  </Link>
                ) : <p>{reservation_id}</p>
              }
            </div>
  
          </>
        );
      },
    },
    {
      Header: 'Sample ID',
      Cell: (cell) => {
        const sample_id = cell.row.original.sample_id;
        return (
          <>
            <Link
              to={`../samplelist/${sample_id}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              {sample_id}
            </Link>
          </>
        );
      },
    },
    {
      Header: "Test Name",
      accessor: (d) => capitalise(d.test_name_en),
    },
    {
      Header: "Test-Parameter Name",
      accessor: (d) => d.ttp_name_en ? (
        <div className="inline-flex p-2">
          {d.ttp_name_en}
        </div>
      ) : null,
    },
    {
      Header: "Unit",
      accessor: "mrr_unit",
      Cell: (cell) => {
        let result_value = cell.row.original.test_result&&cell.row.original.test_result?.length>0?(cell.row.original.test_result[0].ssp_mrr_unit?cell.row.original.test_result[0].ssp_mrr_unit:cell.row.original.mrr_unit):cell.row.original.mrr_unit;
        let ttp_id = cell.row.original.ttp_id;
        let originalCell = cell.row.original;
        const [editing, setEditing] = useState(false);
        const [newUnitValue, setNewUnitValue] = useState(result_value);
  
        const toggleEditing = () => {
          setEditing(!editing);
        };
  
        const handleInputChange = (event) => {
          setNewUnitValue(event.target.value);
        };
        const handleSaveUnit2 = () => {
  
          // console.log('this', handleSaveUnit)
          handleSaveUnit(originalCell,newUnitValue,ttp_id);
          // // handleSaveResult(originalCell,newUnitValue);
          // setEditing(false);
          setNewUnitValue(newUnitValue); // Replace savedValue with the actual saved value
          cell.row.original.mrr_unit = newUnitValue;
  
          setEditing(false);
          // console.log("cell.row.original=>",cell.row.original)
        };
        return (
          <p className="text-sm text-gray-500">
            {editing ? (
              <div>
                <input
                  type="text"
                  value={newUnitValue}
                  onChange={handleInputChange}
                  className='w-full rounded-md'
                />
                <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                  <button size="default" variant="outlined" onClick={() => {handleSaveUnit2();}}>Save</button>
                </span>
              </div>
            ) : (
              <div>
                <button 
                className="text-center rounded-md break-words w-full px-1 py-2 text-sm font-medium text-cyan-600 bg-cyan-200 hover:bg-cyan-300 focus:outline-none focus:bg-cyan-300 transition duration-150 ease-in-out"
                size="default" variant="outlined" onClick={toggleEditing}>{newUnitValue ? newUnitValue : 'Empty'}</button>
              </div>
            )}
          </p>
        );
      },
    },
    {
      Header: 'MRN',
      accessor: (d) => d?.patient_report_mrn ? d.patient_report_mrn : '-',
    },
    {
      Header: 'Status',
      accessor: (d) => getSampleParameterResultStatus(d.sp_status_name_en, d.test_result[0]?.ssp_value,  d.test_result[0]?.ssp_approved_by ),
    },
    {
      Header: 'Result',
      accessor: 'result',
      Cell: (cell) => {
        let originalCell = cell.row.original
        // console.log("originalCell=>", originalCell)
  
        let sp_gen_id = originalCell?.sp_gen_id;
        let fk_rr_id = null;
        let default_min = null;
        let default_max = null;
        let rt_name_en = originalCell?.rt_name_en;
        let ssp_value = (originalCell?.test_result?.length > 0) ? parseInt(originalCell.test_result[0]?.ssp_value) : "";
        let ssp_text_value = (originalCell?.test_result?.length > 0) ? originalCell.test_result[0]?.ssp_text_value : null;
        let ssp_attachment = (originalCell?.test_result?.length > 0) ? originalCell.test_result[0]?.ssp_attachment : null;
        let ssp2_value = (originalCell?.test_result?.length > 0) ? parseInt(originalCell?.test_result[0]?.ssp_value) : "";
        let mmr_inputs_value = { ...originalCell?.mmr_inputs_value };
        let r_type_inputsList2 = originalCell?.r_type_inputsList;
        let inputs_value = originalCell?.mmr_inputs_value;
  
        let tprrlab_inputs_value = originalCell?.tprrlab?.m_reference_ranges_relation?.mmr_inputs_value;
        let tprrlabInputsLables = originalCell?.tprrlabInputs;
        // console.log("From spc Results col", originalCell.labArr[0].lab_analyzer_id)
        let fk_lab_analyzer_id = (originalCell?.test_result?.length > 0 && !isNaN(parseInt(originalCell?.test_result[0]?.fk_lab_analyzer_id))) ? parseInt(originalCell?.test_result[0]?.fk_lab_analyzer_id) : originalCell.labArr && originalCell.labArr.length > 0 && originalCell.labArr[0].lab_analyzer_id ? originalCell.labArr[0].lab_analyzer_id : null;
        // console.log("from spc table headers", fk_lab_analyzer_id)
  
        let r_type_inputsList = r_type_inputsList2 && r_type_inputsList2?.length > 0 ? r_type_inputsList2.filter(function ({ rtim_id }) {
          var key = `${rtim_id}`;
          return !this.has(key) && this.add(key);
        }, new Set) : [];
  
        let min_max_value;
        let Value = "";
        let minValue = "";
        let maxValue = "";
        default_min = (!fk_rr_id ? (originalCell?.mrr_default_min) : (default_min));
        default_max = (!fk_rr_id ? (originalCell?.mrr_default_max) : (default_max));
        fk_rr_id = (!fk_rr_id ? (originalCell?.mrr_id) : (fk_rr_id));
        let mrr_type = originalCell?.mrr_type ? parseInt(originalCell?.mrr_type) : null;
        let colorResult = {};
        if (mrr_type == 17) {
          fk_rr_id = mrr_type;
          const filteredData = inputs_value.filter(obj => obj.color_is_normal === 'yes');
          colorResult = filteredData.length > 0 ? filteredData : [inputs_value[0]];
        }
        if (mrr_type == 17) {
          fk_rr_id = mrr_type;
        }
        if (mrr_type == '5') {
          fk_rr_id = mrr_type;
        }
  
        let result_value = "";
        let result_type = "";
        let resultFieldName = "";
        // console.log("originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT'", originalCell?.mmr_inputs_value[0]?.lable, fk_rr_id)
        if (fk_rr_id == 4) {
          //TEXT
          result_type = "TEXT";
          resultFieldName = "ssp_text_value";
          result_value = originalCell ? originalCell.test_result[0]?.ssp_text_value : originalCell?.test_result[0]?.ssp_text_value ? originalCell?.test_result[0]?.ssp_text_value : 'N/A';
        } else if (fk_rr_id == 5 && originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT') {
          //FILE
          result_type = "POSITIVE / NEGATIVE";
          resultFieldName = "ssp_pos_neg_value";
          result_value = originalCell ? originalCell.test_result[0]?.ssp_pos_neg_value : originalCell?.test_result[0]?.ssp_pos_neg_value ? originalCell?.test_result[0]?.ssp_pos_neg_value : 'N/A';
        } else if (fk_rr_id == 5) {
          // console.log("originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT'", originalCell?.mmr_inputs_value[0]?.lable)
          //FILE
          result_type = "FILE";
          resultFieldName = "ssp_attachment";
          result_value = originalCell ? originalCell.test_result[0]?.ssp_attachment : originalCell?.test_result[0]?.ssp_attachment ? originalCell?.test_result[0]?.ssp_attachment : 'N/A';
        } else if (fk_rr_id == 6) {
          //FILE
          result_type = "POSITIVE / NEGATIVE";
          resultFieldName = "ssp_pos_neg_value";
          result_value = originalCell ? originalCell.test_result[0]?.ssp_pos_neg_value : originalCell?.test_result[0]?.ssp_pos_neg_value ? originalCell?.test_result[0]?.ssp_pos_neg_value : 'N/A';
        } else if (fk_rr_id == 17) {
          //COLOR
          result_type = "COLOR";
          resultFieldName = "ssp_text_value";
          result_value = originalCell ? originalCell.test_result[0]?.ssp_text_value : originalCell?.test_result[0]?.ssp_text_value ? originalCell?.test_result[0]?.ssp_text_value : 'N/A';
        } else if (originalCell?.fk_rr_type_id == 18) {
          //CUSTOM_SELECT
          result_type = "CUSTOM_SELECT";
          resultFieldName = "ssp_text_value";
          result_value = originalCell ? originalCell.test_result[0]?.ssp_text_value : originalCell?.test_result[0]?.ssp_text_value ? originalCell?.test_result[0]?.ssp_text_value : 'N/A';
        } else {
          //RANGE
          result_type = "RANGE";
          resultFieldName = "ssp_value";
          result_value = originalCell ? originalCell.test_result[0]?.ssp_value : originalCell?.test_result[0]?.ssp_value ? originalCell?.test_result[0]?.ssp_value : 'N/A';
        }
  
        let is_sub_rr = originalCell?.is_sub_rr;
        let sub_ref_range = originalCell?.sub_ref_range ? originalCell?.sub_ref_range : [];
  
        let rangeData = [];
  
        if (mrr_type != 17) {
          if (fk_lab_analyzer_id && tprrlab_inputs_value && tprrlabInputsLables.length > 0) {
  
            if (tprrlab_inputs_value.length > 0) {
              tprrlab_inputs_value.map((ip_data, ip_index) => {
                const data = {
                  min: parseFloat(ip_data[2].value).toFixed(2),
                  max: parseFloat(ip_data[4].value).toFixed(2),
                  name: ip_data[1].value //r_type_inputsList[ip_index].rti_title_en
                }
                rangeData.push(data);
              });
            }
  
          } else {
  
            if (is_sub_rr == true) {
              if (sub_ref_range.length > 0) {
  
                const getSubRRData = sub_ref_range[0];
                default_min = getSubRRData.mrr_sub_normal_min;
                default_max = getSubRRData.mrr_sub_normal_max;
  
                if (sub_ref_range[0].mmr_inputs_value !== null && sub_ref_range[0].mmr_inputs_value.length > 0) {
                  sub_ref_range[0].mmr_inputs_value.map((ip_data, ip_index) => {
                    const data = {
                      min: parseFloat(ip_data[2]?.value).toFixed(2),
                      max: parseFloat(ip_data[4]?.value).toFixed(2),
                      name: ip_data[1].value //r_type_inputsList[ip_index].rti_title_en
                    }
                    rangeData.push(data);
                  });
                }
  
              }
            } else {
              if (inputs_value.length > 0) {
                inputs_value.map((ip_data, ip_index) => {
                  const data = {
                    min: parseFloat(ip_data[2]?.value).toFixed(2),
                    max: parseFloat(ip_data[4]?.value).toFixed(2),
                    name: ip_data[1]?.value //r_type_inputsList[ip_index].rti_title_en
                  }
                  rangeData.push(data);
                });
              }
            }
          }
        }
  
        const getObjectFromRange = (num) => {
          // console.log("getObjectFromRange", { num, rangeData, mmr_inputs_value, colorResult, result_value });
          if (fk_rr_id == 5 && originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT') {
            const matchedRange = originalCell?.mmr_inputs_value.find(res => res.value == result_value);
            return matchedRange ? <span style={{ color: 'green' }}>Normal</span> : <span style={{ color: 'red' }}>Abnormal</span>;
          }
          if (fk_rr_id == 17) {
            const matchedRange = colorResult.find(res => res.color_name == result_value);
            return matchedRange ? <span style={{ color: 'green' }}>Normal</span> : <span style={{ color: 'red' }}>Abnormal</span>;
          }
          let colourRangeName = 0;
          const matchedRange = rangeData.find((range, index) => {
            if (range.min && range.max) {
              if (num >= range.min && num <= range.max) {
                colourRangeName = index;
                return true
              }
            }
            if (range.min && range.max == 'NaN') {
              if (num >= range.min) {
                colourRangeName = index;
                return true
              }
            }
            return false
          });
          let colors2 = [
            "#E68600",
            "#FFBF66",
            "#FFFF99",
            "#99FF99",
            "#00FF00",
            "#00CC00",
            "#FF4D4D",
            "#FF0000",
            "#CC0000",
          ];
          const colorsForRange = [
            { color_name: "very low", color: "#E68600" },
            { color_name: "low", color: "#FFBF66" },
            { color_name: "border", color: "#FFFF99" },
            { color_name: "below normal", color: "#99FF99" },
            { color_name: "normal", color: "#00FF00" },
            { color_name: "above normal", color: "#00CC00" },
            { color_name: "low high", color: "#FF4D4D" },
            { color_name: "high", color: "#FF0000" },
            { color_name: "very high", color: "#CC0000" }
          ]
  
          if (rt_name_en) {
            let ct_arr = [];
            let stringArr_rt = [];
            colorsForRange.forEach(v => {
              if (rt_name_en.includes('<')) {
                stringArr_rt = rt_name_en.split(' < ');
              }
              if (rt_name_en.includes('>')) {
                stringArr_rt = rt_name_en.split(' > ');
              }
              stringArr_rt.forEach(str => {
                if (str == v.color_name) {
                  ct_arr.push(v.color)
                }
              })
            })
            colors2 = ct_arr
          }
          return matchedRange ? <span style={{ backgroundColor: `${colors2[colourRangeName]}` }} className={`text-black p-2`}> {matchedRange.name} </span> : '';
        };
  
        let defaultStatus = false;
        let isActive = false;
        if(activeIndex === parseInt(cell.row.index)){
          defaultStatus = true;
          isActive = true;
        }
  
        const [editingRes, setEditingRes] = useState(defaultStatus);
        const [resultValue, setResultValue] = useState(result_value);
  
        const toggleEditingRes = () => {
          setEditingRes(!editingRes);
        };
  
        const savedResult = (value) => {
          setEditingRes(!editingRes);
          if(value){
            setResultValue(value);
            ssp_value = value;
            cell.row.original.ssp_value = value
          }
          //cell.row.original.mrr_unit
        }
  
        const handleInputBlur = () => {
          setEditingRes(!editingRes);
        }
  
        return (
          <p className="text-sm text-gray-500 inline-flex">
  
            {(() => {
              if (fk_rr_id == 5 && originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT') {
                return <span><DropDownResult value={result_value} id={sp_gen_id} originalCell={originalCell} saveUpdateFunction={handleMixResultSaveUpdate} /></span>;
              } else if (fk_rr_id == 5) {
                return <span><FileResultUpload id={sp_gen_id} originalCell={originalCell} saveUpdateFunction={handleMixResultSaveUpdate} /></span>;
              } else if (fk_rr_id == 6) {
                return <span><DropDownResult value={result_value} id={sp_gen_id} originalCell={originalCell} saveUpdateFunction={handleMixResultSaveUpdate} /></span>;
              } else if (fk_rr_id == 17) {
                return <span><ColorDropDownResult inputs_value={inputs_value} value={result_value} id={sp_gen_id} originalCell={originalCell} saveUpdateFunction={handleMixResultSaveUpdate} /></span>;
              } else if (originalCell?.fk_rr_type_id == 18) {
                return <span><CustomResultDropDownResult inputs_value={originalCell?.mmr_inputs_value} value={result_value} id={sp_gen_id} originalCell={originalCell} saveUpdateFunction={handleMixResultSaveUpdate} /></span>;
              } else {
                /* return <Tippy
                  theme="light"
                  placement="left"
                  trigger="click"
                  allowHTML={true}
                  interactive={true}
                  content={
                    <EditResultTooptip
                      fieldName={resultFieldName ? resultFieldName : 'ssp_value'}
                      originalCell={originalCell}
                      saveFunc={handleSaveResult}
                      updateFunc={handleUpdateResult}
                      prevValue={result_value}
                    />
  
                  }
                >
                  <button>
                    {originalCell.test_result?.length > 0 ? (
                      <div className="inline-flex p-2 text-xs underline underline-offset-2">
                        
                        {result_value + ' '}
                        <span>
                          <PencilIcon className="w-4 h-4 ml-2" />
                        </span>
                      </div>
                    ) : (
                      <div className="inline-flex p-2 text-xs underline underline-offset-2">
                        {originalCell.test_result && originalCell.test_result?.length > 0 ?
                          result_value : "Add Result"}
                        <span><PencilIcon className="w-4 h-4 ml-2" /></span>
                      </div>
                    )}
                  </button>
                </Tippy>; */
  
                return  <div>
                          {editingRes ? (
                            <div className='inline-flex'>
                              <EditResultTooptip
                                fieldName={resultFieldName ? resultFieldName : 'ssp_value'}
                                originalCell={originalCell}
                                saveFunc={handleSaveResult}
                                updateFunc={handleUpdateResult}
                                prevValue={resultValue}
                                resultFunc={savedResult}
                                inputIndex={parseInt(cell.row.index)}
                                isActive={isActive}
                                handleInputBlur={handleInputBlur}
                                fk_lab_analyzer_id={fk_lab_analyzer_id}
                                fromTable="sample__processing_columns -> Result Column"
                              />
                              {/* <span>
                                <button className="inline-flex px-2 py-2 text-red-600" onClick={toggleEditingRes}>
                                  <XMarkIcon className="w-4 h-4 ml-2" />
                                </button>
                              </span> */}
                            </div>
                          ) : (
                            <div>
                              <span className="px-2 py-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                                <button className="inline-flex px-2 py-2" onClick={toggleEditingRes}>
                                  {/* {(resultValue == ssp_value) ? resultValue : ssp_value + ' '} Add Result 1 */}
                                  {resultValue ? resultValue : ' Add Result ' }
                                  <PencilIcon className="w-4 h-4 ml-2" />
                                </button>
                              </span>
                            </div>
                          )}
                        </div>
  
              }
            })()}
  
            {fk_rr_id ? (originalCell.test_result?.length > 0 ? (
              <div className="inline-flex p-2 text-xs underline underline-offset-2">
  
                {(() => {
  
                  if (is_sub_rr == true) {
  
                    if (sub_ref_range.length > 0) {
                      let getSubRRData = sub_ref_range[0];
  
                      default_min = getSubRRData.mrr_sub_normal_min;
                      default_max = getSubRRData.mrr_sub_normal_max;
  
                      //return (ssp_value >= default_min && ssp_value <= default_max ? (<span className="text-green-500">Normal</span>) : ssp_value <= default_min ? (<span className="text-red-500">Low</span>) : ssp_value >= default_max ? (<span className="text-red-500">High</span>) : "");
                    } else {
                      return <span>...</span>;
                    }
  
                  } else {
  
                    return (ssp_value) ? ((mmr_inputs_value?.length <= 0) ? ((ssp_value >= default_min) && (ssp_value <= default_max) ? (<span className="text-green-500">Normal</span>) : (ssp_value <= default_min) ? (<span className="text-red-500">Low</span>) : (ssp_value >= default_max) ? (<span className="text-red-500">High</span>) : '') : (r_type_inputsList.map((ele, ind) => {
  
                      // console.log("rti_title_en=>", ele.rti_title_en, ele, r_type_inputsList, originalCell.fk_sample_id)
                      if (ele.rti_title_en == "Normal") {
                        min_max_value = ((ssp_value >= default_min) && (ssp_value <= default_max) ? (<span className="text-green-500">Normal</span>) : (ssp_value <= default_min) ? (<span className="text-red-500">Low</span>) : (ssp_value >= default_max) ? (<span className="text-red-500">High</span>) : '');
                      } else {
                        mmr_inputs_value?.length > 0 ? (mmr_inputs_value[ind]?.map((itj_val, itj_index) => {
  
                          if (itj_val?.lable == "MIN") {
                            minValue = parseInt(itj_val?.value);
                          }
  
                          if (itj_val?.lable == "MAX") {
                            maxValue = parseInt(itj_val?.value);
                          }
                        })) : (<></>);
                        if (maxValue && minValue) {
                          min_max_value = ((ssp2_value >= minValue) && (ssp2_value <= maxValue) ? (<span className="text-green-500">Normal</span>) : (ssp2_value <= minValue) ? (<span className="text-red-500">Low</span>) : (ssp2_value >= maxValue) ? (<span className="text-red-500">High</span>) : '');
                        } else {
                          min_max_value = ((ssp_value >= default_min) && (ssp_value <= default_max) ? (<span className="text-green-500">Normal</span>) : (ssp_value <= default_min) ? (<span className="text-red-500">Low</span>) : (ssp_value >= default_max) ? (<span className="text-red-500">High</span>) : '');
                        }
  
                        // console.log("My Data ssp2_value=>", ssp2_value, "minValue=>", minValue, "maxValue=>", maxValue)
                      }
                    })))
  
                      :
  
                      //ssp_attachment
  
                      (
                        ssp_text_value ?
  
                          //<span>{ssp_text_value}</span>
                          ""
  
                          :
  
                          (
                            ssp_attachment ?
  
                              <span><a href={ssp_attachment ? ssp_attachment : "#"} target="_blank">View</a></span>
  
                              :
  
                              ""
                          )
  
                      )
  
                  }
  
                })()}
  
              </div>
            ) : (
              <div className="inline-flex p-2 text-xs underline underline-offset-2">
                {(parseFloat(originalCell?.test_result[0]?.ssp_value)) ? ((mmr_inputs_value?.length <= 0) ? ((parseFloat(originalCell?.test_result[0]?.ssp_value) >= default_min) && (parseFloat(originalCell?.test_result[0]?.ssp_value) <= default_max) ? (<span className="text-green-500">Normal</span>) : (parseFloat(originalCell?.test_result[0]?.ssp_value) <= default_min) ? (<span className="text-red-500">Low</span>) : (parseFloat(originalCell?.test_result[0]?.ssp_value) >= default_max) ? (<span className="text-red-500">High</span>) : '') : (r_type_inputsList.map((ele, ind) => {
                  if (ele.rti_title_en == "Normal") {
                    min_max_value = ((parseFloat(originalCell?.test_result[0]?.ssp_value) >= default_min) && (parseFloat(originalCell?.test_result[0]?.ssp_value) <= default_max) ? (<span className="text-green-500">Normal</span>) : (parseFloat(originalCell?.test_result[0]?.ssp_value) <= default_min) ? (<span className="text-red-500">Low</span>) : (parseFloat(originalCell?.test_result[0]?.ssp_value) >= default_max) ? (<span className="text-red-500">High</span>) : '');
                  } else {
                    mmr_inputs_value[ind].map((itj_val, itj_index) => {
                      if (itj_val?.lable === "MIN") {
                        minValue = parseFloat(itj_val?.value);
                      }
                      if (itj_val?.lable === "MAX") {
                        maxValue = parseFloat(itj_val?.value);
                      }
  
                    });
                    //min_max_value = ((ssp2_value >= minValue) && (ssp2_value <= maxValue) ? (<span className="text-green-500">Normal</span>) : (ssp2_value <= minValue) ? (<span className="text-red-500">Low</span>) : (ssp2_value >= maxValue) ? (<span className="text-red-500">High</span>) : '');
                  }
                }))) : 'N/A'}
              </div>
            )) : (<span className="text-red-500"></span>)}
            {/* {min_max_value} */} {/* {getObjectFromRange(parseFloat(originalCell?.test_result[0]?.ssp_value))} */}
            {getObjectFromRange(parseFloat(resultValue))}
          </p>
        );
      }
    },
    {
      Header: 'Select Ref. Range',
      accessor: (d) => 'Min: 5 - Max: 10',
      Cell: (cell) => {
        // New Content
        let originalCell = cell.row.original;
        const [selected, setSelected] = useState(originalCell?.ssp_rr_to_use);


        return (
          <div className="basis-3/4">
          {originalCell?.phasesRRList && (
            <>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <select
              value={selected}
              onInput={(e) => { setSelected(e.target.value); changeRefRange({ssp_id: originalCell?.test_result[0]?.ssp_id, ssp_rr_to_use: e.target.value }); }}
              name="filterStatus"
              id="filterStatus"
              className="block max-w-lg rounded-md border-gray-300 shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm">
              <option value={''}>Select Range</option>
              {Object.keys(originalCell?.phasesRRList)?.map((name, i) => (
                <option key={i} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          </>
          )}
        </div>
        )
      }
    },
    {
      Header: 'Ref. Normal',
      accessor: (d) => 'Min: 5 - Max: 10',
      Cell: (cell) => {
        // New Content
        let originalCell = cell.row.original
        // console.log("originalCell=>", originalCell)
  
        let fk_rr_id = null;
        let default_min = null;
        let default_max = null;
        let rt_name_en = originalCell?.rt_name_en;
        let ssp_value = (originalCell?.test_result?.length > 0) ? parseInt(originalCell.test_result[0]?.ssp_value) : "";
        let ssp2_value = (originalCell?.test_result?.length > 0) ? parseInt(originalCell?.test_result[0]?.ssp_value) : "";
        let mmr_inputs_value = { ...originalCell?.mmr_inputs_value };
        let inputs_value = originalCell?.mmr_inputs_value;
        let r_type_inputsList2 = originalCell?.r_type_inputsList;
  
        let tprrlab_inputs_value = originalCell?.tprrlab?.m_reference_ranges_relation?.mmr_inputs_value;
        let tprrlabInputsLables = originalCell?.tprrlabInputs;
        let fk_lab_analyzer_id = (originalCell?.test_result?.length > 0) ? parseInt(originalCell?.test_result[0]?.fk_lab_analyzer_id) : null;
  
        let r_type_inputsList = r_type_inputsList2 && r_type_inputsList2?.length > 0 ? r_type_inputsList2.filter(function ({ rtim_id }) {
          var key = `${rtim_id}`;
          return !this.has(key) && this.add(key);
        }, new Set) : [];
  
        const colors = [
          "bg-green-500",
          "bg-blue-500",
          "bg-orange-200",
          "bg-orange-500",
          "bg-orange-700",
          "bg-red-200",
          "bg-red-500",
          "bg-red-700"
        ]
        let colors2 = [
          "#E68600",
          "#FFBF66",
          "#FFFF99",
          "#99FF99",
          "#00FF00",
          "#00CC00",
          "#FF4D4D",
          "#FF0000",
          "#CC0000",
        ];
        const colorsForRange = [
          { color_name: "very low", color: "#E68600" },
          { color_name: "low", color: "#FFBF66" },
          { color_name: "border", color: "#FFFF99" },
          { color_name: "below normal", color: "#99FF99" },
          { color_name: "normal", color: "#00FF00" },
          { color_name: "above normal", color: "#00CC00" },
          { color_name: "low high", color: "#FF4D4D" },
          { color_name: "high", color: "#FF0000" },
          { color_name: "very high", color: "#CC0000" }
        ]
  
        if (rt_name_en) {
          let ct_arr = [];
          let stringArr_rt = [];
          colorsForRange.forEach(v => {
            if (rt_name_en.includes('<')) {
              stringArr_rt = rt_name_en.split(' < ');
            }
            if (rt_name_en.includes('>')) {
              stringArr_rt = rt_name_en.split(' > ');
            }
            stringArr_rt.forEach(str => {
              if (str == v.color_name) {
                ct_arr.push(v.color)
              }
            })
          })
          colors2 = ct_arr
        }
  
        let min_max_value;
        let Value = "";
        let minValue = "";
        let maxValue = "";
        let result_type = null;
        result_type = originalCell?.result_type;
        default_min = (!fk_rr_id ? (originalCell?.mrr_default_min) : (default_min));
        default_max = (!fk_rr_id ? (originalCell?.mrr_default_max) : (default_max));
        fk_rr_id = (!fk_rr_id ? (originalCell?.mrr_id) : (fk_rr_id));
        let mrr_type = originalCell?.mrr_type ? parseInt(originalCell?.mrr_type) : null;
        let colorResult = {};
        if (mrr_type == 17) {
          fk_rr_id = mrr_type;
          const filteredData = inputs_value.filter(obj => obj.color_is_normal === 'yes');
          colorResult = filteredData.length > 0 ? filteredData : [inputs_value[0]];
        }
  
        if (mrr_type == 5) {
          fk_rr_id = mrr_type;
        }
  
        let is_sub_rr = originalCell?.is_sub_rr; //originalCell?.all_subtest_lists?.is_sub_rr ? originalCell?.all_subtest_lists?.is_sub_rr : false;
        let sub_ref_range = originalCell?.sub_ref_range ? originalCell?.sub_ref_range : []; //originalCell?.all_subtest_lists?.sub_ref_range ? originalCell?.all_subtest_lists?.sub_ref_range : [];
  
        if (is_sub_rr == true) {
          if (sub_ref_range.length > 0) {
            const getSubRRData = sub_ref_range[0];
            default_min = getSubRRData.mrr_sub_normal_min;
            default_max = getSubRRData.mrr_sub_normal_max;
          }
        }
  
        if(mrr_type == 1 && originalCell.test_result?.length == 0){
          // fk_rr_id = mrr_type;
          originalCell.test_result = originalCell.mmr_inputs_value;
        }
  
        return (
          <div>
            {fk_rr_id ? (originalCell.test_result?.length > 0 ? (
  
              <div className="inline-flex p-2 text-xs">
  
                {/* {(ssp_value) ? ((mmr_inputs_value?.length <= 0) ? ((ssp_value >= default_min) && (ssp_value <= default_max) ? 'Normal' : (ssp_value <= default_min) ? 'Low' : (ssp_value >= default_max) ? 'High' : '') : (r_type_inputsList.map((ele, ind) => {
                  if (ele.rti_title_en == "Normal") {
                    min_max_value = 'First' + ((ssp_value >= default_min) && (ssp_value <= default_max) ? 'Normal' : (ssp_value <= default_min) ? 'Low' : (ssp_value >= default_max) ? 'High' : '');
                  } else {
                    mmr_inputs_value?.length > 0 ? (mmr_inputs_value[ind].map((itj_val, itj_index) => {
                      min_max_value = 'Second' + ((ssp_value >= parseInt(itj_val[2]?.value) && ssp_value <= parseInt(itj_val[4]?.value)) ? 'Normal' : (ssp_value <= parseInt(itj_val[2])) ? 'Low' + ' LP' + parseInt(itj_val[2]) + ' HP' + parseInt(itj_val[4]) : (ssp_value >= parseInt(itj_val[4])) ? 'High' : '');
                    })) : (<></>);
                  }
                }))) : ''} */}
  
                {(() => {
  
                  if (is_sub_rr == true) {
  
                    if (sub_ref_range.length > 0) {
                      let getSubRRData = sub_ref_range[0];
  
                      default_min = getSubRRData.mrr_sub_normal_min;
                      default_max = getSubRRData.mrr_sub_normal_max;
  
                      //return (ssp_value >= default_min && ssp_value <= default_max ? "Normal" : ssp_value <= default_min ? "Low" : ssp_value >= default_max ? "High" : "");
                    }
  
                  } else {
  
                    return ssp_value
                      ? mmr_inputs_value?.length <= 0
                        ? ssp_value >= default_min && ssp_value <= default_max
                          ? "Normal"
                          : ssp_value <= default_min
                            ? "Low"
                            : ssp_value >= default_max
                              ? "High"
                              : ""
                        : r_type_inputsList.map((ele, ind) => {
                          if (ele.rti_title_en == "Normal") {
                            min_max_value = "First" + (ssp_value >= default_min && ssp_value <= default_max ? "Normal" : ssp_value <= default_min ? "Low" : ssp_value >= default_max ? "High" : "");
                          } else {
                            mmr_inputs_value?.length > 0 ? (
                              mmr_inputs_value[ind].map((itj_val, itj_index) => {
                                min_max_value =
                                  "Second" +
                                  (ssp_value >= parseInt(itj_val[2]?.value) && ssp_value <= parseInt(itj_val[4]?.value)
                                    ? "Normal"
                                    : ssp_value <= parseInt(itj_val[2])
                                      ? "Low" + " LP" + parseInt(itj_val[2]) + " HP" + parseInt(itj_val[4])
                                      : ssp_value >= parseInt(itj_val[4])
                                        ? "High"
                                        : "");
                              })
                            ) : (
                              <></>
                            );
                          }
                        })
                      : ""
                  }
                })()}
  
  
                {/* <span>{"MIN:" + default_min + " MAX:" + default_max}</span> */}
  
                {/* Show TEXT, FILE, COLOR, RANGE */}
                <div>
                  {(() => {
                    if (fk_rr_id == 4) {
                      return <div className='underline underline-offset-2'>TEXT</div>;
                    } else if (fk_rr_id == 5 && originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT') {
                      return <div className='underline underline-offset-2'>{originalCell?.mmr_inputs_value[0]?.value}</div>;
                    } else if (fk_rr_id == 5) {
                      return <div className='underline underline-offset-2'>FILE</div>;
                    } else if (fk_rr_id == 6) {
                      return <div className='underline underline-offset-2'>{originalCell?.mmr_inputs_value[0]?.value}</div>;
                    } else if (fk_rr_id == 17) {
                      return <div className=''>{colorResult[0]?.color_value}</div>;
                    } else {
                      //return <span>{"MIN:" + default_min + "MAX:" + default_max}</span>;
  
                      if (fk_lab_analyzer_id && tprrlab_inputs_value && tprrlabInputsLables.length > 0) {
  
                        return <div className="box-container">
                          {
                            tprrlabInputsLables.map((data, index) => {
                              return (<div className="box">
                                {/* <p>{data}</p> */}
                                <p>{tprrlab_inputs_value[index][1].value}</p>
                                <div style={{ backgroundColor: `${colors2[index]}` }} className={`text-black px-2 py-2`}>{tprrlab_inputs_value[index][2].value + (tprrlab_inputs_value[index][4].value ? ('-' + tprrlab_inputs_value[index][4].value) : '') + ' ' + ((tprrlab_inputs_value.length - 1) != index ? tprrlab_inputs_value[index][3].value : '')}</div>
                              </div>
                              )
                            })
                          }
                        </div>
  
                      } else {
  
                        if (is_sub_rr === true && sub_ref_range.length > 0) {
  
                          if (sub_ref_range[0].mmr_inputs_value !== null && sub_ref_range[0].mmr_inputs_value.length > 0) {
  
                            return <div className="box-container">
                              {
                                r_type_inputsList.map((data, index) => {
                                  return (<div className="box">
                                    {/* <p>{data.rti_title_en}</p> */}
                                    <p>{sub_ref_range[0].mmr_inputs_value[index][1].value}</p>
                                    <div style={{ backgroundColor: `${colors2[index]}` }} className={`text-black px-2 py-2`}>{sub_ref_range[0].mmr_inputs_value[index][2].value + (sub_ref_range[0].mmr_inputs_value[index][4].value ? ('-' + sub_ref_range[0].mmr_inputs_value[index][4].value) : '') + ' ' + ((sub_ref_range[0].mmr_inputs_value.length - 1) != index ? sub_ref_range[0].mmr_inputs_value[index][3].value : '')}</div>
                                  </div>
                                  )
                                })
                              }
                            </div>
                          }
  
                        } else {
  
                          return <div className="box-container">
                            {
                              r_type_inputsList.map((data, index) => {
                                return (<div className="box">
                                  {/* <p>{data.rti_title_en}</p> */}
                                  <p>{inputs_value[index][1].value}</p>
                                  <div style={{ backgroundColor: `${colors2[index]}` }} className={`text-black px-2 py-2`}>{inputs_value[index][2].value + (inputs_value[index][4].value ? ('-' + inputs_value[index][4].value) : '') + ' ' + ((inputs_value.length - 1) != index ? inputs_value[index][3].value : '')}</div>
                                </div>
                                )
                              })
                            }
                          </div>
  
                        }
  
                      }
  
                    }
                  })()}
                </div>
  
              </div>
            ) : (
              <div className="inline-flex p-2 text-xs underline-offset-2">
                {/* <span>{"MIN:" + default_min + "MAX:" + default_max}</span> */}
                {(fk_rr_id != 5 && fk_rr_id != 6 && fk_rr_id != 17) ?
                  (<div className="box-container">
                    <div className="box">
                      <p>Low</p>
                      <div style={{ backgroundColor: `${colors2[0]}` }} className={`text-black px-2 py-2`}>{default_min}</div>
                    </div>
                    <div className="box">
                      <p>Normal</p>
                      <div style={{ backgroundColor: `${colors2[1]}` }} className={`text-black px-2 py-2`}>{default_min + " - " + default_max}</div>
                    </div>
                    <div className="box">
                      <p>High</p>
                      <div style={{ backgroundColor: `${colors2[2]}` }} className={`text-black px-2 py-2`}>{default_max}</div>
                    </div>
                  </div>) : (<></>)
                }
  
              </div>
            )) : (<span className="text-red-500">Ref Not Linked{originalCell.test_result?.length}</span>)}
  
          </div>
        );
      },
    },
    {
      Header: 'Comment',
      accessor: 'comment',
      Cell: (cell) => {
        let originalCell = cell.row.original;
        return (
          <p className="text-sm text-gray-500">
            {originalCell.mean ? (
              originalCell.mean
            ) : (
              <Tippy
                theme="light"
                placement="left"
                trigger="click"
                allowHTML={true}
                interactive={true}
                content={
                  <EditResultTooptip
                    fieldName="ssp_comment"
                    originalCell={originalCell}
                    saveFunc={handleUpdateComment}
                    updateFunc={handleUpdateComment}
                    prevValue={
                      originalCell
                        ? originalCell.test_result[0]?.ssp_value
                        : originalCell?.test_result[0]?.ssp_value
                          ? originalCell.test_result[0].ssp_value
                          : null
                    }
                  />
                }
              >
                <button>
                  {originalCell.test_result?.length > 0 ? (
                    <div className="inline-flex p-2 text-xs underline underline-offset-2">
                      {originalCell.test_result[0].ssp_comment}
                      <span>
                        <PencilIcon className="w-4 h-4 ml-2" />
                      </span>
                    </div>
                  ) : (
                    <div className="inline-flex p-2 text-xs underline underline-offset-2">
                      {originalCell.test_result &&
                        originalCell.test_result.length > 0
                        ? originalCell.test_result[0]?.ssp_comment == null ||
                          originalCell.test_result[0]?.ssp_comment == undefined ||
                          originalCell.test_result[0]?.ssp_comment == ''
                          ? 'Add Comment'
                          : originalCell.test_result[0]?.ssp_comment
                        : null}
                      <span>
                        <PencilIcon className="w-4 h-4 ml-2" />
                      </span>
                    </div>
                  )}
                </button>
              </Tippy>
            )}
          </p>
        );
      },
    },
  
    {
      Header: 'Action',
      accessor: 'action',
      Cell: (cell) => {
        let [showSpinner, setShowSpinner] = useState(false);
        const initialModalState = {
          state: false,
          data: {},
        };
        const [modal, setModal] = useState(initialModalState);
        let [comment, setComment] = useState('');
        let cellOriginal = cell.row.original;
        const inputField = document.querySelector('#default-checkbox');
        let ssp_attachment = cellOriginal?.test_result.length > 0 ? cellOriginal?.test_result[0]?.ssp_attachment : null;
        let ssp_pos_neg_value = cellOriginal?.test_result.length > 0 ? cellOriginal?.test_result[0]?.ssp_pos_neg_value : null;
        let ssp_text_value = cellOriginal?.test_result.length > 0 ? cellOriginal?.test_result[0]?.ssp_text_value : null;
        // let org_sp_status_id = cellOriginal.fk_sp_status_id ? cellOriginal.fk_sp_status_id : cellOriginal.sp_status_id;
        let org_sp_status_id = cellOriginal.test_result.length > 0 ? cellOriginal.test_result[0].fk_status_id : cellOriginal.fk_status_id;
        cellOriginal.org_sp_status_id = org_sp_status_id;
        cellOriginal.ssp_value = (cellOriginal.test_result && cellOriginal.test_result.length > 0) ? cellOriginal.test_result[0].ssp_value
          : null
      
        
        return (
          <>
        <Modal
            title="Add Comment/Recommendations"
            open={modal.state}
            setOpen={() => {
              setModal((prev) => ({ ...prev, state: false }));
            }}
          >
            <textarea className='rounded mx-auto mt-3' value={comment} onChange={e => setComment(e.target.value)} />
            <div className="mx-auto mt-3">
                <Button
                  onClick={() => {
                    modal.data.doctor_recommendation = comment;
                    setShowSpinner(true);
                    generateReport(modal.data);
                    setModal((prev) => ({ ...prev, state: false }));
                  }}
                >
                  Generate Report
                </Button>
              </div>
          </Modal>
  
            {/* {cellOriginal.fk_sp_status_id}
            {cellOriginal.sp_status_id}
          {cell.row.original.ssp_value} */}
            {/* {(cellOriginal.sp_status_id == 10 || cellOriginal.sp_status_id == 8) ? ( */}
            {((cellOriginal.org_sp_status_id == 6 || cellOriginal.org_sp_status_id == 4) && (cellOriginal.ssp_value || ssp_attachment || ssp_pos_neg_value || ssp_text_value)) ? (
              // {((cellOriginal.sp_status_id == 6 || cellOriginal.sp_status_id == 4) && cellOriginal.ssp_value) ? (//new
              <span className='inline-flex'>
                {showSpinner &&
                  <FaSpinner icon="spinner" className="spinner" />
                }
                <div class="flex items-center mb-4 mt-4 mr-4">
                  {cellOriginal.org_sp_status_id == 6 && (
                    <input id="default-checkbox" type="checkbox" checked={validateCheckBoxes.filter(v => v.key == `${cellOriginal.sample_id}-${cellOriginal.ttp_name_en}`).length > 0 ? true: false}
                    onChange={(e) => {
                      handleValidateCheckBoxes(e.target.checked, cellOriginal);
                    }
                    }
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"/>
                  )} 
                  {cellOriginal.org_sp_status_id == 4 && (
                    <input id="default-checkbox" type="checkbox" checked={approveCheckBoxes.filter(v => v.key == `${cellOriginal.sample_id}-${cellOriginal.ttp_name_en}`).length > 0 ? true: false}
                    onChange={(e) => {
                      handleApproveCheckBoxes(e.target.checked, cellOriginal);
                    }
                    }
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"/>
                  )}
                </div>
                <span className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                  <button
                    type="button"
                    onClick={() => {
                      setShowSpinner(true);
                      let { sample_id } = cellOriginal;
  
                      if (cellOriginal.org_sp_status_id == 6) {
                        // if (cellOriginal.sp_status_id == 6) {// new
                        // if (cellOriginal.sp_status_id == 10) {
                        handleValidateResult(cellOriginal)
                        // handleValidateResult(sample_id)//neww
                      } else if (cellOriginal.org_sp_status_id == 4) {
                        // } else if (cellOriginal.sp_status_id == 4) {//new
                        // } else if (cellOriginal.sp_status_id == 8) {
                        handleApproveResult(cellOriginal)
                        // handleApproveResult(sample_id)//neww
                      }
                    }}
                    size="default"
                    variant="outlined"
                  >
                    {cellOriginal.org_sp_status_id == 6 ? "Validate" : cellOriginal.org_sp_status_id == 4 ? "Approve" : ""}
                    {/* //new */}
                    {/* {cellOriginal.sp_status_id == 6 ? "Validate" : cellOriginal.sp_status_id == 4 ? "Approve" : ""} */}
                    {/* {cellOriginal.sp_status_id == 10 ? "Validate" : cellOriginal.sp_status_id == 8 ? "Approve" : ""} */}
                  </button>
                </span>
              </span>) : (<></>)}
  
             {(cellOriginal.buttonResultFlag && (cellOriginal.ssp_value || cellOriginal.mrr_type == 18) && cellOriginal.org_sp_status_id == 5 && cellOriginal.buttonFlagCount == cellOriginal.samePaitentcount) ? (
              
              // {(cellOriginal.sp_status_id == "5" && cellOriginal.buttonFlag && cellOriginal.ssp_value) ? (//new
              // {(cellOriginal.sp_status_id == "9" && cellOriginal.buttonFlag) ? ( */}
              <span className='inline-flex'>
                {showSpinner &&
                  <FaSpinner icon="spinner" className="spinner" />
                }
                <span className="inline-flex px-2 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                  <button
                    type="button"
                    onClick={() => {
                      // setShowSpinner(true);
                      // generateReport(cell.row.original);
                      setModal((prev) => ({ data: cell.row.original, state: true }));
                    }}
                    size="default"
                    variant="outlined"
                  >
                    Report
                  </button>
                </span>
              </span>
            ) : (
              <></>
            )}
  
            {cellOriginal.sample_report_link && cellOriginal.buttonResultFlag ? (
              <span className="inline-flex whitespace-nowrap px-2 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                <a href={`${cellOriginal.sample_report_link}`} target="_blank">View PDF</a>
  
              </span>) : (<></>)}
            {/* {cellOriginal.smart_report_pdf && cellOriginal.buttonResultFlag ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                <a href={`${cellOriginal.smart_report_pdf}`} target="_blank">View Smart Report PDF</a>
  
              </span>) : (<></>)} */}
          </>
        );
      },
    },
  
    {
      Header: 'Analyzer',
      accessor: '',
      Cell: (cell) => {
        let originalCell = cell.row.original;
        // let updated_fk_lab_ids = originalCell.test_result.length > 0 ? originalCell.test_result[0].fk_lab_id : null;
        let updated_fk_lab_analyzer_ids = originalCell.test_result.length > 0 ? originalCell.test_result[0].fk_lab_analyzer_id : null;
        if(originalCell?.labArr && originalCell?.labArr.length > 0){
          originalCell.labArr = originalCell?.labArr.filter((l,i)=>{
            if(l.all_locations_la_active?.length > 0 && l.all_locations_la_active.includes(selectedLocation.value)){
              return l
            }
          })
        }
        return (
          <div className="w-full">
            {originalCell?.labArr && originalCell?.labArr?.length > 0 ? (
              <select
                className="block max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                name="lab_analyzer_id"
                id="lab_analyzer_id"
                // getFilterAllQualityControleResultList();
                onChange={(e) => { handleUpdateLocChange(originalCell, e.target.value) }}
              >
                <option value={0}> Select Analyzer </option>
                {originalCell?.labArr.map((lab, i) => (
                  <option
                    selected={updated_fk_lab_analyzer_ids &&
                      lab.lab_analyzer_id ===
                        updated_fk_lab_analyzer_ids
                        ? "selected"
                        : i == 0 ? "selected" : ""
                    }
                    key={i}
                    value={JSON.stringify(lab)}
                  >
                    {lab.lab_analyzer_name_en}
                  </option>
                ))}
              </select>
            ) : (
              <select
                className="block max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
                name="fk_loc_id"
                id="fk_loc_id"
                onChange={(e) => { }}
              >
                <option value={0}> Select Analyzer </option>
              </select>
            )}
          </div>
        );
      },
    },
  ];

export const micro_sample_processing_columns = ({ generateReport }) => [

  {
    Header: 'Patient',
    accessor: (d) => d.buttonFlag ? capitalise(d.patient_first_name) : '',
  }, {
    // Header: "Res. ID",
    // accessor: (d) => d.reservation_id,

    Header: "Res. ID",
    Cell: (cell) => {
      const reservation_id = cell.row.original.reservation_id;
      return (
        <>
          <div>
            {
              cell.row.original.reservation_is_active == true ? (
                <Link
                  onClick={() => {
                    localStorage.setItem(
                      "edit_booking_reservation_user_details",
                      JSON.stringify(cell.row.original)
                    );
                    localStorage.setItem(
                      "edit_booking_reservation_id",
                      reservation_id
                    );
                  }}
                  to={`/bookingConfirm`}
                  className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
                >
                  {reservation_id}
                </Link>
              ) : <p>{reservation_id}</p>
            }
          </div>

        </>
      );
    },
  },
  {
    Header: 'Sample ID',
    Cell: (cell) => {
      const sample_id = cell.row.original.sample_id;
      return (
        <>
          <Link
            to={`../samplelist/${sample_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            {sample_id}
          </Link>
        </>
      );
    },
  },
  {
    Header: "Test Name",
    accessor: (d) => capitalise(d.test_name_en),
  },
  {
    Header: "Color",
    accessor: (d) => capitalise(d.mclr_name),
  },
  {
    Header: 'Mobile No.',
    accessor: (d) => capitalise(d.patient_mobile),
  },
  {
    Header: 'Status',
    accessor: (d) => d.sp_status_name_en,
  },

  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      let [showSpinner, setShowSpinner] = useState(false);
      let cellOriginal = cell.row.original;
      let org_sp_status_id = cellOriginal.fk_status_id;
      cellOriginal.org_sp_status_id = org_sp_status_id;

      return (
        <>
          {(cellOriginal.buttonResultFlag && cellOriginal.org_sp_status_id == 5 && cellOriginal.buttonFlagCount == cellOriginal.samePaitentcount) ? (
            <span className='inline-flex'>
              {showSpinner &&
                <FaSpinner icon="spinner" className="spinner" />
              }
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                <button
                  type="button"
                  onClick={() => {
                    setShowSpinner(true);
                    generateReport(cell.row.original);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Report
                </button>
              </span>
            </span>
          ) : (
            <></>
          )}

          {cellOriginal.sample_report_link && cellOriginal.buttonResultFlag ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
              <a href={`${cellOriginal.sample_report_link}`} target="_blank">View PDF</a>

            </span>) : (<></>)}

        </>
      );
    },
  },

  // {
  //   Header: 'Lab',
  //   accessor: '',
  //   Cell: (cell) => {
  //     let originalCell = cell.row.original;
  //     let updated_fk_lab_ids = originalCell.test_result.length > 0 ? originalCell.test_result[0].fk_lab_id : null;
  //     let updated_fk_lab_analyzer_ids = originalCell.test_result.length > 0 ? originalCell.test_result[0].fk_lab_analyzer_id : null;
  //     console.log("updated_fk_lab_analyzer_ids=>", updated_fk_lab_analyzer_ids)
  //     return (
  //       <div className="w-full">
  //         {originalCell?.labArr && originalCell?.labArr?.length > 0 ? (
  //           <select
  //             className="block max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
  //             name="lab_analyzer_id"
  //             id="lab_analyzer_id"
  //             // getFilterAllQualityControleResultList();
  //             onChange={(e) => { handleUpdateLocChange(originalCell, e.target.value) }}
  //           >
  //             <option value={0}> Select Lab </option>
  //             {originalCell?.labArr.map((lab, i) => (
  //               <option
  //                 selected={
  //                   lab.lab_analyzer_id ===
  //                     updated_fk_lab_analyzer_ids
  //                     ? "selected"
  //                     : ""
  //                 }
  //                 key={i}
  //                 value={JSON.stringify(lab)}
  //               >
  //                 {lab.lab_analyzer_name_en}
  //               </option>
  //             ))}
  //           </select>
  //         ) : (
  //           <select
  //             className="block max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
  //             name="fk_loc_id"
  //             id="fk_loc_id"
  //             onChange={(e) => { }}
  //           >
  //             <option value={0}> Select Lab </option>
  //           </select>
  //         )}
  //       </div>
  //     );
  //   },
  // },
];

export const res_sample_process_column = ({ handleUpdateComment, handleSaveResult, handleValidateResult, handleApproveResult, handleUpdateResult, generateReport, viewReport, handleUpdateLocChange, handleMixResultSaveUpdate, activeIndex }) => [
  {
    Header: 'Sample ID',
    Cell: (cell) => {
      const sample_id = cell.row.original.sample_id;
      return (
        <>
          <Link
            to={`../samplelist/${sample_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            {sample_id}
          </Link>
        </>
      );
    },
  },
  {
    Header: "Color",
    accessor: (d) => capitalise(d.mclr_name),
  },
  {
    Header: "Test-Parameter Name",
    accessor: (d) => d.ttp_name_en ? (
      <div className="inline-flex p-2">
        {d.ttp_name_en}
      </div>
    ) : null,
  },
  {
    Header: 'Status',
    accessor: (d) => d.sp_status_name_en,
  },
  {
    Header: 'Result',
    accessor: 'result',
    Cell: (cell) => {
      let originalCell = cell.row.original

      let sp_gen_id = originalCell?.sp_gen_id;
      let fk_rr_id = null;
      let default_min = null;
      let default_max = null;
      let rt_name_en = originalCell?.rt_name_en;
      let ssp_value = (originalCell?.test_result?.length > 0) ? parseInt(originalCell.test_result[0]?.ssp_value) : "";
      let ssp_text_value = (originalCell?.test_result?.length > 0) ? originalCell.test_result[0]?.ssp_text_value : null;
      let ssp_attachment = (originalCell?.test_result?.length > 0) ? originalCell.test_result[0]?.ssp_attachment : null;
      let ssp2_value = (originalCell?.test_result?.length > 0) ? parseInt(originalCell?.test_result[0]?.ssp_value) : "";
      let mmr_inputs_value = { ...originalCell?.mmr_inputs_value };
      let r_type_inputsList2 = originalCell?.r_type_inputsList;
      let inputs_value = originalCell?.mmr_inputs_value;

      let tprrlab_inputs_value = originalCell?.tprrlab?.m_reference_ranges_relation?.mmr_inputs_value;
      let tprrlabInputsLables = originalCell?.tprrlabInputs;
      let fk_lab_analyzer_id = (originalCell?.test_result?.length > 0) ? parseInt(originalCell?.test_result[0]?.fk_lab_analyzer_id) : null;


      let r_type_inputsList = r_type_inputsList2.filter(function ({ rtim_id }) {
        var key = `${rtim_id}`;
        return !this.has(key) && this.add(key);
      }, new Set);

      let min_max_value;
      let Value = "";
      let minValue = "";
      let maxValue = "";
      default_min = (!fk_rr_id ? (originalCell?.mrr_default_min) : (default_min));
      default_max = (!fk_rr_id ? (originalCell?.mrr_default_max) : (default_max));
      fk_rr_id = (!fk_rr_id ? (originalCell?.mrr_id) : (fk_rr_id));
      let mrr_type = originalCell?.mrr_type ? parseInt(originalCell?.mrr_type) : null;
      let colorResult = {};
      if (mrr_type == 17) {
        fk_rr_id = mrr_type;
        const filteredData = inputs_value.filter(obj => obj.color_is_normal === 'yes');
        colorResult = filteredData.length > 0 ? filteredData : [inputs_value[0]];
      }
      if (mrr_type == 17) {
        fk_rr_id = mrr_type;
      }
      if (mrr_type == '5') {
        fk_rr_id = mrr_type;
      }

      let result_value = "";
      let result_type = "";
      let resultFieldName = "";
      // console.log("originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT'", originalCell?.mmr_inputs_value[0]?.lable, fk_rr_id)
      if (fk_rr_id == 4) {
        //TEXT
        result_type = "TEXT";
        resultFieldName = "ssp_text_value";
        result_value = originalCell ? originalCell.test_result[0]?.ssp_text_value : originalCell?.test_result[0]?.ssp_text_value ? originalCell?.test_result[0]?.ssp_text_value : 'N/A';
      } else if (fk_rr_id == 5 && originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT') {
        //FILE
        result_type = "POSITIVE / NEGATIVE";
        resultFieldName = "ssp_pos_neg_value";
        result_value = originalCell ? originalCell.test_result[0]?.ssp_pos_neg_value : originalCell?.test_result[0]?.ssp_pos_neg_value ? originalCell?.test_result[0]?.ssp_pos_neg_value : 'N/A';
      } else if (fk_rr_id == 5) {
        // console.log("originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT'", originalCell?.mmr_inputs_value[0]?.lable)
        //FILE
        result_type = "FILE";
        resultFieldName = "ssp_attachment";
        result_value = originalCell ? originalCell.test_result[0]?.ssp_attachment : originalCell?.test_result[0]?.ssp_attachment ? originalCell?.test_result[0]?.ssp_attachment : 'N/A';
      } else if (fk_rr_id == 6) {
        //FILE
        result_type = "POSITIVE / NEGATIVE";
        resultFieldName = "ssp_pos_neg_value";
        result_value = originalCell ? originalCell.test_result[0]?.ssp_pos_neg_value : originalCell?.test_result[0]?.ssp_pos_neg_value ? originalCell?.test_result[0]?.ssp_pos_neg_value : 'N/A';
      } else if (fk_rr_id == 17) {
        //COLOR
        result_type = "COLOR";
        resultFieldName = "ssp_text_value";
        result_value = originalCell ? originalCell.test_result[0]?.ssp_text_value : originalCell?.test_result[0]?.ssp_text_value ? originalCell?.test_result[0]?.ssp_text_value : 'N/A';
      } else {
        //RANGE
        result_type = "RANGE";
        resultFieldName = "ssp_value";
        result_value = originalCell ? originalCell.test_result[0]?.ssp_value : originalCell?.test_result[0]?.ssp_value ? originalCell?.test_result[0]?.ssp_value : 'N/A';
      }

      let is_sub_rr = originalCell?.is_sub_rr;
      let sub_ref_range = originalCell?.sub_ref_range ? originalCell?.sub_ref_range : [];

      let rangeData = [];

      if (mrr_type != 17) {
        if (fk_lab_analyzer_id && tprrlab_inputs_value && tprrlabInputsLables.length > 0) {

          if (tprrlab_inputs_value.length > 0) {
            tprrlab_inputs_value.map((ip_data, ip_index) => {
              const data = {
                min: parseFloat(ip_data[2].value).toFixed(2),
                max: parseFloat(ip_data[4].value).toFixed(2),
                name: ip_data[1].value //r_type_inputsList[ip_index].rti_title_en
              }
              rangeData.push(data);
            });
          }

        } else {

          if (is_sub_rr == true) {
            if (sub_ref_range.length > 0) {

              const getSubRRData = sub_ref_range[0];
              default_min = getSubRRData.mrr_sub_normal_min;
              default_max = getSubRRData.mrr_sub_normal_max;

              if (sub_ref_range[0].mmr_inputs_value !== null && sub_ref_range[0].mmr_inputs_value.length > 0) {
                sub_ref_range[0].mmr_inputs_value.map((ip_data, ip_index) => {
                  const data = {
                    min: parseFloat(ip_data[2]?.value).toFixed(2),
                    max: parseFloat(ip_data[4]?.value).toFixed(2),
                    name: ip_data[1].value //r_type_inputsList[ip_index].rti_title_en
                  }
                  rangeData.push(data);
                });
              }

            }
          } else {
            if (inputs_value.length > 0) {
              inputs_value.map((ip_data, ip_index) => {
                const data = {
                  min: parseFloat(ip_data[2]?.value).toFixed(2),
                  max: parseFloat(ip_data[4]?.value).toFixed(2),
                  name: ip_data[1]?.value //r_type_inputsList[ip_index].rti_title_en
                }
                rangeData.push(data);
              });
            }
          }
        }
      }

      const getObjectFromRange = (num) => {
        
        if (fk_rr_id == 5 && originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT') {
          const matchedRange = originalCell?.mmr_inputs_value.find(res => res.value == result_value);
          return matchedRange ? <span style={{ color: 'green' }}>Normal</span> : <span style={{ color: 'red' }}>Abnormal</span>;
        }
        if (fk_rr_id == 17) {
          const matchedRange = colorResult.find(res => res.color_name == result_value);
          return matchedRange ? <span style={{ color: 'green' }}>Normal</span> : <span style={{ color: 'red' }}>Abnormal</span>;
        }
        let colourRangeName = 0;
        const matchedRange = rangeData.find((range, index) => {
          if (range.min && range.max) {
            if (num >= range.min && num <= range.max) {
              colourRangeName = index;
              return true
            }
          }
          if (range.min && range.max == 'NaN') {
            if (num >= range.min) {
              colourRangeName = index;
              return true
            }
          }
          return false
        });
        let colors2 = [
          "#E68600",
          "#FFBF66",
          "#FFFF99",
          "#99FF99",
          "#00FF00",
          "#00CC00",
          "#FF4D4D",
          "#FF0000",
          "#CC0000",
        ];
        const colorsForRange = [
          { color_name: "very low", color: "#E68600" },
          { color_name: "low", color: "#FFBF66" },
          { color_name: "border", color: "#FFFF99" },
          { color_name: "below normal", color: "#99FF99" },
          { color_name: "normal", color: "#00FF00" },
          { color_name: "above normal", color: "#00CC00" },
          { color_name: "low high", color: "#FF4D4D" },
          { color_name: "high", color: "#FF0000" },
          { color_name: "very high", color: "#CC0000" }
        ]

        if (rt_name_en) {
          let ct_arr = [];
          let stringArr_rt = [];
          colorsForRange.forEach(v => {
            if (rt_name_en.includes('<')) {
              stringArr_rt = rt_name_en.split(' < ');
            }
            if (rt_name_en.includes('>')) {
              stringArr_rt = rt_name_en.split(' > ');
            }
            stringArr_rt.forEach(str => {
              if (str == v.color_name) {
                ct_arr.push(v.color)
              }
            })
          })
          colors2 = ct_arr
        }
        return matchedRange ? <span style={{ backgroundColor: `${colors2[colourRangeName]}` }} className={`text-black p-2`}> {matchedRange.name} </span> : '';
      };

      let defaultStatus = false;
      let isActive = false;
      if(activeIndex === parseInt(cell.row.index)){
        defaultStatus = true;
        isActive = true;
      }

      const [editingRes, setEditingRes] = useState(defaultStatus);
      const [resultValue, setResultValue] = useState(result_value);

      const toggleEditingRes = () => {
        setEditingRes(!editingRes);
      };

      const savedResult = (value) => {
        setEditingRes(!editingRes);
        if(value){
          setResultValue(value);
          ssp_value = value;
          cell.row.original.ssp_value = value
        }
      }

      const handleInputBlur = () => {
        setEditingRes(!editingRes);
      }

      return (
        <p className="text-sm text-gray-500 inline-flex">

          {(() => {
            if (fk_rr_id == 5 && originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT') {
              return <span><DropDownResult value={result_value} id={sp_gen_id} originalCell={originalCell} saveUpdateFunction={handleMixResultSaveUpdate} /></span>;
            } else if (fk_rr_id == 5) {
              return <span><FileResultUpload id={sp_gen_id} originalCell={originalCell} saveUpdateFunction={handleMixResultSaveUpdate} /></span>;
            } else if (fk_rr_id == 6) {
              return <span><DropDownResult value={result_value} id={sp_gen_id} originalCell={originalCell} saveUpdateFunction={handleMixResultSaveUpdate} /></span>;
            } else if (fk_rr_id == 17) {
              return <span><ColorDropDownResult inputs_value={inputs_value} value={result_value} id={sp_gen_id} originalCell={originalCell} saveUpdateFunction={handleMixResultSaveUpdate} /></span>;
            } else {
              /* return <Tippy
                theme="light"
                placement="left"
                trigger="click"
                allowHTML={true}
                interactive={true}
                content={
                  <EditResultTooptip
                    fieldName={resultFieldName ? resultFieldName : 'ssp_value'}
                    originalCell={originalCell}
                    saveFunc={handleSaveResult}
                    updateFunc={handleUpdateResult}
                    prevValue={result_value}
                  />

                }
              >
                <button>
                  {originalCell.test_result?.length > 0 ? (
                    <div className="inline-flex p-2 text-xs underline underline-offset-2">
                      {result_value + ' '}
                      <span>
                        <PencilIcon className="w-4 h-4 ml-2" />
                      </span>
                    </div>
                  ) : (
                    <div className="inline-flex p-2 text-xs underline underline-offset-2">
                      {originalCell.test_result && originalCell.test_result?.length > 0 ?
                        result_value : "Add Result"}
                      <span><PencilIcon className="w-4 h-4 ml-2" /></span>
                    </div>
                  )}
                </button>
              </Tippy>; */

              return  <div>
                        {editingRes ? (
                            <div className='inline-flex'>
                              <EditResultTooptip
                                fieldName={resultFieldName ? resultFieldName : 'ssp_value'}
                                originalCell={originalCell}
                                saveFunc={handleSaveResult}
                                updateFunc={handleUpdateResult}
                                prevValue={resultValue}
                                resultFunc={savedResult}
                                inputIndex={parseInt(cell.row.index)}
                                isActive={isActive}
                                handleInputBlur={handleInputBlur}
                                fromTable="res_sample_process_column -> Result Column"
                              />
                              {/* <span>
                                <button className="inline-flex px-2 py-2 text-red-600" onClick={toggleEditingRes}>
                                  <XMarkIcon className="w-4 h-4 ml-2" />
                                </button>
                              </span> */}
                            </div>
                          ) : (
                            <div>
                              <span className="px-2 py-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                                <button className="inline-flex px-2 py-2" onClick={toggleEditingRes}>
                                  {/* {(resultValue == ssp_value) ? resultValue : ssp_value + ' '} Add Result 1 */}
                                  {resultValue ? resultValue : ' Add Result ' }
                                  <PencilIcon className="w-4 h-4 ml-2" />
                                </button>
                              </span>
                            </div>
                          )}
                      </div>

            }
          })()}

          {fk_rr_id ? (originalCell.test_result?.length > 0 ? (
            <div className="inline-flex p-2 text-xs underline underline-offset-2">

              {(() => {

                if (is_sub_rr == true) {

                  if (sub_ref_range.length > 0) {
                    let getSubRRData = sub_ref_range[0];

                    default_min = getSubRRData.mrr_sub_normal_min;
                    default_max = getSubRRData.mrr_sub_normal_max;

                    //return (ssp_value >= default_min && ssp_value <= default_max ? (<span className="text-green-500">Normal</span>) : ssp_value <= default_min ? (<span className="text-red-500">Low</span>) : ssp_value >= default_max ? (<span className="text-red-500">High</span>) : "");
                  } else {
                    return <span>...</span>;
                  }

                } else {

                  return (ssp_value) ? ((mmr_inputs_value?.length <= 0) ? ((ssp_value >= default_min) && (ssp_value <= default_max) ? (<span className="text-green-500">Normal</span>) : (ssp_value <= default_min) ? (<span className="text-red-500">Low</span>) : (ssp_value >= default_max) ? (<span className="text-red-500">High</span>) : '') : (r_type_inputsList.map((ele, ind) => {

                    // console.log("rti_title_en=>", ele.rti_title_en, ele, r_type_inputsList, originalCell.fk_sample_id)
                    if (ele.rti_title_en == "Normal") {
                      min_max_value = ((ssp_value >= default_min) && (ssp_value <= default_max) ? (<span className="text-green-500">Normal</span>) : (ssp_value <= default_min) ? (<span className="text-red-500">Low</span>) : (ssp_value >= default_max) ? (<span className="text-red-500">High</span>) : '');
                    } else {
                      mmr_inputs_value?.length > 0 ? (mmr_inputs_value[ind]?.map((itj_val, itj_index) => {

                        if (itj_val?.lable == "MIN") {
                          minValue = parseInt(itj_val?.value);
                        }

                        if (itj_val?.lable == "MAX") {
                          maxValue = parseInt(itj_val?.value);
                        }
                      })) : (<></>);
                      if (maxValue && minValue) {
                        min_max_value = ((ssp2_value >= minValue) && (ssp2_value <= maxValue) ? (<span className="text-green-500">Normal</span>) : (ssp2_value <= minValue) ? (<span className="text-red-500">Low</span>) : (ssp2_value >= maxValue) ? (<span className="text-red-500">High</span>) : '');
                      } else {
                        min_max_value = ((ssp_value >= default_min) && (ssp_value <= default_max) ? (<span className="text-green-500">Normal</span>) : (ssp_value <= default_min) ? (<span className="text-red-500">Low</span>) : (ssp_value >= default_max) ? (<span className="text-red-500">High</span>) : '');
                      }

                      // console.log("My Data ssp2_value=>", ssp2_value, "minValue=>", minValue, "maxValue=>", maxValue)
                    }
                  })))

                    :

                    //ssp_attachment

                    (
                      ssp_text_value ?

                        //<span>{ssp_text_value}</span>
                        ""

                        :

                        (
                          ssp_attachment ?

                            <span><a href={ssp_attachment ? ssp_attachment : "#"} target="_blank">View</a></span>

                            :

                            "Pending"
                        )

                    )

                }

              })()}

            </div>
          ) : (
            <div className="inline-flex p-2 text-xs underline underline-offset-2">
              {(parseFloat(originalCell?.test_result[0]?.ssp_value)) ? ((mmr_inputs_value?.length <= 0) ? ((parseFloat(originalCell?.test_result[0]?.ssp_value) >= default_min) && (parseFloat(originalCell?.test_result[0]?.ssp_value) <= default_max) ? (<span className="text-green-500">Normal</span>) : (parseFloat(originalCell?.test_result[0]?.ssp_value) <= default_min) ? (<span className="text-red-500">Low</span>) : (parseFloat(originalCell?.test_result[0]?.ssp_value) >= default_max) ? (<span className="text-red-500">High</span>) : '') : (r_type_inputsList.map((ele, ind) => {
                if (ele.rti_title_en == "Normal") {
                  min_max_value = ((parseFloat(originalCell?.test_result[0]?.ssp_value) >= default_min) && (parseFloat(originalCell?.test_result[0]?.ssp_value) <= default_max) ? (<span className="text-green-500">Normal</span>) : (parseFloat(originalCell?.test_result[0]?.ssp_value) <= default_min) ? (<span className="text-red-500">Low</span>) : (parseFloat(originalCell?.test_result[0]?.ssp_value) >= default_max) ? (<span className="text-red-500">High</span>) : '');
                } else {
                  mmr_inputs_value[ind].map((itj_val, itj_index) => {
                    if (itj_val?.lable === "MIN") {
                      minValue = parseFloat(itj_val?.value);
                    }
                    if (itj_val?.lable === "MAX") {
                      maxValue = parseFloat(itj_val?.value);
                    }

                  });
                  //min_max_value = ((ssp2_value >= minValue) && (ssp2_value <= maxValue) ? (<span className="text-green-500">Normal</span>) : (ssp2_value <= minValue) ? (<span className="text-red-500">Low</span>) : (ssp2_value >= maxValue) ? (<span className="text-red-500">High</span>) : '');
                }
              }))) : 'N/A'}
            </div>
          )) : (<span className="text-red-500"></span>)}
          {/* {min_max_value} */} {getObjectFromRange(parseFloat(originalCell?.test_result[0]?.ssp_value))}
        </p>
      );
    }
  },
  {
    Header: 'Ref. Normal',
    accessor: (d) => 'Min: 5 - Max: 10',
    Cell: (cell) => {
      // New Content
      let originalCell = cell.row.original
      // console.log("originalCell=>", originalCell)

      let fk_rr_id = null;
      let default_min = null;
      let default_max = null;
      let rt_name_en = originalCell?.rt_name_en;
      let ssp_value = (originalCell?.test_result?.length > 0) ? parseInt(originalCell.test_result[0]?.ssp_value) : "";
      let ssp2_value = (originalCell?.test_result?.length > 0) ? parseInt(originalCell?.test_result[0]?.ssp_value) : "";
      let mmr_inputs_value = { ...originalCell?.mmr_inputs_value };
      let inputs_value = originalCell?.mmr_inputs_value;
      let r_type_inputsList2 = originalCell?.r_type_inputsList;

      let tprrlab_inputs_value = originalCell?.tprrlab?.m_reference_ranges_relation?.mmr_inputs_value;
      let tprrlabInputsLables = originalCell?.tprrlabInputs;
      let fk_lab_analyzer_id = (originalCell?.test_result?.length > 0) ? parseInt(originalCell?.test_result[0]?.fk_lab_analyzer_id) : null;

      let r_type_inputsList = r_type_inputsList2.filter(function ({ rtim_id }) {
        var key = `${rtim_id}`;
        return !this.has(key) && this.add(key);
      }, new Set);

      const colors = [
        "bg-green-500",
        "bg-blue-500",
        "bg-orange-200",
        "bg-orange-500",
        "bg-orange-700",
        "bg-red-200",
        "bg-red-500",
        "bg-red-700"
      ]
      let colors2 = [
        "#E68600",
        "#FFBF66",
        "#FFFF99",
        "#99FF99",
        "#00FF00",
        "#00CC00",
        "#FF4D4D",
        "#FF0000",
        "#CC0000",
      ];
      const colorsForRange = [
        { color_name: "very low", color: "#E68600" },
        { color_name: "low", color: "#FFBF66" },
        { color_name: "border", color: "#FFFF99" },
        { color_name: "below normal", color: "#99FF99" },
        { color_name: "normal", color: "#00FF00" },
        { color_name: "above normal", color: "#00CC00" },
        { color_name: "low high", color: "#FF4D4D" },
        { color_name: "high", color: "#FF0000" },
        { color_name: "very high", color: "#CC0000" }
      ]

      if (rt_name_en) {
        let ct_arr = [];
        let stringArr_rt = [];
        colorsForRange.forEach(v => {
          if (rt_name_en.includes('<')) {
            stringArr_rt = rt_name_en.split(' < ');
          }
          if (rt_name_en.includes('>')) {
            stringArr_rt = rt_name_en.split(' > ');
          }
          stringArr_rt.forEach(str => {
            if (str == v.color_name) {
              ct_arr.push(v.color)
            }
          })
        })
        colors2 = ct_arr
      }

      let min_max_value;
      let Value = "";
      let minValue = "";
      let maxValue = "";
      let result_type = null;
      result_type = originalCell?.result_type;
      default_min = (!fk_rr_id ? (originalCell?.mrr_default_min) : (default_min));
      default_max = (!fk_rr_id ? (originalCell?.mrr_default_max) : (default_max));
      fk_rr_id = (!fk_rr_id ? (originalCell?.mrr_id) : (fk_rr_id));
      let mrr_type = originalCell?.mrr_type ? parseInt(originalCell?.mrr_type) : null;
      let colorResult = {};
      if (mrr_type == 17) {
        fk_rr_id = mrr_type;
        const filteredData = inputs_value.filter(obj => obj.color_is_normal === 'yes');
        colorResult = filteredData.length > 0 ? filteredData : [inputs_value[0]];
      }

      if (mrr_type == 5) {
        fk_rr_id = mrr_type;
      }

      let is_sub_rr = originalCell?.is_sub_rr; //originalCell?.all_subtest_lists?.is_sub_rr ? originalCell?.all_subtest_lists?.is_sub_rr : false;
      let sub_ref_range = originalCell?.sub_ref_range ? originalCell?.sub_ref_range : []; //originalCell?.all_subtest_lists?.sub_ref_range ? originalCell?.all_subtest_lists?.sub_ref_range : [];

      if (is_sub_rr == true) {
        if (sub_ref_range.length > 0) {
          const getSubRRData = sub_ref_range[0];
          default_min = getSubRRData.mrr_sub_normal_min;
          default_max = getSubRRData.mrr_sub_normal_max;
        }
      }

      return (
        <div>
          {fk_rr_id ? (originalCell.test_result?.length > 0 ? (

            <div className="inline-flex p-2 text-xs">

              {/* {(ssp_value) ? ((mmr_inputs_value?.length <= 0) ? ((ssp_value >= default_min) && (ssp_value <= default_max) ? 'Normal' : (ssp_value <= default_min) ? 'Low' : (ssp_value >= default_max) ? 'High' : '') : (r_type_inputsList.map((ele, ind) => {
                if (ele.rti_title_en == "Normal") {
                  min_max_value = 'First' + ((ssp_value >= default_min) && (ssp_value <= default_max) ? 'Normal' : (ssp_value <= default_min) ? 'Low' : (ssp_value >= default_max) ? 'High' : '');
                } else {
                  mmr_inputs_value?.length > 0 ? (mmr_inputs_value[ind].map((itj_val, itj_index) => {
                    min_max_value = 'Second' + ((ssp_value >= parseInt(itj_val[2]?.value) && ssp_value <= parseInt(itj_val[4]?.value)) ? 'Normal' : (ssp_value <= parseInt(itj_val[2])) ? 'Low' + ' LP' + parseInt(itj_val[2]) + ' HP' + parseInt(itj_val[4]) : (ssp_value >= parseInt(itj_val[4])) ? 'High' : '');
                  })) : (<></>);
                }
              }))) : ''} */}

              {(() => {

                if (is_sub_rr == true) {

                  if (sub_ref_range.length > 0) {
                    let getSubRRData = sub_ref_range[0];

                    default_min = getSubRRData.mrr_sub_normal_min;
                    default_max = getSubRRData.mrr_sub_normal_max;

                    //return (ssp_value >= default_min && ssp_value <= default_max ? "Normal" : ssp_value <= default_min ? "Low" : ssp_value >= default_max ? "High" : "");
                  }

                } else {

                  return ssp_value
                    ? mmr_inputs_value?.length <= 0
                      ? ssp_value >= default_min && ssp_value <= default_max
                        ? "Normal"
                        : ssp_value <= default_min
                          ? "Low"
                          : ssp_value >= default_max
                            ? "High"
                            : ""
                      : r_type_inputsList.map((ele, ind) => {
                        if (ele.rti_title_en == "Normal") {
                          min_max_value = "First" + (ssp_value >= default_min && ssp_value <= default_max ? "Normal" : ssp_value <= default_min ? "Low" : ssp_value >= default_max ? "High" : "");
                        } else {
                          mmr_inputs_value?.length > 0 ? (
                            mmr_inputs_value[ind].map((itj_val, itj_index) => {
                              min_max_value =
                                "Second" +
                                (ssp_value >= parseInt(itj_val[2]?.value) && ssp_value <= parseInt(itj_val[4]?.value)
                                  ? "Normal"
                                  : ssp_value <= parseInt(itj_val[2])
                                    ? "Low" + " LP" + parseInt(itj_val[2]) + " HP" + parseInt(itj_val[4])
                                    : ssp_value >= parseInt(itj_val[4])
                                      ? "High"
                                      : "");
                            })
                          ) : (
                            <></>
                          );
                        }
                      })
                    : ""
                }
              })()}


              {/* <span>{"MIN:" + default_min + " MAX:" + default_max}</span> */}

              {/* Show TEXT, FILE, COLOR, RANGE */}
              <div>
                {(() => {
                  if (fk_rr_id == 4) {
                    return <div className='underline underline-offset-2'>TEXT</div>;
                  } else if (fk_rr_id == 5 && originalCell?.mmr_inputs_value[0]?.lable == 'POS-NEG-RESULT') {
                    return <div className='underline underline-offset-2'>{originalCell?.mmr_inputs_value[0]?.value}</div>;
                  } else if (fk_rr_id == 5) {
                    return <div className='underline underline-offset-2'>FILE</div>;
                  } else if (fk_rr_id == 6) {
                    return <div className='underline underline-offset-2'>{originalCell?.mmr_inputs_value[0]?.value}</div>;
                  } else if (fk_rr_id == 17) {
                    return <div className=''>{colorResult[0]?.color_value}</div>;
                  } else {
                    //return <span>{"MIN:" + default_min + "MAX:" + default_max}</span>;

                    if (fk_lab_analyzer_id && tprrlab_inputs_value && tprrlabInputsLables.length > 0) {

                      return <div className="box-container">
                        {
                          tprrlabInputsLables.map((data, index) => {
                            return (<div className="box">
                              {/* <p>{data}</p> */}
                              <p>{tprrlab_inputs_value[index][1].value}</p>
                              <div style={{ backgroundColor: `${colors2[index]}` }} className={`text-black px-2 py-2`}>{tprrlab_inputs_value[index][2].value + (tprrlab_inputs_value[index][4].value ? ('-' + tprrlab_inputs_value[index][4].value) : '') + ' ' + ((tprrlab_inputs_value.length - 1) != index ? tprrlab_inputs_value[index][3].value : '')}</div>
                            </div>
                            )
                          })
                        }
                      </div>

                    } else {

                      if (is_sub_rr === true && sub_ref_range.length > 0) {

                        if (sub_ref_range[0].mmr_inputs_value !== null && sub_ref_range[0].mmr_inputs_value.length > 0) {

                          return <div className="box-container">
                            {
                              r_type_inputsList.map((data, index) => {
                                return (<div className="box">
                                  {/* <p>{data.rti_title_en}</p> */}
                                  <p>{sub_ref_range[0].mmr_inputs_value[index][1].value}</p>
                                  <div style={{ backgroundColor: `${colors2[index]}` }} className={`text-black px-2 py-2`}>{sub_ref_range[0].mmr_inputs_value[index][2].value + (sub_ref_range[0].mmr_inputs_value[index][4].value ? ('-' + sub_ref_range[0].mmr_inputs_value[index][4].value) : '') + ' ' + ((sub_ref_range[0].mmr_inputs_value.length - 1) != index ? sub_ref_range[0].mmr_inputs_value[index][3].value : '')}</div>
                                </div>
                                )
                              })
                            }
                          </div>
                        }

                      } else {

                        return <div className="box-container">
                          {
                            r_type_inputsList.map((data, index) => {
                              return (<div className="box">
                                {/* <p>{data.rti_title_en}</p> */}
                                <p>{inputs_value[index][1].value}</p>
                                <div style={{ backgroundColor: `${colors2[index]}` }} className={`text-black px-2 py-2`}>{inputs_value[index][2].value + (inputs_value[index][4].value ? ('-' + inputs_value[index][4].value) : '') + ' ' + ((inputs_value.length - 1) != index ? inputs_value[index][3].value : '')}</div>
                              </div>
                              )
                            })
                          }
                        </div>

                      }

                    }

                  }
                })()}
              </div>

            </div>
          ) : (
            <div className="inline-flex p-2 text-xs underline-offset-2">
              {/* <span>{"MIN:" + default_min + "MAX:" + default_max}</span> */}
              {(fk_rr_id != 5 && fk_rr_id != 6 && fk_rr_id != 17) ?
                (<div className="box-container">
                  <div className="box">
                    <p>Low</p>
                    <div style={{ backgroundColor: `${colors2[0]}` }} className={`text-black px-2 py-2`}>{default_min}</div>
                  </div>
                  <div className="box">
                    <p>Normal</p>
                    <div style={{ backgroundColor: `${colors2[1]}` }} className={`text-black px-2 py-2`}>{default_min + " - " + default_max}</div>
                  </div>
                  <div className="box">
                    <p>High</p>
                    <div style={{ backgroundColor: `${colors2[2]}` }} className={`text-black px-2 py-2`}>{default_max}</div>
                  </div>
                </div>) : (<></>)
              }

            </div>
          )) : (<span className="text-red-500">Ref Not Linked{originalCell.test_result?.length}</span>)}

        </div>
      );
    },
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    Cell: (cell) => {
      let originalCell = cell.row.original;
      return (
        <p className="text-sm text-gray-500">
          {originalCell.mean ? (
            originalCell.mean
          ) : (
            <Tippy
              theme="light"
              placement="left"
              trigger="click"
              allowHTML={true}
              interactive={true}
              content={
                <EditResultTooptip
                  fieldName="ssp_comment"
                  originalCell={originalCell}
                  saveFunc={handleUpdateComment}
                  updateFunc={handleUpdateComment}
                  prevValue={
                    originalCell
                      ? originalCell.test_result[0]?.ssp_value
                      : originalCell?.test_result[0]?.ssp_value
                        ? originalCell.test_result[0].ssp_value
                        : null
                  }
                />
              }
            >
              <button>
                {originalCell.test_result?.length > 0 ? (
                  <div className="inline-flex p-2 text-xs underline underline-offset-2">
                    {originalCell.test_result[0].ssp_comment}
                    <span>
                      <PencilIcon className="w-4 h-4 ml-2" />
                    </span>
                  </div>
                ) : (
                  <div className="inline-flex p-2 text-xs underline underline-offset-2">
                    {originalCell.test_result &&
                      originalCell.test_result.length > 0
                      ? originalCell.test_result[0]?.ssp_comment == null ||
                        originalCell.test_result[0]?.ssp_comment == undefined ||
                        originalCell.test_result[0]?.ssp_comment == ''
                        ? 'Add Comment'
                        : originalCell.test_result[0]?.ssp_comment
                      : null}
                    <span>
                      <PencilIcon className="w-4 h-4 ml-2" />
                    </span>
                  </div>
                )}
              </button>
            </Tippy>
          )}
        </p>
      );
    },
  },

  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      let [showSpinner, setShowSpinner] = useState(false);
      let cellOriginal = cell.row.original;
      let ssp_attachment = cellOriginal?.test_result.length > 0 ? cellOriginal?.test_result[0]?.ssp_attachment : null;
      let ssp_pos_neg_value = cellOriginal?.test_result.length > 0 ? cellOriginal?.test_result[0]?.ssp_pos_neg_value : null;
      // let org_sp_status_id = cellOriginal.fk_sp_status_id ? cellOriginal.fk_sp_status_id : cellOriginal.sp_status_id;
      let org_sp_status_id = cellOriginal.test_result.length > 0 ? cellOriginal.test_result[0].fk_status_id : cellOriginal.fk_status_id;
      cellOriginal.org_sp_status_id = org_sp_status_id;
      cellOriginal.ssp_value = (cellOriginal.test_result && cellOriginal.test_result.length > 0) ? cellOriginal.test_result[0].ssp_value
        : null
      return (
        <>

          {/* {cellOriginal.fk_sp_status_id}
          {cellOriginal.sp_status_id}
        {cell.row.original.ssp_value} */}
          {/* {(cellOriginal.sp_status_id == 10 || cellOriginal.sp_status_id == 8) ? ( */}
          {((cellOriginal.org_sp_status_id == 6 || cellOriginal.org_sp_status_id == 4) && (cellOriginal.ssp_value || ssp_attachment || ssp_pos_neg_value)) ? (
            // {((cellOriginal.sp_status_id == 6 || cellOriginal.sp_status_id == 4) && cellOriginal.ssp_value) ? (//new
            <span className='inline-flex'>
              {showSpinner &&
                <FaSpinner icon="spinner" className="spinner" />
              }
              <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                <button
                  type="button"
                  onClick={() => {
                    setShowSpinner(true);
                    let { sample_id } = cellOriginal;

                    if (cellOriginal.org_sp_status_id == 6) {
                      // if (cellOriginal.sp_status_id == 6) {// new
                      // if (cellOriginal.sp_status_id == 10) {
                      handleValidateResult(cellOriginal)
                      // handleValidateResult(sample_id)//neww
                    } else if (cellOriginal.org_sp_status_id == 4) {
                      // } else if (cellOriginal.sp_status_id == 4) {//new
                      // } else if (cellOriginal.sp_status_id == 8) {
                      handleApproveResult(cellOriginal)
                      // handleApproveResult(sample_id)//neww
                    }
                  }}
                  size="default"
                  variant="outlined"
                >
                  {cellOriginal.org_sp_status_id == 6 ? "Validate" : cellOriginal.org_sp_status_id == 4 ? "Approve" : ""}
                  {/* //new */}
                  {/* {cellOriginal.sp_status_id == 6 ? "Validate" : cellOriginal.sp_status_id == 4 ? "Approve" : ""} */}
                  {/* {cellOriginal.sp_status_id == 10 ? "Validate" : cellOriginal.sp_status_id == 8 ? "Approve" : ""} */}
                </button>
              </span>
            </span>) : (<></>)}

          {(cellOriginal.buttonFlag && cellOriginal.ssp_value && cellOriginal.org_sp_status_id == 5 && cellOriginal.buttonFlagCount == cellOriginal.samePaitentcount) ? (
            // {(cellOriginal.sp_status_id == "5" && cellOriginal.buttonFlag && cellOriginal.ssp_value) ? (//new
            // {(cellOriginal.sp_status_id == "9" && cellOriginal.buttonFlag) ? (
            <span className='inline-flex'>
              {showSpinner &&
                <FaSpinner icon="spinner" className="spinner" />
              }
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
                <button
                  type="button"
                  onClick={() => {
                    setShowSpinner(true);
                    generateReport(cell.row.original);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Report
                </button>
              </span>
            </span>
          ) : (
            <></>
          )}

          {cellOriginal.sample_report_link && cellOriginal.buttonFlag ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
              <a href={`${cellOriginal.sample_report_link}`} target="_blank">View PDF</a>

            </span>) : (<></>)}
          {cellOriginal.smart_report_pdf && cellOriginal.buttonFlag ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 bg-blue-100 rounded-full">
              <a href={`${cellOriginal.smart_report_pdf}`} target="_blank">View Smart Report PDF</a>

            </span>) : (<></>)}
        </>
      );
    },
  },

  {
    Header: 'Lab',
    accessor: '',
    Cell: (cell) => {
      let originalCell = cell.row.original;
      let updated_fk_lab_ids = originalCell.test_result.length > 0 ? originalCell.test_result[0].fk_lab_id : null;
      let updated_fk_lab_analyzer_ids = originalCell.test_result.length > 0 ? originalCell.test_result[0].fk_lab_analyzer_id : null;
      // console.log("updated_fk_lab_analyzer_ids=>", updated_fk_lab_analyzer_ids)
      return (
        <div className="w-full">
          {originalCell?.labArr && originalCell?.labArr?.length > 0 ? (
            <select
              className="block max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
              name="lab_analyzer_id"
              id="lab_analyzer_id"
              // getFilterAllQualityControleResultList();
              onChange={(e) => { handleUpdateLocChange(originalCell, e.target.value) }}
            >
              <option value={0}> Select Lab </option>
              {originalCell?.labArr.map((lab, i) => (
                <option
                  selected={
                    lab.lab_analyzer_id ===
                      updated_fk_lab_analyzer_ids
                      ? "selected"
                      : ""
                  }
                  key={i}
                  value={JSON.stringify(lab)}
                >
                  {lab.lab_analyzer_name_en}
                </option>
              ))}
            </select>
          ) : (
            <select
              className="block max-w-lg border-gray-300 rounded-md shadow-sm focus:border-cyan-500 focus:ring-cyan-500 sm:max-w-xs sm:text-sm"
              name="fk_loc_id"
              id="fk_loc_id"
              onChange={(e) => { }}
            >
              <option value={0}> Select Lab </option>
            </select>
          )}
        </div>
      );
    },
  },
];

export const vat_columns = () => [
  {
    Header: 'Id',
    accessor: 'vat_id',
  },
  {
    Header: 'Country',
    accessor: (d) => capitalise(d.country),
  },
  {
    Header: 'Hospital',
    accessor: (d) => capitalise(d.hospital),
  },
  {
    Header: 'Percentage',
    accessor: (d) => capitalise(d.percentage),
  },
  {
    Header: 'Status',
    accessor: 'status',
    // accessor: (d) => d.package_is_active == true ? "Active" : "Inactive",
    Cell: (cell) => {
      // const cellId = cell.row.original.vat_id;
      // const enabled = cell.row.original.package_is_active;
      const [enabled, setEnabled] = useState();
      return (
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={vatStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={vatStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.vat_id;
      return (
        <>
          <Link
            to={`/add-vat/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          {/* <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
						<button
							onClick={() => {
								onDeleteOpen(cellId, cell.row.original.package_is_active);
							}}
							size="default"
							variant="outlined"
						>
							{cell.row.original.package_is_active == true ? 'In-Activate' : 'Activate'}
							<span className="sr-only">, </span>
						</button>
					</span> */}
        </>
      );
    },
  },
];
export const coupon_columns = ({ onDeleteOpen, handleDrawer, toggleModal, onStatusActiveOpen }) => [
  {
    Header: "Coupon Name",
    accessor: (d) => capitalise(d.cp_name),
  },
  {
    Header: 'Discount Value',
    accessor: 'cp_discountValue',
  },
  {
    Header: 'Is Percentage',
    accessor: (d) => (d.cp_value_is_percentage ? 'Yes' : 'No'),
  },
  {
    Header: "Active From",
    accessor: (d) => d.cp_fromDate ? moment(convertToAnotherTimezone(d.cp_fromDate)).format('MMMM Do YYYY, h:mm:ss a') : "N/A"
  },
  {
    Header: "Expires At",
    accessor: (d) => d.cp_toDate ? moment(convertToAnotherTimezone(d.cp_toDate)).format('MMMM Do YYYY, h:mm:ss a') : "N/A"
  },

  {
    Header: "Status",
    accessor: (d) => (d.cp_is_active == true ? "Active" : "Inactive"),
    Cell: (cell) => {
      const cellId = cell.row.original.cp_id;
      // let enabled = cell.row.original.cp_is_active;
      let [enabled, setEnabled] = useState(cell.row.original.cp_is_active);
      // const [enabled, setEnabled] = useState()
      return (
        <Switch
          checked={enabled}
          onChange={(e) => {
            setEnabled(e);
            onStatusActiveOpen(cellId, e);
          }}
          className={testStatusToggle(
            enabled ? "bg-cyan-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={testStatusToggle(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      );
    },
  },

  // is used - Not in DB yet

  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.cp_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-blue-600 rounded-full">
            <Link
              to={`/coupons/${cell.row.original.cp_id}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Show Coupon Codes
              </button>
            </Link>
          </span>
        </>
      );
    },
  },
];

export const sample_columns_for_receiving = () => [
  {
    Header: 'Sample Id',
    accessor: (d) => d.fk_sample_id,
  },
  {
    Header: 'Res Id',
    accessor: (d) => d.fk_reservation_id,
  },

  {
    Header: 'Test Name',
    accessor: (d) => capitalise(d.t_test_relation.test_name_en),
  },

  {
    Header: 'Patient',
    accessor: (d) => capitalise(d.m_patient_relation.patient_first_name),
  },

  {
    Header: 'Status',
    accessor: (d) =>
      d.t_sample_relation.m_sample_status_relation.sp_status_name_en,
  },
];

export const purchase_order_list_items_columns = ({ }) => [
  {
    Header: 'ID',
    accessor: 'wpoi_id',
    Cell: (cell) => {
      return (
        <Link
          className="text-sm text-gray-500"
          to={`/add-vendor/${cell.row.original.wpoi_id}`}
        >
          {cell.row.original.wpoi_id}
        </Link>
      );
    },
  },
  {
    Header: 'Unit',
    accessor:
      'w_unit_relation.unit_name_en',
  },
  {
    Header: 'Items',
    accessor:
      'w_item_relation.witem_name_en',
  },
  {
    Header: 'Cost',
    accessor:
      'wpoi_cost',
  },
  {
    Header: 'Quantity',
    accessor:
      'wpoi_quantity',
  },
  {
    Header: 'Created At',
    accessor: (d) => d.wpoi_created_at && moment(convertToAnotherTimezone(d.wpoi_created_at)).format('DD-MM-YYYY hh:mm:ss A'),
  },
]

export const purchase_order_list_in_grn_columns = ({ updtePurcahseOrderStatus, openPurchaseOrderItem, onDeleteOpen }) => [
  {
    Header: 'ID',
    accessor: 'wpo_id',
    Cell: (cell) => {
      return (
        <Link
          className="text-sm text-gray-500"
          to={`/goods-received/${cell.row.original.wpo_id}`}
        >
          {cell.row.original.wpo_id}
        </Link>
      );
    },
  },
  {
    Header: 'Vendor Name',
    accessor:
      'wpo_vendor_relation.vendor_account_name',
  },
  {
    Header: 'Location',
    accessor:
      'wpo_hsp_loc_relation.loc_name_en',
  },
  {
    Header: 'View',
    accessor: 'View',
    Cell: (cell) => {
      const cellId = cell.row.original.wpo_id;
      return (
        <>
          <Link
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            size="default"
            variant="outlined"
            to={`/goods-received/${cell.row.original.wpo_id}`}
          >
            view
          </Link>
        </>
      );
    },
  },
  {
    Header: 'Status',
    accessor: 'Status',
    Cell: (cell) => {
      const cellId = cell.row.original.wpo_id;
      let totalQuantity = 0;
      if (cell.row.original?.w_purchase_order_items && Array.isArray(cell.row.original?.w_purchase_order_items) && cell.row.original?.w_purchase_order_items.length > 0) {
        totalQuantity = cell.row.original?.w_purchase_order_items.reduce((accumulator, object) => {
          return accumulator + parseInt(object.wpoi_quantity);
        }, 0);
      }
      let remainingQunatity = 0;
      if (cell.row.original?.wpo_gr_relation && Array.isArray(cell.row.original?.wpo_gr_relation) && cell.row.original?.wpo_gr_relation.length > 0) {
        remainingQunatity = cell.row.original?.wpo_gr_relation.reduce((accumulator, object) => {
          return accumulator + parseInt(object.quantity);
        }, 0);
      }
      return (
        <>
          {remainingQunatity === 0 &&
            <span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">Pending</span>
          }
          {totalQuantity !== remainingQunatity && remainingQunatity !== 0 &&
            <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300">Incomplete</span>
          }
          {totalQuantity !== 0 && totalQuantity === remainingQunatity &&
            <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">Complete</span>
          }
        </>
      );
    },
  },
];


export const purchase_order_list_columns = ({ updtePurcahseOrderStatus, openPurchaseOrderItem, onDeleteOpen, onAppoveOpen, userData, generatePdf }) => [
  {
    Header: 'ID',
    accessor: 'wpo_id',
    Cell: (cell) => {
      return (
        <Link
          className="text-sm text-gray-500"
          to={`/add-vendor/${cell.row.original.wpo_id}`}
        >
          {cell.row.original.wpo_id}
        </Link>
      );
    },
  },
  {
    Header: 'Vendor Name',
    accessor:
      'wpo_vendor_relation.vendor_account_name',
  },
  {
    Header: 'Location',
    accessor:
      'wpo_hsp_loc_relation.loc_name_en',
  },
  {
    Header: 'View',
    accessor: 'View',
    Cell: (cell) => {
      const cellId = cell.row.original.wpo_id;
      return (
        <>
          <p
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={e => openPurchaseOrderItem(cell.row.original.w_purchase_order_items)}
            >
              View Items<span className="sr-only">, </span>
            </button>
          </p>
        </>
      );
    },
  },
  {
    Header: 'Created At',
    accessor: (d) => d.wpo_created_at && moment(convertToAnotherTimezone(d.wpo_created_at)).format('DD-MM-YYYY hh:mm:ss A'),
  },
  {
    Header: 'Updated At',
    accessor: (d) => d.wpo_updated_at && moment(convertToAnotherTimezone(d.wpo_updated_at)).format('DD-MM-YYYY hh:mm:ss A'),
  },
  // {
  //   Header: 'status',
  //   accessor: 'status',
  //   Cell: (cell) => {
  //     let Cstatus = {
  //       po_id: cell.row.original.wpo_id,
  //       wpo_is_active:
  //         cell.row.original.wpo_is_active === true ? false : true,
  //     };

  //     return (
  //       <Switch
  //         disabled={cell.row.original?.wpo_gr_relation.length > 0}
  //         checked={cell.row.original.wpo_is_active}
  //         onChange={() => {
  //           updtePurcahseOrderStatus(Cstatus);
  //         }}
  //         name="vendor_is_active"
  //         className={`
  //           ${cell.row.original.wpo_is_active
  //             ? 'bg-cyan-600 relative inline-flex h-Switch6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
  //             : 'bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
  //           } ${cell.row.original?.wpo_gr_relation.length > 0 ? 'pointer-events-none bg-sky-200		' : ''}`}
  //       >
  //         <span className="sr-only">Use setting</span>
  //         <span
  //           aria-hidden="true"
  //           className={
  //             cell.row.original.wpo_is_active
  //               ? 'translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
  //               : 'translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
  //           }
  //         />
  //       </Switch>
  //     );
  //   },
  // },

  {
    Header: 'Approve',
    accessor: 'approve',
    Cell: (cell) => {
      let Cstatus = {
        po_id: cell.row.original.wpo_id,
        wpo_is_approved: true,
      };


      return (
        <>
          <span className={`inline-flex text-xs `}>
            <div class="tooltip">
              <button
                disabled={!userData?.user_can_approve_po}
                className={`${cell.row.original.wpo_is_approved ? 'pointer-events-none' : ''}`}
                onClick={() => onAppoveOpen(Cstatus)} size="default" variant="outlined">
                {cell.row.original.wpo_is_approved ?
                  <span className='text-green-600 underline'>Approved</span>
                  :
                  <span className='underline text-cyan-600'>Approve               
                  </span>
                }
                <span className="sr-only">, </span>
              </button>
              {/* <Switch
                disabled={!userData?.user_can_approve_po}
                checked={cell.row.original.wpo_is_approved}
                onChange={() => {
                  updtePurcahseOrderApprovedStatus(Cstatus);
                }}
                name="wpo_is_approved"
                className={`
                  ${cell.row.original.wpo_is_approved
                    ? 'bg-cyan-600 relative inline-flex h-Switch6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
                    : 'bg-gray-200 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
                  }`}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={
                    cell.row.original.wpo_is_approved
                      ? 'translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      : 'translate-x-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                  }
                />
              </Switch> */}
              {!userData?.user_can_approve_po &&
                <span class="tooltiptext2">No Access</span>
              }
            </div>
            <span className="sr-only">, </span>
          </span>
        </>
      );
    },
  },
  {
    Header: 'Pdf',
    accessor: 'pdf',
    Cell: (cell) => {
      const [showSpinner, setShowSpinner] = useState(false);

      return (
        <>
          <span className={`inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full ${cell.row.original.wpo_is_approved ? '' : 'pointer-events-none bg-gray-100'}`}>
            <a
              href={cell.row.original?.wpo_pdf_url}
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              target='__blank'
            >View
            </a>
          </span>
          <span className={`inline-flex px-2 text-xs ml-3 leading- rounded-full`}>
            {
              !cell.row.original.wpo_is_approved ? (
                <button
                  disabled={cell.row.original.wpo_is_approved ? false : true}
                  onClick={e => generatePdf({ po_id: cell.row.original.wpo_id })}
                  className="tooltip text-gray-500 bg-gray-100"
                >Generate PDF<span class="tooltiptext2">Not approved</span>
                </button>
              ) : (
                <button
                  disabled={cell.row.original.wpo_is_approved ? false : true}
                  onClick={e => {generatePdf({ po_id: cell.row.original.wpo_id });setShowSpinner(true)}}
                  className="text-cyan-600 bg-cyan-100 flex"
                >Generate PDF                             
                {showSpinner &&
                  <FaSpinner icon="spinner" className="spinner" />
                }
                </button>
              )
            }
          </span>
        </>
      )
    }
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <span className={`${cell.row.original?.wpo_gr_relation.length > 0 && 'sr-only'} inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full ${cell.row.original?.wpo_gr_relation.length > 0 ? 'pointer-events-none bg-gray-100	' : ''}`}>
            <Link
              to={`/add-purchase-order/${cell.row.original.wpo_id}`}
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >Edit
            </Link>
          </span>
          <span className={`${cell.row.original?.wpo_gr_relation.length > 0 && 'sr-only'} inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full ${cell.row.original?.wpo_gr_relation.length > 0 ? 'pointer-events-none bg-gray-100	' : ''}`}>
            <button onClick={() => onDeleteOpen({ po_id: cell.row.original.wpo_id })} size="default" variant="outlined">
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
          {cell.row.original?.wpo_gr_relation.length > 0 &&
            <>
              <span className={`inline-flex px-2 text-xs leading-5 text-cyan-600 bg-cyan-100 rounded-full ${cell.row.original?.wpo_gr_relation.length > 0 ? ' bg-gray-100	' : ''}`}>
                <div className="tooltip">
                  Edit<span className="tooltiptext2">GRN already created</span>
                </div>
                <span className="sr-only">, </span>
              </span>
              <span className={`inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full ${cell.row.original?.wpo_gr_relation.length > 0 ? ' bg-gray-100	' : ''}`}>
                <div className="tooltip">
                  Delete<span className="tooltiptext2">GRN already created</span>
                </div>
                <span className="sr-only">, </span>
              </span>
            </>
          }
        </>
      );
    },
  },
];

export const incentive_master_columns = ({ role_id }) => [
  {
    Header: "ID",
    accessor: "inct_id",
  },
  {
    Header: "Role",
    accessor: "role_relation.role_name_en",
  },
  {
    Header: "Active From",
    accessor: (d) => d.inct_from_date ? moment(convertToAnotherTimezone(d.inct_from_date)).utc().format('Do MMMM YYYY, h:mm:ss a') : "N/A"
  },
  {
    Header: "Expires At",
    accessor: (d) => d.inct_to_date ? moment(convertToAnotherTimezone(d.inct_to_date)).utc().format('Do MMMM YYYY, h:mm:ss a') : "N/A"
  },
  {
    Header: "Locations",
    accessor: (e) => {
      let t = []
      if (e.locations.length > 0) {
        e.locations.forEach((p) => {
          t.push(p.location_relation?.loc_name_en)
        })
      }
      return <div>
        {t.join(", ")}
      </div>
    }
  },
  {
    Header: "Tests",
    accessor: (e) => {
      let t = []
      if (e.tests.length > 0) {
        e.tests.forEach((test) => {
          t.push(test.test_relation?.test_name_en)
        })
      }
      return <div>
        <div style={{ width: 200, whiteSpace: "pre-line" }}>
          {t.join(", ")}
        </div>
      </div>
    }
  },
  {
    Header: "Packages",
    accessor: (e) => {
      let pack = []
      if (e.packages.length > 0) {
        e.packages.forEach((p) => {
          pack.push(p.package_relation?.package_name_en)
        })
      }
      return <div style={{ width: 200, whiteSpace: "pre-line" }}>
        {pack.join(", ")}
      </div>
    }
  },
  {
    Header: "Value",
    accessor: "inct_value",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      let inct = cell.row.original
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
            <Link
              to={`../../add-incentive-master/${role_id}/${inct.inct_id}`}
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit
            </Link>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button onClick={() => { }} size="default" variant="outlined">
              Delete
            </button>
          </span>
        </>
      );
    },
  },
];

export const storage_location_list_columns = ({ setModal, onDeleteOpen }) => [
  {
    Header: 'Storage ID',
    accessor: 'sl_id',
    width: '20px',
  },
  {
    Header: 'name',
    accessor: 'name',
  },
  {
    Header: 'address',
    accessor: 'address',
  },
  {
    Header: 'lab',
    accessor: 'sl_lab_relation.loc_name_en',
  },
  {
    Header: 'Created At',
    accessor: (d) => moment(convertToAnotherTimezone(d.sl_created_at)).format("do-MMM-YY hh:mm:ss"),
  },
  {
    Header: 'Updated At',
    accessor: (d) => moment(convertToAnotherTimezone(d.sl_updated_at)).format("do-MMM-YY hh:mm:ss"),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  type: 'edit',
                  edit_id: cell.row.original.sl_id,
                  data: {
                    name: cell.row.original.name,
                    address: cell.row.original.address,
                    lab_id: cell.row.original.lab_id,
                  },
                }));
              }}
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.sl_id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const storage_item_location_list_columns = ({
  setModal,
  onDeleteOpen,
}) => [
    {
      Header: 'item',
      accessor: (e) => e?.item_storage_relation?.witem_name_en,
    },

    {
      Header: 'storage location',
      accessor: (e) => {
        if (e?.sli_sl_relation?.name) {
          return e.sli_sl_relation.name;
        } else {
          return '';
        }
      },
    },
    {
      Header: 'quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Created At',
      accessor: (e) => {
        if (e?.sli_created_at) {
          return moment(convertToAnotherTimezone(e?.sli_created_at)).format('DD-MM-YYYY hh:mm:ss A')
        } else {
          return '';
        }
      }
    },
  ];
export const Movement_columns = ({ onDeleteOpen, setModal }) => [
  {
    Header: 'from',
    accessor: (e) => {
      if (e?.from_id?.name) {
        return e?.from_id?.name;
      } else {
        return '';
      }
    },
  },
  {
    Header: 'to',
    accessor: (e) => {
      if (e?.to_id?.name) {
        return e.to_id.name;
      } else {
        return '';
      }
    },
  },
  {
    Header: 'quantity',
    accessor: 'quantity',
  },
  {
    Header: 'item',
    accessor: (e) => e?.item_movement_relation?.witem_name_en,
  },
  {
    Header: 'type',
    accessor: 'type',
  },
  {
    Header: 'Comment',
    accessor: 'mo_comment',
  },
  {
    Header: "Pdf",
    accessor: "pdf",
    Cell: (cell) => {
      const cellId = cell.row.original.mo_pdf_url;
      return (
        <>
          <a
            href={cellId}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            target="_blank"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              View <span className="sr-only">, </span>
            </button>
          </a>
        </>
      );
    },
  },
];

export const grn_columns = ({ set_preview }) => [
  {
    Header: 'GRN REF ID',
    accessor: (e) => e.grn_ref_id,
  },
  {
    Header: 'Item',
    accessor: (e) => e?.item_grn_relation?.witem_name_en,
  },
  {
    Header: 'Quantity',
    accessor: (e) => e.quantity,
  },
  {
    Header: 'Storage Location',
    accessor: (e) => e?.sl_grn_relation?.name,
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                set_preview((prev) => ({
                  ...prev,
                  grn: { flag: true, data: cell.row.original },
                }));
              }}
            >
              Print
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];
export const goods_return_columns = ({ onDeleteOpen, setModal }) => [
  {
    Header: 'purchase order',
    accessor: 'purchase_order_id',
  },
  {
    Header: 'storage location',
    accessor: (e) => e?.sl_grn_rn_relation?.name,
  },
  {
    Header: 'quantity',
    accessor: 'quantity',
  },
  {
    Header: 'item',
    accessor: (e) => e?.item_grn_rn_relation?.witem_name_en,
  },

  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  type: 'edit',
                  edit_id: cell.row.original.grn_return_id,
                }));
              }}
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.grn_return_id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const goods_received_columns = ({ onDeleteOpen, setModal, getAllItems, SingleprintsampleRef, printSampleBarCode, openBarcodeModal }) => [
  {
    Header: 'Sr No.',
    accessor: 'grn_id'
  },
  // {
  //   Header: 'purchase order',
  //   accessor: 'purchase_order_id',
  // },
  {
    Header: 'storage location',
    accessor: (e) => e?.sl_grn_relation?.name,
  },
  {
    Header: 'quantity',
    accessor: 'quantity',
  },
  {
    Header: 'item',
    accessor: (e) => e?.item_grn_relation?.witem_name_en,
  },
  {
    Header: 'Expiry Date',
    accessor: (e) => e?.expire_date && moment(e.expire_date).format('YYYY-MM-DD'),
  },
  {
    Header: 'Batch Number',
    accessor: (e) => e?.batch_number,
  },
  {
    Header: 'Barcode',
    accessor: 'Barcode',
    Cell: (cell) => {
      return (
        <>
          {/* <div className="px-4 py-2 text-xs font-medium border border-grey-500 text-grey-600"> */}
          <div className="">
            {/* {printSampleBarCodeFlag ? ( */}
            {/* <Barcode height={60} displayValue={false} value={cell.row.original.grn_id} /> */}
            <span className="inline-flex px-2 mr-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
                onClick={(e) => {
                  openBarcodeModal(cell.row.original)
                }}
              >
                View
              </button>
            </span>
            <span className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
                onClick={(e) => {
                  printSampleBarCode(cell.row.original, e)
                }}
              >
                Print
              </button>
            </span>
            {/* // ) : (<></>)} */}
          </div>
          <div className='hidden' ref={SingleprintsampleRef}>
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-xs text-grey-600">SN</th>
                  <th className="px-4 py-2 text-xs text-grey-600">GRN ID</th>
                  <th className="px-4 py-2 text-xs text-grey-600">STORAGE LOCATION</th>
                  <th className="px-4 py-2 text-xs text-grey-600">QUANTITY</th>
                  <th className="px-4 py-2 text-xs text-grey-600">ITEM</th>
                  <th className="px-4 py-2 text-xs text-grey-600">EXPIRY DATE</th>
                  <th className="px-4 py-2 text-xs text-grey-600">BATCH NUMBER</th>
                  <th className="px-4 py-2 text-xs text-grey-600">BARCODE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2 text-xs font-medium border border-grey-500 text-grey-600">
                    1.
                  </td>
                  <td className="px-4 py-2 text-xs font-medium border border-grey-500 text-grey-600">
                    {cell.row.original.grn_id || '-'}
                  </td>
                  <td className="px-4 py-2 text-xs font-medium border border-grey-500 text-grey-600">
                    {cell.row.original?.sl_grn_relation?.name || '-'}
                  </td>
                  <td className="px-4 py-2 text-xs font-medium border border-grey-500 text-grey-600">
                    {cell.row.original.quantity || '-'}
                  </td>
                  <td className="px-4 py-2 text-xs font-medium border border-grey-500 text-grey-600">
                    {cell.row.original?.item_grn_relation?.witem_name_en || '-'}
                  </td>
                  <td className="px-4 py-2 text-xs font-medium border border-grey-500 text-grey-600">
                    {cell.row.original?.expire_date && moment(cell.row.original?.expire_date).format('YYYY-MM-DD') || '-'}
                  </td>
                  <td className="px-4 py-2 text-xs font-medium border border-grey-500 text-grey-600">
                    {cell.row.original?.batch_number || '-'}
                  </td>
                  <td className="px-4 py-2 text-xs font-medium border border-grey-500 text-grey-600">
                    <div className="space-y-6">
                      {/* {printSampleBarCodeFlag ? ( */}
                      <Barcode height={60} displayValue={false} value={cell.row.original.grn_id} />
                      {/* // ) : (<></>)} */}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* </div> */}
        </>
      )
    }
  },
  {
    Header: 'Created At',
    accessor: (e) => e?.grn_created_at && moment(convertToAnotherTimezone(e.grn_created_at)).format('DD-MM-YYYY hh:mm:ss A'),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          {/* <span className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                setModal((prev) => ({
                  ...prev,
                  state: true,
                  type: 'edit',
                  edit_id: cell.row.original.grn_id,
                }));
                getAllItems(cell.row.original.purchase_order_id)
              }}
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span> */}
          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cell.row.original.grn_id);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];


export const incentive_dashboard = () => [
  {
    Header: "User Id",
    accessor: "user_id",
    Cell: (cell) => {
      return (
        <>
          <span className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
            <Link
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              to={`/incentive-details/${cell.row.original.user_id}`}
            >
              {cell.row.original.user_id}
            </Link>
          </span>
        </>
      )
    }
  },
  {
    Header: "User Name",
    accessor: (e) => e.user_first_name + " " + e.user_last_name,
  },
  {
    Header: "Role",
    accessor: (e) => e.m_role_relation.role_name_en,
  },
  {
    Header: "Total Incentive Received",
    accessor: (e) => e.total_incentive,
  },
];
export const incentive_details = () => [
  {
    Header: "Reservation Id",
    accessor: "fk_reservation_id",
  },
  {
    Header: "Test / Package",
    accessor: (e) => e.fk_package_id ? "Package" : "Test",
  },
  {
    Header: "Value",
    accessor: "inct_log_amount",
  },
  {
    Header: "Incentive Applied On",
    accessor: (e) => new Date(e.inct_log_created_at).toLocaleString(),
  },
];

export const region_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Region Name(EN)",
    accessor: (d) => capitalise(d.rgn_name_en),
  },
  {
    Header: "Region Name(AR)",
    accessor: (d) => capitalise(d.rgn_name_ar),
  },
  {
    Header: "Tot. Location",
    accessor: "Tot. Location",
    Cell: (cell) => {
      const cellId = cell.row.original.rgn_id;
      return (
        <>
          <Link
            to={`/region-loc/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              View {cell.row.original.countRegionLocMap}<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
  {
    Header: "Status",
    accessor: (d) => d.rgn_is_active == true ? "Active" : "Inactive",
    Cell: (cell) => {
      const cellId = cell.row.original.rgn_id;
      const enabled = cell.row.original.rgn_is_active;
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, enabled);
          }}
          className={categoryStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={categoryStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      )
    }
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.rgn_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      );
    },
  },
];
export const region_location_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Region (EN)",
    accessor: (d) => capitalise(d.m_region_relation?.rgn_name_en),
  },
  {
    Header: "Region (AR)",
    accessor: (d) => capitalise(d.m_region_relation?.rgn_name_ar),
  },
  {
    Header: "Hospital (EN)",
    accessor: (d) => capitalise(d.m_hsp_location_relation?.m_hospital_relation?.hos_name_en),
  },
  {
    Header: "Location (EN)",
    accessor: (d) => capitalise(d.m_hsp_location_relation?.loc_name_en),
  },
  {
    Header: "Location (AR)",
    accessor: (d) => capitalise(d.m_hsp_location_relation?.loc_name_ar),
  },
  {
    Header: "Status",
    accessor: (d) => (d.rgn_loc_is_active == true ? "Active" : "Inactive"),
    Cell: (cell) => {
      const cellId = cell.row.original.rgn_loc_id;
      const enabled = cell.row.original.rgn_loc_is_active;
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.rgn_loc_is_active);
          }}
          className={testStatusToggle(
            enabled ? "bg-cyan-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={testStatusToggle(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      return (
        <>
          <span
            onClick={() =>
              handleDrawer("edit", cell.row.original.rgn_loc_id, cell.row.original)
            }
            className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const itemlist_columns = () => [
  {
    Header: 'Item Name',
    accessor: (e) => e.item_name,
  },
  {
    Header: 'Item Price',
    accessor: (e) => e.item_proce,
  },
  {
    Header: 'Received',
    accessor: (e) => e.received,
  },
  {
    Header: 'Ordered',
    accessor: (e) => e.total,
  },
  {
    Header: 'Remaining',
    accessor: (e) => parseInt(e.total) - e.received,
  },
];

export const outsourcing_report = () => [
  {
    Header: 'Test Id',
    accessor: (e) => e.test_id,
  },
  {
    Header: 'Test',
    accessor: (e) => e.test,
  },
  {
    Header: 'Patient Name',
    accessor: (e) => e.patient_name,
  },
  {
    Header: 'Payment Method',
    accessor: (e) => e.payment_method,
  },
  {
    Header: 'Net Total',
    accessor: (e) => e.net_total,
  },
  {
    Header: 'Paid',
    accessor: (e) => e.paid,
  },
  {
    Header: 'Cash',
    accessor: (e) => e.cash,
  },
  {
    Header: 'Visa',
    accessor: (e) => e.visa,
  },
  {
    Header: 'Remaining',
    accessor: (e) => e.remaining,
  },
  {
    Header: 'Status',
    accessor: (e) => e.status,
  },
  {
    Header: 'Date',
    accessor: (e) => e.date,
  },
  {
    Header: 'Paid By',
    accessor: (e) => e.paid_by,
  },
  {
    Header: 'Open/Closed',
    accessor: (e) => e.open_close,
  },
];

export const User_update_report = () => [
  {
    Header: 'Sample Id',
    accessor: (e) => e.sample_id,
  },
  {
    Header: 'Patient',
    accessor: (e) => e.patient_name,
  },
  {
    Header: 'Identity',
    accessor: (e) => e.identity,
  },
  {
    Header: 'Updated By',
    accessor: (e) => e.updated_by,
  },
  {
    Header: 'Test Type',
    accessor: (e) => e.test_type,
  },
  {
    Header: 'Hospital',
    accessor: (e) => e.hospital,
  },
  {
    Header: 'Status',
    accessor: (e) => e.status,
  },
  {
    Header: 'Updated Type',
    accessor: (e) => e.updated_type,
  },
  {
    Header: 'Before Update',
    accessor: (e) => e.before_update,
  },

  {
    Header: 'After Update',
    accessor: (e) => e.after_update,
  },
  {
    Header: 'Updated Time',
    accessor: (e) => e.updated_time,
  },
];

export const urgent_logs_report_columns = () => [

  // {
  //   Header: "Notifaction Sent",
  //   accessor: (d) => d?.resv_trnx_logs_relation?.resv_tx_urgent_status ? <span style={{color: 'green'}}>SENT</span> : 'NOT SENT'
  // },
  // {
  //   Header: 'NOTIFICATION TEXT',
  //   accessor: d => d?.notify_tittle
  // },
  {
    Header: 'Reservation ID',
    Cell: (cell) => {
      const reservation_id = cell.row.original.resv_tx_resv_id;
      return (
        <>
          <div>
            <Link
              onClick={() => {
                // console.log('PS=>', reservation_id);
                // localStorage.setItem(
                //   'edit_booking_reservation_user_details',
                //   JSON.stringify(cell.row.original)
                // );
                localStorage.setItem(
                  'edit_booking_reservation_id',
                  reservation_id
                );
                // editReservation(cell.row.original);
              }}
              to={`/bookingConfirm`}
              className="inline-flex px-2 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <div className="flex items-center flex-col p-1">
                <span>{reservation_id}{"  "}</span>
                <span>View</span>
              </div>
            </Link>
          </div>
        </>
      );
    },
  },
  {
    Header: 'Patient Name',
    accessor: 'resv_tx_patient_fname_en',
  },
  {
    Header: 'TEST/PACKAGE ID',
    accessor: d => d?.resv_tx_test_id ? d?.resv_tx_test_id : d?.resv_tx_package_id
  },
  {
    Header: 'TEST/PACKAGE',
    accessor: d => d?.resv_tx_test_name_en ? d?.resv_tx_test_name_en : d?.resv_tx_package_name_en
  },
  {
    Header: 'Type',
    accessor: d => d?.resv_is_package === "Y" ? "Package" : "Test"
  },
  // {
  //   Header: 'From',
  //   accessor: d => d?.notify_from_relation?.user_first_name
  // },
  // {
  //   Header: 'TO',
  //   accessor: d => d?.notify_to_relation?.user_first_name
  // },

  //   {
  //   Header: 'Parameter',
  //   accessor: 'mlg_parameters',
  // },
  // // {
  // //   Header: 'Change/Add',
  // //   accessor: 'coupon_relation.cp_name',
  // // },
  // {
  //   Header: "Before Update",
  //   accessor: "mlg_before_update"
  // },
  // {
  //   Header: "After Update",
  //   accessor: "mlg_after_update"
  // },
  // {
  //   Header: "By",
  //   accessor: 'mlg_status_cu_relation.user_first_name'
  // },
  {
    Header: 'Time',
    accessor: (d) => moment(convertToAnotherTimezone(d?.resv_tx_updated_at)).format('DD-MM-YYYY hh:mm:ss A'),
  },
];

export const report_pdf_generated_logs_columns = (handleReportSmsModelOpen) => [
  {
    Header: 'Action',
    Cell: (cell) => {
      const data = cell.row.original;
      return (
        <>
          <div className='flex'>
            <div className='tooltip mt-2 mr-2'>
              <button
                onClick={() => {
                  handleReportSmsModelOpen(data)
                }}
              >
              <SlEnvolope/>
              </button>
              <span class="tooltiptext3">SMS Report Link</span>
            </div>
          <div class="tooltip m-2">
            <a
              className='text-xs underline' 
              target='__blank'
              href={data.sample_report_link}
            > 
            <FaDownload/>
            </a>
            <span class="tooltiptext3">Sample Report Pdf</span>
            </div>
            {/* <div class="tooltip m-2">
              <a
                className='text-xs underline' 
                target='__blank'
                href={data.smart_report_pdf}
              > 
              <FaDownload/>
              </a>
              <span class="tooltiptext3">Smart Report Pdf</span>
            </div> */}
          </div>
        </>
      );
    },
  },
  {
    Header: 'MRN',
    accessor: (e) => Array.isArray(e.t_sample_generated) && e.t_sample_generated.length > 0 && e.t_sample_generated[0].m_patient_relation.patient_report_mrn || '-',
  },
  {
    Header: 'Patient Name',
    accessor: (e) => Array.isArray(e.t_sample_generated) && e.t_sample_generated.length > 0 && e.t_sample_generated[0].m_patient_relation.patient_first_name || '-',
  },
  {
    Header: 'Phone No',
    accessor: (e) => Array.isArray(e.t_sample_generated) && e.t_sample_generated.length > 0 && e.t_sample_generated[0].m_patient_relation.patient_mobile || '-'
  },
  {
    Header: 'Date',
    accessor: (e) => moment(convertToAnotherTimezone(e.sample_collected)).format('DD-MM-YYYY') || '-',
  },
  {
    Header: 'Refer Doctor',
    accessor: (e) => Array.isArray(e.t_sample_generated) && e.t_sample_generated.length > 0 && e.t_sample_generated[0]?.t_reservation_relation?.m_ot_as_ref_clinic_relation?.mc_name || '-',
  },
  {
    Header: 'Reservation ID',
    accessor: (e) => Array.isArray(e.t_sample_generated) && e.t_sample_generated.length > 0 && e.t_sample_generated[0].fk_reservation_id || '-',
  },
  {
    Header: 'Lab Test Name',
    accessor: (e) => Array.isArray(e.t_sample_generated) && e.t_sample_generated.length > 0 && e.t_sample_generated[0].t_test_relation.test_name_en || '-'
  },
  {
    Header: 'National ID',
    accessor: (e) => Array.isArray(e.t_sample_generated) && e.t_sample_generated.length > 0 && e.t_sample_generated[0].m_patient_relation.patient_id_no || '-'
  },
  {
    Header: 'Time',
    accessor: (d) => moment(convertToAnotherTimezone(d?.resv_tx_updated_at)).format('hh:mm:ss A'),
  },
];



export const all_logs_report_columns = () => [

  {
    Header: "Module",
    accessor: "mlg_module_name",
  },
  {
    Header: 'Sub-Module',
    accessor: 'mlg_sub_module_name',
  },
  {
    Header: 'Row Name',
    accessor: 'mlg_row_name'
  },
  {
    Header: 'Parameter',
    accessor: 'mlg_parameters',
  },
  // {
  //   Header: 'Change/Add',
  //   accessor: 'coupon_relation.cp_name',
  // },
  {
    Header: "Before Update",
    accessor: "mlg_before_update"
  },
  {
    Header: "After Update",
    accessor: "mlg_after_update"
  },
  {
    Header: "By",
    accessor: 'mlg_status_cu_relation.user_first_name'
  },
  {
    Header: 'Time',
    accessor: (d) => moment(convertToAnotherTimezone(d.mlg_status_created_at)).format('DD-MM-YYYY hh:mm:ss A'),
  },
];

export const coupon_report_columns = () => [

  {
    Header: "User",
    accessor: (d) => capitalise(d.user_relation.user_first_name),
  },
  {
    Header: 'Reservation Id',
    accessor: 'fk_reservation_id',
  },
  {
    Header: 'Coupon Name',
    accessor: 'coupon_relation.cp_name',
  },
  {
    Header: "Coupon Code",
    accessor: 'coupon_code_details.cpc_code'
  },
  {
    Header: 'Coupon Is Percentage',
    accessor: (d) => d.coupon_relation?.cp_value_is_percentage ? "Yes" : "No",
  },
  {
    Header: 'Coupon Value',
    accessor: 'coupon_relation.cp_discountValue',
  },
  {
    Header: "Total Discount",
    accessor: 'total_discount'
  }
];

export const res_user_report_columns = () => [

  // u_user Name, Reservation ID, Coupon is Percent, Coupon Value, Coupon Name, Coupon Code, Total Discount Applied.
  {
    Header: 'Sr.No',
    accessor: 'rpl_id',
  },
  {
    Header: 'Reservation Id',
    accessor: 'rpl_reservation_id',
  },
  {
    Header: 'Patient Name',
    accessor: 'rpl_patient_name',
  },
  {
    Header: "Updated By",
    accessor: 'rpl_uu_relation.user_first_name'
  },
  {
    Header: "Updated Types",
    accessor: 'rpl_update_keys'
  },
  {
    Header: "Before Update",
    accessor: "Before Update",
    Cell: (cell) => {
      let beforeUpdate = [];
      if (cell.row.original.rpl_old_data.patient_first_name && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('Name')) {
        beforeUpdate.push(cell.row.original.rpl_old_data.patient_first_name)
      }
      if (cell.row.original.rpl_old_data.patient_email && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('email')) {
        beforeUpdate.push(cell.row.original.rpl_old_data.patient_email)
      }
      if (cell.row.original.rpl_old_data.relationship && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('Relationship')) {
        beforeUpdate.push(cell.row.original.rpl_old_data.relationship)
      }
      if (cell.row.original.rpl_old_data.patient_mobile && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('mobile')) {
        beforeUpdate.push(cell.row.original.rpl_old_data.patient_mobile)
      }
      if (cell.row.original.rpl_old_data.patient_dob && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('dob')) {
        beforeUpdate.push(cell.row.original.rpl_old_data.patient_dob)
      }
      if (cell.row.original.rpl_old_data.patient_passport_no && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('passport')) {
        beforeUpdate.push(cell.row.original.rpl_old_data.patient_passport_no)
      }
      if (cell.row.original.rpl_old_data.patient_reasion_test && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('reasonfortest')) {
        beforeUpdate.push(cell.row.original.rpl_old_data.patient_reasion_test)
      }
      if (cell.row.original.rpl_old_data.patient_ref_mrn_no && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('mrnno')) {
        beforeUpdate.push(cell.row.original.rpl_old_data.patient_ref_mrn_no)
      }
      if (cell.row.original.rpl_old_data?.m_gender_relation?.gender_name_en && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('gender')) {
        beforeUpdate.push(cell.row.original.rpl_old_data?.m_gender_relation?.gender_name_en)
      }
      if (cell.row.original?.rpl_old_data?.m_country_relation?.country_name && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('country')) {
        beforeUpdate.push(cell.row.original?.rpl_old_data?.m_country_relation?.country_name)
      }
      return (
        <>
          <span className="text-cyan-900 ">{beforeUpdate.toString()}</span>
        </>
      );
    },
  },
  {
    Header: "After Update",
    accessor: "After Update",
    Cell: (cell) => {
      let afterUpdate = [];
      if (cell.row.original.rpl_new_data.patient_first_name && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('Name')) {
        afterUpdate.push(cell.row.original.rpl_new_data.patient_first_name)
      }
      if (cell.row.original.rpl_new_data.patient_email && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('email')) {
        afterUpdate.push(cell.row.original.rpl_new_data.patient_email)
      }
      if (cell.row.original.rpl_new_data.relationship && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('Relationship')) {
        afterUpdate.push(cell.row.original.rpl_new_data.relationship)
      }
      if (cell.row.original.rpl_new_data.patient_mobile && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('mobile')) {
        afterUpdate.push(cell.row.original.rpl_new_data.patient_mobile)
      }
      if (cell.row.original.rpl_new_data.patient_dob && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('dob')) {
        afterUpdate.push(cell.row.original.rpl_new_data.patient_dob)
      }
      if (cell.row.original.rpl_new_data.patient_passport_no && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('passport')) {
        afterUpdate.push(cell.row.original.rpl_new_data.patient_passport_no)
      }
      if (cell.row.original.rpl_new_data.patient_reasion_test && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('reasonfortest')) {
        afterUpdate.push(cell.row.original.rpl_new_data.patient_reasion_test)
      }
      if (cell.row.original.rpl_new_data.patient_ref_mrn_no && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('mrnno')) {
        afterUpdate.push(cell.row.original.rpl_new_data.patient_ref_mrn_no)
      }
      if (cell.row.original.rpl_new_data?.m_gender_relation?.gender_name_en && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('gender')) {
        afterUpdate.push(cell.row.original.rpl_new_data?.m_gender_relation?.gender_name_en)
      }
      if (cell.row.original?.rpl_new_data?.m_country_relation?.country_name && cell.row.original.rpl_update_keys != '' && cell.row.original.rpl_update_keys.includes('country')) {
        afterUpdate.push(cell.row.original?.rpl_new_data?.m_country_relation?.country_name)
      }
      return (

        <>
          <span className="text-cyan-900 ">{afterUpdate.toString()}</span>
        </>
      );
    },
  },
  {
    Header: "Updated Time",
    accessor: (d) => moment(convertToAnotherTimezone(d.rpl_created_at)).format("do-MMM-YY hh:mm:ss"),
  }
];
export const incentive_report_columns = () => [
  {
    Header: 'Test/Package Name',
    accessor: 'test_or_package_name'
  },
  {
    Header: 'Test/Package',
    accessor: (d) => d.test_or_package == "T" ? "Test" : "Package"
  },
  {
    Header: 'Total Incentive Value',
    accessor: 'total_incentive',
  },
];

export const discount_otp_receivers_columns = ({ onDeleteOpen, handleDrawer, onApprovePoOpen }) => [
  {
    Header: 'Name',
    accessor: (d) =>
      capitalise(d.user_first_name) + ' ' + capitalise(d.user_last_name),
  },
  {
    Header: 'Email',
    accessor: 'user_email',
  },
  {
    Header: "Role",
    accessor: (d) => capitalise(d.m_role_relation.role_name_en),
  },
  // {
  // 	Header: "Location",
  // 	accessor: (d) => capitalise(d.location.location_name),
  // },
  // {
  //   Header: 'Status',
  //   accessor: (d) => (d.user_is_active == true ? 'Active' : 'Inactive'),
  //   Cell: (cell) => {
  //     const cellId = cell.row.original.user_id;
  //     const enabled = cell.row.original.user_is_active;
  //     // const [enabled, setEnabled] = useState()
  //     return (
  //       <Switch
  //         checked={enabled}
  //         onChange={() => {
  //           onDeleteOpen(cellId, cell.row.original.user_is_active);
  //         }}
  //         className={testStatusToggle(
  //           enabled ? 'bg-cyan-600' : 'bg-gray-200',
  //           'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
  //         )}
  //       >
  //         <span className="sr-only">Use setting</span>
  //         <span
  //           aria-hidden="true"
  //           className={testStatusToggle(
  //             enabled ? 'translate-x-5' : 'translate-x-0',
  //             'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
  //           )}
  //         />
  //       </Switch>
  //     );
  //   },
  // },
  {
    Header: 'Receiving Discount OTP',
    accessor: (d) => (d.user_is_discount_otp_receiver == true ? 'Active' : 'Inactive'),
    Cell: (cell) => {
      const cellId = cell.row.original.user_id;
      const enabled = cell.row.original.user_is_discount_otp_receiver;
      // const [enabled, setEnabled] = useState()
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onDeleteOpen(cellId, cell.row.original.user_is_discount_otp_receiver);
          }}
          className={testStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={testStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      )
    }
  },
  {
    Header: 'Approve Purchase Order',
    accessor: (d) => (d.user_can_approve_po == true ? 'Active' : 'Inactive'),
    Cell: (cell) => {
      const userID = cell.row.original.user_id;
      const enabled = cell.row.original.user_can_approve_po;
      // const [enabled, setEnabled] = useState()
      return (
        <Switch
          checked={enabled}
          onChange={() => {
            onApprovePoOpen(userID, cell.row.original.user_can_approve_po);
          }}
          className={testStatusToggle(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span
            aria-hidden="true"
            className={testStatusToggle(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
      )
    }
  },
  {
    Header: 'Mobile',
    accessor: 'user_mobile',
  },
  // {
  //   Header: 'Action',
  //   accessor: 'action',
  //   Cell: (cell) => {
  //     const cellId = cell.row.original.user_id;
  //     return (
  //       <>
  //         <Link
  //           onClick={() => handleDrawer('edit', cellId, cell.row.original)}
  //           className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
  //         >
  //           <button
  //             className="text-cyan-900 "
  //             size="default"
  //             variant="outlined"
  //           >
  //             Edit<span className="sr-only">, </span>
  //           </button>
  //         </Link>

  //         {/* <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
  //           <button
  //             onClick={() => {
  //               onDeleteOpen(cell.row.original.user_id);
  //             }}
  //             size="default"
  //             variant="outlined"
  //           >
  //             Delete
  //             <span className="sr-only">, </span>
  //           </button>
  //         </span> */}
  //       </>
  //     );
  //   },
  // },
];



export const accumulated_revenue_report = () => [
  {
    Header: 'SN.',
    // accessor: "sr",
    Cell: (cell) => {
      // console.log("cell.row.original=>", cell.row)
      return cell.row.original ? (
        <>
          {parseInt(cell.row.index) + parseInt(1)}
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Lab',
    // accessor: "lab",
    accessor: (d) => capitalise(d.collection_loc_relation?.m_hospital_relation?.hos_name_en) + " - " + capitalise(d.collection_loc_relation?.loc_name_en),
  },
  {
    Header: 'Date',
    accessor: (d) => moment((d.date_time)).format("DD-MM-YYYY"),
  },
  {
    Header: 'Day',
    // accessor: "day",
    Cell: (cell) => {
      // console.log("cell.row.original=>", cell.row)
      let date_time = new Date(cell.row.original.date_time);
      return cell.row.original ? (
        <>
          {date_time.toLocaleDateString(undefined, { weekday: 'long' })}
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Total Net-SAR',
    // accessor: "netValue",
    accessor: (d) => (d.rev_amount ? d.rev_amount : 0),
  },
  {
    Header: 'Invoice Discount',
    accessor: (d) => (d.rev_discounted_amount ? d.rev_discounted_amount : 0),
  },
  {
    Header: 'Test/Pack Discount',
    accessor: (d) => (d.rev_total_discount ? d.rev_total_discount : 0),
  },
  {
    Header: 'Total VAT-SAR',
    accessor: (d) => (d.rev_vat_amount ? d.rev_vat_amount : 0),
  },
  {
    Header: 'Net Bill Amt-SAR',
    Cell: (cell) => {
      console.log("cell.row.original=>", cell.row)
      let rev_amount = (cell.row.original.rev_amount) && !isNaN(cell.row.original.rev_amount) ? parseFloat(cell.row.original.rev_amount) : 0;
      let rev_vat_amount = (cell.row.original.rev_vat_amount) && !isNaN(cell.row.original.rev_vat_amount) ? parseFloat(cell.row.original.rev_vat_amount) : 0;
      let rev_total_discount = (cell.row.original.rev_total_discount) && !isNaN(cell.row.original.rev_total_discount) ? parseFloat(cell.row.original.rev_total_discount) : 0;
      let rev_discounted_amount = (cell.row.original.rev_discounted_amount) && !isNaN(cell.row.original.rev_discounted_amount) ? parseFloat(cell.row.original.rev_discounted_amount) : 0;
      let totAmt = parseFloat(rev_amount) + parseFloat(rev_vat_amount);
      let discount = parseFloat(rev_total_discount) + parseFloat(rev_discounted_amount);
      let AMT = rev_amount - rev_total_discount;
      return cell.row.original ? (
        <>
          {AMT}
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Total No Of Patient',
    accessor: (d) => (d.PaitentCount ? d.PaitentCount : 0),
  },

  {
    Header: '',
    accessor: "blank",
  },
  {
    Header: 'Payment Type',
    accessor: (d) => (d.paymentType),
  },
  {
    Header: 'Paid In Cash',
    accessor: (d) => (d.cashAmt ? d.cashAmt : 0),
  },
  {
    Header: 'Paid In Card',
    accessor: (d) => (d.cardAmt ? d.cardAmt : 0),
  },
  {
    Header: 'Refund In Cash',
    Cell: (cell) => {
      console.log("cell.row.original=>", cell.row)
      let is_refunded = cell.row.original.is_refunded;
      let CCAMT = cell.row.original.CCAMT;
      let rev_amount = (cell.row.original.rev_amount) && !isNaN(cell.row.original.rev_amount) ? parseFloat(cell.row.original.rev_amount) : 0;
      let rev_vat_amount = (cell.row.original.rev_vat_amount) && !isNaN(cell.row.original.rev_vat_amount) ? parseFloat(cell.row.original.rev_vat_amount) : 0;
      let rev_total_discount = (cell.row.original.rev_total_discount) && !isNaN(cell.row.original.rev_total_discount) ? parseFloat(cell.row.original.rev_total_discount) : 0;
      let rev_discounted_amount = (cell.row.original.rev_discounted_amount) && !isNaN(cell.row.original.rev_discounted_amount) ? parseFloat(cell.row.original.rev_discounted_amount) : 0;
      let totAmt = parseFloat(rev_amount) + parseFloat(rev_vat_amount);
      let discount = parseFloat(rev_total_discount) + parseFloat(rev_discounted_amount);
      let AMT = totAmt - discount;
      return cell.row.original ? (
        <>
          {is_refunded ? CCAMT : 0}
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Net Cash',
    Cell: (cell) => {
      console.log("cell.row.original=>", cell.row)
      let is_refunded = cell.row.original.is_refunded;
      let CCAMT = cell.row.original.CCAMT;
      let rev_amount = (cell.row.original.rev_amount) && !isNaN(cell.row.original.rev_amount) ? parseFloat(cell.row.original.rev_amount) : 0;
      let rev_vat_amount = (cell.row.original.rev_vat_amount) && !isNaN(cell.row.original.rev_vat_amount) ? parseFloat(cell.row.original.rev_vat_amount) : 0;
      let rev_total_discount = (cell.row.original.rev_total_discount) && !isNaN(cell.row.original.rev_total_discount) ? parseFloat(cell.row.original.rev_total_discount) : 0;
      let rev_discounted_amount = (cell.row.original.rev_discounted_amount) && !isNaN(cell.row.original.rev_discounted_amount) ? parseFloat(cell.row.original.rev_discounted_amount) : 0;
      let totAmt = parseFloat(rev_amount) + parseFloat(rev_vat_amount);
      let discount = parseFloat(rev_total_discount) + parseFloat(rev_discounted_amount);
      let AMT = totAmt - discount;
      return cell.row.original ? (
        <>
          {CCAMT}
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Cheque',
    accessor: "cheque",
  },
  {
    Header: 'Bank Transfer',
    accessor: "bankTransfer",
  },
  {
    Header: 'Bank transfer Number',
    accessor: "banktransferNumber",
  },
  {
    Header: 'Name of bank Account',
    accessor: "bankAccountName",
  },
  {
    Header: 'Adjusted From Advance',
    accessor: "adjustedFromAdvance",
  },
  {
    Header: 'Advance Recieved',
    accessor: "advanceRecieved",
  },

  {
    Header: 'Credit',
    accessor: "credit",
  },
  {
    Header: 'Ins Credit',
    accessor: "insCredit",
  },
  {
    Header: 'Corporate Credit',
    accessor: "corporatecredit",
  },
  {
    Header: 'Total-SAR',
    Cell: (cell) => {
      console.log("cell.row.original=>", cell.row)
      let rev_amount = (cell.row.original.rev_amount) && !isNaN(cell.row.original.rev_amount) ? parseFloat(cell.row.original.rev_amount) : 0;
      let rev_vat_amount = (cell.row.original.rev_vat_amount) && !isNaN(cell.row.original.rev_vat_amount) ? parseFloat(cell.row.original.rev_vat_amount) : 0;
      let rev_total_discount = (cell.row.original.rev_total_discount) && !isNaN(cell.row.original.rev_total_discount) ? parseFloat(cell.row.original.rev_total_discount) : 0;
      let rev_discounted_amount = (cell.row.original.rev_discounted_amount) && !isNaN(cell.row.original.rev_discounted_amount) ? parseFloat(cell.row.original.rev_discounted_amount) : 0;
      let totAmt = parseFloat(rev_amount) + parseFloat(rev_vat_amount);
      let discount = parseFloat(rev_total_discount) + parseFloat(rev_discounted_amount);
      let AMT = totAmt - discount;
      return cell.row.original ? (
        <>
          {Math.round(AMT)}
        </>
      ) : (
        <></>
      );
    },
  },
];

export const test_params_rr_lab_columns = ({ onDeleteOpen }) => [
  {
    Header: 'Ref. Range',
    accessor: (d) => capitalise(d.m_reference_ranges_relation?.mrr_name_en),
  },
  {
    Header: "lab Analyzer (Machine)",
    accessor: (d) => capitalise(d.lt_lab_analyzer_relation?.lab_analyzer_name_en),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      return (
        <>
          <button
            className="text-cyan-900 "
            size="default"
            variant="outlined"
            onClick={() => { onDeleteOpen(cell.row.original.tp_rr_lab_id) }}
          >
            Delete
          </button>
        </>
      );
    },
  },

];

export const monthly_revenue_report = () => [
  {
    Header: 'SN.',
    Cell: (cell) => {
      return cell.row.original ? (
        <>
          {parseInt(cell.row.index) + parseInt(1)}
        </>
      ) : (
        <></>
      );
    },
  },
  {
    Header: 'Branch',
    accessor: (d) => capitalise(d.loc_name_en),
  },
  {
    Header: 'Month',
    accessor: (d) => new Date(d.StartDateF).toLocaleString(undefined, { month: 'long' }),
  },
  {
    Header: 'Date',
    accessor: (d) => moment(convertToAnotherTimezone(d.StartDateF)).format('DD-MM-YYYY') + " / " + moment(convertToAnotherTimezone(d.EndDateF)).format('DD-MM-YYYY'),
  },

  {
    Header: 'Total Cash',
    accessor: (d) => (d.locCashCreditAmtRoun ? d.locCashCreditAmtRoun : 0),
  },
  {
    Header: "Total Credit",
    accessor: (d) => (d.locCashCreditAmtRounOT ? d.locCashCreditAmtRounOT : 0),
  },
  {
    Header: 'Total Revenue (Cash + Credit)',
    accessor: (d) => (d.locCashCreditAmtRounOTWithMain ? d.locCashCreditAmtRounOTWithMain : 0),
  },
];

export const qc_lot_test_params_list_columns = ({ updateQCLotTestParamsResult, qcId }) => [
  {
    Header: 'Test Parameter',
    accessor: (d) => capitalise(d.ttp_name_en),
  },
  {
    Header: 'Result',
    accessor: 'qres_value',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {/* {cell.row.original.qres_value ? (
            cell.row.original.qres_value
          ) : ( */}
          <Tippy
            theme="light"
            placement="left"
            trigger="click"
            allowHTML={true}
            interactive={true}
            content={
              <EditTooptip
                fieldName="qres_value"
                CellId={cell.row.original.fk_test_id}
                func={updateQCLotTestParamsResult}
                Nfunc="updateQCLotTestParamsResult"
                lotId={cell.row.original.fk_lot_id}
                qc_data={cell.row.original}

              />
            }
          >
            <button>{cell.row.original.qres_value ? cell.row.original.qres_value : 'Empty'}</button>
          </Tippy>
          {/* )} */}
        </p>
      );
    },
  },
  {
    Header: 'Time',
    accessor: 'qres_time_of_result',
    Cell: (cell) => {
      return (
        <p className="text-sm text-gray-500">
          {/* {cell.row.original.qres_time_of_result ? (
            cell.row.original.qres_time_of_result
          ) : ( */}
          <Tippy
            theme="light"
            placement="left"
            trigger="click"
            allowHTML={true}
            interactive={true}
            content={
              <EditTooptip
                fieldName="qres_time_of_result"
                CellId={cell.row.original.fk_test_id}
                func={updateQCLotTestParamsResult}
                lotId={cell.row.original.fk_lot_id}
                qc_data={cell.row.original}
                inputType="date"
              />
            }
          >
            <button>{cell.row.original.qres_time_of_result ? cell.row.original.qres_time_of_result : 'Empty'}</button>
          </Tippy>
          {/* // )} */}
        </p>
      );
    },
  },
];

export const outsource_clinic_credit_logs_columns = (id) => [
  {
    Header: "Clinic Amount Added",
    accessor: "credit_amount_added"
  },
  {
    Header: "Previous Balance",
    accessor: "prev_balance"
  },
  {
    Header: "Total Updated Balance",
    accessor: "total_updated_balance"
  },
  {
    Header: "Credit Created By",
    accessor: "m_user_relation.user_email"
  },
  {
    Header: "Credit Created At",
    accessor: (d) => d.ot_clog_created_at && moment(convertToAnotherTimezone(d.ot_clog_created_at)).format('MM-DD-YYYY hh:mm A'),
  },
];

export const outsource_clinic_credit_spend_columns = () => [
  {
    Header: "Previous Balance",
    accessor: "prev_balance"
  },
  {
    Header: "Amount Spent",
    accessor: "credit_amount_deducted"
  },
  {
    Header: "Total Updated Balance",
    accessor: "total_updated_balance"
  },
  {
    Header: "Reservation Id",
    accessor: "fk_reservation_id"
  },
  {
    Header: "Credit Log Created By",
    accessor: "m_user_relation.user_email"
  },
  {
    Header: "Credit Log Created At",
    accessor: (d) => d.ot_clog_created_at && moment(convertToAnotherTimezone(d.ot_clog_created_at)).format('MM-DD-YYYY hh:mm A'),
  },
];

export const location_revenue_report_columns = () => [
  {
    Header: 'Date',
    accessor: (d) => moment(convertToAnotherTimezone(d.date)).format('DD-MM-YYYY'),
  },
  {
    Header: 'Cash Collected',
    accessor: 'cash_collected',
  },
  {
    Header: 'Reservation IDs',
    accessor: (d) => d.res_ids.join(", "),
  },
];

export const organism_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Name English",
    accessor: (d) => capitalise(d.org_name_en),
  },
  {
    Header: 'Name Arabic',
    accessor: (d) => capitalise(d.org_name_ar),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.org_id;
      return (
        <>
          <p
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </p>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const antibiotic_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Name English",
    accessor: (d) => capitalise(d.atb_main_name_en),
  },
  {
    Header: 'Name Arabic',
    accessor: (d) => capitalise(d.atb_main_name_ar),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.atb_main_id;
      return (
        <>
          <p
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </p>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const antibiotic_rt_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Name English",
    accessor: (d) => capitalise(d.mtrt_name_en),
  },
  {
    Header: 'Name Arabic',
    accessor: (d) => capitalise(d.mtrt_name_ar),
  },
  {
    Header: 'Action',
    accessor: 'action',
    Cell: (cell) => {
      const cellId = cell.row.original.mtrt_id;
      return (
        <>
          <p
            onClick={() => handleDrawer('edit', cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </p>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];


// CRM Test Package List Start
export const crm_clinic_test_list_columns = ({ openViewModal }) => [
  {
    Header: "ID",
    accessor: "fk_test_id",
  },
  {
    Header: "Type",
    accessor: (d) => (d.tp_type == "T" ? "Test" : "Package"),
  },
  {
    Header: "Name(EN)",
    accessor: (d) => capitalise(d.t_test_relation ? d.t_test_relation?.test_name_en : d.t_package_relation?.package_name_en ),
  },
  {
    Header: "Price",
    accessor: "tp_price",
  },
  {
    Header: "Selling Price",
    accessor: "tp_selling_price",
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: (cell) => {
      console.log("all View data", cell.row.original);
      // Need all tests or test_params here to pass to the Table
      return (
        <button
          className="text-cyan-500 text-sm"
          size="default"
          variant="outlined"
          onClick={(e) => {
            let type = ""
            if(cell.row.original.fk_test_id){
              type = "Test Params List"
            }else{
              type = "Package Test List"
            }
            openViewModal(type == "Test Params List" ? cell.row.original.test_params : cell.row.original.test_list, type)
          }}
        >
          View
        </button>
      );
    },
  },
];

export const crm_lab_test_list_columns = ({openViewModal}) => [
  {
    Header: 'ID',
    accessor: (d) => d.test_id ? d.test_id : d.package_id,
  },
  {
    Header: 'Type',
    accessor: (d) => d.test_id ? "Test" : "Package",
  },
  {
    Header: 'Name(EN)',
    accessor: (d) => capitalise(d.test_name_en ? d.test_name_en : d.package_name_en ),
  },
  {
    Header: 'Price',
    accessor: (d) => d.hos_price ? d.hos_price : d.package_price,
  },
  {
    Header: 'Min Price',
    accessor: (d) => d.test_min_price ? d.test_min_price : d.package_price,
    // accessor: 'test_min_price',
  },
  {
    Header: "Actions",
    accessor: "actions",
    Cell: (cell) => {
      console.log("all View data", cell.row.original);
      // Need all tests or test_params here to pass to the Table
      return (
        <button
          className="text-cyan-500 text-sm"
          size="default"
          variant="outlined"
          onClick={(e) => {
            let type = ""
            if(cell.row.original.test_id){
              type = "Test Params List"
            }else{
              type = "Package Test List"
            }
            openViewModal(type == "Test Params List" ? cell.row.original.test_params : cell.row.original.test_list, type)
          }}
        >
          View
        </button>
      );
    },
  },
];
// CRM Test Package List End

// CRM Sample Processing Start
export const crm_sample__processing_columns = () => [
  {
    Header: 'Patient',
    accessor: (d) => d.buttonFlag ? capitalise(d.patient_first_name) : '',
    isVisible: false
  }, 
  {
    Header: "Res. ID",
    Cell: (cell) => {
      const reservation_id = cell.row.original.reservation_id;
      return (
        <>
          <div>
            {
              cell.row.original.reservation_is_active == true ? (
                <Link
                  onClick={() => {
                    // console.log("PS=>", reservation_id);
                    localStorage.setItem(
                      "edit_booking_reservation_user_details",
                      JSON.stringify(cell.row.original)
                    );
                    localStorage.setItem(
                      "edit_booking_reservation_id",
                      reservation_id
                    );
                    // editReservation(cell.row.original);
                  }}
                  to={`/bookingConfirm`}
                  className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
                >
                  {reservation_id}
                </Link>
              ) : <p>{reservation_id}</p>
            }
          </div>

        </>
      );
    },
  },
  {
    Header: 'Sample ID',
    Cell: (cell) => {
      const sample_id = cell.row.original.sample_id;
      return (
        <>
          <Link
            to={`../samplelist/${sample_id}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            {sample_id}
          </Link>
        </>
      );
    },
  },
  {
    Header: "Test Name",
    accessor: (d) => capitalise(d.test_name_en),
  },
  {
    Header: "Test-Parameter Name",
    accessor: (d) => d.ttp_name_en ? (
      <div className="inline-flex p-2">
        {d.ttp_name_en}
      </div>
    ) : null,
  },
  {
    Header: 'Mobile No.',
    accessor: (d) => capitalise(d.patient_mobile),
  },
  {
    Header: 'MRN',
    accessor: (d) => d?.patient_report_mrn ? d.patient_report_mrn : '-',
  },
  {
    Header: 'Status',
    accessor: (d) => getSampleParameterResultStatus(d.sp_status_name_en, d.test_result[0]?.ssp_value,  d.test_result[0]?.ssp_approved_by ),
  },
];
// CRM Sample Processing End

// CRM Tests and Test Params Columns - View 
export const crm_tests_test_params_columns = ({type}) => [
  {
    Header: 'ID',
    accessor: type == "Package Test List" ? 'test_id' : 'ttp_id',
  },
  {
    Header: 'Name(EN)',
    accessor: (d) => capitalise(d.ttp_id ? d.ttp_name_en : d.test_name_en),
  },
];
// CRM Tests and Test Params Columns - View - End